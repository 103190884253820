import { animationType } from 'config/variables';
import { translations } from 'locales/translations';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createBidReset } from 'store/actions/vehicle-actions';

// custom hook for auction ending animation , when days , hours , minutes and seconds becomes 0 , show a message that "Auction is ending..." for 2 seconds , after that show a message that "Auction has Ended." for 1 second.
export default function useAuctionEnd(days, hours, minutes, seconds, item, isAuctionEnding, removeAdsAuction, endingTime, endedTime, type = animationType.auction) {
  const { t } = useTranslation();
  const [contestEnding, setContestEnding] = useState(false); //state to show alert messages 
  const [endingMessage, setEndingMessage] = useState('');   // ending message like "Auction is ending..." and "Auction had ended." 
  const dispatch = useDispatch();
  const timeoutIDsRef = useRef({}); // Use a ref to store timeout IDs for each item ID.

  useEffect(() => {
    if (isAuctionEnding && parseInt(days) === 0 && parseInt(hours) === 0 && parseInt(minutes) === 0 && parseInt(seconds) === 0 && !contestEnding) {
      setContestEnding(true);

      //this shows message for 3 second.
      if (type == animationType.hotdeal) {
        setEndingMessage(t(translations.ads.HOTDEAL_IS_ENDING));
      } else {
        setEndingMessage(t(translations.ads.AUCTION_IS_ENDING));
      }
      const endingTimeoutID = setTimeout(() => {

        //this shows message for 2 second.
        if (type == animationType.hotdeal) {
          setEndingMessage(t(translations.ads.HOTDEAL_HAS_ENDED));
        } else {
          setEndingMessage(t(translations.ads.AUCTION_HAS_ENDED));
        }
        const cleanupTimeoutID = setTimeout(() => {
          removeAdsAuction(item.id, item?.owner_vehicle?.body_vehicle?.product_type_id ?? null);
          dispatch(createBidReset({}));
          setContestEnding(false);

        }, endedTime);
        timeoutIDsRef.current[item.id] = cleanupTimeoutID;
      }, endingTime);
      timeoutIDsRef.current[item.id] = endingTimeoutID;
    }
    // when user does bid at last moment and timer becomes zero before bid's api response , than clearTimeout , don't show animation , don't remove auction from the list.  
    if (isAuctionEnding === false && parseInt(days) === 0 && parseInt(hours) === 0 && parseInt(minutes) === 0 && parseInt(seconds) === 0) {
      clearTimeout(timeoutIDsRef.current[item.id])
      setEndingMessage('');
      setContestEnding(false);
    }

  }, [seconds, item, isAuctionEnding]);
  //return an ending message  and a boolean(contestEnding) 
  return [contestEnding, endingMessage];
}



