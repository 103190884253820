import { useEffect, useState } from 'react';
import ChatList from './Component/ChatList';
import { Box, Popover } from '@mui/material';
import { useWindowSize } from 'app/Hooks/windowResize';
import { ChatWrapper } from './style/chatStyle';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import ChatMessageScreen from './Component/ChatMessageScreen';
import * as ChatConstants from 'config/chatConfig';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Nullable } from './interface/interface';
import {
  archiveParticularChatSuccess,
  muteAllChatSuccess,
  unreadAllChatSuccess,
  deleteParticularChatSuccess,
  blockParticularChatSuccess,
  muteParticularChatSuccess,
  archiveParticularChatFailure,
  unreadAllChatFailure,
  muteAllChatFailure,
  deleteParticularChatFailure,
  blockParticularChatFailure,
  unreadParticularChatFailure,
  muteParticularChatFailure,
  getChatSuccess,
  getArchivedChatSuccess,
  getBlockedChatSuccess,
  unreadParticularChatSuccess,
  lastMessagesState,
  resetUserList,
} from 'store/actions/chat-action';
import { Toaster } from 'services/Toaster';
import { CountriesDrop1, getSubsidiaryStored } from 'config/variables';
import { useQueryStringParams } from 'app/Hooks/useParams';
import { getSubsidiaryDetailsRequest, privateSwitchRequest } from 'store/actions/company-actions';
import { getCloseNavbar } from 'store/actions/component-actions';
import { getUsersPermissionsRequest } from 'store/actions/user-actions';
import { useHistory, useLocation } from 'react-router-dom';
import { PrivatePusherClient } from 'websocket';
import NavigationBarSmallDevices from 'app/Component2/Layouts/PublicLayout/NavigationHeader/NavigationBarComponent/NavigationBarSmallDevices';
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import PublicLayout from 'app/Component2/Layouts/PublicLayout';
import { resetMyAds } from 'store/actions/myAds-actions';

const ChatScreen = () => {
  const [mobile] = useWindowSize();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [chatId, setChatId] = useState<Nullable<number>>(null);
  const [swipedChat, setSwipedChat] = useState<Nullable<number>>(null);
  const [chatType, setChatType] = useState<number>(ChatConstants.chatType.ALL_MESSAGES);
  const [showInitiateChatModal, setShowInitiateChatModal] = useState<boolean>(false);
  const [showInitiateGroupChatModal, setShowInitiateGroupChatModal] = useState<boolean>(false);
  const {
    chatList,
    chatListAllData,
    muteAllChat,
    unreadAllChat,
    archivedChatList,
    archivedChatListAllData,
    blockedChatList,
    blockedChatListAllData,
    archiveParticularChat,
    deleteParticularChat,
    blockParticularChat,
    muteParticularChat,
    muteAllChatFailureData,
    unreadAllChatFailureData,
    archiveParticularChatFailureData,
    deleteParticularChatFailureData,
    blockParticularChatFailureData,
    unreadParticularChatFailureData,
    muteParticularChatFailureData,
    unreadParticularChat,
    archivedChatLoaded,
    blockedChatLoaded,
    userListError,
  } = useSelector((state: RootStateOrAny) => state.chat);
  const [chatListData, setChatListData] = useState<Array<Object>>([]);
  const [isFixed, _isFixed] = useState(false);
  const [chatListDataWithPagination, setChatListDataWithPagination] = useState<Array<Object>>([]);
  const [selectedChatID, setSelectedChatID] = useState<boolean>(false);
  const { subsidiaryDetails } = useSelector((state: RootStateOrAny) => state.company);
  const subsidiary = getSubsidiaryStored();
  const params = useQueryStringParams();
  const [isSmallDevice, isMediumDevice] = useDeviceType();
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLButtonElement | null>(null);
  const [countryFlag, _countryFlag] = useState<string>('');
  const [userName, _userName] = useState<string>('');
  const { navBarMenu } = useSelector((state: RootStateOrAny) => state.componentReducers);
  const { userData, permissionsLoading } = useSelector((state: RootStateOrAny) => state.user);
  const { logoUrl, subsidiaryName } = useSelector((state: RootStateOrAny) => state.company);
  const { loggedIn, profileUpdatedData, updatedImage, profileUpdated } = useSelector(
    (state: any) => state.auth
  );

  useEffect(() => {
    const name = ChatConstants.nameTemplate(
      subsidiaryDetails,
      subsidiary,
      subsidiaryName,
      profileUpdatedData,
      userData,
      // user
    );
    _userName(name);
  }, [
    subsidiaryDetails,
    subsidiary,
    userData,
    logoUrl,
    subsidiaryName,
    updatedImage,
    profileUpdated,
    profileUpdatedData,
  ]);

  useEffect(() => {
    handleCloseMenu();
  }, [location.pathname]);

  useEffect(() => {
    if (loggedIn) {
      if (subsidiary && subsidiary !== null && subsidiaryDetails?.address_principal?.country_id) {
        getCountry(subsidiaryDetails.address_principal.country_id);
      } else {
        if (!!userData.addresses && userData.addresses.length) {
          getCountry(userData.addresses[0].country_id);
        }
      }
    }
  }, [subsidiaryDetails]);

  const getCountry = (id: number) => {
    CountriesDrop1().then((data) => {
      data.map((country, i) => {
        if (country.id == id) {
          _countryFlag(country?.flag_thumbnail);
        }
      });
    });
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
    dispatch(getCloseNavbar());
  };

  const open = Boolean(anchorElMenu);
  const id = anchorElMenu ? 'simple-popover ' : undefined;

  useEffect(() => {
    // Switch profile on click on notification
    if (params.subsidiary_id && params.chat_id && params.switch === 'true' && params.subName) {
      changeUser(params.subsidiary_id, params.subName);
      return;
    }
    if (params.chat_id && params.switch === 'true' && userData && Object.keys(userData).length > 0) {
      changeUser(null, null);
      return;
    }
  }, [userData]);

  useEffect(() => {
    setChatId(null)
    localStorage.removeItem('chat_id')
  }, [permissionsLoading])

  const removeQueryParams = () => {
    // remove query params as landed on chat page from notification (which includes chat_id and subsidiary_id as query params)
    const queryParams = new URLSearchParams(location.search);
    const chatId = queryParams.get('chat_id');
    const chatType = queryParams.get('chat_type');
    const subsidiaryId = queryParams.get('subsidiary_id');
    const switchParam = queryParams.get('switch');
    const subName = queryParams.get('subName');

    // if getting chat id and subsidiary Id in url then
    if (subName || chatId || chatType || subsidiaryId || switchParam) {
      // remove chat_id && chat_type && subsidiary_id
      queryParams.delete('chat_id');
      queryParams.delete('chat_type');
      queryParams.delete('subsidiary_id');
      queryParams.delete('switch');
      queryParams.delete('subName');
      history.replace({
        search: queryParams.toString(),
      });
    }
  };
  const changeUser = (id, profileName) => {
    const PrivatePusherClientInstance = PrivatePusherClient()
    dispatch(resetMyAds())

    //  if user loggedin as private user
    if (!getSubsidiaryStored() && id) {
      // then update user permissions and switch to subsidiary
      Toaster.success(`${t(translations.CHAT.ACCOUNT_SWITCH_TO_SUBSIDIARY)} ${profileName}`);
      localStorage.setItem('subsidiary', id);
      PrivatePusherClientInstance?.unsubscribe(`private-inbox.user.${userData.id}`); //and subsidiary to subsidiary
      getUsersPermissionsRequest({ subsidiaryId: id });
      getSubsidiaryDetailsRequest({ subsidiary_Id: id, saveSubsidiary: true, subsidiaryId: id });
      // removeQueryParams();
    }
    // if user is loggedin as subsidiary then
    else if (id && getSubsidiaryStored() && id != getSubsidiaryStored()) {
      //  Store or update subsidiary id in localstorage
      localStorage.setItem('subsidiary', id);
      PrivatePusherClientInstance?.unsubscribe(
        `private-inbox.subsidiary.${getSubsidiaryStored()}.user.${userData.id}`
      ); //account switches to private to subsidiary
      PrivatePusherClientInstance?.unsubscribe(`private-inbox.user.${userData.id}`); //and subsidiary to subsidiary
      localStorage.setItem('subsidiary', id);
      // then update subsidiary details and permissions
      getSubsidiaryDetailsRequest({ subsidiary_Id: id, saveSubsidiary: true, subsidiaryId: id });
      getUsersPermissionsRequest({ subsidiaryId: id });
      Toaster.success(`${t(translations.CHAT.ACCOUNT_SWITCH_TO_SUBSIDIARY)} ${profileName}`);
      // removeQueryParams();
    } else if (id && getSubsidiaryStored() && id == getSubsidiaryStored()) {
      //notification for same subsidiary loggedIn
      // removeQueryParams();
      return;
    } else {
      // switching from subsidiary to private user account
      if (getSubsidiaryStored() && !id) {
        PrivatePusherClientInstance?.unsubscribe(
          `private-inbox.subsidiary.${getSubsidiaryStored()}.user.${userData.id}`
        ); //account switches to private to subsidiary
        localStorage.removeItem('subsidiary');
        dispatch(privateSwitchRequest(true));
        getUsersPermissionsRequest({});
        Toaster.success(
          `${t(translations.CHAT.ACCOUNT_SWITCH_TO_SUBSIDIARY)} ${userData.first_name} ${userData.last_name
          }`
        );
      }
      // removeQueryParams();
      return;
    }
  };

  useEffect(() => {
    setChatListData(chatList);
    setChatListDataWithPagination(chatListAllData);
    if (chatType === ChatConstants.chatType.BLOCKED) {
      setChatListData(blockedChatList);
      setChatListDataWithPagination(blockedChatListAllData);
    } else if (chatType === ChatConstants.chatType.ARCHIVED) {
      setChatListData(archivedChatList);
      setChatListDataWithPagination(archivedChatListAllData);
    }
  }, [chatType, chatList, blockedChatList, archivedChatList]);

  useEffect(() => {
    if (params.chat_type) {
      setChatType(parseInt(params.chat_type));
      setChatId(parseInt(params.chat_id));
      if (window.innerWidth > 992 && params.switch !== 'true') {
        removeQueryParams();
      }
    }
  }, []);

  useEffect(() => {
    if (Object.keys(params).length && params.chat_id) {
      let chatIndex = chatList.findIndex((o) => o.id == params.chat_id);
      if (chatIndex !== -1) {
        dispatch(lastMessagesState(chatList[chatIndex]?.messages ?? []));
        setChatId(parseInt(params.chat_id));
      }
    } else if (localStorage.getItem('chat_id') && window.innerWidth < 992) {
      setChatId(JSON.parse(localStorage.getItem('chat_id')));
    }
  }, [
    chatType,
    mobile,
    selectedChatID,
    subsidiary,
    archivedChatLoaded,
    blockedChatLoaded,
    chatList,
  ]);

  useEffect(() => {
    setSelectedChatID(false);
  }, [subsidiary]);

  useEffect(() => {
    localStorage.setItem('chat_id', chatId);
  }, [chatId]);

  // Updates internal states while deleting particular chat
  const removeData = (chatType, id) => {
    // fetching Current state
    let chatListObject = { ...chatListDataWithPagination };
    let chatListArray = [...chatListData];
    let updatedArray = chatListArray.filter((o) => o.id !== id);
    chatListObject.data.data = updatedArray;
    if (updatedArray.length > 0 && !mobile) {
      setChatId(updatedArray[0].id);
    } else {
      setChatId(null);
    }
    updateReduxData(chatType, chatListObject);
  };

  //Updates internal stated when all chats are read/unread or mute/unmute
  const readOrMuteAllChats = (chatType, key, value) => {
    // fetching Current state
    let chatListObject = { ...chatListDataWithPagination };
    let chatListArray = [...chatListData];
    chatListArray.forEach((element) => {
      element[key] = value;
    });
    chatListObject.data.data = chatListArray;
    updateReduxData(chatType, chatListObject);
  };

  // Updates internal states while muting/unmuting particular chat
  const readOrMuteParticularData = (chatType, key, value) => {
    // fetching Current state
    let chatListObject = { ...chatListDataWithPagination };
    let chatListArray = [...chatListData];
    let index = -1;
    if (swipedChat) {
      index = chatListArray.findIndex((o) => o.id === swipedChat);
    } else {
      index = chatListArray.findIndex((o) => o.id === chatId);
    }
    if (index !== -1) {
      chatListArray[index][key] = value;
    }
    if (chatListObject?.data?.data) {
      chatListObject.data.data = chatListArray;
      updateReduxData(chatType, chatListObject);
    }
    setSwipedChat(null);
  };

  // Updates internal states while archiving/unarchiving particular chat
  const archiveUnarchiveData = (archived) => {
    if (mobile) {
      removeQueryParams();
    }
    // fetching Current state
    let chatListObject = { ...chatListDataWithPagination };
    let chatListArray = [];
    if (archived) {
      chatListArray = [...chatList];
    } else {
      chatListArray = [...archivedChatList];
    }
    // Searching index for existence. if found updates the value of is_archived accordingly to the user request
    let index = -1;
    if (swipedChat) {
      index = chatListArray.findIndex((o) => o.id === swipedChat);
    } else {
      index = chatListArray.findIndex((o) => o.id === chatId);
    }
    if (index !== -1) {
      chatListArray[index].is_archived = archived;

      // Runs when Archived data hasn't been loaded
      if (!archivedChatLoaded && archived) {
        chatListArray.splice(index, 1);
        chatListObject.data.data = chatListArray;
        updateReduxData(ChatConstants.chatType.ALL_MESSAGES, chatListObject);
        setChatId(null);
        setSwipedChat(null);
        return;
      }

      // Runs if chat is archived and data has been loaded
      if (archivedChatLoaded && archived) {
        // Fetches archived chat list and adds archived chat to it
        let updateArchivedList = archivedChatListAllData;
        let newArray = [chatListArray[index], ...archivedChatListAllData.data.data];
        updateArchivedList.data.data = newArray.sort(function (a, b) {
          return new Date(b.last_chat_at).getTime() - new Date(a.last_chat_at).getTime();
        });
        // Removes archived chat from all messages chatlist
        chatListArray.splice(index, 1);
        chatListObject.data.data = chatListArray;
        // dispatch functions that updates reduc states
        updateReduxData(ChatConstants.chatType.ARCHIVED, updateArchivedList);
        updateReduxData(ChatConstants.chatType.ALL_MESSAGES, chatListObject);
        setChatId(null);
        setSwipedChat(null);
        return;
      }
      // Runs if chat is unarchived
      // Fetches all messages chatlist and adds unarchived chat to it
      let updateChatList = chatListAllData;
      let newArray = [chatListArray[index], ...chatListAllData.data.data];
      updateChatList.data.data = newArray.sort(function (a, b) {
        return new Date(b.last_chat_at).getTime() - new Date(a.last_chat_at).getTime();
      });
      // Removes unarchived chat from archived chatlist
      chatListArray.splice(index, 1);
      chatListObject.data.data = chatListArray;
      updateReduxData(ChatConstants.chatType.ARCHIVED, chatListObject);
      updateReduxData(ChatConstants.chatType.ALL_MESSAGES, updateChatList);
      setChatId(null);
      setSwipedChat(null);
    }
  };

  // Updates internal states while blocking/unblocking particular chat
  const blockUnblockData = (blocked) => {
    if (mobile) {
      removeQueryParams();
    }
    // fetching Current state
    let chatListObject = { ...chatListDataWithPagination };
    let chatListArray = [];
    if (blocked) {
      chatListArray = [...chatList];
    } else {
      chatListArray = [...blockedChatList];
    }

    // Searching index for existence
    let index = -1;
    if (swipedChat) {
      index = chatListArray.findIndex((o) => o.id === swipedChat);
    } else {
      index = chatListArray.findIndex((o) => o.id === chatId);
    }

    if (index !== -1) {
      // Runs when blocked data hasn't been loaded
      if (!blockedChatLoaded && blocked) {
        chatListArray.splice(index, 1);
        chatListObject.data.data = chatListArray;
        // dispatch functions that updates reduc states
        updateReduxData(ChatConstants.chatType.ALL_MESSAGES, chatListObject);
        setChatId(null);
        setSwipedChat(null);
        return;
      }

      // Runs if chat is blocked and blocked list data hasn't been loaded
      if (blockedChatLoaded && blocked) {
        // Fetches blocked chat list and adds blocked chat to it
        let updateblockedList = blockedChatListAllData;
        let newArray = [chatListArray[index], ...blockedChatListAllData.data.data];
        updateblockedList.data.data = newArray.sort(function (a, b) {
          return new Date(b.last_chat_at).getTime() - new Date(a.last_chat_at).getTime();
        });
        // Removes blocked chat from all messages chatlist
        chatListArray.splice(index, 1);
        chatListObject.data.data = chatListArray;
        // dispatch functions that updates reduc states
        updateReduxData(ChatConstants.chatType.BLOCKED, updateblockedList);
        updateReduxData(ChatConstants.chatType.ALL_MESSAGES, chatListObject);
        setChatId(null);
        setSwipedChat(null);
        return;
      }
      // Runs if chat is unblocked
      // Fetches all messages chatlist and adds unblocked chat to it
      let updateChatList = chatListAllData;
      let newArray = [chatListArray[index], ...chatListAllData.data.data];
      updateChatList.data.data = newArray.sort(function (a, b) {
        return new Date(b.last_chat_at).getTime() - new Date(a.last_chat_at).getTime();
      });
      // Removes unblocked chat from blocked chatlist
      chatListArray.splice(index, 1);
      chatListObject.data.data = chatListArray;
      updateReduxData(ChatConstants.chatType.BLOCKED, chatListObject);
      updateReduxData(ChatConstants.chatType.ALL_MESSAGES, updateChatList);
      setChatId(null);
      setSwipedChat(null);
    }
  };

  // Updates redux states with latest changes in data
  const updateReduxData = (chatType, data) => {
    if (chatType === ChatConstants.chatType.ALL_MESSAGES) {
      dispatch(getChatSuccess({ data: data, append: false }));
    } else if (chatType === ChatConstants.chatType.ARCHIVED) {
      dispatch(getArchivedChatSuccess({ data: data, append: false }));
    } else if (chatType === ChatConstants.chatType.BLOCKED) {
      dispatch(getBlockedChatSuccess({ data: data, append: false }));
    }
  };

  // ----------------------------------- Success Handling -----------------------------------
  useEffect(() => {
    // Archive particular chat success handling
    if (archiveParticularChat && Object.keys(archiveParticularChat).length > 0) {
      archiveUnarchiveData(archiveParticularChat.data.is_archived);
      Toaster.success(archiveParticularChat.data.message);
      dispatch(archiveParticularChatSuccess({}));
    }

    // Block particular chat success handling
    if (blockParticularChat && Object.keys(blockParticularChat).length > 0) {
      blockUnblockData(blockParticularChat.data.is_blocked);
      Toaster.success(blockParticularChat.data.message);
      dispatch(blockParticularChatSuccess({}));
    }

    // Mute All chat success handling
    if (muteAllChat && Object.keys(muteAllChat).length > 0) {
      readOrMuteAllChats(chatType, 'is_muted', muteAllChat.data.is_muted);
      // muteUnmuteAllChats(chatType,muteAllChat.data.is_muted)
      Toaster.success(muteAllChat.data.message);
      dispatch(muteAllChatSuccess({}));
    }

    // Unread all chat success handling
    if (unreadAllChat && Object.keys(unreadAllChat).length > 0) {
      readOrMuteAllChats(chatType, 'is_read', unreadAllChat.data.is_read);
      Toaster.success(unreadAllChat.data.message);
      dispatch(unreadAllChatSuccess({}));
    }

    // delete particular chat success handling
    if (deleteParticularChat && Object.keys(deleteParticularChat).length > 0) {
      Toaster.success(deleteParticularChat.data.message);
      removeData(chatType, chatId);
      dispatch(deleteParticularChatSuccess({}));
    }

    // Mute particular chat success handling
    if (muteParticularChat && Object.keys(muteParticularChat).length > 0) {
      readOrMuteParticularData(chatType, 'is_muted', muteParticularChat.data.is_muted);
      Toaster.success(muteParticularChat.data.message);
      dispatch(muteParticularChatSuccess({}));
    }

    // Unread particular chat success handling
    if (unreadParticularChat && Object.keys(unreadParticularChat).length > 0) {
      readOrMuteParticularData(chatType, 'is_read', unreadParticularChat.data.is_read);
      dispatch(unreadParticularChatSuccess({}));
    }
  }, [
    muteParticularChat,
    archiveParticularChat,
    muteAllChat,
    unreadAllChat,
    deleteParticularChat,
    blockParticularChat,
    unreadParticularChat,
  ]);

  // ----------------------------------- Error Handling -----------------------------------

  useEffect(() => {
    // Archive particular chat Error message display
    if (
      archiveParticularChatFailureData &&
      Object.keys(archiveParticularChatFailureData).length > 0
    ) {
      Toaster.error(archiveParticularChatFailureData.message);
      dispatch(archiveParticularChatFailure({}));
    }

    // Unread all chat Error message display
    if (unreadAllChatFailureData && Object.keys(unreadAllChatFailureData).length > 0) {
      Toaster.error(unreadAllChatFailureData.message);
      dispatch(unreadAllChatFailure({}));
    }

    // Mute All chat Error message display
    if (muteAllChatFailureData && Object.keys(muteAllChatFailureData).length > 0) {
      Toaster.error(muteAllChatFailureData.message);
      dispatch(muteAllChatFailure({}));
    }

    // Delete particular chat Error message display
    if (
      deleteParticularChatFailureData &&
      Object.keys(deleteParticularChatFailureData).length > 0
    ) {
      Toaster.error(deleteParticularChatFailureData.message);
      dispatch(deleteParticularChatFailure({}));
    }

    // Block particular chat Error message display
    if (blockParticularChatFailureData && Object.keys(blockParticularChatFailureData).length > 0) {
      Toaster.error(blockParticularChatFailureData.message);
      dispatch(blockParticularChatFailure({}));
    }

    // Unread particular chat Error message display
    if (
      unreadParticularChatFailureData !== undefined &&
      Object.keys(unreadParticularChatFailureData).length > 0
    ) {
      Toaster.error(unreadParticularChatFailureData.message);
      dispatch(unreadParticularChatFailure({}));
    }

    // Mute particular chat Error message display
    if (muteParticularChatFailureData && Object.keys(muteParticularChatFailureData).length > 0) {
      Toaster.error(muteParticularChatFailureData.message);
      dispatch(muteParticularChatFailure({}));
    }

    if (userListError && Object.keys(userListError).length > 0) {
      Toaster.error(userListError.message)
      dispatch(resetUserList({}))
    }
  }, [
    unreadParticularChatFailureData,
    muteParticularChatFailureData,
    blockParticularChatFailureData,
    archiveParticularChatFailureData,
    unreadAllChatFailureData,
    muteAllChatFailureData,
    deleteParticularChatFailureData,
    userListError
  ]);

  return (
    <PublicLayout
      showNavbar={true}
      hideFooter={true}
      hideHeader={isSmallDevice || isMediumDevice ? true : false}
      chatId={chatId}
    >
      {(isSmallDevice || isMediumDevice) && (
        <Popover
          id={id}
          open={navBarMenu}
          anchorEl={anchorElMenu}
          onClose={() => handleCloseMenu()}
          anchorPosition={{ top: 0, left: 0 }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className="menuPopover"
          sx={{
            '.MuiPaper-elevation': {
              top: '0 !important',
              left: '0 !important',
              borderRadius: '0px',
              maxWidth: '100% !important',
              maxHeight: '100% !important',
            },
          }}
        >
          <NavigationBarSmallDevices
            handleCloseMenu={handleCloseMenu}
            userName={userName}
            countryFlag={countryFlag}
          />
        </Popover>
      )}
      <ChatWrapper>
        {!mobile || (!showInitiateChatModal && !chatId) ? (
          <Box
            className="screenHeader"
            sx={{
              '.mobile-header': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
            <span className={mobile ? 'mobile-header' : ''}>{t(translations.CHAT.CHATS)}</span>
          </Box>
        ) : (
          ''
        )}

        {window.innerWidth < 992 ? (
          <Box sx={{ width: '100%' }}>
            {chatId ? (
              <ChatMessageScreen
                showInitiateGroupChatModal={showInitiateGroupChatModal}
                setShowInitiateGroupChatModal={setShowInitiateGroupChatModal}
                chatId={chatId}
                setChatId={setChatId}
                chatType={chatType}
              />
            ) : (
              <ChatList
                chatId={chatId}
                setChatId={setChatId}
                chatType={chatType}
                setChatType={setChatType}
                showInitiateChatModal={showInitiateChatModal}
                setShowInitiateChatModal={setShowInitiateChatModal}
                drawer={false}
                chatListData={chatListData}
                setSwipedChat={setSwipedChat}
                setSelectedChatID={setSelectedChatID}
              />
            )}
          </Box>
        ) : (
          <Box className="chatScreenContentBox">
            <Box className="chatListContentBox">
              <ChatList
                chatId={chatId}
                setChatId={setChatId}
                chatType={chatType}
                setChatType={setChatType}
                showInitiateGroupChatModal={showInitiateGroupChatModal}
                setShowInitiateGroupChatModal={setShowInitiateGroupChatModal}
                showInitiateChatModal={showInitiateChatModal}
                setShowInitiateChatModal={setShowInitiateChatModal}
                drawer={false}
                chatListData={chatListData}
                setSelectedChatID={setSelectedChatID}
              />
            </Box>
            <Box className="chatMessageContentBox">
              <ChatMessageScreen
                showInitiateGroupChatModal={showInitiateGroupChatModal}
                setShowInitiateGroupChatModal={setShowInitiateGroupChatModal}
                chatId={chatId}
                setChatId={setChatId}
                chatType={chatType}
                key={chatId}
              />
            </Box>
          </Box>
        )}
      </ChatWrapper>
    </PublicLayout>
  );
};

export default ChatScreen;
