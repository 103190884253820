import { Box } from '@mui/material'
import MobileMenu from 'app/Component2/Layouts/PublicLayout/NavigationHeader/MobileMenu'
import { SvgArrowBack, SvgSiteLogo } from 'app/components/svgicons/svgNew'
import { Link } from 'react-router-dom'
import { routes } from 'routes/routes'

const Header = ({ handleGoBack }) => {

    return (
        <Box className='flexRow justifyContentBetween alignItemsCenter header'>
            <SvgArrowBack onClick={handleGoBack} />
            <Link to={routes.vehicleRoutes.adsHome} style={{ marginLeft: '10px' }}>
                <SvgSiteLogo style={{ width: '200px', height: '50px' }} />
            </Link>
            <MobileMenu />
        </Box>
    )
}

export default Header