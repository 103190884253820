import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Button, CircularProgress, FormHelperText, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { loginRequest, resendEmailRequest } from 'store/actions/auth-actions';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Strings, EmailValidationRegex, getTokenStored } from 'config/variables';
import { routes } from 'routes/routes';
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader';
import { Box } from '@mui/material';
import { SvgEye, SvgEyeClosed, SvgMobileLeftArrow, SvgSiteLogo } from 'app/components/svgicons/svgNew';
import { colorNew } from 'styles/StyleConstants';
import AuthLayout from 'app/Component2/Layouts/AuthLayout/AuthLayout';
import TopBar from 'app/Component2/Layouts/AdsLayouts/component/TopBar';
import { translations } from 'locales/translations';
import moment from 'moment';

const Features = (props: any) => {

  // State, props, constants and other variables
  const [email, _email] = useState<string>('');
  const [password, _password] = useState<string>('');
  const [emailError, _emailError] = useState<string>('');
  const [passwordError, _passwordError] = useState<string>('');
  const [confirmationMessage, _confirmationMessage] = useState<string>('');
  const [emailVerified, _emailVerified] = useState<boolean>(true);
  const [mssgColor, _mssgColor] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>('password');

  // Hookes
  const history = useHistory()
  const isMounted = useRef(false);
  const inputRef: any = useRef(null);
  const caretPositionRef: any = useRef(3);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //Redux State
  const { loginError, emailResendSuccess, loading, loggedIn, } = useSelector((state: RootStateOrAny) => state.auth)

  //props
  const { isHeader, setOpenLogin } = props;

  // Hooks and effects
  const handleClickShowPassword = useCallback(() => {
    setShowPassword((show) => !show);
    if (inputType === 'password' || inputType === 'text') {
      caretPositionRef.current = inputRef.current.selectionStart;
    }

    setInputType(currentType => currentType === 'text' ? 'password' : 'text');

    if (inputType === 'password' || inputType === 'text') {
      setTimeout(() => {
        inputRef?.current?.setSelectionRange(caretPositionRef.current, caretPositionRef.current);
        inputRef?.current?.focus();
      }, 0);
    }
  }, [inputType]);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isMounted.current) {
      if (Object.keys(loginError).length > 0) {
        const { data, message } = loginError;

        if (data) {
          const { is_verified, email, password, item } = data;
          if ('is_verified' in data) {
            _emailVerified(is_verified)
            if (!is_verified)
              _confirmationMessage(message);
          }

          if (email) {
            _emailError(email[0]);
            return
          }
          if (password) {
            _passwordError(password[0])
            return
          }
          if (item?.item) {
            _confirmationMessage(t(translations.LOGIN_FORM.CENSOR_VALIDATION_ERROR, { time: moment(item?.item).local().format('DD-MM-YYYY HH:mm:ss') }));
            return
          }
          if (message) {
            _confirmationMessage(message);
            return
          }
        }

        if (message) {
          _confirmationMessage(message);
        }
      }
    } else {
      isMounted.current = true;
    }
  }, [loginError]);


  useEffect(() => {
    setTimeout(() => {
      sessionStorage.removeItem("changeEmail");
    }, 30000);
  }, [])

  useEffect(() => {
    if (Object.keys(emailResendSuccess).length) {
      if ('message' in emailResendSuccess) {
        _confirmationMessage(emailResendSuccess.message);
        _emailVerified(true);
      }
    }
  }, [emailResendSuccess]);

  // Methods
  const _handleSubmit = (e) => {
    _mssgColor(false)
    if (getTokenStored() != null) {
      window.location.reload();
    } else {
      e.preventDefault();
      _emailError('');
      _passwordError('');
      _confirmationMessage("");
      _emailVerified(true)
      email.trim() == '' ? _emailError(Strings.required) : _emailError('');
      password.trim() == '' ? _passwordError(Strings.required) : _passwordError('');
      if (email.trim() == '' || password.trim() == '') return false;
      if (!EmailValidationRegex.email.test(email) && email) {
        _emailError(Strings.email);
        return false;
      }
      dispatch(loginRequest({ email: email.trim(), password: password.trim() }))
    }
  };

  useEffect(() => {
    if (loggedIn && Object.keys(loginError).length < 1 && isHeader) {
      setOpenLogin(null)
    }
  }, [loggedIn])

  useEffect(() => {
    const loginButton = document.getElementById("myButton") as HTMLElement
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter" || event.keyCode === 13) {
        event.preventDefault();
        loginButton?.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const resendLink = () => {
    _emailVerified(true)
    if (!emailVerified) {
      dispatch(resendEmailRequest({ email }))

    }
    _mssgColor(true)
  }

  const handleChangePass = (e) => {
    _password(e.target.value)
  }


  return (

    <AuthLayout
      title={'Login Page'}
      isHeader={isHeader}
    >
      {loading && !isHeader && <ContentLoader />}
      <Box
        className="authForm"
        sx={{
          margin: '0px',
          p: {
            md: !isHeader && 3,
            xs: isHeader ? 1 : '0 8px'
          },
          '.topBar': {
            background: 'rgb(249, 250, 251) !important'
          }
        }}
      >
        <TopBar
          icons={<SvgMobileLeftArrow onClick={() => history.goBack()} />}
          title={<Link to={routes.vehicleRoutes.adsHome} style={{ marginLeft: '10px' }}>
            <SvgSiteLogo style={{ width: '200px', height: '50px' }} />
          </Link>}
        />
        <div className="authform-inner">
          <Box>
            <Box
              sx={{
                marginBottom: {
                  md: 4,
                  xs: 2
                }
              }}
            >
              {isHeader ?
                <Box sx={{
                  borderBottom: '1px solid #e6e6e6',
                  marginBottom: '10px',
                  paddingBottom: '10px',
                  'span': {
                    fontSize: '21px',
                    fontWeight: 500,
                    color: '#000',
                  }
                }}>
                  <span> {t(translations.LOGIN_FORM.LOGIN_REQUIRED)}</span>
                </Box>
                : <Typography className="auth-title" variant='h1'>{t(translations.authScreens.SIGNIN_YOUR_ACCOUNT)}</Typography>}
              {isHeader && <span className='sign_in'>{t(translations.authScreens.SIGNIN_YOUR_ACCOUNT)}</span>}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography className='not-account'>{t(translations.authScreens.DONT_HAVE_ACOUNT)} <Typography component={Link} to={routes.authRoutes.register} className='join-here'>{t(translations.authScreens.JOIN_HERE)}</Typography></Typography>

              </Box>
            </Box>
            <Box className="input-wrapper">
              <TextField id="outlined-basic" label={t(translations.formText.Email)} variant="outlined" value={email}
                onChange={e => _email((e.target.value).replace(/\s/g, ''))}
                InputProps={{ className: "textBgWhite" }}
                sx={{ width: '100%' }}
              />
              <span className="error">{emailError}</span>
            </Box>
            <Box className="input-wrapper" sx={{
              mb: '0 !important'
            }}>
              <TextField id="outlined-basic" label={t(translations.formText.Password)} variant="outlined" value={password}
                type={inputType}
                inputRef={inputRef}
                onChange={e => handleChangePass(e)}
                sx={{
                  width: '100%'
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <SvgEye /> : <SvgEyeClosed />}
                      </IconButton>
                    </InputAdornment>
                }}
              />
              <span className="error">{passwordError}</span>
            </Box>
            <FormHelperText error={true} style={{ color: emailResendSuccess?.success && mssgColor ? '#31bf35' : '' }}>
              {emailVerified ?
                confirmationMessage :
                <Box sx={{
                  color: "#d32f2f"
                }} onClick={() => resendLink()}>
                  {confirmationMessage}
                  <span onClick={() => resendLink()}>{t(translations.ads.CLICK_HERE)}</span>
                  <span style={{ color: colorNew.primaryColor, textDecoration: 'none', cursor: 'pointer', textTransform: 'lowercase' }}>{` ${t(translations.authScreens.TO_RESEND)}`}.</span>
                </Box>}
            </FormHelperText>

            <Button id='myButton' onClick={(e) => _handleSubmit(e)} sx={{ my: 2 }} className="primary-btn">
              {loading ? <CircularProgress color='inherit' size='20px' sx={{ margin: 1 }} /> : t(translations.authScreens.LOGIN)}
            </Button>

            <Box className='forgot-password'>
              <Link to={routes.authRoutes.forgotPassword}>
                <>
                  {t(translations.formText.FORGOTPASSWORD)}
                </>
              </Link>
            </Box>
          </Box >
        </div >
      </Box >
    </AuthLayout >
  );
};


export default Features;