import { localstorageKey } from 'config/constants';
import { productTypes, setCookie } from 'config/variables';
import { put, takeEvery } from 'redux-saga/effects';
import { routes } from 'routes/routes';
import { getUserDataSuccess } from 'store/actions/user-actions';
import {
  loginRequest,
  loginFailure,
  loginSuccess,
  logoutRequest,
  logoutFailure,
  logoutSuccess,
  registerRequest,
  registerFailure,
  registerSuccess,
  activateAccountRequest,
  activateAccountFailure,
  activateAccountSuccess,
  forgotRequest,
  forgotFailure,
  forgotSuccess,
  resetPasswordRequest,
  resetPasswordFailure,
  resetPasswordSuccess,
  resendEmailRequest,
  resendEmailSuccess,
  resendEmailFailure,
  verifyIdRequest,
  verifyIdSuccess,
  verifyIdFailure,
  verifiedIdentityRequest,
  verifiedIdentitySuccess,
  verifiedIdentityFailure,
  getActiveLanguageSuccess,
  getActiveLanguageFailure,
  getActiveLanguageRequest,
  changeLanguageSuccess,
  changeLanguageFailure,
  changeLanguageRequest,
  saveDeviceTokenRequest,
  removeDeviceTokenRequest,
  saveDeviceTokenSuccess,
  saveDeviceTokenFailure,
  removeDeviceTokenSuccess,
  removeDeviceTokenFailure,
  getFiltersRequest,
  getFiltersSuccess,
  getFiltersFailure
} from '../actions/auth-actions';
import { ApiPaths } from './../../services/ApiPaths';
import Api from './../../services/axios';
import { getBikeFilterSuccess, getCarFilterSuccess, getMotorCycleFilterSuccess, getStepFilterSuccess, getVehicleTypeSuccess, removeLoading, setLoading } from 'store/actions/vehicle-actions';
const getAuth = (state) => state.auth

export const saveTokenToLocal = (params: any) => {
  localStorage.setItem('token', params.access_token);
  localStorage.setItem('language_id', params.language_id);
}

export const getdeviceTokenStored = () => {
  return localStorage.getItem('deviceToken');
}

export const removeDataFromLocal = () => {
  let keysToRemove = ["token", "user", "subsidiary", "activeSubsidiary", "deviceToken", "reconnectCount", "reconnectCountForPresenceChannel", "chatScrollPosition", "chat_id", 'bidHidePop', 'notificationTokenSaved'];
  keysToRemove.forEach(k =>
    localStorage.removeItem(k))
}

export const saveUserDataToLocal = (params: any) => {
  localStorage.setItem('user', JSON.stringify(params));
}

function* _loginRequest({ payload }) {
  try {
    const uri = ApiPaths.auth.login
    let deviceToken = yield getdeviceTokenStored();
    if (deviceToken != null) {
      payload["device_id"] = JSON.parse(deviceToken);
      payload["device_type"] = "web";
    }
    const { data } = yield Api.post(uri, payload);
    localStorage.removeItem(localstorageKey.sliderData);
    localStorage.removeItem(localstorageKey.slidersFetched);
    yield saveTokenToLocal(data);
    let userData = yield getUserRequest();
    yield saveUserDataToLocal(userData.data.user);
    yield put(getUserDataSuccess(userData))
    yield put(loginSuccess(userData.data));
  } catch (e) {
    yield put(loginFailure(e.response));
  }
}

function* _logoutRequest() {
  try {
    let uri = ApiPaths.auth.logout
    let deviceToken = yield getdeviceTokenStored();
    if (deviceToken != null) {
      uri += '?device_id=' + JSON.parse(deviceToken);
    }
    const { data } = yield Api.get(uri);
    yield removeDataFromLocal();
    yield put(logoutSuccess(data));
    const getCurrentLang = localStorage.getItem(localstorageKey.i18nextLng);
    if (getCurrentLang && getCurrentLang !== null) {
      window.location.href = '/' + getCurrentLang + routes.authRoutes.login;
    } else {
      window.location.href = '/fr' + routes.authRoutes.login;
    }
  } catch (e) {
    yield put(logoutFailure(e.response));
  }
}

function* _registerRequest({ payload }) {
  try {
    const uri = ApiPaths.auth.register
    const { data } = yield Api.post(uri, {
      "email": payload.email,
      "password": payload.password,
      "country_id": payload.countryId,
      "phone": payload.phone,
      "language_id": payload.languageId,
      "g-recaptcha-response": payload.capKeyToken
    });
    yield put(registerSuccess());
  } catch (e) {
    yield put(registerFailure(e.response.data));
  }
}

function* _activateAccountRequest({ payload }) {
  try {
    const uri = ApiPaths.auth.activateAccount + "/" + payload.id + "/" + payload.hash;
    const { data } = yield Api.get(uri, {
      params: {
        email: payload.email,
        expires: payload.expires,
        signature: payload.signature
      }
    });
    yield put(activateAccountSuccess(data));
  } catch (e) {
    yield put(activateAccountFailure());
  }
}

function* _forgotRequest({ payload }) {
  try {
    const uri = ApiPaths.auth.sendPasswordResetEmail;
    const { data } = yield Api.post(uri, payload);
    yield put(forgotSuccess(data));
  } catch (e) {
    yield put(forgotFailure(e.response.data));
  }
}

function* _resetPasswordRequest({ payload }) {
  try {
    const uri = ApiPaths.auth.resetPassword;
    const { data } = yield Api.post(uri, {
      "email": payload.email,
      "password": payload.password,
      "password_confirmation": payload.confirmPassword,
      "token": payload.token
    });
    yield put(resetPasswordSuccess());
  } catch (e) {
    yield put(resetPasswordFailure(e.response.data));
  }
}



function* getUserRequest() {
  try {
    const uri = ApiPaths.auth.getUser;
    const { data } = yield Api.get(uri);
    return data;
  } catch (e) {
  }
}

function* _resendEmailRequest({ payload }) {
  try {
    const uri = ApiPaths.auth.resendMail;
    const { data } = yield Api.post(uri, payload);
    const row = data;
    yield put(resendEmailSuccess(row));
  } catch (e) {
    yield put(resendEmailFailure(e.response));
  }
}

function* _verifyIdRequest() {
  try {
    const uri = ApiPaths.auth.getUser + ApiPaths.auth.verifiedIdentity;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(verifyIdSuccess(row));
  } catch (e) {
    yield put(verifyIdFailure(e.response));
  }
}

function* _verifiedIdentityRequest({ payload }) {
  try {
    const uri = ApiPaths.auth.getUser + ApiPaths.auth.verifyids;
    const { data } = yield Api.post(uri, payload);
    const row = data.data
    yield put(verifiedIdentitySuccess(row));
  } catch (e) {
    yield put(verifiedIdentityFailure(e.response));
  }
}

function* _getActiveLanguageRequest({ payload }) {
  try {
    let uri = ApiPaths.auth.languages;
    const { data } = yield Api.get(uri, {});
    const row = data
    yield put(getActiveLanguageSuccess(row));
  } catch (e) {
    yield put(getActiveLanguageFailure(e.response));
  }
}

function* _changeLanguageRequest({ payload }) {
  try {
    let uri = ApiPaths.auth.getUser + ApiPaths.auth.changeLanguage + "/" + payload.language_id;
    const { data } = yield Api.post(uri, {});
    const row = data
    yield put(changeLanguageSuccess(payload.language_id));
  } catch (e) {
    yield put(changeLanguageFailure(e.response));
  }
}

function* _saveDeviceTokenRequest({ payload }) {
  try {
    let uri = ApiPaths.auth.getUsers + ApiPaths.auth.addDeviceToke + "?_method=PATCH";
    const { data } = yield Api.post(uri, payload);
    localStorage.setItem('notificationTokenSaved', "true")
    const row = data
    yield put(saveDeviceTokenSuccess());
  } catch (e) {
    yield put(saveDeviceTokenFailure(e.response));
  }
}

function* _removeDeviceTokenRequest({ payload }) {
  try {
    let uri = ApiPaths.auth.getUsers + ApiPaths.auth.removeDeviceToke + "?_method=PATCH";
    const { data } = yield Api.post(uri, payload);
    localStorage.removeItem('deviceToken')
    const row = data
    yield put(removeDeviceTokenSuccess());
  } catch (e) {
    yield put(removeDeviceTokenFailure(e.response));
  }
}

function* _getFiltersRequest({ payload }) {
  yield put(setLoading());
  try {
    let uri = ApiPaths.public.filters;

    const { data } = yield Api.get(uri);
    const row = data.data
    if (row.common_data) {
      localStorage.setItem(localstorageKey.countries, JSON.stringify(row.common_data?.countries ?? []))
      localStorage.setItem(localstorageKey.groupedCountries, JSON.stringify(row.common_data?.groupedCountries ?? {}))
      localStorage.setItem(localstorageKey.vehicleTypeData, JSON.stringify(row.common_data.product_types ?? {}))
      localStorage.setItem(localstorageKey.contactSubjects, JSON.stringify(row.common_data.contact_subjects ?? {}))
      if (row.common_data.product_types) {
        yield put(getVehicleTypeSuccess(row.common_data.product_types ?? {}))
      }
      if (row.common_data.languages) {
        yield put(getActiveLanguageSuccess({ languages: row.common_data.languages }))
        localStorage.setItem(localstorageKey.activeLanguages, JSON.stringify({ languages: row.common_data.languages }))
      }
    }
    if (row.vehicle_filters) {
      let getLanguaget = localStorage.getItem(localstorageKey.i18nextLng);
      let laguagesVariable;
      if (getLanguaget === 'en-GB' || getLanguaget === 'us' || getLanguaget === 'en') {
        laguagesVariable = 'en'
      } else if (getLanguaget === 'fr') {
        laguagesVariable = 'fr'
      } else {
        laguagesVariable = getLanguaget ? getLanguaget : 'en'
      }
      let carFilters = row.vehicle_filters[productTypes.car] ? {
        lang: laguagesVariable,
        ...row.vehicle_filters[productTypes.car]
      } : {}
      localStorage.setItem(localstorageKey.carFilterdata, JSON.stringify(carFilters))

      if (row?.backlink && Object.keys(row.backlink).length)
        localStorage.setItem(localstorageKey.backlinks, JSON.stringify(row.backlink))

      if (row?.purchase_block_time)
        localStorage.setItem(localstorageKey.purchaseBlockTime, JSON.stringify(row.purchase_block_time))

      if (row?.hotdeal_payment_release_delay_days)
        localStorage.setItem(localstorageKey.hotdealPaymentReleaseDelayDays, JSON.stringify(row.hotdeal_payment_release_delay_days))

      yield put(getCarFilterSuccess(carFilters))

      localStorage.setItem(localstorageKey.motorCycleFilterdata, JSON.stringify(row.vehicle_filters[productTypes.motorcycles] ?? {}))
      yield put(getMotorCycleFilterSuccess(row.vehicle_filters[productTypes.motorcycles] ?? {}))

      localStorage.setItem(localstorageKey.stepFilterdata, JSON.stringify(row.vehicle_filters[productTypes.step] ?? {}))
      yield put(getStepFilterSuccess(row.vehicle_filters[productTypes.step] ?? {}))

      localStorage.setItem(localstorageKey.bikeFilterdata, JSON.stringify(row.vehicle_filters[productTypes.bike] ?? {}))
      yield put(getBikeFilterSuccess(row.vehicle_filters[productTypes.bike] ?? {}))
    }
    localStorage.setItem(localstorageKey.deleteFilterData, JSON.stringify(payload.time))
    yield put(removeLoading());
  } catch (e) {
    yield put(getFiltersFailure(e.response));
    yield put(removeLoading());
  }
}

export const authSagas = [
  takeEvery(loginRequest, _loginRequest),
  takeEvery(logoutRequest, _logoutRequest),
  takeEvery(registerRequest, _registerRequest),
  takeEvery(activateAccountRequest, _activateAccountRequest),
  takeEvery(forgotRequest, _forgotRequest),
  takeEvery(resetPasswordRequest, _resetPasswordRequest),
  takeEvery(resendEmailRequest, _resendEmailRequest),
  takeEvery(verifiedIdentityRequest, _verifiedIdentityRequest),
  takeEvery(verifyIdRequest, _verifyIdRequest),
  takeEvery(getActiveLanguageRequest, _getActiveLanguageRequest),
  takeEvery(changeLanguageRequest, _changeLanguageRequest),
  takeEvery(saveDeviceTokenRequest, _saveDeviceTokenRequest),
  takeEvery(removeDeviceTokenRequest, _removeDeviceTokenRequest),
  takeEvery(getFiltersRequest, _getFiltersRequest)
];