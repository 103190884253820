import { handleActions } from 'redux-actions';
import {
  checkVatRequest,
  checkVatSuccess,
  checkVatFailure,
  checkSlugRequest,
  checkSlugSuccess,
  checkSlugFailure,
  createParentRequest,
  createParentSuccess,
  createParentFailure,
  createParentReset,
  createCompanyRequest,
  createCompanySuccess,
  createCompanyFailure,
  getSubsidiaryDetailsRequest,
  getSubsidiaryDetailsSuccess,
  getSubsidiaryDetailsFailure,
  createRoleRequest,
  createRoleSuccess,
  createRoleFailure,
  getRoleDetailsRequest,
  getRoleDetailsSuccess,
  getRoleDetailsFailure,
  editRoleRequest,
  editRoleSuccess,
  editRoleFailure,
  deleteRoleRequest,
  deleteRoleSuccess,
  deleteRoleFailure,
  getRolesRequest,
  getRolesSuccess,
  getRolesFailure,
  getUserSubsidiaryRequest,
  getUserSubsidiarySuccess,
  getUserSubsidiaryFailure,
  getSubscriptionPlansRequest,
  getSubscriptionPlansSuccess,
  getSubscriptionPlansFailure,
  getSubscriptionPlanDetailsRequest,
  getSubscriptionPlanDetailsSuccess,
  getSubscriptionPlanDetailsFailure,
  getFeaturesRequest,
  getFeaturesSuccess,
  getFeaturesFailure,
  getPermissionsRequest,
  getPermissionsSuccess,
  getPermissionsFailure,
  createSubscriptionRequest,
  createSubscriptionSuccess,
  createSubscriptionFailure,
  createSubscriptionReset,
  getSubsidiarySubscriptionStatusRequest,
  getSubsidiarySubscriptionStatusSuccess,
  getSubsidiarySubscriptionStatusFailure,
  getMembersRequest,
  getMembersSuccess,
  getMembersFailure,
  changeStatusSubsidiaryMemberRequest,
  changeStatusSubsidiaryMemberSuccess,
  changeStatusSubsidiaryMemberFailure,
  deleteSubsidiaryMemberRequest,
  deleteSubsidiaryMemberSuccess,
  deleteSubsidiaryMemberFailure,
  editSubsidiaryMemberRequest,
  editSubsidiaryMemberSuccess,
  editSubsidiaryMemberReset,
  editSubsidiaryMemberFailure,
  updateSubsidiaryMemberRolesRequest,
  updateSubsidiaryMemberRolesSuccess,
  updateSubsidiaryMemberRolesFailure,
  getSuggestedMembersRequest,
  getSuggestedMembersSuccess,
  getSuggestedMembersFailure,
  addSubsidiaryMembersRequest,
  addSubsidiaryMembersSuccess,
  addSubsidiaryMembersFailure,
  getDepartmentsRequest,
  getDepartmentsSuccess,
  getDepartmentsFailure,
  getSubsidiaryDepartmentsRequest,
  getSubsidiaryDepartmentsSuccess,
  getSubsidiaryDepartmentsFailure,
  addSubsidiaryDepartmentsRequest,
  addSubsidiaryDepartmentsSuccess,
  addSubsidiaryDepartmentsFailure,
  deleteSubsidiaryDepartmentsRequest,
  deleteSubsidiaryDepartmentsSuccess,
  deleteSubsidiaryDepartmentsFailure,
  getDepartmentSchedulesRequest,
  getDepartmentSchedulesSuccess,
  getDepartmentSchedulesFailure,
  addDepartmentSchedulesRequest,
  addDepartmentSchedulesSuccess,
  addDepartmentSchedulesFailure,
  updateSubsidiaryPrivacyRequest,
  updateSubsidiaryPrivacySuccess,
  updateSubsidiaryPrivacyFailure,
  getAllCardsRequest,
  getAllCardsSuccess,
  getAllCardsFailure,
  addCardRequest,
  addCardSuccess,
  addCardFailure,
  deleteCardRequest,
  deleteCardSuccess,
  deleteCardFailure,
  updateCardRequest,
  updateCardSuccess,
  updateCardFailure,
  createStripeCustomerRequest,
  createStripeCustomerSuccess,
  createStripeCustomerFailure,
  getCheckoutTotalRequest,
  getCheckoutTotalSuccess,
  getCheckoutTotalFailure,
  addAppointmentPeriodRequest,
  addAppointmentPeriodSuccess,
  addAppointmentPeriodFailure,
  getDepartmentAvailabilityRequest,
  getDepartmentAvailabilitySuccess,
  getDepartmentAvailabilityFailure,
  deleteSubsidiaryRequest,
  deleteSubsidiarySuccess,
  deleteSubsidiaryFailure,
  getUserSubsidiariesRequest,
  getUserSubsidiariesSuccess,
  getUserSubsidiariesFailure,
  updateVatRequest,
  updateVatSuccess,
  updateVatFailure,
  editSubsidiaryRequest,
  editSubsidiarySuccess,
  editSubsidiaryReset,
  editSubsidiaryFailure,
  getInvoiceRequest,
  getInvoiceSuccess,
  getInvoiceFailure,
  getInvoiceDetailRequest,
  getInvoiceDetailSuccess,
  getInvoiceDetailFailure,
  addSubsidiaryLogoRequest,
  addSubsidiaryLogoSuccess,
  addSubsidiaryLogoReset,
  addSubsidiaryLogoFailure,
  handleInvitationUserRequest,
  handleInvitationUserSuccess,
  handleInvitationUserFailure,
  downloadPdfRequest,
  downloadPdfSuccess,
  downloadPdfFailure,
  deleteSubsidiaryMemberReset,
  changeStatusSubsidiaryMemberReset,
  reactivateSubscriptionRequest,
  reactivateSubscriptionSuccess,
  reactivateSubscriptionFailure,
  reactivateSubscriptionReset,
  cancelStripeScheduleRequest,
  cancelStripeScheduleSuccess,
  cancelStripeScheduleFailure,
  previewPriceRequest,
  previewPriceSuccess,
  previewPriceFailure,
  changePlanRequest,
  changePlanSuccess,
  changePlanFailure,
  subscriptionPlanDetailRequest,
  subscriptionPlanDetailSuccess,
  subscriptionPlanDetailFailure,
  cancelUpcomingSubscriptionRequest,
  cancelUpcomingSubscriptionSuccess,
  cancelUpcomingSubscriptionFailure,
  cancelStripeScheduleReset,
  previewPriceReset,
  changePlanReset,
  subscriptionPlanDetailReset,
  cancelUpcomingSubscriptionReset,
  deleteCardReset,
  addCardReset,
  updateCardReset,
  getNotificationsRequest,
  getNotificationsFailure,
  getNotificationsSuccess,
  readNotificationRequest,
  readNotificationFailure,
  readNotificationSuccess,
  readAllNotificationsRequest,
  readAllNotificationsFailure,
  readAllNotificationsSuccess,
  deleteNotificationRequest,
  deleteNotificationFailure,
  deleteNotificationSuccess,
  respondNotificationRequest,
  respondNotificationFailure,
  respondNotificationSuccess,
  openNotificationsRequest,
  openNotificationsFailure,
  openNotificationsSuccess,
  getNotificationCountRequest,
  getNotificationCountFailure,
  getNotificationCountSuccess,
  createIntentRequest,
  createIntentFailure,
  createIntentSuccess,
  storeNewCardRequest,
  storeNewCardSuccess,
  storeNewCardFailure,
  showLoaderRequest,
  hideLoaderRequest,
  storeNewCardReset,
  getSubsidiaryDetailsReset,
  getNotificationsSuccessCartobike,
  getNotificationsSuccessInvitation,
  getSubsidiaryDetailsChange,
  privateSwitchRequest,
  privateSwitchSuccess,
  createSubscriptionErrorMessage,
  getInvoiceDetailReset,


} from '../actions/company-actions';

let subsidiary = localStorage.getItem('subsidiary');

const initialState = {
  loading: false,
  createStripeCustomer: false,
  vatValidData: {},
  vatValidFailure: {},
  checkSlugData: {},
  parentCreatedSuccess: {},
  createParentError: {},
  createCompanySuccess: false,
  createCompanyError: {},
  subsidiaryDetails: {},
  rolesList: [],
  subsidiaryData: [],
  subscriptionPlans: {},
  annualOff: '',
  allFeatures: [],
  allPermissions: [],
  memberList: {},
  getMembersErros: {},
  suggestedMembersList: {},
  subsidiaryDepartments: [],
  subsidiaryDepartmentsError: {},
  departmentList: [],
  addSubsidiaryDepartmentsError: {},
  deleteSubsidiaryDepartmentsError: {},
  deleteSubsidiaryDepartments: false,
  deleteSubsidiaryDepartmentsData: {},
  scheduleList: [],
  scheduleListError: {},
  updateScheduleSuccess: false,
  addMemberSuccess: false,
  memberInfo: {},
  schedulesUpdated: false,
  scheduleUpdatedFailure: {},
  cardsList: [],
  cardAddedSuccess: {},
  checkoutTotal: {},
  checkoutFailure: {},
  cardDeletedSuccess: false,
  cardAddedError: {},
  editMemberSuccess: false,
  editMemberRolesSuccess: false,
  disableMemberSuccess: false,
  deleteMemberSuccess: false,
  deleteMemberFailure: {},
  subsidiarySubscriptionStatusSuccess: {},
  getSubsidiarySubscriptionStatusError: {},
  subscriptionPlanDetailsSuccess: {},
  appointmentPeriodSuccess: false,
  appointmentPeriodFailure: {},
  availabilityList: {},
  roleDetails: {},
  roleDetailsError: false,
  subsidiaryDeletedSuccess: false,
  subsidiaryDeletedError: false,
  userData: {},
  vatUpdated: false,
  vatUpdatedData: {},
  vatUpdatedError: {},
  roleCreated: false,
  roleCreatedError: {},
  roleUpdated: false,
  roleUpdatedEror: {},
  invoiceList: [],
  subscriptionCreatedSuccess: {},
  subscriptionCreatedFailure: {},
  addSubsidiaryDepartments: false,
  updateAddSubsidiaryLogoSuccess: false,
  addSubsidiaryLogoFailureError: {},
  logoUrl: {},
  invitationStatusSuccess: {},
  invitationStatusError: {},
  subsidiaryMemberUpdateSuccess: false,
  subsidiaryEditSuccess: false,
  subsidiaryName: {},
  editSubsidiaryError: {},
  editSubsidiaryMemberError: {},
  addSubsidiaryMembersError: {},
  statusSubsidiaryMemberSuccess: false,
  reactivateSubscriptionStatus: {},
  reactivateSubscriptionError: {},
  reactivateSubscriptionToaster: false,
  successCancelStripeSchedule: {},
  successCancelStripeError: {},
  cancelStripeScheduleToaster: false,
  previewPriceData: {},
  previewPriceToaster: false,
  previewPriceFailureError: {},
  previewPriceFailureErrorStatus: "",
  changePlanData: {},
  changePlanFailureError: {},
  subscriptionPlanDetailData: {},
  subscriptionPlanDetailToaster: false,
  cancelUpcomingSubscriptionData: {},
  cancelUpcomingSubscriptionToaster: false,
  addCardToaster: false,
  deleteCardFailureError: {},
  invoiceData: {},
  notificationData: {},
  notificationListing: [],
  notificationDeleted: {},
  deleteNotificationError: {},
  notificationResponse: {},
  notificationSent: false,
  notificationResponseError: {},
  NotificationOpened: false,
  notificationCount: {},
  createIntentData: {},
  createIntentError: {},
  subsidiaryPrivacyFailure: {},
  updatedPermission: false,
  loadingNotification: false,
  storeNewCardData: {},
  notificationDataCartobike: {},
  notificationListingCartobike: [],
  notificationDataInvitation: {},
  notificationListingInvitation: [],
  cardsListErrors: {},
  errorInvoice: {},
  getRolesError: {},
  subsidaryHit: false,
  subIsChanged: false,
  subscriptionErrorMessage: {},
  switchPlanLoading: false,
  updatedPrivacy: {},
  paymentCardId: null,
  updatedData: {},
  invoicesLoading: true,
};

const company = handleActions(
  {
    [checkVatRequest]: state => ({
      ...state,
      loading: true,
      vatValidData: false,
      vatValidFailure: {}
    }),
    [checkVatSuccess]: (state, { payload }) => ({
      ...state,
      vatValidData: payload,
      loading: false,
    }),
    [checkVatFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vatValidFailure: payload.data
    }),
    [checkSlugRequest]: state => ({
      ...state,
      loading: true,
      checkSlugData: {}
    }),
    [checkSlugSuccess]: (state, { payload }) => ({
      ...state,
      checkSlugData: payload,
      loading: false,
    }),
    [checkSlugFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),

    //create parent company
    [createParentRequest]: state => ({
      ...state,
      loading: true,
      createParentError: {}
    }),
    [createParentSuccess]: (state, { payload }) => ({
      ...state,
      parentCreatedSuccess: payload.parent,
      loading: false,
    }),
    [createParentFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      createParentError: payload.data
    }),
    [createParentReset]: (state, { payload }) => ({
      ...state,
      createParentError: {},
      parentCreatedSuccess: {}
    }),

    [createCompanyRequest]: state => ({
      ...state,
      loading: true,
      createCompanySuccess: false
    }),
    [createCompanySuccess]: (state, { payload }) => ({
      ...state,
      createCompanySuccess: true,
      loading: false,
    }),
    [createCompanyFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      createCompanyError: payload.data
    }),

    [getSubsidiaryDetailsRequest]: state => ({
      ...state,
      loading: true,
      subsidaryHit: false,
      subIsChanged: false,
      subsidiaryDetails: {}
    }),
    [getSubsidiaryDetailsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      subsidaryHit: true,
      subsidiaryDetails: payload.subsidiary
    }),
    [getSubsidiaryDetailsChange]: (state, { payload }) => ({
      ...state,
      subIsChanged: payload,
    }),
    [getSubsidiaryDetailsReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      subsidaryHit: false,
      subsidiaryDetails: {}
    }),
    [getSubsidiaryDetailsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),
    [createRoleRequest]: state => ({
      ...state,
      loading: true,
      roleCreatedError: {},
      roleCreated: false
    }),
    [createRoleSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      roleCreated: true,
    }),
    [createRoleFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      roleCreatedError: payload.data
    }),

    //get a role detail
    [getRoleDetailsRequest]: state => ({
      ...state,
      loading: true,
    }),
    [getRoleDetailsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      roleDetails: payload.role
    }),
    [getRoleDetailsFailure]: (state, { payload }) => ({
      ...state,
      roleDetailsError: true,
      loading: false,
    }),

    [editRoleRequest]: state => ({
      ...state,
      loading: true,
      roleUpdated: false,
      roleUpdatedError: {}
    }),
    [editRoleSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      roleUpdated: true
    }),
    [editRoleFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      roleUpdatedError: payload.data
    }),
    [deleteRoleRequest]: state => ({
      ...state,
      loading: true,
      deleteRole: false,
    }),
    [deleteRoleSuccess]: (state, { payload }) => ({
      ...state,
      deleteRole: true,
      loading: false,
    }),
    [deleteRoleFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      deleteRole: false,
      deleteRoleError: payload.data,
    }),
    [getRolesRequest]: state => ({
      ...state,
      loading: true,
      getRolesError: {}
    }),
    [getRolesSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      rolesList: payload.roles,
      getRolesError: {}
    }),
    [getRolesFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      getRolesError: payload
    }),
    [getUserSubsidiaryRequest]: state => ({
      ...state,
      loading: true,
    }),
    [getUserSubsidiarySuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      subsidiaryData: payload.account
    }),
    [getUserSubsidiaryFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),
    [getSubscriptionPlansRequest]: state => ({
      ...state,
      loading: true,
    }),
    [getSubscriptionPlansSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      annualOff: payload.annualOff,
      subscriptionPlans: payload.plans
    }),
    [getSubscriptionPlansFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),

    //get details of a plan
    [getSubscriptionPlanDetailsRequest]: state => ({
      ...state,
      loading: true,
    }),
    [getSubscriptionPlanDetailsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      subscriptionPlanDetailsSuccess: payload.plan
    }),
    [getSubscriptionPlanDetailsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),

    //get all features
    [getFeaturesRequest]: state => ({
      ...state,
      loading: true,
    }),
    [getFeaturesSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      // allFeatures:payload.features
      allFeatures: payload
    }),
    [getFeaturesFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),

    //get all permissions
    [getPermissionsRequest]: state => ({
      ...state,
      loading: true,
    }),
    [getPermissionsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      allPermissions: payload.permissions
    }),
    [getPermissionsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),

    [createSubscriptionRequest]: state => ({
      ...state,
      loading: true,
      subscriptionCreatedSuccess: {},
      subscriptionCreatedFailure: {}
    }),
    [createSubscriptionSuccess]: (state, { payload }) => (
      {
        ...state,
        loading: false,
        subscriptionCreatedSuccess: payload
      }),
    [createSubscriptionErrorMessage]: (state, { payload }) => (
      {
        ...state,
        loading: false,
        subscriptionErrorMessage: payload
      }),
    [createSubscriptionFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      subscriptionCreatedFailure: payload
    }),
    [createSubscriptionReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      subscriptionCreatedFailure: {},
      // subscriptionCreatedSuccess:{}
    }),

    //get subsidiary subscription Status
    [getSubsidiarySubscriptionStatusRequest]: state => ({
      ...state,
      loading: true,
      subsidiarySubscriptionStatusSuccess: {},
      getSubsidiarySubscriptionStatusError: {}
    }),
    [getSubsidiarySubscriptionStatusSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      subsidiarySubscriptionStatusSuccess: payload,
      getSubsidiarySubscriptionStatusError: {}
    }),
    [getSubsidiarySubscriptionStatusFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      getSubsidiarySubscriptionStatusError: payload
    }),

    [getMembersRequest]: state => ({
      ...state,
      loading: true,
      memberList: {},
      getMembersErros: {}
    }),
    [getMembersSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      memberList: payload,
      getMembersErros: {}
    }),
    [getMembersFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      getMembersErros: payload
    }),

    //Enable/disable member status
    [changeStatusSubsidiaryMemberRequest]: state => ({
      ...state,
      loading: true,
      disableMemberSuccess: false,
      statusSubsidiaryMemberSuccess: false
    }),
    [changeStatusSubsidiaryMemberSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      statusSubsidiaryMemberSuccess: true,
      disableMemberSuccess: payload.success
    }),
    [changeStatusSubsidiaryMemberFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      disableMemberSuccess: false,
    }),
    [changeStatusSubsidiaryMemberReset]: state => ({
      ...state,
      loading: true,
      statusSubsidiaryMemberSuccess: false,
      disableMemberSuccess: false
    }),

    // delete subsidiary member
    [deleteSubsidiaryMemberRequest]: state => ({
      ...state,
      loading: true,
      deleteMemberSuccess: false,
      deleteMemberFailure: {}
    }),
    [deleteSubsidiaryMemberSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      deleteMemberSuccess: payload.success
    }),
    [deleteSubsidiaryMemberFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      deleteMemberFailure: payload
    }),
    [deleteSubsidiaryMemberReset]: state => ({
      ...state,
      loading: false,
      deleteMemberSuccess: false,
    }),

    // edit subsidiary member
    [editSubsidiaryMemberRequest]: state => ({
      ...state,
      loading: true,
      subsidiaryMemberUpdateSuccess: false,
      editMemberSuccess: false,
    }),
    [editSubsidiaryMemberSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      subsidiaryMemberUpdateSuccess: true,
      editMemberSuccess: payload.success
    }),
    [editSubsidiaryMemberReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      subsidiaryMemberUpdateSuccess: false,
    }),
    [editSubsidiaryMemberFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      editMemberSuccess: false,
      editSubsidiaryMemberError: payload.data
    }),

    // update subsidiary role and department api
    [updateSubsidiaryMemberRolesRequest]: state => ({
      ...state,
      loading: true,
      editMemberRolesSuccess: false
    }),
    [updateSubsidiaryMemberRolesSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      editMemberRolesSuccess: true
    }),
    [updateSubsidiaryMemberRolesFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      editMemberRolesSuccess: false
    }),

    [getSuggestedMembersRequest]: state => ({
      ...state,
      loading: true,
    }),
    [getSuggestedMembersSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      suggestedMembersList: payload.users
    }),
    [getSuggestedMembersFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),
    [addSubsidiaryMembersRequest]: state => ({
      ...state,
      loading: true,
      addMemberSuccess: false
    }),
    [addSubsidiaryMembersSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      addMemberSuccess: payload.success,
      memberInfo: payload.data
    }),
    [addSubsidiaryMembersFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      addMemberSuccess: false,
      addSubsidiaryMembersError: payload

    }),
    [getDepartmentsRequest]: state => ({
      ...state,
      loading: true,
    }),
    [getDepartmentsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      departmentList: payload.items
    }),
    [getDepartmentsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),
    [getSubsidiaryDepartmentsRequest]: state => ({
      ...state,
      loading: true,
      subsidiaryDepartments: [],
    }),
    [getSubsidiaryDepartmentsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      subsidiaryDepartments: payload.departments
    }),
    [getSubsidiaryDepartmentsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      subsidiaryDepartmentsError: payload
    }),
    [addSubsidiaryDepartmentsRequest]: state => ({
      ...state,
      loading: true,
      addSubsidiaryDepartments: false,
      addSubsidiaryDepartmentsError: {}
    }),
    [addSubsidiaryDepartmentsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      addSubsidiaryDepartments: true
    }),
    [addSubsidiaryDepartmentsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      addSubsidiaryDepartmentsError: payload.data
    }),
    [deleteSubsidiaryDepartmentsRequest]: state => ({
      ...state,
      loading: true,
      deleteSubsidiaryDepartments: false,
      deleteSubsidiaryDepartmentsError: {}
    }),
    [deleteSubsidiaryDepartmentsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      deleteSubsidiaryDepartments: true,
      deleteSubsidiaryDepartmentsData: payload
    }),
    [deleteSubsidiaryDepartmentsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      deleteSubsidiaryDepartments: false,
      deleteSubsidiaryDepartmentsError: payload.data
    }),
    [getDepartmentSchedulesRequest]: state => ({
      ...state,
      loading: true,
    }),
    [getDepartmentSchedulesSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      scheduleList: payload.schedules
    }),
    [getDepartmentSchedulesFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      scheduleListError: payload

    }),
    [addDepartmentSchedulesRequest]: state => ({
      ...state,
      loading: true,
      schedulesUpdated: false,
      scheduleUpdatedFailure: {}
    }),
    [addDepartmentSchedulesSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      schedulesUpdated: true,
      scheduleUpdatedFailure: {}
    }),
    [addDepartmentSchedulesFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      schedulesUpdated: false,
      scheduleUpdatedFailure: payload.data
    }),
    [updateSubsidiaryPrivacyRequest]: state => ({
      ...state,
      loading: true,
      updatedPermission: false,
    }),
    [updateSubsidiaryPrivacySuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      updatedPermission: true,
      updatedPrivacy: payload
    }),
    [updateSubsidiaryPrivacyFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      subsidiaryPrivacyFailure: payload,
      // updatedPermission:true
    }),
    [getAllCardsRequest]: state => ({
      ...state,
      loading: true,
    }),
    [getAllCardsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      cardsList: payload.cards
    }),
    [getAllCardsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      cardsListErrors: payload
    }),
    [addCardRequest]: state => ({
      ...state,
      loading: true,
      cardAddedSuccess: {}
    }),
    [addCardSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      addCardToaster: true,
      cardAddedSuccess: payload.card,

    }),
    [addCardFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      cardAddedSuccess: {},
      cardAddedError: payload.data,
    }),
    [addCardReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      addCardToaster: false,
      cardAddedSuccess: {}
    }),
    [deleteCardRequest]: state => ({
      ...state,
      loading: true,
      cardDeletedSuccess: false,
    }),
    [deleteCardSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      paymentCardId: payload,
      cardDeletedSuccess: true
    }),
    [deleteCardFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      cardDeletedSuccess: false,
      deleteCardFailureError: payload.data
    }),
    [deleteCardReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      cardDeletedSuccess: false
    }),

    //update card
    [updateCardRequest]: state => ({
      ...state,
      loading: true,
      updateCardData: false,
      updateCardError: {}
    }),
    [updateCardSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      updatedData: payload,
      updateCardData: true
    }),
    [updateCardFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      updateCardError: payload.data
    }),
    [updateCardReset]: state => ({
      ...state,
      loading: true,
      updateCardData: false,
    }),

    [createStripeCustomerRequest]: state => ({
      ...state,
      createStripeCustomer: false,
      loading: true,
    }),
    [createStripeCustomerSuccess]: (state, { payload }) => ({
      ...state,
      createStripeCustomer: true,
      loading: false,
    }),
    [createStripeCustomerFailure]: (state, { payload }) => ({
      ...state,
      createStripeCustomer: false,
      loading: false,
    }),
    [getCheckoutTotalRequest]: state => ({
      ...state,
      loading: true,
      checkoutTotal: {},
      checkoutFailure: {}
    }),
    [getCheckoutTotalSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      checkoutTotal: payload.order
    }),
    [getCheckoutTotalFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      checkoutFailure: payload.data
    }),

    //add appointment period and number of appointments/period
    [addAppointmentPeriodRequest]: state => ({
      ...state,
      loading: true,
      appointmentPeriodSuccess: false,
      appointmentPeriodFailure: {}
    }),
    [addAppointmentPeriodSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      appointmentPeriodSuccess: true,
    }),
    [addAppointmentPeriodFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      appointmentPeriodFailure: payload.data,
    }),

    // get department availability
    [getDepartmentAvailabilityRequest]: state => ({
      ...state,
      loading: true,
    }),
    [getDepartmentAvailabilitySuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      availabilityList: payload
    }),
    [getDepartmentAvailabilityFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      availabilityList: {}
    }),

    // delete subsidiary
    [deleteSubsidiaryRequest]: state => ({
      ...state,
      loading: true,
      subsidiaryDeletedSuccess: false,
    }),
    [deleteSubsidiarySuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      subsidiaryDeletedSuccess: true,
      subsidiaryDeletedError: false
    }),
    [deleteSubsidiaryFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      subsidiaryDeletedSuccess: false,
      subsidiaryDeletedError: true
    }),

    //get user and subsidiary details
    [getUserSubsidiariesRequest]: state => ({
      ...state,
      loading: true,
      userData: {}
    }),
    [getUserSubsidiariesSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      userData: payload.user
    }),
    [getUserSubsidiariesFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),

    //update vat
    [updateVatRequest]: state => ({
      ...state,
      loading: true,
      vatUpdated: false,
      vatUpdatedData: {},
      vatUpdatedError: {}
    }),
    [updateVatSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vatUpdatedData: payload,
      vatUpdated: true
    }),
    [updateVatFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vatUpdated: false,
      vatUpdatedError: payload.data
    }),

    //edit subsidiary
    [editSubsidiaryRequest]: state => ({
      ...state,
      loading: true,
      subsidiaryEditSuccess: false,
      editSubsidiaryError: {}
    }),
    [editSubsidiarySuccess]: (state, { payload }) => ({
      ...state,
      subsidiaryEditSuccess: true,
      subsidiaryName: payload,
      loading: false,
    }),
    [editSubsidiaryReset]: (state, { payload }) => ({
      ...state,
      subsidiaryEditSuccess: false,
      loading: false,
    }),
    [editSubsidiaryFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      editSubsidiaryError: payload.data
    }),

    //get invoice list
    [getInvoiceRequest]: state => ({
      ...state,
      invoicesLoading: true,
      errorInvoice: {}
    }),
    [getInvoiceSuccess]: (state, { payload }) => ({
      ...state,
      invoiceList: payload.data.invoices,
      invoicesLoading: false,
      errorInvoice: {}
    }),
    [getInvoiceFailure]: (state, { payload }) => ({
      ...state,
      invoicesLoading: false,
      errorInvoice: payload
    }),

    [getInvoiceDetailRequest]: state => ({
      ...state,
      loading: true,
    }),
    [getInvoiceDetailSuccess]: (state, { payload }) => ({
      ...state,
      invoiceData: payload,
      loading: false,
    }),
    [getInvoiceDetailFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      invoiceDetailError: payload
    }),
    [getInvoiceDetailReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      invoiceDetailError: {},
      invoiceData: {}
    }),

    [addSubsidiaryLogoRequest]: state => ({
      ...state,
      updateAddSubsidiaryLogoSuccess: false,
      addSubsidiaryLogoFailureError: {},
      loading: true,
    }),
    [addSubsidiaryLogoSuccess]: (state, { payload }) => ({
      ...state,
      updateAddSubsidiaryLogoSuccess: true,
      logoUrl: payload.data,
      loading: false,
    }),
    [addSubsidiaryLogoFailure]: (state, { payload }) => ({
      ...state,
      addSubsidiaryLogoFailureError: payload.data,
      loading: false,
    }),
    [addSubsidiaryLogoReset]: (state, { payload }) => ({
      ...state,
      updateAddSubsidiaryLogoSuccess: false,
      addSubsidiaryLogoFailureError: {},
      loading: false,
    }),

    //handle invitation by user
    [handleInvitationUserRequest]: state => ({
      ...state,
      loading: true,
      invitationStatusSuccess: {},
      invitationStatusError: {}
    }),
    [handleInvitationUserSuccess]: (state, { payload }) => ({
      ...state,
      invitationStatusSuccess: payload,
      loading: false,
    }),
    [handleInvitationUserFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      invitationStatusError: payload.data
    }),

    [downloadPdfRequest]: state => ({
      ...state,
      loading: true,
    }),
    [downloadPdfSuccess]: (state, { payload }) => ({
      ...state,
      pdfDownloadSuccess: payload,
      loading: false,
    }),
    [downloadPdfFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      pdfDownloadSuccessError: payload.data
    }),

    [reactivateSubscriptionRequest]: state => ({
      ...state,
      loading: true,
      reactivateSubscriptionStatus: {},
      reactivateSubscriptionToaster: false,
    }),
    [reactivateSubscriptionSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      reactivateSubscriptionStatus: payload,
      reactivateSubscriptionToaster: true
    }),
    [reactivateSubscriptionFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      reactivateSubscriptionError: payload,
    }),
    [reactivateSubscriptionReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      reactivateSubscriptionToaster: false
    }),


    [cancelStripeScheduleRequest]: state => ({
      ...state,
      loading: true,
      successCancelStripeError: {}
    }),
    [cancelStripeScheduleSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      cancelStripeScheduleToaster: true,
      successCancelStripeSchedule: payload
    }),
    [cancelStripeScheduleFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      successCancelStripeError: payload,
    }),
    [cancelStripeScheduleReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      cancelStripeScheduleToaster: false
    }),

    [previewPriceRequest]: state => ({
      ...state,
      loading: true,
      previewPriceToaster: false,
      switchPlanLoading: true,
      previewPriceFailureErrorStatus: ""
    }),
    [previewPriceSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      previewPriceToaster: true,
      switchPlanLoading: false,
      previewPriceData: payload,
      previewPriceFailureErrorStatus: ""
    }),
    [previewPriceFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      switchPlanLoading: false,
      previewPriceFailureError: payload.data,
      previewPriceFailureErrorStatus: payload.status
    }),
    [previewPriceReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      previewPriceToaster: false,
    }),


    [changePlanRequest]: state => ({
      ...state,
      loading: true,
      changePlanData: {}
    }),
    [changePlanSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      changePlanToaster: true,
      changePlanData: payload
    }),
    [changePlanFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      changePlanFailureError: payload
    }),
    [changePlanReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      changePlanToaster: false,
    }),
    [subscriptionPlanDetailRequest]: state => ({
      ...state,
      loading: true,
    }),
    [subscriptionPlanDetailSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      subscriptionPlanDetailToaster: true,
      subscriptionPlanDetailData: payload
    }),
    [subscriptionPlanDetailFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),
    [subscriptionPlanDetailReset]: (state, { payload }) => ({
      ...state,
      subscriptionPlanDetailToaster: false,
      loading: false,
    }),
    [cancelUpcomingSubscriptionRequest]: state => ({
      ...state,
      loading: true,
    }),
    [cancelUpcomingSubscriptionSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      cancelUpcomingSubscriptionToaster: true,
      cancelUpcomingSubscriptionData: payload
    }),
    [cancelUpcomingSubscriptionFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),
    [cancelUpcomingSubscriptionReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      cancelUpcomingSubscriptionToaster: false

    }),

    //get all notifications
    [getNotificationsRequest]: (state, { payload }) => ({
      ...state,
      loadingNotification: payload.page === 1 ? true : false,
      loading: payload.page === 1 ? true : false,
      notificationData: {},
    }),
    [getNotificationsSuccess]: (state, { payload }) => {
      if (payload.notifications.current_page == 1) {
        return {
          ...state,
          loading: false,
          loadingNotification: false,
          notificationData: payload.notifications,
          notificationListing: payload.notifications.data
        }
      } else {
        state.notificationListing = state.notificationListing.concat(payload.notifications.data)
        return {
          ...state,
          loading: false,
          loadingNotification: false,
          notificationData: payload.notifications,
          // notificationListing: [...state.notificationListing, ...payload.notifications.data]          
        }
      }
    },
    [getNotificationsSuccessCartobike]: (state, { payload }) => {
      if (payload.notifications.current_page == 1) {
        return {
          ...state,
          loading: false,
          loadingNotification: false,
          notificationDataCartobike: payload.notifications,
          notificationListingCartobike: payload.notifications.data
        }
      } else {
        state.notificationListingCartobike = state.notificationListingCartobike.concat(payload.notifications.data)
        return {
          ...state,
          loading: false,
          loadingNotification: false,
          notificationDataCartobike: payload.notifications,
        }
      }
    },
    [getNotificationsSuccessInvitation]: (state, { payload }) => {
      if (payload.notifications.current_page == 1) {
        return {
          ...state,
          loading: false,
          loadingNotification: false,
          notificationDataInvitation: payload.notifications,
          notificationListingInvitation: payload.notifications.data
        }
      } else {
        state.notificationListingInvitation = state.notificationListingInvitation.concat(payload.notifications.data)
        return {
          ...state,
          loading: false,
          loadingNotification: false,
          notificationDataInvitation: payload.notifications,
        }
      }
    },
    [getNotificationsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      loadingNotification: false,
    }),

    //read a notification
    [readNotificationRequest]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),
    [readNotificationSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),
    [readNotificationFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),

    //read all notifications
    [readAllNotificationsRequest]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),
    [readAllNotificationsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),
    [readAllNotificationsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),

    //delete notification
    [deleteNotificationRequest]: (state, { payload }) => ({
      ...state,
      loading: false,
      notificationDeleted: {},
      deleteNotificationError: {}
    }),
    [deleteNotificationSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      notificationDeleted: payload
    }),
    [deleteNotificationFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      deleteNotificationError: payload
    }),

    //respond to invitations in notifications
    [respondNotificationRequest]: (state, { payload }) => ({
      ...state,
      loading: false,
      notificationSent: false,
      notificationResponse: {},
      notificationResponseError: {}
    }),
    [respondNotificationSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      notificationSent: true,
      notificationResponse: payload
    }),
    [respondNotificationFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      notificationSent: false,
      notificationResponseError: payload.data
    }),

    //open notifications
    [openNotificationsRequest]: (state, { payload }) => ({
      ...state,
      loading: false,
      notificationOpened: false
    }),
    [openNotificationsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      notificationOpened: payload.success
    }),
    [openNotificationsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),

    //get notification count
    [getNotificationCountRequest]: (state, { payload }) => ({
      ...state,
      loading: false,
      notificationCount: {}
    }),
    [getNotificationCountSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      notificationCount: payload
    }),
    [getNotificationCountFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),


    // Create Intent 
    [createIntentRequest]: (state, { payload }) => ({
      ...state,
      loading: true
    }),
    [createIntentSuccess]: (state, { payload }) => (
      {
        ...state,
        createIntentData: payload,
        loading: false,
      }),
    [createIntentFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      createIntentError: payload,
    }),
    [storeNewCardRequest]: state => ({
      ...state,
      loading: true,
      storeNewCard: false
    }),
    [storeNewCardSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      storeNewCardData: payload.data,
      storeNewCard: true

    }),
    [storeNewCardFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      storeNewCard: false
    }),
    [storeNewCardReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      storeNewCard: false
    }),
    [showLoaderRequest]: state => ({
      ...state,
      loading: true,
    }),
    [hideLoaderRequest]: state => ({
      ...state,
      loading: false,
    }),
    [privateSwitchRequest]: state => ({
      ...state,
      loading: true,
      subIsChanged: false,
    }),
    [privateSwitchSuccess]: (state, payload) => ({
      ...state,
      subIsChanged: true,
    }),
  },
  initialState,
);

export default company;
