import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const CreditPageWrapper = styled.div`
  font-family: Rubik;
  max-width: 1440px;
  width: 100%;
  margin: 40px auto;
  gap: 40px;  
  display: flex;
  flex-direction: column;
  min-height: 495px;

.feature-container{
  display: flex;
  flex-direction: row;  
  gap:47px;  
}

.credit-balance-container{
  max-width: 343px;
  width: 100%;
  height: 57px;
  top: 271px;
  left: 135px;
  gap: 0px;
  border-radius: 3px 0px 0px 0px;
  opacity: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
   
}
 .background-green{
  background: #F3FFF6;
 }
 .background-red{
  background: #fff3f3;
}

.text{
  font-size: 21px;
  font-weight: 400;
  line-height: 24.89px;
  letter-spacing: 0.02em;
  text-align: center;

}
.balance{

}
.balance-value-green{ 
  font-weight: 500;
  text-align: center;
  color: #28A745;
}
.balance-value-red{ 
  font-weight: 500;
  text-align: center;
  color: #d10505;
}

.header-credit{
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.73px;
  letter-spacing: 0.02em;
}
.credit-desc{
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  color:  #666666;
  margin-bottom: 32px ;

}
.credit{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  
}

.features{
  display: flex;
  flex-direction: column;
  padding: 14px;
  width: 50%;
 background-color : #FCFCFC ;
}

.feature-heading{
font-size: 21px;
font-weight: 600;
line-height: 29.16px;
letter-spacing: 0.02em;
text-align: left;
max-width: 420px;

}
li{
font-size: 15px;
font-weight: 400;
line-height: 25.5px;
letter-spacing: 0.015em;
text-align: left;
color: #666666;
/* max-width: 420px; */
}

.pricing-container{  
  border: 1px solid #ECEFFF;
  padding: '24px';
  border-radius: 6px;
  padding: 24px;
}
.creds{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap:25px;
  margin-top: 24px;
  overflow-y: auto;
}
.creditPrice-heading{
  font-size: 23px;
  font-weight: 500;
  line-height: 27.26px;
  letter-spacing: 0.02em;
  text-align: left;

}

.credit-section{
  border: 1px solid #ECEFFF;
  max-width: 262px;
  min-width: 262px;
  padding: 16px;
  width:100%;
  position: relative;
  background: var(--Color-Gray-50, #F8FAFC);
}

.credit-section:hover{
  box-shadow: 0 0 1px #666666;
}
.total-credit{
font-size: 27px;
font-weight: 500;
line-height: 43.75px;
letter-spacing: 0.015em;
text-align: left; 

}
.credits{
font-size: 18px;
font-weight: 600;
line-height: 29.16px;
letter-spacing: 0.015em;
text-align: left;
}

.credit-price{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.validity-days{
  font-weight: 500;
  text-transform: lowercase;
}

.popular-tag{
    /* max-width: 100px; */
    width: fit-content;
    padding: 0px 10px;
    background: #FBC623;
    border-radius: 2px;
    font-size:13px;
    font-family: 400;
    position: absolute;
    top:-10px;   
}

.pageHeader{
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: white;
      padding: 10px 0;
  }

@media (max-width :1440px){
  max-width: 1170px;
}
@media (max-width :1170px){
  padding: 16px;
}

@media (max-width :992px){
  padding: 0 16px;
  min-height: 100vh;
  .pageHeader {
    h2{
      font-family: Inter;
      font-size: 21px;
      font-weight: 500;
      line-height: 25.41px;
      letter-spacing: 0.02em;
      margin-top: -10px;
      margin: 0;
    }
  }
  margin: 0px auto;
  gap:16px;
  .pricing-container{
    padding: 14px;
  }
  .feature-container{  
  flex-wrap: wrap-reverse;
  gap:16px;  
 }
 .credit ,.features{
  width: 100%;
}
.header-credit, .credit-desc {
  display: none;
}
.feature-heading{
  font-size: 15px;
  font-weight: 500;
}
.features{
  border: 1px solid #f2f2f2;
  border-radius: 6px;
}
.features-list li{
font-size: 13px;
font-weight: 400;
line-height: 22 px;
letter-spacing: 1.5%;
}
.credit-balance-container , .credit-section{
  max-width: 100%;
}
.credit-section{
  min-width: 100%;
}
.creds{
  flex-wrap: wrap;
  padding:0

}
.creditPrice-heading{
  font-size: 21px;
}
}
`