import { NavBarWrapper } from '../Style/NavStyle'
import NavigationBarLargeDevices from './NavigationBarComponent/NavigationBarLargeDevices'

const WebMenu = ({ isFixed }) => {

    return (
        <NavBarWrapper className='nav-bar'>
            <NavigationBarLargeDevices />
        </NavBarWrapper>
    )
}

export default WebMenu;