import { useEffect, useMemo } from 'react';
import { Box, Button, Modal, Tooltip } from '@mui/material'
import { UserDetailWrapper } from './style'
import { SvgLocation, SvgMobile, SvgStar, SvgfluentIcon } from '../svgicons/svgNew'
import UserPlaceholer from 'app/assets/Images/userPlaceholderSquareImage.svg'
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import ChatIcon from 'app/pages/Chat/Component/ChildComponents/ChatIcon';
import { UserInterface } from 'app/pages/AdsHomePage/interface/interface';
import { profileTypeString } from 'config/publicProfileConfig';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import UserContactsPopUp from 'app/Component2/Layouts/AdsLayouts/component/AdsListing/UserContactsPopUp';
import { useState } from 'react';
import { adsTypeName, handleCallCount, localstorageKey, showAuctionsTo } from 'config/constants';
import { callRequest } from 'store/actions/vehicle-actions';
import { useDispatch } from 'react-redux';
import UserDetailSkeleton from './UserDetailSkeleton';
import { SvgClose } from '../svgicons/svg';
import { getPublicAdsListReset } from 'store/actions/public-actions';

const UserDetails = ({ userDetail, adId, handleChat, showcaseAuction, hideUserInfo, adListingType, myDivRef, showOnlyMessage = false, showUserDetails = true, isDisableBtn = false }: UserInterface) => {

  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch();
  const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()
  const [open, _open] = useState<boolean>(false);
  const [openLocationPopup, setOpenLocationPopup] = useState<boolean>(false);

  const phoneNumber = useMemo(() => {
    let phoneNo = ''
    if (userDetail?.phone || userDetail?.phone_principal) {
      phoneNo = userDetail.limited_address.prefix_call + " " + (userDetail?.phone || userDetail?.phone_principal)
    }
    return phoneNo
  }, [userDetail])

  useEffect(() => {
    localStorage.removeItem(localstorageKey.prevPath)
  }, [])

  // Navigation to Subsidiary's shop page
  const handleNavigate = () => {
    if (userDetail.source == profileTypeString.subsidiary) {
      return routes.publicRoutes.shopURL + "/" + userDetail.slug + '/classic/car'
    }
  }

  const handleNavigatetoPublicProfile = () => {
    if (userDetail.source == profileTypeString.user) {
      return routes.publicRoutes.public_profile_user + "/" + userDetail.user_name
    }
    if (userDetail.source == profileTypeString.subsidiary) {
      return routes.publicRoutes.public_profile_subisidiary + "/" + (userDetail.slug || userDetail.user_name)
    }
  }


  const showcaseAdsText = () => {
    return userDetail.vehicles_count < 2 ? t(translations.PUBLICPROFILE.AD, { ad: userDetail.vehicles_count }) : t(translations.PUBLICPROFILE.ADS, { ads: userDetail.vehicles_count });
  }

  const handleLocationPopup = () => {
    setOpenLocationPopup(true)
  }

  const UserDetailsSection = () => {
    if (hideUserInfo == showAuctionsTo.LOGGEDIN && (adListingType === adsTypeName.auctionAds)) {
      return <UserDetailSkeleton />
    }
    else if (hideUserInfo == showAuctionsTo.PRIVATE && (adListingType === adsTypeName.auctionAds)) {
      return <Box className='flexRow justifyContentBetween userInfoContainer denied'>
        <p>{t(translations.ads.SALES_DATA_FOR_PRIVATE)}</p>
      </Box>
    }
    else if (hideUserInfo == showAuctionsTo.DEALER && (adListingType === adsTypeName.auctionAds)) {
      return <Box className='flexRow justifyContentBetween userInfoContainer denied'>
        <p>{t(translations.ads.SALES_DATA_FOR_PROFESSIONALES)}</p>
      </Box>
    }
    else if (!showUserDetails) {
      return <Box className='flexRow userDetailsButtonGroup'>

        {(phoneNumber && phoneNumber !== '') && (

          isSmallDevice ?
            <a href={`tel:${phoneNumber}`} onClick={() => handleCallCount(adId, callRequest, dispatch)} className='callButton'>
              <SvgMobile />
              {t(translations.ads.CALL)}
            </a>
            :
            <Button className='callButton' onClick={() => {
              handleCallCount(adId, callRequest, dispatch)
              _open(true)
            }}>
              <SvgMobile />
              {t(translations.ads.CALL)}
            </Button>
        )}

        <ChatIcon itemId={'ad_id'} ads={"contact"} handleChat={handleChat} />

      </Box>
    }
    else {
      return <Box className={`flexRow justifyContentBetween userInfoContainer`} sx={{ minWidth: '100%' }}>
        <Link to={handleNavigatetoPublicProfile()} className='flexRow pointer userDetailLink'>
          <Box className='flexRow alignItemsCenter' sx={{ position: 'relative', height: 'fit-content' }}>
            <img src={userDetail?.profile_picture_url ?? userDetail?.logo_url ?? UserPlaceholer} alt="user_image" className='userImage' />

            {userDetail?.limited_address?.flag_thumbnail && <img src={userDetail?.limited_address.flag_thumbnail} alt="flag_image" className='flagImage' />}
          </Box>
          <Box className='flexColumn userDetailLink' sx={{ ml: { xs: 1, sm: 2 } }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span className='name'> {userDetail?.pseudo ?? userDetail?.name ?? "-"} {userDetail?.profile_is_verified ? <SvgfluentIcon /> : null} </span>
            </Box>
            <Box className='flexRow alignItemsCenter user-detail'>
              <span className='flexRow' onClick={(event) => {
                event.preventDefault()
                handleLocationPopup()
              }}>  <SvgLocation /> {userDetail?.limited_address?.postal_code ? userDetail.limited_address.postal_code : '-'}</span>
              <Box className='line'></Box>
              <SvgStar className='star' />
              <span className='rating'> {userDetail?.average_ratings ?? 0} ({userDetail?.total_ratings_count ?? 0})</span>
            </Box>
          </Box>
        </Link>
        <Box className="flexColumn justifyContentCenter" >
          <Box className='flexRow justifyContentAround button-wrapper' ref={myDivRef} sx={{ gap: '5px' }}>
            {(!showOnlyMessage && phoneNumber != '') &&
              <Tooltip title={t(translations.PUBLICPROFILE.CALL) ?? ''}>
                {
                  isSmallDevice ?
                    <a href={`tel:${phoneNumber}`} onClick={() => handleCallCount(adId, callRequest, dispatch)} className='phoneIcon'>
                      <SvgMobile />
                    </a>
                    :
                    <span onClick={() => {
                      _open(true)
                      handleCallCount(adId, callRequest, dispatch)
                    }} className='phoneIcon pointer'>
                      <SvgMobile />
                    </span>
                }
              </Tooltip>
            }
            <ChatIcon isDisableBtn={isDisableBtn} itemId={'ad_id'} ads={(adListingType != 'classAds') && showOnlyMessage ? "adsList" : phoneNumber != '' ? "adsDetail" : "adsList"} handleChat={handleChat} />
          </Box>
        </Box>
        {
          userDetail?.source == profileTypeString.subsidiary ?
            <Box className='profileBadge'>
              <Link className='link' to={{
                pathname: handleNavigate(),
                prevPath: location.pathname,
                prevLocation: location.search
              }}
                onClick={() => dispatch(getPublicAdsListReset())}
              > {`${t(translations.ads.PRO_SELLER)} ( ${showcaseAdsText()} )`}</Link>
            </Box>
            :
            <Box className='profileBadge  private'>
              <span>{`${t(translations.ads.PRIVATE_SELLER)}`}</span>
            </Box>
        }
      </Box>
    }
  }
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: isSmallDevice ? 300 : 400,
    width: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    px: 0,
    py: 2,
  };


  return (
    <>
      <UserDetailWrapper style={{ maxWidth: isSmallDevice ? "100%" : (showcaseAuction && isLargeDevice) ? '70%' : '470px' }} className='userDetailsContainer'>
        {UserDetailsSection()}
        <UserContactsPopUp open={open} setOpen={_open} phone={phoneNumber} email={''} btn={'call'} />
      </UserDetailWrapper>
      <Modal
        open={openLocationPopup}
        onClose={() => setOpenLocationPopup(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UserDetailWrapper>
          <Box sx={style} className='infoModal'>
            <Box className='relative'>
              <SvgClose className='closeIcon' onClick={() => setOpenLocationPopup(false)} />
            </Box>
            <span className='locationSubHeader modalHeader border'>{t(translations.ads.LOCATION)}</span>
            <p className='locationContent modalContent '><SvgLocation /><span className='postalCode'>{userDetail?.limited_address?.postal_code || '-'}</span> <span className='locality'>{userDetail?.limited_address?.locality || '-'}</span></p>
            <span className='locationContent country-name'>{userDetail?.limited_address?.country || '-'}</span>
          </Box>
        </UserDetailWrapper>
      </Modal>

    </>
  )
}

export default UserDetails