import { Box, Button } from '@mui/material'
import { SvgCartobikeCredit } from 'app/components/svgicons/svgNew'
import { translations } from 'locales/translations'
import { Trans, useTranslation } from 'react-i18next'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LearnMorePopup from './LearnMorePopup'
import { handleBuyCredits } from 'config/utils'
import { hasPermissions, PERMISSIONS } from 'config/permission'

const CreditBalance = () => {
    //redux states
    const { myWalletBalance } = useSelector((state: RootStateOrAny) => state.auth)
    const permissionList = useSelector((state: any) => state.user.permissionData)


    // hooks
    const { t } = useTranslation();
    const history = useHistory();
    const hasAddPermission = hasPermissions(permissionList, PERMISSIONS.FEATURES.FINANCE, PERMISSIONS.ACTIONS.ADD)
    const hasUpdatePermission = hasPermissions(permissionList, PERMISSIONS.FEATURES.FINANCE, PERMISSIONS.ACTIONS.UPDATE)


    const LearmMoreCreditContent = [
        <Trans
            i18nKey={t(translations.CTB_CREDIT.FLASH_SALES)}
            components={{
                bold: <strong className='bold-text' />,
            }}
        />,
        <Trans
            i18nKey={t(translations.CTB_CREDIT.NO_RESERVATION)}
            components={{
                bold: <strong className='bold-text' />,
            }}
        />,
        <Trans
            i18nKey={t(translations.CTB_CREDIT.LICENSE_PLATE_SEARCH)}
            components={{
                bold: <strong className='bold-text' />,
            }}
        />
    ]

    return (
        <>
            <Box className='flexRow justifyContentBetween alignItemsCenter cartobike-credit'>
                <Box className='flexColumn '>
                    <h1 className='credit-headers'>{t(translations.HOT_DEALS.CARTOBIKE_CREDIT)}</h1>
                    <span className='ctb-description'>{t(translations.HOT_DEALS.CARTOBIKE_CREDIT_DESCRIPTION)}</span>
                </Box>
                <Box className='learn-more'>
                    <LearnMorePopup header={t(translations.HOT_DEALS.CARTOBIKE_CREDIT)} contentList={LearmMoreCreditContent} buttonText={t(translations.HOT_DEALS.LEARN_MORE)} />
                </Box>
            </Box>
            <Box className='flexRow justifyContentBetween alignItemsCenter cartobike-balance'>
                <Box className={'credit-bal '}>
                    <h2 className='credit-headers'>{t(translations.HOT_DEALS.AVAIL_BALANCE)}</h2>
                    <span className='flexRow alignItemsCenter available-credits'>{myWalletBalance} <SvgCartobikeCredit /></span>
                </Box>
                {(hasAddPermission || hasUpdatePermission) && <Box className='learn-more'>
                    <Button variant="contained" className='button-credits' onClick={() => handleBuyCredits(history, history.location.pathname)}>
                        {t(translations.HOT_DEALS.BUY_CREDITS)}
                    </Button>
                </Box>}
            </Box>
        </>
    )
}

export default CreditBalance