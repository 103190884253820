import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const FilterWrapper = styled.div` 
 &>div::-webkit-scrollbar{
 /* width:0;
 height:0; */
}
.not-applied-filters{
  margin:0 !important;
}
span{
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  color: #000;
}
.filterHeader{
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0.42px;
    min-height : 40px;
    display : flex;
    align-items : center;
}
.filterDropdown{
  padding: 8px;
}
.openDropDown{
  border: 2px solid #6579F4;
  transition: all .5s ease-in-out;
}
.closeDropDown{
  border: 2px solid #fff;
  transition: all .5s ease-in-out;
}
.fieldHeader span, .soldVehicle{
  color: #666 !important;
  text-align: start;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.36px;
}
.fieldText{
  margin-left : 5px;
}
.dropdownIcon svg {
  width:17px;
}
.dropdownIcon svg path{
  fill : rgb(102, 102, 102) !important
}
.showFilter{
  opacity: 1;
  max-height: 1200px;
  height: auto;
  transition: max-height .75s,opacity .5s ease-in;
}
.mobileSearchBox{ 
  width: 100%;
  gap: 5px;
  margin-bottom: 10px;

}
.mobileSearchBox svg{
  height : 20px;
}
.mobileSearchBox span{
  color : #666666;

}
.mobileSearchBox input{
  width : 100%;
  height: 40px;
  border : none;
  font-size: 18px;
  background: #F8F8F8;
  color : #666666;
  &:focus-visible{
     outline:none; 
  }
}
.hideFilter{
  transition: max-height .5s,opacity .75s ease-out;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
}
.filterList{
  overflow: auto;
  max-height: calc(100vh - 210px);
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.filterCheckBox .MuiFormControlLabel-label{
  color: #000;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.vatCheckBoxLabel{
  color: #000;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.myAdsFilters{
  margin: 0 0 8px 0;
}
.filterDropDownOption svg{
  color:#666
}

// ------------------------------------------------------EQUIPMENTS------------------------------------------------------
.equipmentsList{
  border: 1px solid #CCC;
}
.searchEquimentsField{
  border: 1px solid #CCC;
  gap:5px;
}
.searchEquimentsField svg{
  margin-left : 5px;
  width :22px;
  height :22px;
}
.searchEquimentsField span{
  color: var(--text-deep-gray,#666);
  font-weight: 400;
  letter-spacing: 0.3px;
}
.searchEquimentsField input{
  width : 100%;
  border: none;
  height : 40px;
  color: var(--text-deep-gray,#666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.equipmentsCategory{
  display : flex;
  flex-direction : row;
  overflow-x: scroll;
  max-width: 100%;
  width: 100%;
}
.categoryName{
  cursor : pointer;
  padding : 10px 15px;
  color: var(--text-medium-gray, #999);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  min-width : fit-content;
}
.activeCategory{
  color:#6579F4;
  border-bottom 1px solid #6579F4;
}
.equimentListContainer{
  display : flex;
  flex-direction : column;
  gap : 10px;
  max-height : calc(100vh - 328px);
  overflow-y : auto;
}
.equipment{
  border-radius: 4px;
  border: 1px solid ${colorNew.colorGrayButton};
  background: #FFF;
}
.equipmentBox{
  margin : 5px 0;
}
.equipmentBox img{
  width: 32px;
  height: 32px;
  margin : 0 10px;
}
.equipmentBox span{
  color: var(--text-deep-gray, #666);
  font-size: 13px;
  font-weight: 400;
  line-height: 144.523%; /* 18.788px */
  letter-spacing: 0.26px;
}

// ------------------------------------------------------Applied Filters------------------------------------------------------
.appliedfilter{
  padding : 5px;
  min-width : max-content;
  border-radius: 4px;
  background: var(--Tint-2, #F1F1F9);
}
.appliedfilterHeader{
  color: var(--Shade-1, #384386);
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.3px;
}
.appliedFilterValue{
  display : flex;
  flex-direction : row;
}
.filterValue{
  padding : 0 5px;
  color: var(--Shade-1, #384386);
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  display : flex;
  flex-direction : row;
  align-items : center;
  text-transform: capitalize;
}
.filterValue .removeIcon{
  margin-left :5px;
  cursor : pointer;
  width :10px;
}

// ------------------------------------------------------Mobile Drawer------------------------------------------------------
.deleteButton{
  height : 50px;
  width : 100px;
  border-radius: 6px;
  border: 1px solid ${colorNew.colorGrayButton};
  background: #FFF;
}
.deleteButton svg{
  width: 25px;
  height: 25px;
}
.applyButton{
  width : calc(100% - 110px);
  height : 50px;
  border-radius: 6px;
  background: var(--Primary-Color, #6579F4);
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.42px; 
} 
@media (max-width:768px){
  .filterHeaderMobile{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
  }
  .filterList{
    height: unset;
    max-height: unset; 
  }
  .filterDropdown:last-child {
    margin-bottom: 80px;
  }
  .closeFilters{
    bottom: 0;
    background-color: white;
    padding: 20px;
    gap: 10px;
    position: fixed;
    width: 100%;
  }
 }

 @media (min-width :769px) and (max-width :992px){

  .filterList{
    max-height: calc(100vh - 100px);
  }
}

`