import { Box, Divider } from '@mui/material';
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import { SvgTrophyIcon } from 'app/components/svgicons/svgNew';
import { adsTypeName, bidTYpes, showAuctionsTo } from 'config/constants';
import { getSubsidiaryStored, numberFormat, trophy } from 'config/variables';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'app/components/stylesComponents/Ads/ProgressBar';
import Bid from './bid/bid';
import AuctionSwitchProfile from 'app/Component2/Layouts/AdsLayouts/component/AdsListing/AuctionSwitchProfile';
import Counter from './counter/counter';
import UserDetails from 'app/components/UserDetails';
import Information from 'app/components/stylesComponents/Ads/Information';
import TrophyInfoModal from 'app/components/stylesComponents/Ads/TrophyInfoModal';
import { useSelector } from 'react-redux';

const BidsTimerUserDetails = ({
  currentPrice,
  setOpenModalProgress,
  isActive,
  adDetail,
  removeAdsAuction,
  handleChat,
  handleDisplayBiding,
  auctionTime,
  setOpenModalTrophyInfo,
  openModalProgress,
  openModalTrophyInfo,
  _contestEndingMessage,
  _isContestEnding,
  isFocused,
  _isFocused,
  bid,
  _bid,
  autoBid,
  _autoBid,
  _updateTimer,
  showUserDetails,
}) => {
  const { t } = useTranslation();
  const [isSmallDevice, isMediumDevice] = useDeviceType();

  const { loggedIn } = useSelector((state: any) => state.auth);

  const subsidiaryId = getSubsidiaryStored();

  return (
    <>
      <Box className="flexRow justifyContentBetween priceMainContainer">
        <Box sx={{ width: '100%' }} className="priceContainer flexColumn">
          {isSmallDevice ? (
            <Box className="currentPrice">{t(translations.ads.CURRENT_PRICE)}</Box>
          ) : null}
          <Box className="price-wrap">
            <p className={`price`}>
              € {currentPrice || currentPrice === '0' ? (
                currentPrice
              ) : (
                <span className="hiddenPrice">★★★★★</span>
              )}
            </p>
            <Box
              sx={{
                div: {
                  justifyContent: 'flex-start !important',
                },
              }}
            >
              <ProgressBar
                filledPercent={adDetail.filled_percent ?? 0}
                handleOpenProgress={() => setOpenModalProgress(true)}
                positionIcon={'right'}
              />
            </Box>
          </Box>
        </Box>
        {adDetail?.best_bid?.amount && loggedIn ? (
          <>
            <Box className="trophy">{adDetail.trophy == trophy.won && <SvgTrophyIcon />}</Box>
            {adDetail?.best_bid?.type == bidTYpes.auto && (
              <span className="autoBidText">{t(translations.ads.AUTO_BID)}</span>
            )}
            <Box sx={{ width: '49%' }} className="myBid flexColumn">
              <Box className="flexRow justifyContentBetween alignItemsCenter">
                <span className="priceHeader">
                  {t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_MY_BID)}
                </span>
              </Box>
              {handleDisplayBiding(adDetail) == showAuctionsTo.ALL ? (
                <span className="price">
                  € {numberFormat(Math.trunc(adDetail?.best_bid?.amount)) ?? 0}
                </span>
              ) : (
                'Unavailable'
              )}
            </Box>
          </>
        ) : null}
      </Box>
      {(isSmallDevice || isMediumDevice) && (
        <>
          {handleDisplayBiding(adDetail) === showAuctionsTo.ALL ? (
            <Box className="bidTimer flexColumn justifyContentStart alignItemsCenter">
              <Divider className="divider" />
              <Bid
                adDetail={adDetail}
                key={adDetail?.best_manual_bid_relation?.amount}
                isActive={isActive}
                isFocused={isFocused}
                _isFocused={_isFocused}
                bid={bid}
                _bid={_bid}
                autoBid={autoBid}
                _autoBid={_autoBid}
              />{' '}
            </Box>
          ) : (
            <AuctionSwitchProfile
              subsidiaryId={subsidiaryId}
              adDetail={adDetail}
              isActive={isActive}
            />
          )}
        </>
      )}
      {isSmallDevice || isMediumDevice ? (
        <Counter
          adDetail={adDetail}
          auctionTime={auctionTime}
          removeAdsAuction={removeAdsAuction}
          _isContestEnding={_isContestEnding}
          _contestEndingMessage={_contestEndingMessage}
          _updateTimer={_updateTimer}
        />
      ) : null}
      <UserDetails
        adId={adDetail.id}
        userDetail={adDetail.user_data}
        handleChat={handleChat}
        adListingType={adsTypeName.auctionAds}
        hideUserInfo={handleDisplayBiding(adDetail)}
        showOnlyMessage={true}
        showUserDetails={showUserDetails}
      />
      <Information
        openModalProgress={openModalProgress}
        handleCloseProgress={() => setOpenModalProgress(false)}
      />
      <TrophyInfoModal
        openModal={openModalTrophyInfo}
        handleClose={() => setOpenModalTrophyInfo(false)}
      />
    </>
  );
};

export default BidsTimerUserDetails;
