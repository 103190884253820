import { Box, Fade, Tab, Tabs } from '@mui/material';
import TabParent from 'app/Component2/Layouts/AdsLayouts/component/AdsListing/TabParent';
import { useWindowSize } from 'app/Hooks/windowResize';
import React, { useEffect, useRef, useState } from 'react'
import UserList from './userList';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import * as publicProfileConstants from "config/publicProfileConfig"
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { followersListRequest, followingListRequest } from 'store/actions/publicProfile-action';
import { useParams } from 'react-router-dom';
import { getSubsidiaryStored } from 'config/variables';
import { followers } from 'app/pages/PublicProfile/interface/interface';

const FollowersAndFollowing = ({ selectedTab, setOpen, followersCount = 0, followingCount }) => {

  const { profileSuccessData, followersList, followingList, followersLastPage, followingLastPage, followUnfollowSuccessData } = useSelector((state: RootStateOrAny) => state.publicProfile)
  const { loggedIn, user } = useSelector((state: RootStateOrAny) => state.auth)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [mobile] = useWindowSize()
  const { userType, slug } = useParams()

  const [valueTab, setValueTab] = React.useState(() => selectedTab || publicProfileConstants.FollowerTabs.Followers);

  const scrollRefFollower: any = useRef(null)
  const scrollRefFollowing: any = useRef(null)

  const [followers, _followers] = useState<Array<followers>>([])
  const [following, _following] = useState<Array<followers>>([])

  const [followerKeyword, _followerKeyword] = useState<string>('')
  const [followingKeyword, _followingKeyword] = useState<string>('')

  const [followerPage, _followerPage] = useState<number>(1)
  const [followingPage, _followingPage] = useState<number>(1)

  const [scrollFlagFollowers, _scrollFlagFollowers] = useState<boolean>(true);
  const [scrollFlagFollowing, _scrollFlagFollowing] = useState<boolean>(true);

  const [selfProfile, _selfProfile] = useState<boolean>(false)
  const [actionTaken, _actionTaken] = useState<boolean>(false)


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };


  // Initially requesting followers  list
  useEffect(() => {
    requestFollowersList()
  }, [profileSuccessData.id, getSubsidiaryStored(), followerKeyword])


  // Function for calling followers list API
  const requestFollowersList = () => {
    let payload = {
      page: 1,
      keyword: followerKeyword
    }
    if (userType == publicProfileConstants.profile.user) {
      payload['profile_user_id'] = profileSuccessData.id
    }
    else if (userType == publicProfileConstants.profile.pro) {
      payload['profile_subsidiary_id'] = profileSuccessData.id
    }
    dispatch(followersListRequest(payload))
  }


  // Initially requesting following list
  useEffect(() => {
    requestFollowingList()
  }, [profileSuccessData.id, getSubsidiaryStored(), followingKeyword])


  // Setting variable if user opens his profile
  useEffect(() => {
    if (loggedIn && profileSuccessData && Object.keys(profileSuccessData).length > 0) {
      // Comparing id's of logged in user and public profile page's id when logged in as private user
      if (user.id == profileSuccessData.id && !getSubsidiaryStored()) {
        _selfProfile(true)
        return
      }
      // Comparing id's of logged in subsidiary and public profile page's id when logged in as subsidiary user
      if (getSubsidiaryStored() && getSubsidiaryStored() == profileSuccessData.id) {
        _selfProfile(true)
        return
      }
      _selfProfile(false)
    }
  }, [profileSuccessData.id, slug, getSubsidiaryStored()])


  useEffect(() => {
    // setting variable for API call when user follows/unfollows any user on tab change when he has opened self profile
    if (followUnfollowSuccessData && Object.keys(followUnfollowSuccessData).length > 0 && selfProfile) {
      _actionTaken(true)
    }
  }, [followUnfollowSuccessData])

  useEffect(() => {
    if (valueTab == publicProfileConstants.FollowerTabs.Followers && followers.length > 0 && actionTaken) {
      requestFollowersList()
      _actionTaken(false)
    }
    if (valueTab == publicProfileConstants.FollowerTabs.Following && following.length > 0 && actionTaken) {
      requestFollowingList()
      _actionTaken(false)
    }

  }, [valueTab])

  // Function for calling following list API
  const requestFollowingList = () => {
    let payload = {
      page: 1,
      keyword: followingKeyword
    }
    if (userType == publicProfileConstants.profile.user) {
      payload['profile_user_id'] = profileSuccessData.id
    }
    else if (userType == publicProfileConstants.profile.pro) {
      payload['profile_subsidiary_id'] = profileSuccessData.id
    }
    dispatch(followingListRequest(payload))
  }


  // setting Followers List
  useEffect(() => {
    _followers(followersList)
  }, [followersList])

  // setting Following List
  useEffect(() => {
    _following(followingList)
  }, [followingList])


  //Function request for next page data when scrolled to bottom
  const handleScrollFollowers = (e) => {
    // bottom return boolean value and Math.abs to get absolute value 
    const bottom = (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1;
    if (bottom && scrollFlagFollowers && followerPage < followersLastPage) {
      _followerPage(followerPage + 1)
      let payload = {
        page: followerPage + 1,
        keyword: followerKeyword
      }
      if (userType == publicProfileConstants.profile.user) {
        payload['profile_user_id'] = profileSuccessData.id
      }
      else if (userType == publicProfileConstants.profile.pro) {
        payload['profile_subsidiary_id'] = profileSuccessData.id
      }
      dispatch(followersListRequest(payload))
      _scrollFlagFollowers(false)
      return;
    } else {
      _scrollFlagFollowers(true)
    }
  }

  //Function request for next page data when scrolled to bottom
  const handleScrollFollowing = (e) => {
    // bottom return boolean value and Math.abs to get absolute value 
    const bottom = (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1;
    if (bottom && scrollFlagFollowing && followingPage < followingLastPage) {
      _followingPage(followingPage + 1)
      let payload = {
        page: followingPage + 1,
        keyword: followingKeyword
      }
      if (userType == publicProfileConstants.profile.user) {
        payload['profile_user_id'] = profileSuccessData.id
      }
      else if (userType == publicProfileConstants.profile.pro) {
        payload['profile_subsidiary_id'] = profileSuccessData.id
      }
      dispatch(followingListRequest(payload))
      _scrollFlagFollowing(false)
      return;
    } else {
      _scrollFlagFollowing(true)
    }
  }



  return (
    <Box sx={{ height: mobile ? "92vh" : "auto" }}>
      <Tabs value={valueTab} onChange={handleChange} aria-label="icon position tabs example" sx={{ width: '100%', borderBottom: '1px solid #e9e9e9' }}>
        <Tab label={`${publicProfileConstants.convertFollowersCount(followersCount) || 0} ${(followersCount > 1) ? t(translations.PUBLICPROFILE.FOLLOWERS) : t(translations.PUBLICPROFILE.FOLLOWER)}`} iconPosition="start" sx={{ width: '50%', maxWidth: '100%' }} style={{ textTransform: 'none', fontSize: '18px' }} />
        <Tab label={`${publicProfileConstants.convertFollowersCount(followingCount) || 0} ${t(translations.PUBLICPROFILE.FOLLOWING)}`} iconPosition="start" sx={{ width: '50%', maxWidth: '100%' }} style={{ textTransform: 'none', fontSize: '18px' }} />
      </Tabs>
      <TabParent value={valueTab} index={publicProfileConstants.FollowerTabs.Followers} setWidth={"width-100 no-margin"}>
        <Fade in={true}>
          <Box>
            <UserList list={followers} keyword={followerKeyword} setKeyword={_followerKeyword} setPage={_followerPage} scrollFunc={handleScrollFollowers} refFunc={scrollRefFollower} listType={publicProfileConstants.FollowerTabs.Followers} setOpen={setOpen} updateUserList={_followers} />
          </Box>
        </Fade>
      </TabParent>
      <TabParent value={valueTab} index={publicProfileConstants.FollowerTabs.Following} setWidth={"width-100 no-margin"}>
        <Fade in={true}>
          <Box>
            <UserList list={following} keyword={followingKeyword} setKeyword={_followingKeyword} setPage={_followingPage} scrollFunc={handleScrollFollowing} refFunc={scrollRefFollowing} listType={publicProfileConstants.FollowerTabs.Following} setOpen={setOpen} updateUserList={_following} />
          </Box>
        </Fade>
      </TabParent>
    </Box>
  )
}

export default FollowersAndFollowing