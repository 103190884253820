import React, { useEffect, useState } from 'react'
import { FilterWrapper } from './style'
import { Box, Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { Filters, stringToArray } from './filterConfig'
import KeyData from './components/keyData'
import Price from './components/price'
import Equipment from './components/equipment'
import Performance from './components/performance'
import History from './components/history'
import SellerAndWarranty from './components/sellerAndWarranty'
import Country from './components/country'
import { urlParamInterface } from 'config/interface'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { adsTypes, adsTypesAuction, vehicles } from 'config/variables'
import EquipmentList from './components/equipmentList'
import { routes } from 'routes/routes'
import Electric from './components/electric'
import { getParamQueries } from 'config/appConfig'
import { Nullable } from 'app/pages/Chat/interface/interface'
import { SVGCross, SvgMinus, SvgMobileLeftArrow, SvgPlus } from '../svgicons/svgNew'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import SelectProduct from './components/selectProduct'
import { SvgSearchLite } from '../svgicons/svg'
import { RootStateOrAny, useSelector } from 'react-redux'
import { localstorageKey } from 'config/constants'
import MyadsFilters from './components/myAdsFilter'

const Filter = ({ handleBackFromFilters, keyword, handleChangeSearch }) => {

    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const { vehicle, id, brand_id, model_id, country_id, ads, auction_ads, ads_type, slug }: urlParamInterface = useParams();

    const [isSmallDevice, isMediumDevice] = useDeviceType()

    const [isMyAdsPage, _isMyAdsPage] = useState(() => location.pathname.includes(routes.vehicleRoutes.myAds))
    const [isPublicAdsPage, _isPublicAdsPage] = useState(() => location.pathname.includes(routes.vehicleRoutes.publicAds))
    const [isAuctionAdsPage, _isAuctionAdsPage] = useState(() => location.pathname.includes(routes.vehicleRoutes.auctionAd))
    const [isShopPage, _isShopPage] = useState(() => location.pathname.includes(routes.publicRoutes.shopURL))
    const [isHotdealPage, _isHotdealPage] = useState(() => location.pathname.includes(routes.publicRoutes.hotDeals))

    const { loadingForce } = useSelector((state: RootStateOrAny) => state.vehicle)

    const [openDropdown, setOpenDropdown] = useState<number>(() =>
        // If page is of my ads we will open product filter dropown
        isMyAdsPage || isShopPage ?
            Filters.PRODUCT :
            // If page is of public ads we will open key data filter dropown
            Filters.KEY_DATA
    )

    const [filterData, _filterData] = useState<Object>({})
    const [selectedEquipments, setSelectedEquipments] = useState<Array<number>>([])
    const [showEquipments, setShowEquipments] = useState<boolean>(false)

    const [clearFilter, _clearFilter] = useState<boolean>(false)
    const [rerenderFilters, _rerenderFilters] = useState<Nullable<number>>()

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown == dropdown) {
            setOpenDropdown(Filters.NONE)
            return
        }
        setOpenDropdown(dropdown)
    }

    useEffect(() => {
        if (vehicle == vehicles.step) {
            if (localStorage.getItem(localstorageKey.stepFilterdata)) {
                _filterData(JSON.parse(localStorage.getItem(localstorageKey.stepFilterdata) || ''))
            }
            return
        }
        if (vehicle == vehicles.bike) {
            if (localStorage.getItem(localstorageKey.bikeFilterdata)) {
                _filterData(JSON.parse(localStorage.getItem(localstorageKey.bikeFilterdata) || ''))
            }
            return
        }
        if (vehicle == vehicles.motorcycles) {
            if (localStorage.getItem(localstorageKey.motorCycleFilterdata)) {
                _filterData(JSON.parse(localStorage.getItem(localstorageKey.motorCycleFilterdata) || ''))
            }
            return
        }
        if (vehicle == vehicles.car) {
            if (localStorage.getItem(localstorageKey.carFilterdata)) {
                _filterData(JSON.parse(localStorage.getItem(localstorageKey.carFilterdata) || ''))
            }
            return
        }

    }, [vehicle, loadingForce])

    useEffect(() => {
        if (clearFilter) {
            _rerenderFilters(Math.random())
        }
        _clearFilter(false)
    }, [clearFilter])

    useEffect(() => {
        if (location.state == undefined && selectedEquipments.length > 0) {
            updateURL('equipment_id', selectedEquipments)
        }
    }, [selectedEquipments])

    // Sets values got from url to fields (runs only when page is rendered for the first time)
    useEffect(() => {
        const payload = getParamQueries(location.search)
        stringToArray(setSelectedEquipments, payload.equipment_id ?? null)
    }, [location.search])


    const showClearFilter = () => {

        if (brand_id || model_id || country_id) {
            return true
        }

        const payload = getParamQueries(location.search)

        if (payload && Object.keys(payload).length > 0) {
            if (Object.keys(payload).length == 1 && payload['page']) {
                return false
            }
            return true
        }
        return false
    }

    // Updating URL whenever any of the filter is applied
    const updateURL = (filterName, filterValue) => {

        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        // Add only if filter value is not empty (number / string ) or blank array (multilple selection fields)
        if (
            ((typeof filterValue == 'number' || typeof filterValue == 'string') && filterValue !== '' && filterValue !== null && filterValue !== undefined) ||
            (filterValue !== null && typeof filterValue == 'object' && filterValue.length > 0)
        ) {

            // Checks if filter is already present or not in URL (if yes replace it with new values)
            if (params.has(filterName)) {
                params.delete(filterName)
            }
            params.append(filterName, filterValue)
            if ((isPublicAdsPage || isShopPage) && params.has('page')) {
                params.delete('page')
                params.append('page', 1)
            }
        } else {
            // Removes query if filter value is blank
            params.delete(filterName)
        }
        // Updates URL string
        history.replace({ search: params.toString().replaceAll('%2C', ',') })
        if (!isShopPage) {
            window.scrollTo(0, 0)
        }
    }

    const handleClearFilter = () => {
        if (isAuctionAdsPage) {
            history.replace(`${routes.vehicleRoutes.auctionAd}/${vehicle}/${id}`)
            return
        }
        if (isPublicAdsPage) {
            history.replace(`${routes.vehicleRoutes.publicAds}/${vehicle}`)
            return
        }
        if (isShopPage) {
            const shopRoute = `${routes.publicRoutes.shopURL}/${slug}`;
            if (ads_type == adsTypes.classic) {
                history.push(`${shopRoute}${routes.vehicleRoutes.classic}/${vehicle}`)
                return
            }
            if (ads_type == adsTypes.auction) {
                history.push(`${shopRoute}${routes.vehicleRoutes.auction}/${vehicle}`)
            }
            if (ads_type == adsTypes.hotDeals) {
                history.push(`${shopRoute}${routes.vehicleRoutes.hotDeals}/${vehicle}`)
            }
        }
        if (isMyAdsPage) {
            const myAdsVehicle = `${routes.vehicleRoutes.myAds}/${vehicle}`;
            const myAdsVehicleAuction = `${myAdsVehicle}${routes.vehicleRoutes.auction}`;
            if (ads === adsTypes.classic) {
                history.replace(`${myAdsVehicle}${routes.vehicleRoutes.classic}`)
                return
            }
            if (ads === adsTypes.park) {
                history.replace(`${myAdsVehicle}${routes.vehicleRoutes.park}`)
                return
            }
            if (ads === adsTypes.hotDeals) {
                history.replace(`${myAdsVehicle}${routes.vehicleRoutes.hotDeals}`)
                return
            }
            if (ads === adsTypes.auction) {
                if (auction_ads == adsTypesAuction.live) {
                    history.replace(`${myAdsVehicleAuction}${routes.vehicleRoutes.live}`)
                    return
                }
                if (auction_ads == adsTypesAuction.action) {
                    history.replace(`${myAdsVehicleAuction}${routes.vehicleRoutes.action}`)
                    return
                }
                if (auction_ads == adsTypesAuction.history) {
                    history.replace(`${myAdsVehicleAuction}${routes.vehicleRoutes.history}`)
                }
            }
        }
        _clearFilter(true)
    }


    return (
        <FilterWrapper style={{ height: isSmallDevice || isMediumDevice ? '100%' : 'calc(100vh - 150px)' }} id='filterContainer'>

            <Box display={showEquipments ? 'none' : 'block'} sx={{ marginLeft: '6px' }}>
                <Box sx={{ px: 2, py: 1 }} className='flexRow justifyContentBetween alignItemsCenter filterHeaderMobile'>
                    {isSmallDevice || (isMediumDevice && !isMyAdsPage) ?
                        <>
                            <SvgMobileLeftArrow onClick={handleBackFromFilters} />
                            <span className='filterHeader'>{t(translations.ads.FILTER)}</span>
                            <SvgMobileLeftArrow style={{ opacity: '0' }} />
                        </> :
                        <>
                            <span className='filterHeader'>{t(translations.ads.FILTER)}</span>
                            {showClearFilter() && <span onClick={handleClearFilter} className='flexRow justifyContentBetween alignItemsCenter pointer' style={{ color: '#EC1C24', marginTop: '1px' }}><SVGCross style={{ width: '20px' }} /> {t(translations.FILTER_COMPONENT.FILTER_CLEAR_ALL)}</span>}
                        </>
                    }
                </Box>


                <Box sx={{ p: 2 }} className='filterList scrollBar' key={rerenderFilters}>

                    {
                        // Search doesn't appears for my ads in auction 
                        (isSmallDevice || isMediumDevice) && ads !== adsTypes.auction && <Box className='mobileSearchBox flexRow alignItemsCenter'>
                            <SvgSearchLite />
                            <span>|</span>
                            <input type='text' placeholder={t(translations.ads.SEARCH)} onChange={(e) => handleChangeSearch(e)} value={keyword} />
                        </Box>
                    }


                    {/* Product filter only appears on my ads page and shop page */}
                    {(isMyAdsPage || (isShopPage && !isHotdealPage)) && <Box className='myAdsFilters'>
                        <SelectProduct />
                    </Box>}

                    {/* Product filter only appears on my ads page */}
                    {(isMyAdsPage) && <MyadsFilters />}


                    <Box className={'flexColumn filterDropdown ' + (openDropdown == Filters.KEY_DATA ? 'openDropDown' : 'closeDropDown')}>
                        <Box className='flexRow justifyContentBetween fieldHeader alignItemsCenter pointer' onClick={() => handleOpenDropdown(Filters.KEY_DATA)}>
                            <span>{t(translations.FILTER_COMPONENT.FILTER_KEY_DATA)}</span>
                            <span className='dropdownIcon'>{openDropdown == Filters.KEY_DATA ? <SvgMinus /> : <SvgPlus />}</span>
                        </Box>
                        <Box className={'flexColumn ' + (openDropdown == Filters.KEY_DATA ? 'showFilter' : 'hideFilter')} sx={{ mt: 2 }}>
                            <KeyData filterData={filterData} updateURL={updateURL} />
                        </Box>
                    </Box>

                    {(isPublicAdsPage || isShopPage) && <Box className={'flexColumn filterDropdown ' + (openDropdown == Filters.PRICE ? 'openDropDown' : 'closeDropDown')}>
                        <Box className='flexRow justifyContentBetween fieldHeader alignItemsCenter pointer' onClick={() => handleOpenDropdown(Filters.PRICE)}>
                            <span>{t(translations.ads.PRICE)}</span>
                            <span className='dropdownIcon'>{openDropdown == Filters.PRICE ? <SvgMinus /> : <SvgPlus />}</span>
                        </Box>
                        <Box className={'flexColumn ' + (openDropdown == Filters.PRICE ? 'showFilter' : 'hideFilter')} sx={{ mt: 2 }}>
                            <Price updateURL={updateURL} />
                        </Box>
                    </Box>}

                    {/* Filter available only for cars */}
                    {(vehicle == vehicles.car || vehicle == vehicles.motorcycles) && <Box className={'flexColumn filterDropdown ' + (openDropdown == Filters.PERFORMANCE ? 'openDropDown' : 'closeDropDown')}>
                        <Box className='flexRow justifyContentBetween fieldHeader alignItemsCenter pointer' onClick={() => handleOpenDropdown(Filters.PERFORMANCE)}>
                            <span>{t(translations.FILTER_COMPONENT.FILTER_COMPONENT_PERFORMANCE)}</span>
                            <span className='dropdownIcon'>{openDropdown == Filters.PERFORMANCE ? <SvgMinus /> : <SvgPlus />}</span>
                        </Box>
                        <Box className={'flexColumn ' + (openDropdown == Filters.PERFORMANCE ? 'showFilter' : 'hideFilter')} sx={{ mt: 2 }}>
                            <Performance filterData={filterData} updateURL={updateURL} vehicle={vehicle} />
                        </Box>
                    </Box>}

                    {/* Filter available only for cars */}
                    {(vehicle == vehicles.bike || vehicle == vehicles.step) && <Box className={'flexColumn filterDropdown ' + (openDropdown == Filters.ELECTRIC ? 'openDropDown' : 'closeDropDown')}>
                        <Box className='flexRow justifyContentBetween fieldHeader alignItemsCenter pointer' onClick={() => handleOpenDropdown(Filters.ELECTRIC)}>
                            <span>{t(translations.FILTER_COMPONENT.FILTER_COMPONENT_ELECTRIC)}</span>
                            <span className='dropdownIcon'>{openDropdown == Filters.ELECTRIC ? <SvgMinus /> : <SvgPlus />}</span>
                        </Box>
                        <Box className={'flexColumn ' + (openDropdown == Filters.ELECTRIC ? 'showFilter' : 'hideFilter')} sx={{ mt: 2, pl: 1 }}>
                            <Electric updateURL={updateURL} />
                        </Box>
                    </Box>}

                    <Box className={'flexColumn filterDropdown ' + (openDropdown == Filters.HISTORY ? 'openDropDown' : 'closeDropDown')}>
                        <Box className='flexRow justifyContentBetween fieldHeader alignItemsCenter pointer' onClick={() => handleOpenDropdown(Filters.HISTORY)}>
                            <span>{t(translations.FILTER_COMPONENT.FILTER_COMPONENT_HISTORY)}</span>
                            <span className='dropdownIcon'>{openDropdown == Filters.HISTORY ? <SvgMinus /> : <SvgPlus />}</span>
                        </Box>
                        <Box className={'flexColumn ' + (openDropdown == Filters.HISTORY ? 'showFilter' : 'hideFilter')} sx={{ mt: 2 }}>
                            <History updateURL={updateURL} />
                        </Box>
                    </Box>

                    {vehicle != vehicles.step &&
                        <Box className={'flexColumn filterDropdown ' + (openDropdown == Filters.EQUIPMENT ? 'openDropDown' : 'closeDropDown')}>
                            <Box className='flexRow justifyContentBetween fieldHeader alignItemsCenter pointer' onClick={() => handleOpenDropdown(Filters.EQUIPMENT)}>
                                <span>{t(translations.FILTER_COMPONENT.FILTER_COMPONENT_EQUIPMENTS)}</span>
                                <span className='dropdownIcon'>{openDropdown == Filters.EQUIPMENT ? <SvgMinus /> : <SvgPlus />}</span>
                            </Box>
                            <Box className={'flexColumn ' + (openDropdown == Filters.EQUIPMENT ? 'showFilter' : 'hideFilter')} sx={{ mt: 2 }}>
                                <Equipment filterData={filterData} showEquipments={showEquipments} setShowEquipments={setShowEquipments} selectedEquipments={selectedEquipments} updateURL={updateURL} />
                            </Box>
                        </Box>
                    }

                    {/* Seller and warraty filters is not available for my ads and shop page */}
                    {(!isMyAdsPage && !isShopPage) && <Box className={'flexColumn filterDropdown ' + (openDropdown == Filters.SELLER_AND_WARRANTY ? 'openDropDown' : 'closeDropDown')}>
                        <Box className='flexRow justifyContentBetween fieldHeader alignItemsCenter pointer' onClick={() => handleOpenDropdown(Filters.SELLER_AND_WARRANTY)}>
                            <span>{t(translations.FILTER_COMPONENT.FILTER_COMPONENT_SELLER_AND_WARRANTY)}</span>
                            <span className='dropdownIcon'>{openDropdown == Filters.SELLER_AND_WARRANTY ? <SvgMinus /> : <SvgPlus />}</span>
                        </Box>
                        <Box className={'flexColumn ' + (openDropdown == Filters.SELLER_AND_WARRANTY ? 'showFilter' : 'hideFilter')} sx={{ mt: 2 }}>
                            <SellerAndWarranty updateURL={updateURL} />
                        </Box>
                    </Box>}

                    {/* Country filters is only available public classic ads */}
                    {isPublicAdsPage && <Box className={'flexColumn filterDropdown ' + (openDropdown == Filters.COUNTRY ? 'openDropDown' : 'closeDropDown')}>
                        <Box className='flexRow justifyContentBetween fieldHeader alignItemsCenter pointer' onClick={() => handleOpenDropdown(Filters.COUNTRY)}>
                            <span>{t(translations.FILTER_COMPONENT.FILTER_COMPONENT_COUNTRY)}</span>
                            <span className='dropdownIcon'>{openDropdown == Filters.COUNTRY ? <SvgMinus /> : <SvgPlus />}</span>
                        </Box>
                        <Box className={'flexColumn ' + (openDropdown == Filters.COUNTRY ? 'showFilter' : 'hideFilter')} sx={{ mt: 2 }}>
                            <Country updateURL={updateURL} />
                        </Box>
                    </Box>}
                </Box>
            </Box>


            {showEquipments && <Box>
                <EquipmentList showEquipments={showEquipments} setShowEquipments={setShowEquipments} selectedEquipments={selectedEquipments} setSelectedEquipments={setSelectedEquipments} equipmentFilterData={filterData?.equipmentList ?? {}} />
            </Box>}

        </FilterWrapper >
    )
}

export default Filter