
import { MenuItem, Select } from '@mui/material'
import {  SvgcheckICon, SvgcheckIcon, SvgelectricIcon } from 'app/components/svgicons/svgNew';
import { getProductTypeId, productTypes, vehicles, getAcceptLanguage } from 'config/variables';
import carbodyPlaceholder from 'app/AssetNew/Images/placeholdBody.png';
import tinycolor from 'tinycolor2';
import {  getVehicleModelListRequest } from 'store/actions/vehicle-actions';
import AdsImageNotFound from 'app/components/RepeatComponents/AdsImageNotFound';
import { getCountryNameBySlug, localstorageKey } from 'config/constants';
import { Link, matchPath, useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { routes } from 'routes/routes';
import { getFiltersRequest } from 'store/actions/auth-actions';
import { urlParamInterface } from 'config/interface';
import { envVariables } from 'config/appConfig';
import { ApiPaths } from 'services/ApiPaths';
import { Nullable } from 'app/pages/Chat/interface/interface';

// Get Data From Local Storage
const getCarFilterList: any = localStorage.getItem(localstorageKey.carFilterdata)
export const getCarFilterListObj = JSON.parse(getCarFilterList || '{}');
const getBikeFilterList: any = localStorage.getItem(localstorageKey.bikeFilterdata)
export const getBikeFilterListObj = JSON.parse(getBikeFilterList || '{}');
const getStepFilterList: any = localStorage.getItem(localstorageKey.stepFilterdata)
export const getStepFilterListObj = JSON.parse(getStepFilterList || '{}');
const getVehicleTypeData: any = localStorage.getItem(localstorageKey.vehicleTypeData)
export const getVehicleTypeDataObj = JSON.parse(getVehicleTypeData || '{}');
const url: URL = new URL(window.location.href);
const params: URLSearchParams = url.searchParams;


// Brand Filter Component 
export const BrandFilterList = (props: any) => {
  const { filterEl, selectEl } = props;
  const [changeBrand, setChangeBrand] = useState<Array<any>>([]);
  const {
    carFilterList,
    bikeFilterList,
    stepFilterList
  } = useSelector((stete: any) => stete.vehicle);
  const { vehicle, brand_id, model_id, id, country_id, slug, ads_type }: urlParamInterface = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    let arr = []
    if (vehicle === `${vehicles.car.toLocaleLowerCase()}`) {
      if (carFilterList?.brandList) {
        arr = carFilterList?.brandList.items ? carFilterList?.brandList.items : []
      } else {
        arr = getCarFilterListObj?.brandList?.items ? getCarFilterListObj?.brandList?.items : []
      }
    } else if (vehicle === `${vehicles.bike.toLocaleLowerCase()}`) {
      if (bikeFilterList?.brandList) {
        arr = bikeFilterList?.brandList.items ? bikeFilterList?.brandList.items : [];
      } else {
        arr = getBikeFilterListObj?.brandList?.items ? getBikeFilterListObj?.brandList?.items : [];
      }
    } else if (vehicle === `${vehicles.step.toLocaleLowerCase()}`) {
      if (stepFilterList?.brandList) {
        arr = stepFilterList?.brandList.items ? stepFilterList?.brandList.items : [];
      } else {
        arr = getStepFilterListObj?.brandList?.items ? getStepFilterListObj?.brandList?.items : []
      }
    } else {
      if (carFilterList?.brandList) {
        arr = carFilterList?.brandList.items ? carFilterList?.brandList.items : []
      } else {
        arr = getCarFilterListObj?.brandList?.items ? getCarFilterListObj?.brandList?.items : []
      }
    }

    setChangeBrand(arr);
  }, [carFilterList, bikeFilterList, stepFilterList, vehicle]);

  // set brand id or slug filter
  const path = location.pathname;
  const auctionListing = matchPath(path, { path: routes.vehicleRoutes.auctionAd + '/:id' })
  const publicListing = matchPath(path, { path: routes.vehicleRoutes.publicAds + '/:vehicle' })
  const shopPage = matchPath(path, { path: routes.publicRoutes.shop })
  const countryNameBrandObj = getCountryNameBySlug(brand_id);
  const countryNameModelObj = getCountryNameBySlug(model_id);
  const changeBrandId = (e) => {
    const val = e.target.value; // selected value
    let historyPushLink: string = ""; // generate and concate url
    let countryByName: any // country name 
    if (model_id) { // model exist in url
      countryByName = countryNameModelObj
    } else {// brand exist in url
      countryByName = countryNameBrandObj
    }
    if (val) { // brand slug value

      // generate model payload 
      const filter = {
        product_type_id: getProductTypeId(vehicle),
      }
      const brandId = changeBrand.find((x) => x.slug === val)

      //  check model id
      if (brandId.id) {
        filter['brand_id'] = brandId.id;
        //model api action
        dispatch(getVehicleModelListRequest({ filter }))
      }
    }
    // check auction screen
    if (auctionListing && auctionListing !== null) {
      if (countryByName && val) { // model or  brand exist in url
        historyPushLink = `${routes.vehicleRoutes.auctionAd}/${vehicle}/${id}/${val}/${countryByName.slug}`
      } else if (countryByName && !val) { // model or  brand and brand value in defined
        historyPushLink = `${routes.vehicleRoutes.auctionAd}/${vehicle}/${id}/${countryByName.slug}`
      } else if (val) { // set only brand slug
        if (country_id) {
          historyPushLink = `${routes.vehicleRoutes.auctionAd}/${vehicle}/${id}/${val}/${country_id}`
        } else {
          historyPushLink = `${routes.vehicleRoutes.auctionAd}/${vehicle}/${id}/${val}/`
        }
      } else { // remove brand slug 
        if (country_id) {
          historyPushLink = `${routes.vehicleRoutes.auctionAd}/${vehicle}/${id}/${country_id}`
        } else {
          historyPushLink = `${routes.vehicleRoutes.auctionAd}/${vehicle}/${id}/`
        }
      }
    } else if (publicListing && publicListing !== null) { // check public screen
      if (countryByName && val) {  // model or  brand exist in url
        historyPushLink = `${routes.vehicleRoutes.publicAds}/${vehicle}/${val}/${countryByName.slug}`;
      } else if (countryByName && !val) { // model or  brand and brand value in defined
        historyPushLink = `${routes.vehicleRoutes.publicAds}/${vehicle}/${countryByName.slug}`;
      } else if (val) { // set only brand slug
        //historyPushLink = `${routes.vehicleRoutes.publicAds}/${vehicle}/${val}` 
        if (country_id) { // country exist in url
          historyPushLink = `${routes.vehicleRoutes.publicAds}/${vehicle}/${val}/${country_id}`
        } else { // country not exist in url
          historyPushLink = `${routes.vehicleRoutes.publicAds}/${vehicle}/${val}`
        }
      } else { // remove brand slug 
        if (country_id) { // country exist in url
          historyPushLink = `${routes.vehicleRoutes.publicAds}/${vehicle}/${country_id}`
        } else { // country not exist in url
          historyPushLink = `${routes.vehicleRoutes.publicAds}/${vehicle}`
        }
      }
    } else if (shopPage && shopPage !== null) { // check shop page url
      if (val) { // set only brand slug
        historyPushLink = `${routes.publicRoutes.shopURL}/${slug}/${ads_type}/${vehicle}/${val}`;
      } else { // remove brand slug 
        historyPushLink = `${routes.publicRoutes.shopURL}/${slug}/${ads_type}/${vehicle}`;
      }
    }
    // query params in url
    if (location.search) {
      historyPushLink += location.search;
    }
    // new url set
    history.replace(historyPushLink);
  }

  // check valid screen from model brand country structure
  const checkBrandRouter = () => {
    if ((auctionListing && auctionListing !== null) || (publicListing && publicListing !== null) || (shopPage && shopPage !== null)) {
      return true
    }
    return false
  }

  // get selected brand value 
  const BrandSelectedValue = () => {
    if (countryNameBrandObj) {  // model match with country
      return '';
    } else if (brand_id) { // exist in url 
      return brand_id
    } else if (filterEl.brand_id.value) { // get brand value through params
      return filterEl.brand_id.value
    }
    return '' // retune blank string
  }
  return (
    <Select
      value={BrandSelectedValue()}
      onChange={checkBrandRouter() ? changeBrandId : selectEl}
      name="brand_id"
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem value={''}>
        <em>{t(translations.ads.ALL)}</em>
      </MenuItem>
      {
        changeBrand.length > 0 ?
          changeBrand.map((item, index) => (
            <MenuItem value={checkBrandRouter() ? item.slug : item.id} key={item.id}>{item.name}</MenuItem>
          ))
          : null
      }
    </Select>
  )
}

// Euro Filter Component 
export const EuroStandardFilter = (props: any) => {
  const { filterEl, selectEl } = props;
  const [changeEuroStandard, setChangeEuroStandard] = useState<Array<any>>([]);
  const { carFilterList } = useSelector((stete: any) => stete.vehicle);
  const { vehicle }: urlParamInterface = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    let arr = []
    if (vehicle === vehicles.car.toLocaleLowerCase()) {
      if (carFilterList?.euroList) {
        arr = carFilterList?.euroList.items ? carFilterList?.euroList.items : []
      } else {
        arr = getCarFilterListObj?.euroList?.items ? getCarFilterListObj?.euroList?.items : []
      }
    }

    setChangeEuroStandard(arr);
  }, [carFilterList, vehicle])
  return (
    <Select
      value={filterEl.euro_standard_level.value ? filterEl.euro_standard_level.value : ""}
      onChange={selectEl}
      name="euro_standard_level"
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem value={''}>
        <em>{t(translations.ads.ALL)}</em>
      </MenuItem>
      {
        changeEuroStandard.length > 0 ?
          changeEuroStandard.map((item, index) => (
            <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
          ))
          : null
      }
    </Select>
  )
}

// Interiors Component
export const InteriorListFilter = (props: any) => {
  const { filterEl, selectEl } = props;
  const [changeEuroStandard, setChangeEuroStandard] = useState<Array<any>>([]);
  const { carFilterList } = useSelector((stete: any) => stete.vehicle);
  const { t } = useTranslation();
  const { vehicle } = useParams();
  useEffect(() => {
    let arr = []
    if (vehicle === vehicles.car) {
      if (carFilterList?.interiorList) {
        arr = carFilterList?.interiorList.items ? carFilterList?.interiorList.items : []
      } else {
        arr = getCarFilterListObj?.interiorList?.items ? getCarFilterListObj?.interiorList?.items : []
      }
    }

    setChangeEuroStandard(arr);
  }, [carFilterList, vehicle])
  return (
    <Select
      value={filterEl.interior_id.value ? filterEl.interior_id.value : ""}
      onChange={selectEl}
      name="interior_id"
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem value={''}>
        <em>{t(translations.FILTER_COMPONENT.FILTER_COMPONENT_ALL)}</em>
      </MenuItem>
      {
        changeEuroStandard.length > 0 ?
          changeEuroStandard.map((item, index) => (
            <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
          ))
          : null
      }
    </Select>
  )
}

// CAR Vehicle GearBox Component
export const CarGearBox = (props: any) => {
  const { changeFilterValue, filterEl } = props;
  const [changeGearBox, setchangeGearBox] = useState<Array<any>>([]);
  const { carFilterList } = useSelector((stete: any) => stete.vehicle);

  useEffect(() => {
    let arr = []
    if (carFilterList?.gearList) {
      arr = carFilterList?.gearList ? carFilterList?.gearList?.items : []
    } else {
      arr = getCarFilterListObj?.gearList ? getCarFilterListObj?.gearList?.items : []
    }
    setchangeGearBox(arr);
  }, [carFilterList, params?.product_type_id])

  return (
    <>
      {
        changeGearBox.length > 0 ?
          changeGearBox.map((item) => (
            <span key={item.id}
              className={`pointer ${filterEl.gearbox_id.value === item.id ? 'active' : ''}`}
              onClick={() => {
                if (filterEl.gearbox_id.value === item.id) {
                  changeFilterValue('gearbox_id', '')
                } else {
                  changeFilterValue('gearbox_id', item.id)
                }
              }
              }
            >{item.name}</span>
          ))
          : null
      }
    </>
  )
}

// All Vehicle Bodies Component
export const Bodies = (props: any) => {
  const { filterEl, vehilceBodyId, selectVehicleBody } = props;
  const [changeBodies, setChangeBodies] = useState<Array<any>>([])
  const { carFilterList,
    bikeFilterList,
    stepFilterList
  } = useSelector((stete: any) => stete.vehicle);
  const { vehicle }: urlParamInterface = useParams();
  useEffect(() => {
    let bodyFilter = []
    if (vehicle === vehicles.car) {
      if (carFilterList?.bodiesList) {
        bodyFilter = carFilterList?.bodiesList.items ? carFilterList?.bodiesList.items : []
      } else {
        bodyFilter = getCarFilterListObj?.bodiesList?.items ? getCarFilterListObj?.bodiesList?.items : []
      }
    } else if (vehicle === vehicles.bike) {
      if (bikeFilterList?.bodiesList) {
        bodyFilter = bikeFilterList?.bodiesList.items ? bikeFilterList?.bodiesList.items : [];
      } else {
        bodyFilter = getBikeFilterListObj?.bodiesList?.items ? getBikeFilterListObj?.bodiesList?.items : [];
      }
    } else if (vehicle === vehicles.step) {
      if (stepFilterList?.bodiesList) {
        bodyFilter = stepFilterList?.bodiesList.items ? stepFilterList?.bodiesList.items : [];
      } else {
        bodyFilter = getStepFilterListObj?.bodiesList?.items ? getStepFilterListObj?.bodiesList?.items : []
      }
    } else {
      if (carFilterList?.bodiesList) {
        bodyFilter = carFilterList?.bodiesList.items ? carFilterList?.bodiesList.items : []
      } else {
        bodyFilter = getCarFilterListObj?.bodiesList?.items ? getCarFilterListObj?.bodiesList?.items : []
      }
    }
    setChangeBodies(bodyFilter)
  }, [carFilterList, bikeFilterList, stepFilterList, vehicle])
  return (
    <>
      {changeBodies.length > 0 ?
        changeBodies.map((item: any, index) => (
          <div key={item.id} className={`body-box pointer ${vehilceBodyId.includes(item.id || `${item.id}`) ? 'active' : ''}`}

            onClick={(e) => {
              selectVehicleBody(item.id)
              // executeScroll(e, index);
            }}
          >
            {vehilceBodyId.includes(item.id || `${item.id}`) ?
              <span className="icon"><SvgcheckIcon /></span>
              : null
            }
            {
              item.media && item.media.length > 0 ?
                <img src={item.media[0].original_url} alt="bodyimage" />
                : <img src={carbodyPlaceholder} alt="bodyimage" />
            }
            <span className="name" >{item?.name}</span>
          </div>
        ))
        : null
      }
    </>

  )
}

// All VehicleTypes Component
export const VehicleTypes = (props: any) => {
  const { resetValue } = props;
  const [changeVehicleType, setChangeVehicleType] = useState<Array<any>>([]);
  const [defaultVehicle, _defaultVehicle] = useState<string>('');
  const location: any = useLocation();
  const { vehicle, slug, ads, auction_ads, ads_type }: urlParamInterface = useParams();
  const { vehicleTypes } = useSelector((state: any) => state.vehicle);

  useEffect(() => {
    if (!vehicle) {
      _defaultVehicle(vehicles.car)
    }
  }, [])

  useEffect(() => {
    let arr = []
    if (vehicleTypes && vehicleTypes.length > 0) {
      arr = vehicleTypes ? vehicleTypes : [];
    } else if (getVehicleTypeDataObj?.items) {
      arr = getVehicleTypeDataObj.items ? getVehicleTypeDataObj?.items : []
    }
    setChangeVehicleType(arr);
  }, [vehicleTypes])


  // function for get vehicle name from object of vehicleType obj
  const getVehicleName = (id) => {
    //  compare id with car
    if (id === productTypes.car) {
      return vehicles.car
      //  compare id with bike
    } else if (id === productTypes.bike) {
      return vehicles.bike
      //  compare id with step
    } else if (id === productTypes.step) {
      return vehicles.step
    }
  }
  const generateVehicleTypeRouter = (id) => {
    // get vehicle Name 
    const vehicleType = getVehicleName(id)

    if (matchPath(location.pathname, { path: routes.vehicleRoutes.publicAds + '/:vehicle' })) { // match router with public listing screen
      return routes.vehicleRoutes.publicAds + `/${vehicleType}`
    } else if (matchPath(location.pathname, { path: routes.vehicleRoutes.auction + '/:vehicle' })) {
      return routes.vehicleRoutes.auction + `/${vehicleType}`
    } else if (matchPath(location.pathname, { path: routes.vehicleRoutes.myAds + '/:vehicle/' })) { // match router with my ads listing screen
      let link: string; // generate link for my ads screen according to select ads Type
      if (auction_ads) {
        link = routes.vehicleRoutes.myAds + `/${vehicleType}/${ads}/${auction_ads}`
      } else if (ads) {
        link = routes.vehicleRoutes.myAds + `/${vehicleType}/${ads}`
      } else {
        link = routes.vehicleRoutes.myAds + `/${vehicleType}`
      }
      return link;
    } else if (matchPath(location.pathname, { path: routes.publicRoutes.shop })) { // match screen with shop screen
      return `${routes.publicRoutes.shopURL}/${slug}/${ads_type}/${vehicleType}`;
    } else {
      return routes.vehicleRoutes.publicAds;
    }
  }

  return (
    <>
      {changeVehicleType && changeVehicleType.length > 0 ?
        changeVehicleType.map((item: any, index: number) => (
          <Link
            key={index}
            to={generateVehicleTypeRouter(item.id)}
            onClick={() => resetValue()}
            className={`items ${vehicle === getVehicleName(item.id) || (!vehicle && defaultVehicle === getVehicleName(item.id)) ? 'active' : ''}`} onClick={() => { }}>
            {
              item.media && item.media.length > 0 ?
                <img src={item.media[0].original_url} />
                :
                <AdsImageNotFound />
            }
          </Link>
        ))
        : null
      }
    </>
  )
}

// All Fuel Component
export const Fuels = (props: any) => {
  const { filterEl, changeFilterValue } = props;
  const [changeFuel, setChangeFuel] = useState<Array<any>>([])
  const { carFilterList,
    bikeFilterList,
    stepFilterList
  } = useSelector((stete: any) => stete.vehicle);
  const { vehicle } = useParams();
  // let changeFuel = [];
  useEffect(() => {
    let arr = []
    if (vehicle === vehicles.car) {
      if (carFilterList?.fuelList) {
        arr = carFilterList?.fuelList?.items ? carFilterList?.fuelList?.items : [];
      } else {
        arr = getCarFilterListObj?.fuelList?.items ? getCarFilterListObj?.fuelList?.items : []
      }
    }
    else if (vehicle === vehicles.bike) {
      if (bikeFilterList?.fuelList) {
        arr = bikeFilterList?.fuelList?.items ? bikeFilterList?.fuelList?.items : []
      } else {
        arr = getBikeFilterListObj?.fuelList?.items ? getBikeFilterListObj?.fuelList?.items : []
      }
    } else if (vehicle === vehicles.step) {
      if (stepFilterList?.fuelList) {
        arr = stepFilterList?.fuelList?.items ? stepFilterList?.fuelList?.items : []
      } else {
        arr = getStepFilterListObj?.fuelList?.items ? getStepFilterListObj?.fuelList?.items : []
      }
    } else {
      if (carFilterList?.fuelList) {
        arr = carFilterList?.fuelList?.items ? carFilterList?.fuelList?.items : [];
      } else {
        arr = getCarFilterListObj?.fuelList?.items ? getCarFilterListObj?.fuelList?.items : []
      }
    }
    setChangeFuel(arr);
  }, [carFilterList, bikeFilterList, stepFilterList, vehicle])
  return (
    <>
      {changeFuel.length > 0 ?
        changeFuel.map((item: any) => (
          <div className='pointer' key={item.id}>
            {
              item.name === 'electric' ?
                <span className={`pointer ${filterEl.fuel_id.value === item.id ? 'active' : ''}`}
                  onClick={() => {
                    if (filterEl.fuel_id.value === item.id) {
                      changeFilterValue('fuel_id', '')
                    } else {
                      changeFilterValue('fuel_id', item.id)
                    }
                  }
                  }
                >{item.name} <SvgelectricIcon style={{
                  marginLeft: 10,
                }} /> </span>
                :
                <span className={`pointer ${filterEl.fuel_id.value === item.id ? 'active' : ''}`} onClick={() => {
                  if (filterEl.fuel_id.value === item.id) {
                    changeFilterValue('fuel_id', '')
                  } else {
                    changeFilterValue('fuel_id', item.id)
                  }
                }}
                >{item.name}</span>
            }
          </div>
        )) : null
      }
    </>
  )
}

// All Colors Component
export const Colors = (props: any) => {
  const { filterEl, setColor } = props;
  const [changeColor, setchangeColor] = useState<Array<any>>([]);
  const { carFilterList,
    bikeFilterList,
    stepFilterList
  } = useSelector((stete: any) => stete.vehicle);
  const { vehicle } = useParams();
  useEffect(() => {
    let arr = []
    if (vehicle === vehicles.car) {
      if (carFilterList?.colorList) {
        arr = carFilterList?.colorList?.items ? carFilterList?.colorList?.items : [];
      } else {
        arr = getCarFilterListObj?.colorList?.items ? getCarFilterListObj?.colorList?.items : [];
      }
    } else if (vehicle === vehicles.bike) {
      if (bikeFilterList?.colorList) {
        arr = bikeFilterList?.colorList?.items ? bikeFilterList?.colorList?.items : [];
      } else {
        arr = getBikeFilterListObj?.colorList?.items ? getBikeFilterListObj?.colorList?.items : [];
      }

    } else if (vehicle === vehicles.step) {
      if (stepFilterList?.colorList) {
        arr = stepFilterList?.colorList?.items ? stepFilterList?.colorList?.items : [];
      } else {
        arr = getStepFilterListObj?.colorList?.items ? getStepFilterListObj?.colorList?.items : [];
      }
    } else {
      if (carFilterList?.colorList) {
        arr = carFilterList?.colorList?.items ? carFilterList?.colorList?.items : [];
      } else {
        arr = getCarFilterListObj?.colorList?.items ? getCarFilterListObj?.colorList?.items : [];
      }
    }
    setchangeColor(arr);
  }, [carFilterList, bikeFilterList, stepFilterList, vehicle])


  return (
    <>
      {
        changeColor.length > 0 ?
          changeColor.map((item: any) => {
            let colorButton = tinycolor(item.hexa)
            return (
              <div className="color-parent pointer"
                key={item.id}
                onClick={() => {
                  setColor(item.id)
                }
                }
              >
                <span style={{ backgroundColor: item.hexa }}
                  className={item.hexa === '#fcfcfc' || item.hexa === '#ffffff' ? 'outborder' : ''}
                >
                  {filterEl.outside_color_id.value === item.id ? <SvgcheckICon className={colorButton.isDark() ? '' : 'white'} /> : null}
                </span>
              </div>
            )
          })
          : null
      }
    </>
  )
}

// All Frames Component
export const FrameSizes = (props: any) => {
  const { changeFilterValue, filterEl } = props;
  const [changeFramSize, setchangeFramSize] = useState<Array<any>>([]);

  const { carFilterList,
    bikeFilterList,
    stepFilterList
  } = useSelector((stete: any) => stete.vehicle);
  const { vehicle } = useParams();
  useEffect(() => {
    let arr = [];
    if (vehicle === vehicles.car) {
      if (carFilterList?.frameList) {
        arr = getCarFilterListObj?.frameList?.items ? getCarFilterListObj?.frameList?.items : [];
      } else {
        arr = getCarFilterListObj?.frameList?.items ? getCarFilterListObj?.frameList?.items : [];
      }
    } else if (vehicle === vehicles.bike) {
      if (bikeFilterList?.frameList) {
        arr = bikeFilterList?.frameList?.items ? bikeFilterList?.frameList?.items : [];
      } else {
        arr = getBikeFilterListObj?.frameList?.items ? getBikeFilterListObj?.frameList?.items : [];
      }
    } else if (vehicle === vehicles.step) {
      if (stepFilterList?.frameList) {
        arr = stepFilterList?.frameList?.items ? stepFilterList?.frameList?.items : [];
      } else {
        arr = getCarFilterListObj?.frameList?.items ? getCarFilterListObj?.frameList?.items : [];
      }
    }
    setchangeFramSize(arr);
  }, [carFilterList, bikeFilterList, stepFilterList, vehicle])
  return (
    <>
      {changeFramSize ?
        changeFramSize?.map((item: any) => (
          <span key={item.id}
            className={`pointer ${filterEl.frame_size_id.value === item.id ? 'active' : ''}`}
            onClick={() => {
              if (filterEl.frame_size_id.value === item.id) {
                changeFilterValue('frame_size_id', '')
              } else {
                changeFilterValue('frame_size_id', item.id)
              }
            }}
          >{item.name}</span>
        )) : null
      }
    </>
  )
}

// Hit Filter related API According to the condition
// export const getAllFilter = async (dispatch, forceHit) => {
//   let currentLan = localStorage.getItem(localstorageKey.i18nextLng);
//   const getCarLocalData = localStorage.getItem(localstorageKey.carFilterdata);
//   const getBikeLocalData = localStorage.getItem(localstorageKey.bikeFilterdata);
//   const getStepLocalData = localStorage.getItem(localstorageKey.stepFilterdata);
//   const getVehicleTypeDataData = localStorage.getItem(localstorageKey.vehicleTypeData);
//   if (forceHit) {
//     dispatch(getFiltersRequest())
//     // dispatch(getActiveLanguageRequest())
//     // dispatch(getCarFilterRequest());
//     // dispatch(getBikeFilterRequest());
//     // dispatch(getStepFilterRequest());
//     // dispatch(getVehicleTypeRequest());
//     // fetch(`${process.env.REACT_APP_API_URL}${ApiPaths.common.countries}` + "/?continent=all", {})
//     //   .then((response) => response.json())
//     //   .then((res) => {
//     //     localStorage.setItem("countries", JSON.stringify(res.data.items))

//     //   })
//     //   .catch((e) => console.warn(e))
//   } else if ((!getCarLocalData || !getBikeLocalData || !getStepLocalData) || getCarFilterListObj.lang !== currentLan || !getVehicleTypeDataData) {
//     dispatch(getFiltersRequest())

//     // dispatch(getActiveLanguageRequest())
//     // dispatch(getCarFilterRequest());
//     // dispatch(getBikeFilterRequest());
//     // dispatch(getStepFilterRequest());
//     // dispatch(getVehicleTypeRequest());
//   }
// }

export const setFilters = async (dispatch) => {
  const localStorageTime = parseInt(localStorage.getItem(localstorageKey.deleteFilterData) || '0');

  fetch(`${envVariables.apiUrl}${ApiPaths.public.filters_change}`, {
    headers: {
      'Accept-Language': getAcceptLanguage(),
      'Cache-Control': 'max-age=0',  // Ensure fresh data
    },
  })
    .then(res => res.json())
    .then((res) => {
      const lastFilterUpdateTime = res?.data?.filter_changed_time && res.data.filter_changed_time !== null ? new Date(res?.data?.filter_changed_time).getTime() : new Date().getTime();
      const currentLan = localStorage.getItem(localstorageKey.i18nextLng);

      // call filter APi
      // - when filter change time is greater then the localstoragetime (i.e deleteFilterData time)
      // - when there is no time stored in localstorage
      // - when language in filter and localstorage are not same
      if ((localStorageTime && localStorageTime < lastFilterUpdateTime) || (localStorageTime == 0) || (getCarFilterListObj.lang !== currentLan)) {
        localStorage.removeItem(localstorageKey.slidersFetched)
        localStorage.removeItem(localstorageKey.sliderData)
        localStorage.removeItem(localstorageKey.partnerSliderData)

        dispatch(getFiltersRequest({ time: lastFilterUpdateTime }))
        return
      }
    })
    .catch(err => {
      throw new Error(err)
    })
}
// Defaoult Value Filter

export const defaultValues = () => {
  const producTypeUrl: Nullable<string> = params.get('product_type_id');
  return {
    'product_type_id': { name: 'vehicle', value: producTypeUrl ? producTypeUrl : productTypes.car },
    'new_vehicle': { name: 'New vehicle', value: "" },
    'brand_id': { name: 'brand', value: "" },
    'model_id': { name: 'model', value: "" },
    'fuel_id': { name: 'Fuel', value: "" },
    'body_condition_id': { name: 'Condition', value: "" },
    'body_vehicle_id': { name: 'Body', value: "" },
    'interior_id': { name: 'interior', value: "" },
    'warranty_start': { name: 'warranty', value: "" },
    'euro_standard_start': { name: 'Euro Standard', value: "" },
    'equipment_id': { name: 'Equipments', value: "" },
    'country_id': { name: 'Country', value: "" },
    'outside_color_id': { name: 'Color', value: "" },
    'owner_number': { name: 'Previous Owner', value: "" },
    'battery_range_max': { name: 'Drive Range', value: "" },
    'frame_size_id': { name: 'Frame Size', value: "" },
    'gearbox_id': { name: 'Transmission', value: "" },
    'display_for': { name: 'Seller', value: "" },
    'euro_standard_level': { name: 'Euro Standard', value: "" },
    'motor_power_watt': { name: 'Enginer Power', value: "" },
    '0_100': { name: 'Max Speed', value: "" },
    'vehicle_mileage_start': {
      name: 'Mileage', value: {
        vehicle_mileage_start: "",
        vehicle_mileage_end: "",
      }
    },

    'seats_start': {
      name: 'Seats', value: {
        seats_start: "",
        seats_end: "",
      }
    },

    'price_start': {
      name: 'Price', value: {
        price_start: "",
        price_end: "",
      }
    },

    'registration_start': {
      name: 'First Registration', value: {
        'registration_start': "",
        'registration_end': "",
      }
    },
    'power_cv_start': {
      name: 'Power', value: {
        'power_cv_start': "",
        'power_cv_end': "",
      }
    },

    'charge_duration_home_80_start': {
      name: 'Home charging time', value: {
        "charge_duration_home_80_start": "",
        "charge_duration_home_80_end": ""
      }
    },

    'charge_duration_fast_80_start': {
      name: 'Public fast charging', value: {
        'charge_duration_fast_80_start': "",
        'charge_duration_fast_80_end': "",

      }
    },
  }
}

// Reset Filter Obj
export const resetValue = (setData) => {
  let clone = JSON.stringify(defaultValues);
  let cloneObj = JSON.parse(clone)
  setData(cloneObj)
}

