import { Box } from '@mui/material';
import { SvgBellIcon, SvgMobileLeftArrow, SvgfluentIcon } from 'app/components/svgicons/svgNew';
import React, { useEffect, useState } from 'react';
import UserAvatar from './UserAvatar';
import { Avatar } from '../../interface/interface';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'app/Hooks/windowResize';
import moment from 'moment';
import { SvgMuted } from 'app/components/svgicons/svg2';
import * as ChatConstants from "config/chatConfig"
import { useQueryStringParams } from 'app/Hooks/useParams';
import { useHistory } from 'react-router-dom';
import { getChatSuccess, lastMessagesState } from 'store/actions/chat-action';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes/routes';
import { weekDays } from 'config/constants';
import AccountVerifiedIcon from 'app/Component2/GlobalComponent/AccountVerifiedIcon ';
import { decodeHTMLEntities } from 'config/utils';

const AvatarandDetails = ({ description, groupTitle, userInfo, text, hideStatus, hideFlag, chatTime, setChatId, popup, hidebackButton, isRead, isMuted, newChat, activeText, chatId, messageList, typeOfChat, chatData, followersCount }: Avatar) => {
  const { user } = useSelector((state: RootStateOrAny) => state.auth)
  const { chatList, chatListAllData, blockedByUsers, blockedBySubs } = useSelector((state: RootStateOrAny) => state.chat)

  const [mobile] = useWindowSize();
  const [active, _active] = useState<boolean>(false)
  const params = useQueryStringParams();
  const history = useHistory()
  const dispatch = useDispatch()

  const { t } = useTranslation();

  function translateDay(day) {
    switch (day) {
      case weekDays.Monday:
        return t(translations.ads.MONDAY);
      case weekDays.Tuesday:
        return t(translations.ads.TUESDAY);
      case weekDays.Wednesday:
        return t(translations.ads.WEDNESDAY);
      case weekDays.Thursday:
        return t(translations.ads.THURSDAY);
      case weekDays.Friday:
        return t(translations.ads.FRIDAY);
      case weekDays.Saturday:
        return t(translations.ads.SATURDAY);
      case weekDays.Sunday:
        return t(translations.ads.SUNDAY);
      default:
        return day;
    }
  }

  const getTime = (time) => {
    // Get the current date
    let currentDate = moment();

    // Calculate the date 7 days ago
    let sevenDaysAgo = moment().subtract(7, 'days');

    if (!moment.isMoment(time)) {
      time = moment(time);
    }

    // if chat of same day then display time
    if (time.isSame(moment(), 'day')) {
      return time.format('HH:mm');
    }

    // if chat from last day (yesterday)
    else if (time.isSame(moment().subtract(1, 'd'), 'day')) {
      return t(translations.CHAT.YESTERDAY);
    }

    // if chat last week then display (week day name)
    else if (time.isBetween(sevenDaysAgo, currentDate, null, '[]')) {
      const translatedDay = translateDay(time.format('dddd'));
      return translatedDay;
    }

    else {
      return time.format('DD/MM/YYYY');  // if chat is 1 week or more days ago then display date
    }

  }

  const { onlineUserData } = useSelector((state: RootStateOrAny) => state.onlineUsers)

  useEffect(() => {
    if (chatData && Object.keys(chatData).length > 0 && chatData.type === ChatConstants.TYPE_OF_CHAT.GROUP_CHAT) {
      //If chat of group type then hide active status 
      _active(false)
      return;
    }
    if (userInfo && Object.keys(userInfo).length > 0) {
      // if user is blocked then hide active now status
      if ((userInfo.subsidiary_id && blockedBySubs.some(o => o == userInfo.subsidiary_id)) || (userInfo.id && blockedByUsers.some(o => o == userInfo.id))) {
        _active(false)
        return;
      }
      if (userInfo.subsidiary_id) {
        _active(false)
        // If chat is of group type then hide status(active now)
        return;
      }
      if (userInfo.id) {
        // Display active status only if user is online
        _active(onlineUserData.includes(userInfo.id.toString()))
      }
    }
  }, [userInfo, onlineUserData, blockedByUsers, chatData])

  const handleBack = () => {
    let chatListObject = { ...chatListAllData }
    let newData = []
    chatList.forEach((elem) => {
      if (!elem.is_archived) {
        newData.push(elem)
      }
    })
    chatListObject.data.data = newData
    dispatch(getChatSuccess({ data: chatListObject, append: false }))
    localStorage.setItem('chat_id', null)
    setChatId(null)
  }


  const handleNavigate = () => {
    // Runs only if we are in chatmessagesceen component
    if (!messageList) {
      if (chatData && Object.keys(chatData).length > 0 && chatData.chat_user_info && Object.keys(chatData.chat_user_info).length > 0) {
        if (chatId)
          history.replace(routes.userRoutes.chat + "/?chat_id=" + chatId)

        if (chatData.chat_user_info.subsidiary_id) {
          history.push(routes.publicRoutes.public_profile_subisidiary + "/" + chatData.chat_user_info.user_name)
          return
        }
        history.push(routes.publicRoutes.public_profile_user + "/" + chatData.chat_user_info.user_name)
      }
    }
  }

  return (
    <Box className='avtar-detail-wrapper' sx={{
      maxWidth: popup ? '85%' : 'auto',
      width: (mobile && !messageList) || popup ? '85%' : 'auto'
    }} >
      {(mobile && !hidebackButton) || (popup && !hidebackButton) ?
        <Box sx={{ paddingX: '8px' }} className='pointer' onClick={() => {
          if (params.chat_id) {
            //  If user initiate chat from other pages instead of chat list then go back to same page from chat message screen
            history.goBack();
            return
          }
          dispatch(lastMessagesState([]))
          // Otherwise go back to chatlist 
          handleBack()
        }}>
          <SvgMobileLeftArrow />
        </Box> : ""}
      <UserAvatar hideStatus={hideStatus} hideFlag={hideFlag} userDetails={userInfo} newChat={newChat} active={active} />
      <Box className='user-details'
        sx={{
          maxWidth: popup ? '70%' : 'auto',
        }}>
        <Box className='chat-Time'>
          <Box className={(messageList ? " user-name" : "") + " flexRow"} sx={{ width: '100%' }}>
            <span
              style={{
                fontSize: '18px',
                width: 'fit-content',
                maxWidth: messageList ? '100%' : window.innerWidth < 992 || (popup && !messageList) ? '100%' : 'auto'
              }}
              className={(!isRead && messageList ? "unread" : "read") + (messageList || popup || mobile ? " name" : "") + " pointer"}
              title={userInfo && userInfo.name ? userInfo.name : t(translations.CHAT.ANONYMOUS)}
              onClick={handleNavigate}
            >
              {groupTitle ? groupTitle : userInfo && userInfo.name ? userInfo.name : t(translations.CHAT.ANONYMOUS)}
            </span>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <AccountVerifiedIcon isVerified={userInfo?.profile_is_verified && !groupTitle} />
              {chatData && chatData.type == ChatConstants.chat_type.GROUPCHAT && chatData.is_receptionist_chat == ChatConstants.receptionistChat.true && <SvgBellIcon />}
            </span>
          </Box>
          <span className='days'> {chatTime ? getTime(chatTime) : ""}</span>
        </Box>
        {!description && <Box className='lastMessage'>
          <span style={{ fontSize: '15px' }} className={(!isRead && messageList ? "unread" : "read") + (followersCount ? t(translations.PUBLICPROFILE.FOLLOWERS) : "")}>
            {text ? decodeHTMLEntities(text) : active && activeText && userInfo && userInfo.id ? activeText : <Box sx={{ minHeight: description ? '24px' : '' }}>{" "}</Box>}
          </span>
        </Box>}
        {isMuted === ChatConstants.muteStatus.mute && <Box className='mutedIcon'>
          <SvgMuted />
        </Box>}


        {(!isRead && messageList) && <Box sx={{
          position: 'absolute',
          right: isMuted ? 20 : 10,
          bottom: -3,
        }}>
          <span className='blueDot'></span>
        </Box>}
      </Box>
    </Box>
  );
};

export default AvatarandDetails;