import React, { useState } from 'react'
import { SVGHandShake, SVGHelpCenter, SvgAddVehicleIcon, SvgClose, SvgUnliked } from 'app/components/svgicons/svg'
import { Box, Button } from '@mui/material'
import { NavBarWrapper } from '../../Style/NavStyle'
import { SvgActionPlaces, SvgAds, SvgBikeType, SvgCarType, SvgCarbody, SvgDashboard, SvgFaq, SvgHotdeals, SvgLanguageIcon, SvgLegal, SvgMinus, SvgMotorCycleType, SvgPlus, SvgSiteLogo, SvgStepType, SvgUserIcon, SvgVectorDownArrow } from 'app/components/svgicons/svgNew'
import { routes } from 'routes/routes'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { langugageSwitchOpen, switchUserOpen } from 'store/actions/component-actions'
import { getSubsidiaryStored, vehicles } from 'config/variables'
import ProfileImage from 'app/Component2/GlobalComponent/ProfileImage'
import { Link } from 'react-router-dom'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { resetPublicProfile } from 'store/actions/publicProfile-action'
import { setCreateAdModalOpen } from 'store/actions/public-actions'
import loginIcon from 'app/AssetNew/Images/login-png.png'

const NavigationBarSmallDevices = ({ handleCloseMenu, userName, countryFlag }) => {


  const dispatch = useDispatch()
  const { t } = useTranslation()
  const subsidiary = getSubsidiaryStored()
  const [, isMediumDevice] = useDeviceType()

  const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth);

  const [openDropdown, _openDropdown] = useState<string>('')

  const handleSwitchProfile = () => {
    handleCloseMenu()
    dispatch(langugageSwitchOpen())
  }


  return (
    <NavBarWrapper>
      <Box sx={{ width: '100vw', height: '100vh', padding: '0 15px' }}>

        <Box className='flexRow justifyContentBetween alignItemsCenter' >
          <Box sx={{ p: 1 }}>
            <Link to={routes.vehicleRoutes.adsHome}>
              <span className='site'>
                <SvgSiteLogo />
              </span>
            </Link>
          </Box>
          <Box onClick={() => handleCloseMenu()} sx={{ p: 1 }}><SvgClose className='closeIcon' /></Box>
        </Box>

        {isMediumDevice && <Box className='flexRow justifyContentBetween alignItemsCenter' sx={{ my: 2 }}>

          <Button onClick={() => dispatch(setCreateAdModalOpen(true))}>
            <Box className='addProduct' sx={{ p: 1 }} >
              <Box className='addProductIcon'>
                <SvgAddVehicleIcon />
              </Box>
              <span>{`${t(translations.webTexts.ADD_PRODUCT)}`}</span>
            </Box>
          </Button>

          {loggedIn ?
            <Box className='flexRow alignItemsCenter' sx={{ ml: 2 }}>
              <Box component={Link} to={subsidiary ? routes.companyRoutes.companyProfile : routes.userRoutes.myProfile} className="imageWrapper" onClick={() => dispatch(resetPublicProfile())}>
                <ProfileImage classes="image-1" />
                {countryFlag ? <img src={countryFlag} className="image-2" alt={countryFlag} /> : null}
              </Box>
              <span className="text-name">{userName ? userName : t(translations.profileScreen.PSEUDONAME)}</span>
              <Box className='switchProfile'>
                <SvgVectorDownArrow onClick={() => dispatch(switchUserOpen())} />
              </Box>
            </Box>
            :
            <Link to={routes.authRoutes.login} className='loginBtn'>
              <span>
                <SvgUserIcon />{t(translations.formText.SIGNIN)}
              </span>
            </Link>
          }
        </Box>}

        <Box sx={{ p: 1 }} className='mobileMenu'>
          <span className='menuHeaderTab'>{t(translations.NAVBAR.PRODUCTS)}</span>

          <Box sx={{ my: 1, px: 1 }}>
            <Box className='flexRow justifyContentBetween' onClick={() => _openDropdown(openDropdown === 'vehicle' ? '' : 'vehicle')}>
              <Box className='flexRow'>
                <SvgCarbody style={{ width: '25px', height: '25px' }} className='vehicleSvg' />
                <Box className='flexColumn' sx={{ ml: 1 }}>
                  <span className='iconHeaderTab'>{t(translations.ads.VEHICLES)}</span>
                  {/* <span className='menuHeaderTab iconTextTab' style={{color:'#666666'}}>{t(translations.NAVBAR.EXPLORE_VEHICLE)}</span> */}
                </Box>
              </Box>
              {
                openDropdown === "vehicle" ?
                  <SvgMinus className='accordianIcon' /> :
                  <SvgPlus className='accordianIcon' />
              }
            </Box>
            <Box className={(openDropdown === 'vehicle' ? ' show' : '') + ' flexColumn justifyContentBetween menuLinkMobile toggle'} sx={{ my: 1, px: 4 }}>
              <Link to={{
                pathname: `${routes.vehicleRoutes.publicAds}/${vehicles.car}`,
                state: { redirect: true }
              }}>
                <span className='pointer flexRow alignItemsCenter'><SvgCarType style={{ marginRight: '10px' }} className='logoSvg' />{t(translations.NAVBAR.CARS)}</span>
              </Link>
              <Link to={{
                pathname: `${routes.vehicleRoutes.publicAds}/${vehicles.motorcycles}`,
                state: { redirect: true }
              }}>
                <span className='pointer flexRow alignItemsCenter'><SvgMotorCycleType style={{ marginRight: '10px' }} className='logoSvg' />{t(translations.NAVBAR.MOTORCYCLES)}</span>
              </Link>
              <Link to={{
                pathname: `${routes.vehicleRoutes.publicAds}/${vehicles.bike}`,
                state: { redirect: true }
              }}>
                <span className='pointer flexRow alignItemsCenter'><SvgBikeType style={{ marginRight: '10px' }} className='logoSvg' />{t(translations.NAVBAR.BIKES)}</span>
              </Link>
              <Link to={{
                pathname: `${routes.vehicleRoutes.publicAds}/${vehicles.step}`,
                state: { redirect: true }
              }}>
                <span className='pointer flexRow alignItemsCenter'><SvgStepType style={{ marginRight: '10px' }} className='logoSvg' />{t(translations.NAVBAR.SCOOTERS)}</span>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box sx={{ p: 1 }} className='mobileMenu'>
          <span className='menuHeaderTab'>{t(translations.ads.AUCTION)}</span>
          <Box sx={{ py: 1, px: 1 }}>
            <Box className='flexRow justifyContentBetween' onClick={() => _openDropdown(openDropdown === 'auction' ? '' : 'auction')}>
              <Box className='flexRow'>
                <SvgActionPlaces style={{ width: '25px', height: '25px' }} />
                <Box className='flexColumn' sx={{ ml: 1 }}>
                  <span className='iconHeaderTab'>{t(translations.summaryVehicleScreen.AUCTIONS_ADS)}</span>
                  {/* <span className='menuHeaderTab iconTextTab' style={{color:'#666666'}}>{t(translations.NAVBAR.DESCRIPTION_AUCTION_TAB)}</span> */}
                </Box>
              </Box>
              {
                openDropdown === "auction" ?
                  <SvgMinus className='accordianIcon' />
                  : <SvgPlus className='accordianIcon' />
              }
            </Box>
            <Box className={(openDropdown == 'auction' ? ' show' : '') + ' flexColumn justifyContentBetween menuLinkMobile toggle'} sx={{ my: 1, px: 4 }}>
              <Link to={`${routes.vehicleRoutes.auction}/${vehicles.car}`}>
                <span className='pointer flexRow alignItemsCenter'><SvgCarType style={{ marginRight: '10px' }} className='logoSvg' />{t(translations.NAVBAR.AUCTION_CAR)}</span>
              </Link>
              <Link to={`${routes.vehicleRoutes.auction}/${vehicles.motorcycles}`}>
                <span className='pointer flexRow alignItemsCenter'><SvgMotorCycleType style={{ marginRight: '10px' }} className='logoSvg' />{t(translations.NAVBAR.AUCTION_MOTORCYCLES)}</span>
              </Link>
              <Link to={`${routes.vehicleRoutes.auction}/${vehicles.bike}`}>
                <span className='pointer flexRow alignItemsCenter'><SvgBikeType style={{ marginRight: '10px' }} className='logoSvg' />{t(translations.NAVBAR.AUCTION_BIKE)}</span>
              </Link>
              <Link to={`${routes.vehicleRoutes.auction}/${vehicles.step}`}>
                <span className='pointer flexRow alignItemsCenter'><SvgStepType style={{ marginRight: '10px' }} className='logoSvg' />{t(translations.NAVBAR.AUCTION_STEP)}</span>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box sx={{ p: 1 }} className='mobileMenu'>
          <span className='menuHeaderTab'>{t(translations.HOT_DEALS.HOT_DEALS)}</span>
          <Box className='flexColumn justifyContentBetween flexWrap menuLinkMobile' sx={{ px: 1 }}>
            <Link to={routes.publicRoutes.hotDeals} className='flexRow alignItemsCenter'>
              <span className='iconHeaderTab' ><SvgHotdeals style={{ marginRight: '10px' }} />{t(translations.HOT_DEALS.HOT_DEALS)}</span>
              <span className='newTag'>{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_NEW)}</span>
            </Link>
          </Box>
        </Box>

        {/* User profile link appear only when user has looged in. */}
        <Box sx={{ p: 1 }} className='mobileMenu'>
          <span className='menuHeaderTab'>{t(translations.NAVBAR.MY_CARTOBIKE)}</span>
          <Box className='flexColumn justifyContentBetween flexWrap menuLinkMobile' sx={{ px: 1 }}>
            {loggedIn && <>
              <Link to={`${routes.vehicleRoutes.myAds}/${vehicles.car}${routes.vehicleRoutes.classic}`}>
                <span className='iconHeaderTab' ><SvgAds style={{ marginRight: '10px' }} />{t(translations.NAVBAR.MY_ADS)}</span>
              </Link>
              <Link to={`${routes.vehicleRoutes.myAds}/${vehicles.car}${routes.vehicleRoutes.park}`}>
                <span className='iconHeaderTab' > <SvgCarbody style={{ width: '25px', height: '25px', marginRight: '10px' }} className='vehicleSvg' /> {t(translations.NAVBAR.MY_ORDERS)}</span>
              </Link>
              {subsidiary && <>
                <Link to={`${routes.vehicleRoutes.myAds}/${vehicles.car}${routes.vehicleRoutes.auction}/live`}>
                  <span className='iconHeaderTab' ><SvgActionPlaces style={{ width: '25px', height: '25px', marginRight: '10px' }} /> {t(translations.NAVBAR.MY_AUCTION)}</span>
                </Link>
                <Link to={`${routes.vehicleRoutes.myAds}/${vehicles.car}${routes.vehicleRoutes.hotDeals}`}>
                  <span className='iconHeaderTab' ><SvgHotdeals style={{ marginRight: '10px' }} /> {t(translations.HOT_DEALS.MY_HOT_DEALS)} </span>
                </Link>
              </>
              }
              <Link to={`${routes.vehicleRoutes.auctionPlace}${routes.vehicleRoutes.auctionPlaceInprogress}`}>
                <span className='iconHeaderTab' ><SvgActionPlaces style={{ marginRight: '10px' }} />{t(translations.webTexts.AUCTION_PLACE)}</span>
              </Link>
            </>}
            <Link to={`${routes.userRoutes.myFavourites}/${vehicles.car}`}>
              <span className='iconHeaderTab SvgUnliked' ><SvgUnliked style={{ marginRight: '10px' }} />{t(translations.NAVBAR.MY_FAVOURITES)}</span>
            </Link>

          </Box>
        </Box>

        <Box sx={{ p: 1 }} className='mobileMenu'>
          <span className='menuHeaderTab'>{t(translations.webTexts.OTHERS)}</span>
          <Box className='flexColumn justifyContentBetween flexWrap menuLinkMobile' sx={{ px: 1 }}>
            <Link to={routes.publicRoutes.faq}>
              <span className='iconHeaderTab'><SvgFaq style={{ marginRight: '10px' }} />{t(translations.FOOTER_MENU.FAQ)}</span>
            </Link>
            <Link to={routes.publicRoutes.contactUs} onClick={() => window.scrollTo(0, 0)}>
              <span className='iconHeaderTab'><SVGHelpCenter style={{ marginRight: '10px' }} />{t(translations.profileScreen.HELP_CENTER)}</span>
            </Link>
            <Link to={routes.publicRoutes.become_partner}>
              <span className='iconHeaderTab'><SVGHandShake style={{ marginRight: '10px' }} />{t(translations.NAVBAR.BECOME_A_PARTNER)}</span>
            </Link>
          </Box>
          <Box sx={{ my: 1, px: 1 }}>
            <Box className='flexRow justifyContentBetween' onClick={() => _openDropdown(openDropdown === 'others' ? '' : 'others')}>
              <Box className='flexRow'>
                <SvgLegal style={{ width: '25px', height: '25px' }} className='vehicleSvg' />
                <Box className='flexColumn' sx={{ ml: 1 }}>
                  <span className='iconHeaderTab'>{t(translations.MOBILE_SIDEBAR.MOBILE_SIDEBAR_LEGAL)}</span>
                </Box>
              </Box>
              {
                openDropdown === "others" ?
                  <SvgMinus className='accordianIcon' /> :
                  <SvgPlus className='accordianIcon' />
              }
            </Box>
            <Box className={(openDropdown === 'others' ? ' show' : '') + ' flexColumn justifyContentBetween menuLinkMobile toggle'} sx={{ my: 1, px: 4 }}>
              <Link to={routes.publicRoutes.terms}>
                <span className='pointer flexRow alignItemsCenter'>{t(translations.FOOTER.FOOTER_TERMS)}</span>
              </Link>
              <Link to={routes.publicRoutes.privacy_policy}>
                <span className='pointer flexRow alignItemsCenter'>{t(translations.FOOTER.FOOTER_PRIVACYPOLICY)}</span>
              </Link>
              <Link to={routes.publicRoutes.data_protection}>
                <span className='pointer flexRow alignItemsCenter'>{t(translations.FOOTER.FOOTER_DATAPROTECTION)}</span>
              </Link>
              <Link to={routes.publicRoutes.legal_notice}>
                <span className='pointer flexRow alignItemsCenter'>{t(translations.FOOTER.FOOTER_LEGALNOTICE)}</span>
              </Link>
              <Link to={routes.publicRoutes.legal_documents}>
                <span className='pointer flexRow alignItemsCenter'>{t(translations.FOOTER.FOOTER_LEGALDOCUMENTS)}</span>
              </Link>
            </Box>
          </Box>
          <Box sx={{ p: 1 }} className='mobileMenu'>
            <span className='iconHeaderTab' onClick={handleSwitchProfile}><SvgLanguageIcon style={{ marginRight: '10px' }} />{t(translations.MOBILE_SIDEBAR.MOBILE_SIDEBAR_LANGUAGES)}</span>
          </Box>

          {!loggedIn && <Box sx={{ p: 1 }} className='mobileMenu'>
            <Link to={routes.authRoutes.login}>
              <span className='iconHeaderTab' >
                <img src={loginIcon} width='20' height="20" style={{ marginRight: '15px' }} />
                {t(translations.formText.SIGNIN)}
              </span>
            </Link>
          </Box>
          }


        </Box>
      </Box>
    </NavBarWrapper>
  )
}

export default NavigationBarSmallDevices