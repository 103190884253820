import { Box } from '@mui/system'
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes/routes';
import Auction from './Auction';
import Vehicle from './Vehicle';
import MyCartoBike from './MyCartoBike';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';
import { vehicles } from 'config/variables';

const NavigationBarLargeDevices = () => {

    const { t } = useTranslation();
    const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth);

    return (
        <Stack direction="row" >
            <Box className='menu pointer'>
                <Link to={routes.vehicleRoutes.adsHome}>
                    <span className='nav-btns' >{t(translations.ads.HOME)}</span>
                </Link>
            </Box>
            <Box className='menu pointer sub-menu'>
                <span className='nav-btns'>{t(translations.ads.VEHICLE)}</span>
                <Vehicle />
            </Box>
            <Box className='menu pointer sub-menu'>
                <span className='nav-btns'>{t(translations.ads.AUCTION)}</span>
                <Auction />
            </Box>
            <Box className='menu pointer'>
                <Link to={routes.publicRoutes.hotDeals} className='newTagContainer'>
                    <span className='nav-btns' >
                        {t(translations.HOT_DEALS.HOT_DEALS)}
                        <span className='newTag'>{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_NEW)}</span>
                    </span>
                </Link>
            </Box>
            {/* User profile link appear only when user has looged in. */}
            {loggedIn &&
                <>
                    <Box className='menu pointer sub-menu'>
                        <span className='nav-btns'>{t(translations.NAVBAR.MY_CARTOBIKE)}</span>
                        <MyCartoBike />
                    </Box>
                </>
            }
            <Box className='menu pointer'>
                <Link to={`${routes.userRoutes.myFavourites}/${vehicles.car}`} className='newTagContainer'>
                    <span className='nav-btns' >
                        {t(translations.NAVBAR.MY_FAVOURITES)}
                    </span>
                </Link>
            </Box>
        </Stack>
    )
}

export default NavigationBarLargeDevices