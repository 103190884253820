import { Box, Typography } from '@mui/material'
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom'
import { creditType } from 'config/constants'
import { hasPermissions, PERMISSIONS } from 'config/permission'
import { numberFormat } from 'config/variables'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const CreditSection = ({ credits, handleBuyNow, stripeProductId }) => {
    const { t } = useTranslation()
    const amountInEuro = ((credits.unit_amount) / 100).toFixed(2);

    const permissionList = useSelector((state: any) => state.user.permissionData)
    const hasAddPermission = hasPermissions(permissionList, PERMISSIONS.FEATURES.FINANCE, PERMISSIONS.ACTIONS.ADD)
    const hasUpdatePermission = hasPermissions(permissionList, PERMISSIONS.FEATURES.FINANCE, PERMISSIONS.ACTIONS.UPDATE)

    return (
        <Box className='credit-section'>
            {credits.is_popular === creditType.popular ? <Box className={'popular-tag'}>{t(translations.HOT_DEALS.MOST_POPULAR)}</Box> : null}
            <Box className='credit-price'>
                <Box className=''>
                    <Typography className='credits'>{t(translations.HOT_DEALS.CREDITS, { credits: credits.credit_points ?? 0, creditText: credits.credit_points > 1 ? t(translations.invoiceScreen.CREDITS) : t(translations.HOT_DEALS.CREDIT) })}</Typography>
                    <Box className='validity'>{t(translations.HOT_DEALS.VALIDITY)}: <span className='validity-days'> {credits.validity_in_days > 1 ? t(translations.HOT_DEALS.DAYS, { days: credits.validity_in_days }) : t(translations.HOT_DEALS.DAY, { days: credits.validity_in_days })}</span></Box>
                </Box>
                <Box className='total-credit'>€{numberFormat(amountInEuro)}</Box>
            </Box>
            <Box className='buy-now-btn'>
                <ButtonCustom textBtn={t(translations.HOT_DEALS.BUY_NOW)}
                    clickFunction={() => {
                        handleBuyNow(credits.id, stripeProductId, credits.stripe_price_id)
                    }}
                    btnColor={'black-btn'}
                    disabled={!hasAddPermission && !hasUpdatePermission}
                    style={{
                        border: '1px solid #cccccc',
                        color: "#000",
                        width: '100%',
                        marginTop: '12px',
                        textTransform: 'capitalize',
                        fontSize: '15px',
                        fontWeight: 400,
                        background: '#fff'
                    }} />
            </Box>
        </Box>
    )
}

export default CreditSection


