import { Box, Skeleton } from '@mui/material'

const ChatSkeleton = () => {
    return (
        <Box className='chatSkeleton'>
            <Skeleton variant="circular" width={40} height={40} />
            <Box sx={{ marginLeft: '10px' }} className='skeletonMessageContainer'>
                <Skeleton variant="text" sx={{ fontSize: '18px' }} className='chatSkeletonName' />
                <Skeleton variant="text" sx={{ fontSize: '15px' }} className='chatSkeletonMessage' />
            </Box>
        </Box>
    )
}

export default ChatSkeleton