import { Box, Button, Popper } from '@mui/material'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { SvgAddNewIcon, SvgNotificationIconHeader, SvgSiteLogo, SvgSiteLogoWithoutName, SvgUserIcon } from 'app/components/svgicons/svgNew'
import ChatIcon from 'app/pages/Chat/Component/ChildComponents/ChatIcon';
import { nameTemplate } from 'config/chatConfig';
import { getSubsidiaryStored } from 'config/variables';
import { translations } from 'locales/translations';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { routes } from 'routes/routes'
import { notificationRequest } from 'store/actions/component-actions';
import Features from 'app/pages/LoginPage/Features';
import ProfilePopup from '../NavigationHeader/NavigationBarComponent/ProfilePopup';
import useOutsideAlerter from 'app/Hooks/outSideDiv';
import WebMenu from '../NavigationHeader/WebMenu';
import { setCreateAdModalOpen } from 'store/actions/public-actions';
import LanguageSwitchinHeader from './languageSwitchinHeader';
import MobileMenu from '../NavigationHeader/MobileMenu';

const MainNavbar = ({ backButton, isFixed, _isFixed, showNavbar = true }) => {

    // Redux state values
    const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth);
    const { notificationCount, subsidiaryDetails, logoUrl, subsidiaryName } = useSelector((state: RootStateOrAny) => state.company);
    const { profileUpdatedData, updatedImage, profileUpdated } = useSelector((state: any) => state.auth)
    const { userData } = useSelector((state: any) => state.user)
    const { isAppBarOpen } = useSelector((state: any) => state.publicReducer)

    // Local states
    const [countNotifications, _countNotifications] = useState<number>(0);
    const [countryFlag, _countryFlag] = useState<string>('');
    const [userName, _userName] = useState<string>("")
    const [openProfile, setOpenProfile] = useState<HTMLButtonElement | null>(null);
    const [openLogin, setOpenLogin] = useState<HTMLButtonElement | null>(null);

    // refrenece Id for popper
    const openPopup = Boolean(openProfile);
    const id = openPopup ? 'simple-popover' : undefined;

    const openLoginPopup = Boolean(openLogin);
    const loginRefId = openLoginPopup ? 'simple-popover' : undefined;


    //Hooks
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()
    const location = useLocation()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const ref = useRef()

    // getting values from localstorage
    const subsidiary = getSubsidiaryStored()

    // hook that closes dropdown when clicked outside the target
    useOutsideAlerter(ref, () => {
        setOpenProfile(null)
        setOpenLogin(null)
    })




    useEffect(() => {
        const name = nameTemplate(subsidiaryDetails, subsidiary, subsidiaryName, profileUpdatedData, userData)
        _userName(name)
    }, [subsidiaryDetails, subsidiary, logoUrl, subsidiaryName, updatedImage, profileUpdated, profileUpdatedData, userData])

    useEffect(() => {
        if (loggedIn) {
            if (subsidiary && subsidiary !== null && subsidiaryDetails?.address_principal?.country_id) {
                if (subsidiaryDetails && Object.keys(subsidiaryDetails).length > 0) {
                    _countryFlag(subsidiaryDetails.address_principal.country.flag_thumbnail)
                }
            } else {
                if (userData && Object.keys(userData).length > 0) {
                    _countryFlag(userData.limited_address.flag_thumbnail)
                }
            }
        }
    }, [subsidiaryDetails, userData])

    useEffect(() => {
        if (notificationCount && notificationCount !== null) {
            if (Object.keys(notificationCount).length > 0) {
                if ('data' in notificationCount) {
                    _countNotifications(notificationCount.data.new_notification_count);
                }
            }
        }
    }, [notificationCount])


    useEffect(() => {
        function handleScroll() {
            if (isAppBarOpen) {
                if (window.scrollY >= 60) {
                    _isFixed(() => true)
                }
                if (window.scrollY <= 1) {
                    _isFixed(() => false)
                }
            } else _isFixed(() => true);
        }
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAppBarOpen]);


    const handleNotification = () => {
        dispatch(notificationRequest())
    }
    const handlePopper = (event: React.MouseEvent<HTMLElement>) => {
        setOpenProfile(openProfile ? null : event.currentTarget);
    };

    const handleLoginPopper = (event: React.MouseEvent<HTMLElement>) => {
        setOpenLogin(openLogin ? null : event.currentTarget);
    };

    return (
        <>
            <Box className='flexRow justifyContentBetween alignItemsCenter top-bar'>
                <span className='site-logo'>
                    {backButton ? <Box className=' pointer'>{backButton}</Box> : null}
                    <Link to={routes.vehicleRoutes.adsHome} style={{ marginLeft: '10px' }}>
                        <SvgSiteLogo style={{ width: isSmallDevice ? '200px' : '250px', height: '50px' }} />
                    </Link>
                </span>

                <Box ref={ref} sx={{ py: 1, alignItems: 'center' }} className='flexRow navbar' >
                    {
                        // Appears only for loggedin users
                        <Box className="flexRow alignItemsCenter" sx={{
                            gap: '16px',
                            '.MuiButton-root': {
                                minWidth: 'auto',
                                backgroundColor: 'transparent',

                            }
                        }}>

                            {/* Does not appears on add vehicle page */}
                            {!isSmallDevice && !isMediumDevice ?
                                !location.pathname.includes(routes.vehicleRoutes.addVehicle) &&
                                !location.pathname.includes(routes.vehicleRoutes.vehicleSummary) &&
                                <Button className='addProduct' sx={{ gap: '10px' }} onClick={() => dispatch(setCreateAdModalOpen(true))}>
                                    <SvgAddNewIcon /> <span>{`${t(translations.webTexts.START_SELL)}`}</span>
                                </Button>
                                :
                                null
                            }

                            <LanguageSwitchinHeader />


                            {!isSmallDevice &&
                                <>
                                    {loggedIn ?
                                        <Button sx={{
                                            p: 0,
                                            '.icon-dot-header': {
                                                width: '9px',
                                                height: '9px',
                                                background: 'rgb(236, 28, 36)',
                                                position: 'absolute',
                                                borderRadius: '6px',
                                                right: '12px',
                                                top: '12px'
                                            }
                                        }}
                                            onClick={handleNotification}
                                            disableRipple >
                                            <SvgNotificationIconHeader />
                                            {countNotifications > 0 && <span className='icon-dot-header'></span>}
                                        </Button>
                                        :

                                        <Button sx={{ p: 0 }} onClick={handleLoginPopper} disableRipple>
                                            <SvgNotificationIconHeader />
                                        </Button>
                                    }

                                    {
                                        !location.pathname.includes(routes.userRoutes.chat) ?
                                            loggedIn ?
                                                <Button sx={{ padding: 0 }} disableRipple>
                                                    <ChatIcon />
                                                </Button> :
                                                <Button sx={{ padding: 0 }} onClick={handleLoginPopper}>
                                                    <ChatIcon ads={'logout'} />
                                                </Button>
                                            : null
                                    }
                                </>
                            }
                        </Box>

                    }

                    {
                        (isLargeDevice || isExtraLargeDevice) ?
                            // Appears in desktop view only
                            loggedIn ?
                                <ProfilePopup handlePopper={handlePopper} open={openPopup} id={id} userName={userName} setOpenProfile={setOpenProfile} openProfile={openProfile} countryFlag={countryFlag} />
                                :
                                <Box className='flexRow alignItemsCenter pointer' sx={{ ml: 2 }}>
                                    <span className='loginBtn' style={{ gap: '10px' }} onClick={handleLoginPopper}>
                                        <SvgUserIcon />{t(translations.formText.SIGNIN)}
                                    </span>
                                    {/* Login popup in header */}
                                    <Popper
                                        open={openLoginPopup}
                                        id={loginRefId}
                                        anchorEl={openLogin}
                                        disablePortal={true}
                                        placement="bottom-end"
                                        PopperProps={{ style: { zIndex: 999 }, }}
                                    >
                                        <Box sx={{ position: 'relative', zIndex: 1, top: '15px', }}>
                                            <Box className='popup-pointer' />
                                        </Box>
                                        <Box sx={{ position: 'relative', top: '18px', borderRadius: '6px', boxShadow: 24 }}>
                                            <Features isHeader={true} setOpenLogin={setOpenLogin} />
                                        </Box>
                                    </Popper>
                                </Box>
                            :
                            // Appears in tab/mobile view
                            <MobileMenu />
                    }
                </Box >

            </Box>
            {(showNavbar && (isLargeDevice || isExtraLargeDevice) ? <WebMenu isFixed={isFixed} /> : null)}
        </>
    )
}

export default MainNavbar