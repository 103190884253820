import React, { useCallback, useMemo, useState } from 'react'
import { LayoutWrapper } from './Style/layoutWrapper'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import MobileNavBottom from 'app/components/NavBar/MobileNavBottom'
import { Helmet } from 'react-helmet-async'
import Footer from './Footer/footer'
import { Box } from '@mui/material'
import { getProductTypeId, sitemapCountries, userTypes } from 'config/variables'
import { RootStateOrAny, useSelector } from 'react-redux'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import userPlaceHolder from 'app/assets/Images/userplaceholder.png';
import MainNavbar from './Components/navbar'
import { useTranslation } from 'react-i18next'
import { hreflang, languagesObject, localstorageKey } from 'config/constants'
import { routes } from 'routes/routes'
import { urlParamInterface } from 'config/interface'
import { checkKey } from 'app/components/Filters/filterConfig'

const PublicLayout = ({ schemaData, schemaForRating, meta, type, description, image, shareUrl, hideHeader, backButton, showNavbar, hideFooter, hideFooterNavBar, chatId, children, isNot404page = true }) => {

  const location = useLocation();
  const [isSmallDevice] = useDeviceType()
  const { i18n } = useTranslation();

  const { vehicle, brand_id: brandId, model_id: modelId, country_id: countryId, slug }: urlParamInterface = useParams();

  const { profileSuccessData } = useSelector((state: RootStateOrAny) => state.publicProfile)
  const { adData, adLoader } = useSelector((state: RootStateOrAny) => state.vehicle)

  const [isFixed, _isFixed] = useState(false);

  const profilePage = matchPath(location.pathname, {
    path: `/:userType(${userTypes.pro}|${userTypes.user})/:slug?`,
    exact: true,
    strict: false
  });

  // Fetching countries data from localstorage
  const localCountriesData = localStorage.getItem(localstorageKey.countries)
  const countries = !localCountriesData ? [] : JSON.parse(localCountriesData);

  const productType = useMemo(() => getProductTypeId(vehicle), [vehicle])

  const languages = useMemo(() => getLanguages(), [i18n.language]);

  // Function that returns selected language by user from URL for sitemap URLS 
  // Will return "en" if any language other than de, en, es, fr ,nl  is applied
  function getLanguages() {
    if (i18n.language === 'en-GB' || i18n.language === 'en-US') {
      return `/${languagesObject.en}`
    } else if (i18n.language) {
      return '/' + i18n.language
    }
    return `/${languagesObject.fr}`
  }

  const getUrl = useCallback(() => {
    let str = location.pathname
    // Will modify url only where brand and model filters are applicable
    if (location.pathname.includes(routes.vehicleRoutes.publicAds) || location.pathname.includes(routes.vehicleRoutes.auctionAd) || location.pathname.includes(routes.publicRoutes.shopURL)) {
      // Checking if brand filter was applied, if yes getting right slug for it and update in URL
      if (brandId) {
        const filterValue = checkKey(brandId, productType, 'brand')
        str = str.replace(brandId, filterValue?.slug ?? brandId)
      }
      // Checking if model filter was applied, if yes getting right slug for it and update in URL
      if (modelId) {
        const filterValue = checkKey(modelId, productType, 'model')
        str = str.replace(modelId, filterValue?.slug ?? modelId)
      }
      return str
    }
    return str
  }, [location.pathname])


  // If country filter is applied then we will show canonical tag in case of particular countries only (belgium, netherlands,germany,france,spain)
  const showCanonicalTags = useCallback(() => {

    const shouldHideCanonicalTags = (
      location.pathname.includes(routes.vehicleRoutes.auctionAds) ||
      location.pathname.includes(`${routes.publicRoutes.hotDeal}/`)
    )

    const isClassicAdsDetailsPage = location.pathname.includes(routes.vehicleRoutes.publicAds) && slug && slug !== ''

    // We wont be creating canonical tags if ad doesn't exists (classic ads)
    if (isClassicAdsDetailsPage && !adLoader && Object.keys(adData).length == 0) {
      return false
    }

    // We wont be creating canonical tags for auction ads details page
    if (shouldHideCanonicalTags) {
      return false
    }

    const shouldCheckFilter = (
      location.pathname.includes(routes.vehicleRoutes.publicAds) ||
      location.pathname.includes(routes.vehicleRoutes.auction) ||
      location.pathname.includes(routes.publicRoutes.hotDeals) ||
      location.pathname.includes(routes.publicRoutes.shopURL)
    )

    if (shouldCheckFilter) {

      if (!brandId && !modelId && !countryId) {
        return true;
      }

      // Checking if brand filter was applied
      if (brandId) {

        const filterValue = checkKey(brandId, productType, 'brand')
        // returning false if filter was wrongly applied
        if (!filterValue) {
          return false
        }

        // returning true if there was no model and country filter applied
        // or country filter was applied as brand filter
        if (
          (filterValue.name == 'brand_slug' && !modelId && !countryId) ||
          (filterValue?.name && filterValue.name == 'country_slug' && sitemapCountries(filterValue.slug))
        ) {
          return true
        }

      }

      // Checking if model filter was applied
      if (modelId) {

        const filterValue = checkKey(modelId, productType, 'model')
        // returning false if filter was wrongly applied
        if (!filterValue) {
          return false
        }

        // returning true if there was no country filter applied and model filter was applied correctly.
        if (
          (filterValue.name == 'model_slug' && !countryId) ||
          (filterValue?.name && filterValue.name == 'country_slug' && sitemapCountries(filterValue.slug))
        ) {
          return true
        }
      }

      // Checking if country filter was correctly applied, if not returning false
      if (countryId) {

        const filterValue = checkKey(countryId, productType, 'country')
        // returning false if filter was wrongly applied
        if (!filterValue) {
          return false
        }

        if (filterValue?.slug && sitemapCountries(filterValue.slug)) {
          return true
        }

      }

      return false

    }
    return true
  }, [location.pathname, countries, brandId, modelId])


  return (
    <LayoutWrapper>
      {/* Seo Properties Pass Throw Child Component */}
      <Helmet
        titleTemplate="%s"
        defaultTitle="CarToBike"
        htmlAttributes={{ lang: i18n.language }}
      >
        {isNot404page ?
          <>
            <script type="application/ld+json">
              {JSON.stringify(schemaData)}
            </script>
            <script type="application/ld+json">
              {JSON.stringify(schemaForRating)}
            </script>
          </>
          :
          <meta name="prerender-status-code" content="404"></meta>
        }

        <title>{meta ? meta : null}</title>
        {description ? <meta name="description" content={description} /> : null}

        {isNot404page && meta && <meta property="og:title" content={meta} />}
        {isNot404page && type && <meta property="og:type" content={type} />}
        {isNot404page && description && <meta property="og:description" content={description} />}
        {isNot404page && image && <meta property="og:image" content={image} />}
        {isNot404page && shareUrl && <meta property="og:url" content={shareUrl} />}
        {isNot404page && <meta property="og:image:width" content={"800"} />}
        {isNot404page && <meta property="og:image:height" content={"405"} />}
        {isNot404page && <meta property="og:image:user_generated" content="true" />}


        {profilePage ? <link rel="preload" href={profileSuccessData.medium_profile_picture_url || userPlaceHolder} as="image" media="(max-width: 360px)" /> : null}

        {
          showCanonicalTags() ?
            <>
              <link rel="canonical" href={`${window.location.origin}${languages}${getUrl()}`} />
              {hreflang.map((el: string) => (
                <link rel="alternate" hrefLang={el} href={`${window.location.origin}/${el}${getUrl()}`} key={el} />
              ))}
              <link rel="alternate" hrefLang="x-default" href={`${window.location.origin}/fr${getUrl()}`} />
            </> :
            <meta name="robots" content="noindex" data-rh="true" />
        }
      </Helmet>
      {!hideHeader &&
        <Box className='main-nav'>
          <MainNavbar backButton={backButton} isFixed={isFixed} _isFixed={_isFixed} showNavbar={showNavbar} />
        </Box>
      }
      <Box className={`main-container ${!showNavbar ? 'hide-menu' : ''}`}>  {children}</Box>

      {!hideFooter && <>
        <Footer />
      </>}
      {isSmallDevice && !hideFooterNavBar && !chatId && <MobileNavBottom />}
    </LayoutWrapper>
  )
}

export default PublicLayout
