import { Backdrop, Box, CircularProgress, Drawer, SwipeableDrawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  sidebarOpenReset,
  langugageSwitchReset,
  switchUserClose,
  notificationRequest,
  notificationReset,
  getWebSideMenuOpenReset,
} from 'store/actions/component-actions';
import styled from 'styled-components';
import MobileLanguages from '../Languages/MobileLanguages';
import MobileSidebar from '../Sidebar/MobileSidebar';
import SwitchAccountNew from '../SwitchAccount/SwitchAccountNew';
import Feature from 'app/pages/NotificationPage/Feature/index';
import Sidebar from 'app/Component2/Layouts/AdsLayouts/Sidebar';

export const GlobalComponent = () => {
  const componentState = useSelector((state: any) => state.componentReducers);
  const { loadFilter } = useSelector((state: any) => state.vehicle);
  const dispatch = useDispatch();

  return (
    <>
      <Drawer
        anchor={`left`}
        open={componentState.sidebarCheck ? true : false}
        onClose={() => dispatch(sidebarOpenReset())}
      >
        <MobileSidebar />
      </Drawer>
      <Drawer
        anchor={`left`}
        open={componentState.webMenuOpen ? true : false}
        onClose={() => dispatch(getWebSideMenuOpenReset())}
        sx={{
          '.MuiPaper-root': {
            background: 'transparent',
            borderRadius: '0px 20px 20px 0',
          },
        }}
      >
        <Sidebar />
      </Drawer>
      <Drawer
        anchor={`right`}
        open={componentState.languageOpen ? true : false}
        onClose={() => dispatch(langugageSwitchReset())}
      >
        <MobileLanguages />
      </Drawer>
      <DrawerCustom
        anchor={`bottom`}
        open={componentState.switchUserDrawer}
        onClose={() => dispatch(switchUserClose())}
      >
        <SwitchAccountNew isFromHeader={false} />
      </DrawerCustom>
      <Drawer
        anchor='right'
        open={componentState.notification ? true : false}
        onClose={() => dispatch(notificationReset())}
      >
        <Box sx={{ width: 380 }}>
          <Feature />
        </Box>
      </Drawer>
      <Backdrop
        open={loadFilter}
        sx={{
          zIndex: 999
        }}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
};
export default GlobalComponent;
const DrawerCustom = styled(Drawer)`
  .MuiDrawer-paperAnchorBottom {
    max-height: 70vh;
    min-height: 50vh;
    border-radius: 5px 5px 0 0 ;
  }
  @media (max-width : 768px) {    
    .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorRight{
      width: 100%;
    }
  }
`;
