import { Box } from '@mui/material'
import { SvgMobileLeftArrow, SvgSiteLogo } from '../svgicons/svgNew'
import { Link, useHistory } from 'react-router-dom'
import MobileMenu from 'app/Component2/Layouts/PublicLayout/NavigationHeader/MobileMenu'
import { routes } from 'routes/routes'


const StaticSmallDeviceHeader = () => {
    const history = useHistory()

    const handleGoBack = () => {
        // go back on home page , if there is no previous url in history related to cartobike,
        // length <= 2 means one is current page , one is google home page, if there more page than there two page , than redirect on previous page from those pages 
        if (history.length <= 2) {
            history.push('/')
        }
        else history.goBack();
    }

    return (
        <>
            <Box className='flexRow justifyContentBetween' style={{ width: '100%', alignItems: 'center', position: 'sticky', top: 0, padding: '10px 15px', height: '50px', backgroundColor: '#fff', zIndex: 1, borderBottom: '1px solid rgb(239 239 239)' }}>
                <SvgMobileLeftArrow onClick={handleGoBack} />
                <Link to={routes.vehicleRoutes.adsHome} style={{ marginLeft: '10px' }}>
                    <SvgSiteLogo style={{ width: '200px', height: '50px' }} />
                </Link>
                <MobileMenu />
            </Box>
        </>
    )
}

export default StaticSmallDeviceHeader