import UserLayout from 'app/Component2/Layouts/UserLayout/UserLayout'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { MyWallerWrapper } from './style/MyWallerWrapper'
import CreditHistory from './components/CreditHistory'
import CreditBalance from './components/CreditBalance'
import { Box } from '@mui/material'
import { SvgArrowBack } from 'app/components/svgicons/svgNew'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { useHistory } from 'react-router-dom'
import SwitchAccountByNotificationClick from 'app/Component2/GlobalComponent/SwitchAccountByNotificationClick'
import { useQueryStringParams } from 'app/Hooks/useParams'
import { useEffect } from 'react'
import { hasPermissions, PERMISSIONS } from 'config/permission'
import { useDispatch, useSelector } from 'react-redux'
import PermissionComponent from 'app/Component2/GlobalComponent/PermissionComponent'
import { creditsBalanceRequest } from 'store/actions/user-actions'

const MyWallet = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isSmallDevice, isMediumDevice] = useDeviceType()
    const history = useHistory();
    const params = useQueryStringParams();

    const permissionList = useSelector((state: any) => state.user.permissionData)
    const hasReadAccess = hasPermissions(permissionList, PERMISSIONS.FEATURES.FINANCE, PERMISSIONS.ACTIONS.READ)

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(creditsBalanceRequest())
    }, [])

    return (
        <>
            {params?.switch && <SwitchAccountByNotificationClick />}
            <UserLayout title={t(translations.HOT_DEALS.CARTOBIKE_CREDIT)} >
                <MyWallerWrapper>
                    {(isSmallDevice || isMediumDevice) &&
                        <Box className='flexRow justifyContentBetween alignItemsCenter pageHeader'>
                            <SvgArrowBack onClick={() => history.goBack()} />
                            <h2>{t(translations.HOT_DEALS.CARTOBIKE_CREDIT)}</h2>
                            <SvgArrowBack style={{ visibility: 'hidden' }} />
                        </Box>}
                    {hasReadAccess ? <>
                        <CreditBalance />
                        <CreditHistory />
                    </> : <PermissionComponent />}
                </MyWallerWrapper>
            </UserLayout>
        </>
    )
}

export default MyWallet