import { Box, FormControl, FormControlLabel, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";
export const IconBedge = styled.div`
  display: flex;
  align-items: left;
  border-radius: 29px;
  flex-direction: column;
  max-width:100%;
  border-radius: 30px;
  font-size: 15px;
  display: flex;
  font-weight:400;
  span{
    font-weight : 400;
    white-space: nowrap;  
    overflow: hidden;
    text-overflow: ellipsis;
    color: #999999 !important;
    text-wrap:wrap;
    text-align: left;
    width:100%;
    font-size: 15px;
    font-style: normal;
    line-height: 22px; 
  }
  @media (max-width:768px){
    span{ 
       font-size:13px;
       font-weight:350;
    }
  }
`

export const AdsTextButton = styled.button`
  border: 0;
  background-color: ${colorNew.colorGray};
  font-size: 13px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 10px;
  &.war{
    text-transform: lowercase;
  }
  svg{
    margin-right: 5px;
    height: 18px;
    width: 18px;
  }
  &.w-100{
    width: 100%;
  }
  &:not(.colorGray){
    color: ${colorNew.primaryColor};
  }
  &.colorGray{
    color: ${colorNew.colorGrayDark};
  }
`

export const DetailsTitle = styled(Typography)`
  font-size: 21px;
  font-weight: 500;
  margin-bottom:13px;
  color: #000;  
`
export const VatTab = styled.label`
  font-size: 13px;
  font-weight: 600;
  background: ${colorNew.colorGreenDark};
  color:${colorNew.colorWhite};
  display: inline-block;
  padding: 2px 8px;
  border-radius: 10px;
`

export const DetailListing = styled(Box)`  
  padding:5px 15px;
  border-radius:5px;
  label {
    max-width: 50%; 
  }
  span {
    max-width: 45%;
    display: flex;
    text-align: right;
  }

`

export const FormControlLabelCus = styled(FormControlLabel)`
  opacity: 0.5;
  &.active{
    opacity: 1;
    .MuiTypography-root{
      
    }
  }
`
export const FormControlSelectCus = styled(FormControl)`
  width: 100%;
  margin: 0;
  &.bg-fade{
    .MuiOutlinedInput-root{
      background-color: ${colorNew.colorGray};
      border-radius: 0;
    }
  }
`
export const TextFieldCus = styled(TextField)`
  width: 100%;
  margin: 0;
  color: ${colorNew.primaryColor};
  &.uppercase{
    input{
      text-transform: uppercase;
    }
  }
  .MuiOutlinedInput-root{
    input{
      color: ${colorNew.primaryColor};
      background-color: ${colorNew.colorWhite};
      &::placeholder{
        display: none;
        color: ${colorNew.primaryColor} !important;
      }
    } 
  }
  .MuiInputLabel-root.MuiInputLabel-formControl{
    display: none;
  }
  fieldset{
    top:0;
    legend{
      display: block;
    }
  }
`

export const FilterLabel = styled.label`
  display: block;
  font-size: 15px;
  color: ${colorNew.colorGrayDark2};
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px; 
  svg{
    margin-right: 5px;
    height:17px;
    width:17px;
    margin-top: 4px;
  }
`
export const FilterRadioSelection = styled.div`
  &.scroll{
    overflow: auto;
  }
  display:flex;

  span{
    white-space: nowrap;
    margin:2px;
    display: inline-block;
    height: 40px;
    padding: 0 10px; 
    /* max-width: calc(50% - 10px); */
    /* width: calc(50% - 10px); */
    border: 1px solid ${colorNew.colorGrayDark};
    text-align: center;
    height: 40px;
    border-radius:4px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    &.active{
      opacity: 1;
      border: 1px solid ${colorNew.primaryColor};
    }
  }

`

export const SelectElementStyled = styled(Box)`
  display: flex;
  overflow: auto;
  scroll-behavior: smooth;
  .body-box{
    display: flex;
    border-radius: 10px;
    width: 100px;
    height: 98px;
    min-width: 100px;
    min-height: 98px;
    position: relative;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    flex-direction: column;
    &:not(:last-of-type){
      margin-right: 6px;
    }
    img{
      width: 79px;
      height: 79px;
      object-fit: contain;
    }
    &.active{
      border: 1px solid ${colorNew.primaryColor};
    }
    .name{
      font-size: 12px;
      white-space: normal;
      position: absolute;
      bottom: 0;
      white-space: nowrap;
      text-align: left;
      text-overflow: ellipsis;
      max-width: 90%;
      overflow: hidden
    }
    .check{
      position: absolute;
      width: 20px;
      height: 20px;
      right: 5px;
      top: 5px;
      border: 2px solid #7ABE44;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
    }
    .close{
      position: absolute;
      background: red;
      right: 0;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      top:0;
      svg{
        height: 10px;
        width: 10px;
      }
    }
  }
`


export const StatusBar = styled(Box)`
 
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  position:sticky;
  top: 0;
  z-index: 1;
  .toggler-icon{
    width: 25px;
  }
  .title{
    text-align: center;
    white-space: nowrap;
  }
  .right-bar{
    min-width: 30px;
    .component{
      display: flex;
      justify-content: end;
      span{
      height: 35px;
      display: flex;
      align-items: center;
      max-width: 35px;
      min-width: 35px;
      justify-content: center;
      margin: 0 3px;
      svg{
        max-width: 22px;
        height: 22px;
        min-width: 22px;
      }
      }
    }
  }
  .like-share-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .icon-style{
    background: transparent;
    // height: 40px;
    // width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
  @media (min-width:900px){
    padding: 0 15px;
  }
  @media(max-width:899px){
    padding: 0 0px;
  }
`
export const DetailNavbarStyle = styled(Box)`
.like-share-container{
  display: flex;
  justify-content: space-between;
  color: ${colorNew.colorGrayDark4};
  font-weight: 400;
  font-size: 13px;
  align-items: center;
}
.header-timer{
  color: #617AF7;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
}
.ad-title-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ad-title-ellipsis{
display: block;
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail-Bar-wraper{
  display: flex;
  column-gap: 20px;
  min-height: 40px;
  max-height: 42px;
}
`