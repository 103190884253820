import { Box } from '@mui/material'
import { SvgClose, SvgDoc2 } from 'app/components/svgicons/svg'
import { translations } from 'locales/translations'
import React from 'react'
import { useTranslation } from 'react-i18next'

const AttachmentPreview = ({ attachmentFile, index, attachment, setAttachment, inputBoxRef }) => {

    const { t } = useTranslation()

    return (
        <Box className='previewContainer'>
            {attachmentFile.type.includes("image") && <img className="imagePreview" src={URL.createObjectURL(attachmentFile)} alt={"image-" + index} key={index} />}
            {!attachmentFile.type.includes("image") && <Box className='fileMessage'>
                <span>{attachmentFile.name}</span>
                <Box className='docDetails'>
                    <SvgDoc2 style={{ marginLeft: '8px' }} /><span> {t(translations.CHAT.FILE)}</span>
                </Box>
            </Box>}
            <SvgClose className='pointer closeIcon' onClick={() => {
                setAttachment(attachment.filter((number, i) => i !== index))
            }}
            />

        </Box>
    )
}

export default AttachmentPreview