
import { numberFormat } from "./variables"

export const localstorageKey = {
  carFilterdata: 'carFilterdata',
  stepFilterdata: 'stepFilterdata',
  bikeFilterdata: 'bikeFilterdata',
  motorCycleFilterdata: 'motorCycleFilterdata',
  deleteFilterData: 'deleteFilterData',
  i18nextLng: 'i18nextLng',
  screen: 'screen',
  adsId: 'adsId',
  vehicleTypeData: 'vehicleTypeData',
  resubscribesubsidiary: 'resubscribesubsidiary',
  activeSubsidiary: 'activeSubsidiary',
  countries: "countries",
  prevPath: 'previousPath',
  token: 'token',
  auctionList: 'auctionlist',
  groupedCountries: 'groupedCountries',
  activeLanguages: 'activeLanguages',
  selectedVehicle: 'selectedVehicle',
  selectedProductCategory: 'selectProductCategory',
  user: 'user',
  contactSubjects: "contactSubjects",
  backlinks: 'backlinks',
  slidersFetched: 'slidersFetched',
  sliderData: 'sliderData',
  partnerSliderData: 'partnerSliderData',
  price_id: 'price_id',
  stripeProductId: "stripeProductId",
  buyCreditPage: "buyCreditPage",
  paymentFailure: 'paymentFailure',
  productType: 'productType',
  purchaseCredit: 'purchaseCredit',
  makeCreditPaymentIntentId: "makeCreditPaymentIntentId",
  hotdealReservationIntentId: "hotdealReservationIntentId",
  purchaseCreditByHotdeal: "purchaseCreditByHotdeal",
  hotdealAdId: "hotdealAdId",
  purchaseBlockTime: "purchaseBlockTime",
  hotdealPaymentReleaseDelayDays: "hotdealPaymentReleaseDelayDays",
  displayHotdealPopup: 'displayHotdealPopup'
}

export const showAuctionsTo = {
  ALL: '0',
  DEALER: '1',
  PRIVATE: '2',
  LOGGEDIN: '3',
  UNCOMPLETE_PROFILE: '4'
}

export const changePlanScreens = {
  changeplanMainScreen: 0,
  planDetailScreen: 1,
  changePlanSummaryScreen: 2,
  changePlanPaymentMethodScreen: 3,
}
export const filterAuction = {
  auctionActiveOrUpcoming: 1,
  auctionActive: 1,
  auctionUpcoming: 1
}

export const heightWidthkey = {
  scrollHeight: 1800
}

export const bidTYpes = {
  auto: 1,
  mannual: 0
}

export const adsTypesNum = {
  classicAds: 0,
  auctionAds: 1,
  hotDealAds: 3
}

export const hotDealAdStatus = {
  available: 1,
  sold: 2,
  eventEnded: 3
}

export const types = {
  faqFrontend: 2
}

export const bidAcceptedType = {
  accept: 1,
  reject: 2,
  default: 0
}

export const hreflang = [
  'de',
  'en',
  'es',
  'fr',
  'nl'
];

export const languagesObject = {
  de: "de",
  en: "en",
  es: "es",
  fr: "fr",
  nl: "nl",
  enUS: "en-US"
};

export const statusCode = {
  permissionDenied: 403,
};

export const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 8, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

export const getCountryNameBySlug = (slug, arr = []) => {
  const countries = arr.length ? arr : JSON.parse(localStorage.getItem(localstorageKey.countries) || '[]')
  const countrieObject = countries.length > 0 ? countries.find((x) => x.slug === slug) : false
  return countrieObject;
}

export const handleCallCount = (id, callRequest, dispatch) => {
  let payload: any = {
    vehicle_id: id,
  };
  dispatch(callRequest(payload))
}

export const adsPageType = {
  publicAdsPage: 'publicAdsPage',
  adsDetailPage: 'adsDetailPage',
  publicAuction: 'public-auction',
  publicAdsAuctionPage: 'publicAdsAuctionPage'
}


export const auctionFilter = {
  all: '',
  startedOnly: 'active',
  inExtraTime: 'extra_time',
  startSoonOnly: 'auction_upcoming_or_ongoing_registration'
}

export const adsTypeName = {
  classicAds: "classicAds",
  auctionAds: "auctionAds"
}


export const documentImageSize = 5;

export const defaultDiscount = 44;

export const addTypes = {
  park: 'park',
  auctionActionRequired: 'auction-required-action',
  classic: 'classic',
  auctionLive: 'auction-live'
}

export const bidAcceptRejectValues = {
  accept: 1,
  reject: 2
}

export const getCurrentPrice = (item, loggedIn, subsidiaryId, userData) => {
  if (loggedIn) {
    if (userData && userData.profile_is_complete == false) {
      return null
    }
    if ((item.display_for == showAuctionsTo.PRIVATE && subsidiaryId) || (item.display_for == showAuctionsTo.DEALER && (subsidiaryId == null || subsidiaryId == undefined))) {
      // display **** instead of price if user in not allowed to see ad 
      return null;
    }
    if (item?.best_manual_bid_relation && item?.best_manual_bid_relation.amount) {
      // display current price of ad
      return numberFormat(Math.trunc(item.best_manual_bid_relation.amount))
    }
    if (item.start_price) {
      return numberFormat(Math.trunc(item.start_price))
    }

    return '0';
  }
  // display **** for guest users
  return null
}


export const ProductCategories = {
  vehicle: 0,
  tire: 1,
  parts: 2
}


export const selectAll = {
  select: 0,
  deSelect: 1
}

export const groupedSelect = {
  none: 0,
  select1: 1,
  select2: 2
}

export const wheelTypes = {
  hubcups: 0,
  rims: 1
}

export const dimensions = {
  width: 0,
  height: 1,
  diameter: 2
}


export const condition = {
  used: 0,
  new: 1
}


export const damageLevels = {
  lite: 0,
  medium: 1,
  strong: 2,
  replace: 3
}


export const CVUnits = {
  cv: 1,
  kw: 2
}

export const publicOrDealer = {
  public: 0,
  dealer: 1
}



export const editModes = {
  editPrice: 'edit_price',
  editInfo: 'edit_info'
}

export const FieldValdations = {
  WARRANTY_VALIDATIION_MINIMUM: 1,
  WARRANTY_VALIDATIION_MAXIMUM: 84
}

export const userTypeId = {
  DEALER: '1',
  PRIVATE: '2'
}

export const fieldsArray = ['first_name', 'last_name', 'email', 'phone', 'subject_id']

export const weekDays = {
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
}

export const creditType = {
  popular: 1,
  notPopular: 0,
}

export const minimumStartingPrice = 30;

export const creditExpensType = {
  PURCHASED: 1,
  USED: 2,
  EXPIRED: 3,
  ADDED: 4,
  REFUNDED: 5,
  LICENSESEARCH: 6
}

export const paymentType = {
  BUYCREDIT: 1,
  RESERVE_VEHICLE: 2
}

export const cardType = {
  DEFAULT_CARD: 1,
  NONE_DEFAULT_CARD: 0

}

export const invoiceStatus = {
  PAID: 'paid',
  UNCOLLECTIBLE: 'uncollectible'
}
export const reservationRequestCancelledBy = {
  SELLER: 1,
  BUYER: 2
}

export const paymentMode = {
  CARD: 'card',
  BANCONTACT: 'bancontact',
  SEPA_DEBIT: 'sepa_debit',
}
