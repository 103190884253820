import { handleActions } from 'redux-actions';
import {
  getChatFailure,
  getChatRequest,
  getChatSuccess,
  getArchivedChatRequest,
  getArchivedChatSuccess,
  getArchivedChatFailure,
  getBlockedChatRequest,
  getBlockedChatSuccess,
  getBlockedChatFailure,
  getUserListRequest,
  getUserListSuccess,
  getUserListFailure,
  chatStoreRequest,
  chatStoreSuccess,
  chatStoreFailure,
  particularChatRequest,
  particularChatSuccess,
  particularChatFailure,
  deleteParticularChatRequest,
  deleteParticularChatSuccess,
  deleteParticularChatFailure,
  blockParticularChatRequest,
  blockParticularChatSuccess,
  blockParticularChatFailure,
  archiveParticularChatRequest,
  archiveParticularChatSuccess,
  archiveParticularChatFailure,
  unreadParticularChatRequest,
  unreadParticularChatSuccess,
  unreadParticularChatFailure,
  muteParticularChatRequest,
  muteParticularChatSuccess,
  muteParticularChatFailure,
  unreadAllChatRequest,
  unreadAllChatSuccess,
  unreadAllChatFailure,
  muteAllChatRequest,
  muteAllChatSuccess,
  muteAllChatFailure,
  sendMessageRequest,
  sendMessageSuccess,
  sendMessageFailure,
  makeReceptionistRequest,
  makeReceptionistSuccess,
  makeReceptionistFailure,
  resetChatRequest,
  messageGroupByDateRequest,
  messageGroupByDateSuccess,
  getNewMessageNotification,
  setChatPopUpState,
  getsubsidiaryActiveMemberListRequest,
  getsubsidiaryActiveMemberListSuccess,
  getsubsidiaryActiveMemberListFailure,
  addMemberRequest,
  addMemberSuccess,
  addMemberFailure,
  updateGroupDetailRequest,
  updateGroupDetailSuccess,
  updateGroupDetailFailure,
  blockedByRequest,
  blockedBySuccess,
  blockedByFailure,
  blockChatLoadedState,
  lastMessagesState,
  resetUserList
} from 'store/actions/chat-action';

const initialState = {
  loadingChat: false,
  loadingAllChats: true,
  loadingBlockedChats: true,
  loadingArchivedChats: true,
  chatsRequested: true,
  chatStoreData: {},
  chatStoreError: {},
  chatList: [],
  chatListAllData: {},
  chatError: {},
  archivedChatList: [],
  archivedChatListAllData: {},
  archivedChatLoaded: false,
  archivedChatError: {},
  blockedChatList: [],
  blockedChatListAllData: {},
  blockedChatLoaded: false,
  blockedChatError: {},
  loadingUsers: false,
  userList: [],
  userListLastPage: null,
  userListError: {},
  loadingMessages: false,
  particularChatMessages: {},
  particularChatError: {},
  deleteParticularChat: {},
  deleteParticularChatFailureData: {},
  blockParticularChat: {},
  blockParticularChatFailureData: {},
  archiveParticularChat: {},
  archiveParticularChatFailureData: {},
  unreadParticularChat: {},
  unreadParticularChatFailureData: {},
  muteParticularChat: {},
  muteParticularChatFailureData: {},
  unreadAllChat: {},
  unreadAllChatFailureData: {},
  muteAllChat: {},
  muteAllChatFailureData: {},
  sendMessageSuccessData: {},
  sendMessageFailureData: {},
  makeReceptionistDataFailureData: {},
  receptionistData: {},
  onlineUserData: [],
  messageGroupByDate: {},
  newMessageNotification: false,
  loadingStoreChat: false,
  chatPopUpState: false,
  subsidiaryMemberFailureData: {},
  subsidiaryMemberList: {},
  addMemberListFailure: {},
  groupUpdateLoading: false,
  addMemberList: {},
  removeMemberList: {},
  removeMemberListFailure: {},
  blockedByUsers: [],
  blockedBySubs: [],
  lastMessages: []
}
const chat = handleActions({
  // Open chat popOver on web
  [setChatPopUpState]: (state, { payload }) => (
    {
      ...state,
      chatPopUpState: payload
    }),
  [blockChatLoadedState]: (state) => (
    {
      ...state,
      blockedChatLoaded: false
    }),
  //  Get Chat list reducer
  [getChatRequest]: state => ({
    ...state,
    loadingAllChats: true,
    chatsRequested: true
  }),
  [getChatSuccess]: (state, { payload }) => (
    {
      ...state,
      chatList: payload.append ? [...state.chatList, ...payload.data.data.data] : payload.data && payload.data.data ? payload.data.data.data : [],
      chatListAllData: payload?.data || {},
      loadingAllChats: false,
      chatsRequested: false
    }
  ),
  [getChatFailure]: (state, { payload }) => ({
    ...state,
    chatError: payload.data,
    loadingAllChats: false
  }),

  //  Get Archved chat list reducer
  [getArchivedChatRequest]: state => ({
    ...state,
    loadingArchivedChats: true,
  }),
  [getArchivedChatSuccess]: (state, { payload }) => (
    {
      ...state,
      archivedChatList: payload.append ? [...state.archivedChatList, ...payload.data.data.data] : payload.data.data.data,
      archivedChatListAllData: payload?.data || {},
      archivedChatLoaded: true,
      loadingArchivedChats: false,
    }),
  [getArchivedChatFailure]: (state, { payload }) => ({
    ...state,
    archivedChatError: payload.data,
    loadingArchivedChats: false,
  }),

  //  Get Blocked Chat list reducer
  [getBlockedChatRequest]: state => ({
    ...state,
    loadingBlockedChats: true,
  }),
  [getBlockedChatSuccess]: (state, { payload }) => (
    {
      ...state,
      blockedChatList: payload.append ? [...state.blockedChatList, ...payload.data.data.data] : payload.data.data.data,
      blockedChatListAllData: payload?.data || {},
      blockedChatLoaded: true,
      loadingBlockedChats: false,
    }),
  [getBlockedChatFailure]: (state, { payload }) => ({
    ...state,
    blockedChatError: payload.data,
    loadingBlockedChats: false,
  }),


  //  Get user list reducer
  [getUserListRequest]: state => ({
    ...state,
    loadingUsers: true,
  }),
  [getUserListSuccess]: (state, { payload }) => (
    {
      ...state,
      loadingUsers: false,
      userListLastPage: payload.data.last_page,
      userList: payload.data.current_page === 1 ? payload.data.data : [...state.userList, ...payload.data.data],
    }),
  [getUserListFailure]: (state, { payload }) => (
    {
      ...state,
      loadingUsers: false,
      userListError: payload?.data,
    }),
  [resetUserList]: (state) => (
    {
      ...state,
      loadingUsers: false,
      userList: [],
      userListLastPage: null,
      userListError: {}
    }),

  // Create chat store Reducer
  [chatStoreRequest]: state => ({
    ...state,
    loadingStoreChat: true,
    loadingChat: true,
  }),
  [chatStoreSuccess]: (state, { payload }) => (
    {
      ...state,
      loadingChat: false,
      loadingStoreChat: false,
      chatStoreData: payload,
      lastMessages: payload?.data?.item.messages ?? []
    }),
  [chatStoreFailure]: (state, { payload }) => ({
    ...state,
    loadingChat: false,
    loadingStoreChat: false,
    chatStoreError: payload.data,
  }),

  //  Get particular chat's messages reducer
  [particularChatRequest]: state => ({
    ...state,
    loadingMessages: true,
  }),
  [particularChatSuccess]: (state, { payload }) => (
    {
      ...state,
      loadingMessages: false,
      particularChatMessages: payload,
    }),
  [particularChatFailure]: (state, { payload }) => ({
    ...state,
    loadingMessages: false,
    particularChatError: payload.data,
  }),
  [lastMessagesState]: (state, { payload }) => (
    {
      ...state,
      lastMessages: payload
    }),

  // Delete Particular Chat 
  [deleteParticularChatRequest]: state => ({
    ...state,
    deleteParticularChat: {}
  }),
  [deleteParticularChatSuccess]: (state, { payload }) => (
    {
      ...state,
      deleteParticularChat: payload,
    }),
  [deleteParticularChatFailure]: (state, { payload }) => ({
    ...state,
    deleteParticularChat: {},
    deleteParticularChatFailureData: payload.data,
  }),

  // Archive Particular Chat 
  [archiveParticularChatRequest]: state => ({
    ...state,
    archiveParticularChat: {}
  }),
  [archiveParticularChatSuccess]: (state, { payload }) => (
    {
      ...state,
      archiveParticularChat: payload,
    }),
  [archiveParticularChatFailure]: (state, { payload }) => ({
    ...state,
    archiveParticularChat: {},
    archiveParticularChatFailureData: payload.data,
  }),

  // Block Particular Chat 
  [blockParticularChatRequest]: state => ({
    ...state,
    blockParticularChat: {}
  }),
  [blockParticularChatSuccess]: (state, { payload }) => (
    {
      ...state,
      blockParticularChat: payload,
    }),
  [blockParticularChatFailure]: (state, { payload }) => ({
    ...state,
    blockParticularChat: {},
    blockParticularChatFailureData: payload.data,
  }),

  // Unread Particular Chat 
  [unreadParticularChatRequest]: state => ({
    ...state,
    unreadParticularChat: {}
  }),
  [unreadParticularChatSuccess]: (state, { payload }) => (
    {
      ...state,
      unreadParticularChat: payload,
    }),
  [unreadParticularChatFailure]: (state, { payload }) => ({
    ...state,
    unreadParticularChat: {},
    unreadParticularChatFailureData: payload.data,
  }),

  // mute Particular Chat 
  [muteParticularChatRequest]: state => ({
    ...state,
    muteParticularChat: {}
  }),
  [muteParticularChatSuccess]: (state, { payload }) => (
    {
      ...state,
      muteParticularChat: payload,
    }),
  [muteParticularChatFailure]: (state, { payload }) => ({
    ...state,
    muteParticularChat: {},
    muteParticularChatFailureData: payload.data,
  }),

  // Unread All Chat 
  [unreadAllChatRequest]: state => ({
    ...state,
    unreadAllChat: {}
  }),
  [unreadAllChatSuccess]: (state, { payload }) => (
    {
      ...state,
      unreadAllChat: payload,
    }),
  [unreadAllChatFailure]: (state, { payload }) => ({
    ...state,
    unreadAllChat: {},
    unreadAllChatFailureData: payload.data,
  }),

  // mute All Chat 
  [muteAllChatRequest]: state => ({
    ...state,
    muteAllChat: {}
  }),
  [muteAllChatSuccess]: (state, { payload }) => (
    {
      ...state,
      muteAllChat: payload,
    }),
  [muteAllChatFailure]: (state, { payload }) => ({
    ...state,
    muteAllChat: {},
    muteAllChatFailureData: payload.data,
  }),

  // send Message 
  [sendMessageRequest]: state => ({
    ...state,
    sendMessageSuccessData: {}
  }),
  [sendMessageSuccess]: (state, { payload }) => (
    {
      ...state,
      sendMessageSuccessData: payload,
    }),
  [sendMessageFailure]: (state, { payload }) => ({
    ...state,
    sendMessageSuccessData: {},
    sendMessageFailureData: payload,
  }),

  // Make user as Receptionist Reducer
  [makeReceptionistRequest]: state => ({
    ...state,
    receptionistData: {}
  }),
  [makeReceptionistSuccess]: (state, { payload }) => (
    {
      ...state,
      receptionistData: payload,
    }),
  [makeReceptionistFailure]: (state, { payload }) => ({
    ...state,
    receptionistData: {},
    makeReceptionistDataFailureData: payload.data,
  }),

  // Reset all chat states
  [resetChatRequest]: state => ({
    ...state,
    chatList: [],
    chatListAllData: {},
    archivedChatList: [],
    archivedChatListAllData: {},
    blockedChatList: [],
    blockedChatListAllData: {},
    userList: [],
    blockedByUsers: [],
    blockedBySubs: []
  }),

  //group by chat messages reducer
  [messageGroupByDateRequest]: (state, { payload }) => ({
    ...state,
  }),
  [messageGroupByDateSuccess]: (state, { payload }) => (
    {
      ...state,
      messageGroupByDate: { ...state.messageGroupByDate, ...payload },
    }),

  // Get New message notification 
  [getNewMessageNotification]: (state, { payload }) => ({
    ...state,
    newMessageNotification: payload
  }),

  // Make user as Receptionist Reducer
  [getsubsidiaryActiveMemberListRequest]: state => ({
    ...state,
    subsidiaryMemberList: {}
  }),
  [getsubsidiaryActiveMemberListSuccess]: (state, { payload }) => (
    {
      ...state,
      subsidiaryMemberList: payload.data,
    }),
  [getsubsidiaryActiveMemberListFailure]: (state, { payload }) => ({
    ...state,
    subsidiaryMemberList: {},
    subsidiaryMemberFailureData: payload.data,
  }),
  //Add member in group 
  [addMemberRequest]: state => ({
    ...state,
    addMemberList: {},
    groupUpdateLoading: true
  }),
  [addMemberSuccess]: (state, { payload }) => (
    {
      ...state,
      addMemberList: payload,
      groupUpdateLoading: false
    }),
  [addMemberFailure]: (state, { payload }) => ({
    ...state,
    addMemberListFailure: payload,
    groupUpdateLoading: false
  }),

  //Add member in group 
  [updateGroupDetailRequest]: state => ({
    ...state,
    removeMemberList: {},
    groupUpdateLoading: true
  }),
  [updateGroupDetailSuccess]: (state, { payload }) => (
    {
      ...state,
      removeMemberList: payload,
      groupUpdateLoading: false
    }),
  [updateGroupDetailFailure]: (state, { payload }) => ({
    ...state,
    removeMemberList: {},
    removeMemberListFailure: payload,
    groupUpdateLoading: false

  }),

  //group by chat messages reducer
  [blockedByRequest]: (state, { payload }) => ({
    ...state,
    blockedByUsers: [],
    blockedBySubs: []
  }),
  [blockedBySuccess]: (state, { payload }) => (
    {
      ...state,
      blockedByUsers: [...payload.data.blockedByUsers],
      blockedBySubs: [...payload.data.blockedBySubsidiaries]
    }),

  // Get New message notification 
  [blockedByFailure]: (state, { payload }) => ({
    ...state
  }),


}, initialState,)

export default chat;