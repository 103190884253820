import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const UserDetailWrapper = styled.div`
/* max-width: 400px; */

width: 100%;
padding-bottom: 10px;
.userInfoContainer{
  padding: 8px 12px; 
  border: 1px solid #F2F2F2;
  border-radius: 3px;
  margin-top:10px;
  position:relative;
  gap:10px;
  &>*{
    margin-top:8px;
  }
  display: flex;
  justify-content: space-between;
  &.denied{
    margin:0;
    padding:0;
    p{
      padding:0 10px;
      color: #666; 
      margin:15px 0;
      font-size: 15px; 
      font-weight: 400; 
    }
  }
}
.profileBadge{
  position:absolute;
  top:-20px;
  left:10px;
  border-radius: 3px;
  color:white;
  font-size:12px;
  font-weight:400;
  padding: 2px 5px;
  background: radial-gradient(4692.25% 100% at 0% 50%, #384386 0%, #6E78BA 51.05%, #384386 100%);
  a{
    color:white;
    font-size:12px;
    font-weight:400;
  }
   &.private{
    background:lightgray;
    span{
      color:#666;
    }
   }
}
 
.userImage{
  object-fit:cover;
  margin-top:0px !important;
  border-radius: 4px;
  width: 32px;
  height: 32px;
}
.user-detail{
  font-size:12px;
  font-weight:400;
  color:#666666 !important;
  display:flex; 
  flex-direction:row;
  align-items:center;
  justify-content:start; 
  span{
    min-width:max-content;
    &:first-child{
      gap:3px;
    }
  }
  .line{
    width: 1px;
    background: #e5e5e5;
    height: 10px;
    margin-left: 8px;
  }
}
.chatIcon button{
  height:40px;
  white-space: nowrap;
}
.flagImage{
  width: 12px;
  height: 12px;
  position: absolute;
  right: -4px;
  top: 22px;
  border-radius:50%;
}
.blurEffect{
  filter: blur(5px); 
}
.userDetailLink{
  /* min-width: 72%; */
}
.name{
  color: #101010;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 137.5% */
  letter-spacing: 0.1px;  
}
.location{
  color: var(--text-deep-gray, #666);
  /* Caption */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
}
.rating{
  margin-left: 5px;
  color: #777;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.26px;
}
.star{
  width: 16px;
  height: 16px;
  margin-left:8px;
}
.dealer{
  background: #F2F2F2;
  border-radius: 3px;
  padding: 0 8px;
  margin-left:10px;
  color: var(--text-deep-gray, #666);
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.phoneIcon{
  padding : 3px;
  border-radius : 4px;
  width: 32px;
  height: 32px;
  background :${colorNew.colorGreen};
}
.phoneIcon svg{
  width :25px !important;
  height :25px !important;
}
.link , .linkText {
  min-width:100%;
  width:max-content;
  text-align:center; 
}
.link{
  text-align : center; 
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 162.023%; /* 24.303px */
  letter-spacing: 0.225px;
  text-decoration-line: none;
  cursor : pointer;
}
.linkText{ 
  text-align : center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 162.023%; /* 24.303px */
  letter-spacing: 0.225px;
}
.btnText{
  color : white;
  font-size:15px;
}

.infoModal{
    display: flex;
    flex-direction: column;
    border-radius: 2px;
  }
.infoModal .locationSubHeader{
  justify-content: center  !important;
  margin-bottom: 10px;
  padding: 0px 10px 10px;
}
.border{
  border-bottom: 2px solid #eaeaea; 
}
.locationSubHeader{
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;  
}

.locationContent{
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #999999;
  padding:0px 20px;
  margin: 0px;
}
.country-name{
  margin-left: 15px;
}
.modalContent{
  line-height: 25px;
}
.closeIcon{
  position: absolute;
  right: 10px;
  top: 2px;
  cursor: pointer;
  width: 18px;
}
.postalCode{
  padding: 2px;
}
.locality{
  color: #101010;
}
.userDetailsButtonGroup{
  margin-top: 20px;
  display: flex;
  gap: 20px;
  button{
    height: 40px;
    width: 140px;
    white-space: nowrap;
  }
  .callButton{
    box-shadow : unset;
    background: rgba(122, 190, 68, 1);
    width: 140px;
    text-transform: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 4px;
  }
  span {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  span svg{
    width: 28px;
    height: 28px;
  }
  .icon{
    margin: 0;
  }

}
@media (max-width:992px) {
  .userDetailsButtonGroup{
    justify-content: center;
  }
}
@media (max-width:768px) {
  padding:0 !important;
.userDetailLink{
  min-width: 55%;
}
.userInfoContainer{
  padding:10px !important;
  border:none;
  background:#fbfbfb;
}  
.profileBadge{
  left:10px !important;
  top:-20px !important;
}
.line,.star{
  margin-left:8px !important;
} 
 
max-width  : 100%;
 
.link, .linkText {
  width:inherit !important;
}

 
}
@media screen and (max-width:420px) {
  .name {    max-width: 185px ;}
}
`