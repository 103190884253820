import { Button, Drawer } from '@mui/material';
import { SvgEpMenu } from 'app/components/svgicons/svgNew';
import { nameTemplate } from 'config/chatConfig';
import { CountriesDrop1, getSubsidiaryStored } from 'config/variables';
import React, { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCloseNavbar, getOpenNavbar } from 'store/actions/component-actions';
import NavigationBarSmallDevices from './NavigationBarComponent/NavigationBarSmallDevices';

const MobileMenu = () => {

    const dispatch = useDispatch();
    const subsidiary = getSubsidiaryStored()
    const location = useLocation();
    const [anchorElMenu, setAnchorElMenu] = useState<HTMLButtonElement | null>(null);
    const [countryFlag, _countryFlag] = useState<string>('');
    const [userName, _userName] = useState<string>("")

    const { navBarMenu } = useSelector((state: RootStateOrAny) => state.componentReducers)
    const { subsidiaryDetails, logoUrl, subsidiaryName } = useSelector((state: RootStateOrAny) => state.company);
    const { profileUpdatedData, updatedImage, profileUpdated, loggedIn } = useSelector((state: any) => state.auth)
    const { userData } = useSelector((state: any) => state.user)

    useEffect(() => {
        const name = nameTemplate(subsidiaryDetails, subsidiary, subsidiaryName, profileUpdatedData, userData)
        _userName(name)
    }, [subsidiaryDetails, subsidiary, userData, logoUrl, subsidiaryName, updatedImage, profileUpdated, profileUpdatedData])

    useEffect(() => {
        handleCloseMenu()
    }, [location.pathname])

    useEffect(() => {
        let isMounted = true;               // note mutable flag

        if (isMounted) {
            if (loggedIn) {
                if (subsidiary && subsidiary !== null && subsidiaryDetails?.address_principal?.country_id) {
                    getCountry(subsidiaryDetails.address_principal.country_id)
                } else {
                    if (!!userData.addresses && userData.addresses.length) {
                        getCountry(userData.addresses[0].country_id)
                    }
                }
            }
        }
        return () => { isMounted = false }; // cleanup toggles value, if unmounted
    }, [subsidiaryDetails, userData])


    const getCountry = (id: number) => {
        CountriesDrop1().then(data => {
            data.map((country, i) => {
                if (country.id == id) {
                    _countryFlag(country?.flag_thumbnail)
                }
            });
        })
    }

    const handleCloseMenu = () => {
        setAnchorElMenu(null);
        dispatch(getCloseNavbar())
    };

    const id = anchorElMenu ? 'simple-popover ' : undefined;


    return (
        <>
            <Button className='menuIcon' onClick={() => dispatch(getOpenNavbar())}>
                <SvgEpMenu />
            </Button>
            <Drawer
                id={id}
                anchor='right'
                open={navBarMenu}
                onClose={() => handleCloseMenu()}
                className='menuPopover'
                sx={{
                    '.MuiPaper-elevation': {
                        top: '0 !important',
                        left: '0 !important',
                        borderRadius: '0px',
                        maxWidth: '100% !important',
                        maxHeight: '100% !important'
                    }
                }}
            >
                <NavigationBarSmallDevices handleCloseMenu={handleCloseMenu} userName={userName} countryFlag={countryFlag} />
            </Drawer>
        </>
    )
}

export default MobileMenu