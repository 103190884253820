import { Box, Skeleton } from '@mui/material'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import styled from 'styled-components'

const AdSkeleton = () => {

    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    return (
        <Wrapper>
            <Box className={(isSmallDevice || isMediumDevice ? 'flexColumn' : 'flexRow') + ' adContainer'}>
                <Box className='imageSkeleton'>
                    <Skeleton width={225} height={167} variant="rectangular" />
                </Box>
                <Box className='flexColumn justifyContentBetween contentContainer' sx={{ px: (isSmallDevice || isMediumDevice) ? 0 : 2, mt: 0.5 }}>
                    <Box>
                        <Skeleton variant="rectangular" className='heading' height={20} />
                        <Box className='flexRow details' sx={{ my: 2 }}>
                            {[1, 2, 3, 4].map((item) => {
                                return <Box sx={{ mr: 2 }} className='flexColumn alignItemsCenter' key={item}>
                                    <Skeleton variant="rectangular" className='detailItems' width={60} height={10} />
                                </Box>
                            })}
                        </Box>
                    </Box>
                    <Skeleton variant="rectangular" height={10} width={100} />
                    <Box className="flexRow userDetails" sx={{ marginTop: '5px', paddingTop: 2 }}>
                        <Skeleton variant="rectangular" width='50px' height='50px' />
                        <Box className='flexColumn' sx={{ ml: 1 }}>
                            <Skeleton variant="rectangular" className='text1' width='150px' height='15px' />
                            <Skeleton variant="rectangular" width='50px' height='11px' />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Wrapper>
    )
}

export default AdSkeleton

const Wrapper = styled(Box)`
.adContainer{
    align-items:start;
    margin-bottom: 5px
}
.dealerData{
    height:90px;
    width:350px;
}
.userDetails{
    width:350px;
}
.heading{
    width:350px;
    margin-top:8px;
}
.text1{
    margin-bottom:8px;
}
.details{
    width:100%;
    max-width:350px;
    margin-top : 16px;
    .MuiSkeleton-rectangular{
        width:50px;
    }
}
@media(max-width: 1200px){
    .heading{
        width:315px!important;
        margin-bottom:8px;
        margin-top:8px;
     }
}
@media(min-width: 992px){
    .heading{
        width:315px!important;
        margin-bottom:8px;
        margin-top:0px;
     }
}
@media (max-width: 768px) {
    /* .imageSkeleton {width: 100%} */
}
@media(max-width: 992px){
    .imageSkeleton{
        margin: 0 auto;
    }
}
@media(max-width: 550px){
    .adContainer{
        padding-left:0px !important;
    }
    .imageSkeleton{
        padding:0px 12px !important;
        margin: 0 auto;
        .MuiSkeleton-root{
            width:280px !important;
        }
    }
    .text1{
        margin-bottom:8px !important;
    }
    .contentContainer {
        margin-top:8px !important;
    }
    .heading{
       width:280px !important;
       margin-bottom:8px !important;
       margin-top:8px !important;
    }
    .details{
        width:100%;
        max-width:280px !important;
        .MuiSkeleton-rectangular{
            width:40px !important;
        }
    }
    .userDetails{
        width:280px;
    }
}
`