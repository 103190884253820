import { Box, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import AvatarandDetails from '../ChildComponents/AvatarandDetails';
import ChatMessage from '../ChildComponents/ChatMessage';
import ChooseAttachment from '../ChildComponents/ChooseAttachment';
import { ChatWrapper } from '../../style/chatStyle';
import { ChatMessagesInterface, ChatScreenType, chatDataInterface } from '../../interface/interface';
import noChatImage from '../Assets/NoChat.png'
import { translations } from 'locales/translations';
import ArchiveAndBlockedText from '../ChildComponents/ArchiveAndBlockedText';
import { SvgSend } from 'app/components/svgicons/svg2';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import * as ChatConstants from "config/chatConfig"
import { addMemberSuccess, updateGroupDetailFailure, updateGroupDetailSuccess, getArchivedChatSuccess, getChatSuccess, getNewMessageNotification, particularChatRequest, particularChatSuccess, sendMessageFailure, sendMessageRequest, sendMessageSuccess, unreadParticularChatRequest, addMemberFailure, getsubsidiaryActiveMemberListRequest } from 'store/actions/chat-action';
import { Toaster } from 'services/Toaster';
import { SvgSetting } from 'app/components/svgicons/svg';
import ChatOptions from '../ChildComponents/ChatOptions';
import moment from 'moment';
import { PrivatePusherClient } from 'websocket';
import { getSubsidiaryStored } from 'config/variables';
import { useWindowSize } from 'app/Hooks/windowResize';
import { localstorageKey } from 'config/constants';
import CreateNewGroupChat from '../ChildComponents/CreateNewGroupChat';
import AttachmentPreview from '../ChildComponents/AttachmentPreview';
import { v4 as uuidv4 } from 'uuid';

const ChatMessageScreen = ({ setShowInitiateGroupChatModal, showInitiateGroupChatModal, popup, setChatId, chatId, chatType }: ChatScreenType) => {

  const { chatList, chatListAllData, archivedChatList, archivedChatListAllData, blockedChatList, particularChatMessages, loadingMessages, loadingChat, sendMessageSuccessData, sendMessageFailureData, unreadAllChat, addMemberList, removeMemberList, removeMemberListFailure, addMemberListFailure, lastMessages, subsidiaryMemberList } = useSelector((state: RootStateOrAny) => state.chat)

  const user = useSelector((state: RootStateOrAny) => state.user.userData)

  const [userData, setUserData] = useState<Object>({})
  //State for displaying chat options 
  const [showOptions, _showOptions] = useState<boolean>(false)
  const [text, setText] = useState<String>('')
  const [messages, setMessages] = useState<Array<ChatMessagesInterface>>([])
  const [attachment, setAttachment] = useState<Array<string>>([])
  const [attachmentType, setAttachmentType] = useState('')
  const [showChatOptions, _showChatOptions] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [unread, setUnread] = useState<boolean>(false)
  const [chatData, setChatdata] = useState<chatDataInterface>({})
  const [isGroup, setIsGroup] = useState<boolean>(false)
  const [groupMembers, setGroupMembers] = useState<Array<Object>>()
  const [groupTitle, setGroupTitle] = useState<string>('')
  const [imagePreviews, setImagePreviews] = useState<Array<string>>([]);
  const [messageRecievedViaSocket, _messageRecievedViaSocket] = useState<boolean>(false)

  // React Hooks
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const scrollRef: any = useRef(null)
  const [mobile] = useWindowSize();
  const sub = getSubsidiaryStored()

  useEffect(() => {
    if (mobile && messages.length > 0) {
      return
    }
    if (lastMessages.length > 0) {
      setMessages(lastMessages)
    }
  }, [lastMessages])

  // Getting values for current active Chat
  useEffect(() => {
    if (chatList && chatList.length > 0 && chatType === ChatConstants.chatType.ALL_MESSAGES) {
      setChatdata(chatList.find((o) => o.id === chatId))
    }
    else if (blockedChatList && blockedChatList.length > 0 && chatType === ChatConstants.chatType.BLOCKED) {
      setChatdata(blockedChatList.find((o) => o.id === chatId))
    } else if (archivedChatList && archivedChatList.length > 0 && chatType === ChatConstants.chatType.ARCHIVED) {
      setChatdata(archivedChatList.find((o) => o.id === chatId))
    }
  }, [chatList, archivedChatList, blockedChatList, chatId, chatType])

  useEffect(() => {
    if (chatData && Object.keys(chatData).length > 0) {
      if (chatData.is_old_chat && chatData.messages && chatData.messages.length == 1) {
        setPage(page + 1)
        dispatch(particularChatRequest({ id: chatId, page: page + 1 }))
      }
    }
  }, [chatData])


  useEffect(() => {
    if (removeMemberList && Object.keys(removeMemberList).length > 0 && removeMemberList.success) {
      // after getting response of save group setting API updating chats data
      let chatListObject = { ...chatListAllData }
      let chatListArray = [...chatList]
      // chat type is archieved then
      if (chatType === ChatConstants.chatType.ARCHIVED) {
        chatListObject = { ...archivedChatListAllData }
        chatListArray = [...archivedChatList]
      }
      // checking if chat exist or not
      let index = chatListArray.findIndex((o) => o.id === chatId)
      if (index !== -1) {
        // if exist then updating title
        chatListArray[index]['title'] = groupTitle
      }
      // sorting chats on base of last_chat_at whenever any changes has made in chatlist
      chatListObject.data.data = chatListArray.sort(function (a, b) { return new Date(b.last_chat_at).getTime() - new Date(a.last_chat_at).getTime() })
      if (chatType === ChatConstants.chatType.ALL_MESSAGES) {
        dispatch(getChatSuccess({ data: chatListObject, append: false }))
      }
      if (chatType === ChatConstants.chatType.ARCHIVED) {
        dispatch(getArchivedChatSuccess({ data: chatListObject, append: false }))
      }
      Toaster.success(removeMemberList.data.message)
      // update redux after display success message
      dispatch(updateGroupDetailSuccess({}))
    }
  }, [removeMemberList])


  useEffect(() => {
    if (addMemberList && Object.keys(addMemberList).length > 0 && addMemberList.success) {
      Toaster.success(addMemberList.message)
      dispatch(addMemberSuccess({}))
    }
  }, [addMemberList])

  // Requesting for Read chat API call  for currently opened chat when switching to any other chat
  useEffect(() => {
    return () => {
      if (chatId && messageRecievedViaSocket) {
        let data = {
          id: chatId,
          is_read: ChatConstants.readStatus.read
        }
        dispatch(unreadParticularChatRequest(data))
      }
    }

  }, [messageRecievedViaSocket])

  useEffect(() => {
    if (removeMemberListFailure && Object.keys(removeMemberListFailure).length > 0 && removeMemberListFailure.data) {
      if (removeMemberListFailure.data.data) {
        Toaster.error(Object.values(removeMemberListFailure.data.data).toString())
        dispatch(updateGroupDetailFailure({}))
        return
      }
      Toaster.error(removeMemberListFailure.data.message)
      dispatch(updateGroupDetailFailure({}))
    }
  }, [removeMemberListFailure])

  useEffect(() => {
    if (addMemberListFailure && Object.keys(addMemberListFailure).length > 0 && addMemberListFailure.data) {
      if (addMemberListFailure.data.data) {
        Toaster.error(Object.values(addMemberListFailure.data.data).toString())
        dispatch(addMemberFailure({}))
        return
      }
      Toaster.error(addMemberListFailure.data.message)
      dispatch(addMemberFailure({}))
    }
  }, [addMemberListFailure])

  const handleScroll = (e) => {
    if (!loadingMessages) {
      const bottom = e.target.scrollHeight + e.target.scrollTop - 10 <= e.target.clientHeight;
      if (bottom && page == 0) {
        setPage(page + 1)
        dispatch(particularChatRequest({ id: chatId, page: page + 1 }))
        return
      }
      if (bottom && Object.keys(particularChatMessages).length > 0 && particularChatMessages.data.current_page < particularChatMessages.data.last_page) {
        setPage(page + 1)
        dispatch(particularChatRequest({ id: chatId, page: page + 1 }))
      }
    }
  }

  useEffect(() => {
    // Function that is called on page refresh for removing chatID from local storage
    const unloadCallback = () => {
      if (messages.length === 0) {
        localStorage.removeItem("chat_id")
      }
    };
    document.body.classList.add('noBodyScroll');
    document.getElementsByTagName('html')[0].classList.add('noHtmlScroll')
    dispatch(getNewMessageNotification(false))

    // removing chat ID from local storage in case page is refreshed and there are no messages in chat
    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      localStorage.removeItem('chat_id')
      window.removeEventListener("beforeunload", unloadCallback);
      dispatch(particularChatSuccess({}))
      document.body.classList.remove('noBodyScroll');
      document.getElementsByTagName('html')[0].classList.remove('noHtmlScroll')
      // setChatId(null)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(localstorageKey.screen, 'detailScreen');
    return () => {
      localStorage.removeItem("chat_id")
    }
  }, [])

  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    if (isMounted) {
      if (sendMessageSuccessData && Object.keys(sendMessageSuccessData).length > 0) {
        if (chatId === sendMessageSuccessData.data.item.chat_id) {
          // updating current messages data with latest api response data 
          let oldmessages = [...messages]
          let existence = oldmessages.findIndex(o => o.refId == sendMessageSuccessData.data.refId)
          if (existence !== -1) {
            oldmessages[existence] = sendMessageSuccessData.data.item
            setMessages(oldmessages)
            updateMessages(sendMessageSuccessData.data.item)
          }
        }
        dispatch(sendMessageSuccess({}))
      }
      return
    }
    isMounted.current = true;
  }, [sendMessageSuccessData])

  // Resets chat if block/unblock event recieved if chat is opened in all messages list
  useEffect(() => {
    if (blockedChatList && blockedChatList.length > 0 && chatType === ChatConstants.chatType.ALL_MESSAGES) {
      let index = blockedChatList.findIndex((o) => o.id === chatId)
      if (index !== -1) {
        setChatId(null)
      }
    }
  }, [blockedChatList])

  // Resets chat if block/unblock event recieved if chat is opened in blocked list
  useEffect(() => {
    if (chatList && chatList.length > 0 && chatType === ChatConstants.chatType.BLOCKED) {
      let index = chatList.findIndex((o) => o.id === chatId)
      if (index !== -1) {
        setChatId(null)
      }
    }
  }, [chatList])

  useEffect(() => {
    if (unreadAllChat && Object.keys(unreadAllChat).length > 0) {
      if (unreadAllChat.data.is_read === ChatConstants.readStatus.unRead) {
        setUnread(true)
      }
    }
  }, [unreadAllChat])


  useEffect(() => {
    if (messages.length > 0) {
      _showChatOptions(true)
    }
  }, [messages])

  useEffect(() => {
    if (particularChatMessages && Object.keys(particularChatMessages).length > 0) {
      if (particularChatMessages.data.current_page === 1) {
        setMessages([...particularChatMessages.data.data])
      } else {
        let data = []
        for (let i = 0; i < particularChatMessages.data.data.length; i++) {
          data.push(particularChatMessages.data.data[i])
        }
        setMessages([...messages, ...data])
      }
    }
  }, [particularChatMessages])

  // Getting values for current active Chat
  useEffect(() => {
    if (chatId) {
      let data = {}
      if (chatList && chatList.length > 0 && chatType === ChatConstants.chatType.ALL_MESSAGES) {
        data = chatList.find((o) => o.id === chatId)
      }
      else if (blockedChatList && blockedChatList.length > 0 && chatType === ChatConstants.chatType.BLOCKED) {
        data = blockedChatList.find((o) => o.id === chatId)
      } else if (archivedChatList && archivedChatList.length > 0 && chatType === ChatConstants.chatType.ARCHIVED) {
        data = archivedChatList.find((o) => o.id === chatId)
      }
      if (data && Object.keys(data).length > 0) {
        setUserData(data['chat_user_info'])
        if (data['type'] === ChatConstants.chat_type.GROUPCHAT) {
          if (data['is_admin'])
            setIsGroup(true)
          setGroupMembers(data['users'])
          if (data['title'] === null) {
            // setGroupTitle(data['chat_user_info'].name)
          } else {
            setGroupTitle(data['title'])
          }
        }
      }
    }
  }, [chatId, chatList, blockedChatList, archivedChatList])


  useEffect(() => {
    if (!unread && userData && Object.keys(userData).length > 0) {
      handleRead()
    }
  }, [userData])

  const handleText = (e) => {
    if (e.target.value.replace(/\n/g, '  ').length > 2500) {
      Toaster.error(t(translations.CHAT.MESSAGE_LENGTH_CANT_EXCEED_2500_CHARACTERS))
      return;
    }
    setText(e.target.value)
  }

  const [online, setOnline] = useState<boolean>(true)

  window.addEventListener('online', handleOnline);
  window.addEventListener('offline', handleOffline);

  function handleOnline() {
    // Internet connection is available after some delay when reconnected
    setTimeout(() => {
      setOnline(true)
    }, 6000)
  }

  function handleOffline() {
    // Internet connection is available, 
    setOnline(false)
  }


  const sendMessage = (messageType, time) => {
    // If user is not connected to internet, then not allowing to send messages
    if (!online) {
      Toaster.error(t(translations.CHAT.NO_INTERNET_CONNECTION))
      return;
    }
    const refId = uuidv4();
    // Sending message if user is connected to internet        
    // Code for sending data
    let data = {
      chat_id: chatId,
      type: messageType === ChatConstants.attachmentType.FILE_OR_IMAGE ? ChatConstants.attachmentType.FILE_OR_IMAGE : ChatConstants.attachmentType.TEXT,
      message: text,
      attachment: attachment,
      refId: refId
    }
    dispatch(sendMessageRequest(data))
    document.getElementById('standard-basic')?.focus()
    let addMessage = {
      user_id: user.id,
      created_at: time,
      media: [],
      refId: refId
    }
    if (attachment.length) {
      attachment.map((attachment, index) => {
        if (attachmentType === 'image') {
          addMessage['media'].push({
            mime_type: "image/jpg",
            original_url: attachment,
            medium_url: attachment
          })
        } else {
          addMessage['media'].push({
            mime_type: "attachment/file",
            file_name: attachment.name
          })
        }
      })

    }
    if (text !== '') {
      addMessage['message'] = text.trim()
    }
    let appendNewMessage = [addMessage, ...messages]
    let chatListObject = { ...chatListAllData }
    let chatListArray = [...chatList]
    let index = chatListArray.findIndex((o) => o.id === chatId)
    if (index !== -1) {
      chatListArray[index]['last_message'] = {
        chat_id: chatId,
        media: data['media'],
        message: text ? text : `${t(translations.CHAT.FILE_SENT)}`
      }
      chatListArray[index]['last_chat_at'] = new Date()
      chatListArray[index]['is_read'] = ChatConstants.readStatus.read
    }
    chatListArray.sort(function (a, b) { return new Date(b.last_chat_at).getTime() - new Date(a.last_chat_at).getTime() })
    if (chatListObject?.data?.data) {
      chatListObject.data.data = chatListArray
      dispatch(getChatSuccess({ data: chatListObject, append: false }))
      setMessages(appendNewMessage)
    }
    // Removes scroll position if chat is opened in mobile/popup
    if (localStorage.getItem('chatScrollPosition')) {
      localStorage.removeItem('chatScrollPosition')
    }
    setText('')
    setAttachment([])
    setAttachmentType('')
    setTimeout(() => {
      let elem = document.getElementById('scrollmsgDiv')
      if (elem && !ChatConstants.elementIsVisibleInViewport(elem)) {
        elem.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        divScroller()
      }
    }, 200)
  }

  const divScroller = () => {
    setTimeout(() => {
      let elem = document.getElementById('textField')
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
      }
    }, 1500)
  }

  const handleRead = () => {
    // Sending read as true for is_read as marking it chat status read when opening it. 
    let read = ChatConstants.readStatus.read
    if (chatList && Object.keys(chatList).length > 0 && chatType === ChatConstants.chatType.ALL_MESSAGES) {
      read = chatList.find((o) => o.id === chatId)?.is_read
    }
    else if (blockedChatList && Object.keys(blockedChatList).length > 0 && chatType === ChatConstants.chatType.BLOCKED) {
      read = blockedChatList.find((o) => o.id === chatId)?.is_read
    } else if (archivedChatList && Object.keys(archivedChatList).length > 0 && chatType === ChatConstants.chatType.ARCHIVED) {
      read = archivedChatList.find((o) => o.id === chatId)?.is_read
    }
    if (!read) {
      let data = {
        id: chatId,
        is_read: ChatConstants.readStatus.read
      }
      dispatch(unreadParticularChatRequest(data))
    }
  }

  useEffect(() => {
    if (sendMessageFailureData && Object.keys(sendMessageFailureData).length > 0 && sendMessageFailureData.data) {
      if (sendMessageFailureData.data.data) {
        Toaster.error(Object.values(sendMessageFailureData.data.data).toString())
        dispatch(sendMessageFailure({}))
        return
      }
      Toaster.error(sendMessageFailureData.data.message)
      dispatch(sendMessageFailure({}))
    }
  }, [sendMessageFailureData])

  const [newMessage, _newMessage] = useState<object>({})

  const connectPusher = () => {

    const userId = user.id;

    if (userId === undefined || userId === null || userId <= 0) return;

    const privateChannelName = sub ? `private-inbox.subsidiary.${sub}.user.${userId}` : `private-inbox.user.${userId}`;
    const PrivatePusherClientInstance = PrivatePusherClient();
    const privateChannel = PrivatePusherClientInstance?.subscribe(privateChannelName);

    privateChannel?.bind("pusher:subscription_succeeded", (members) => {
    });

    privateChannel?.bind("pusher:subscription_error", (error) => {
      var { status } = error;
    });

    privateChannel?.bind("new-message", (message) => {
      if (message.event) {
        if (message.event === "receptionistChangeEvent") {
          setChatId(null)
        }
        if (message.event === "blockEvent" || message.event === "unblockEvent") {
          if (chatId && chatId == localStorage.getItem('chat_id')) {
            setMessages([])
            dispatch(particularChatRequest({ id: chatId, page: 1 }))
          }
        }
        return
      }
      const recievedMsg = JSON.parse(JSON.stringify(message.body));
      if (recievedMsg === null && recievedMsg === undefined) return;
      _newMessage(recievedMsg)
    });
  }
  useEffect(() => {
    connectPusher()
  }, [sub])

  useEffect(() => {
    if (newMessage && Object.keys(newMessage).length > 0) {
      if (newMessage['chat_id']) {
        if (newMessage['chat_id'] == chatId) {
          _messageRecievedViaSocket(true)
          // only unique message append on chat screen when getting new message from socket
          setMessages([newMessage, ...messages])
        }
      }
      _newMessage({})
    }
  }, [newMessage])

  const getTime = (time) => {
    if (!moment.isMoment(time)) {
      time = moment(time);
    }
    if (time.isSame(moment(), 'day')) {// if chat of same day then display today
      return t(translations.CHAT.TODAY);
    } else if (time.isSame(moment().subtract(1, 'd'), 'day')) {// if chat from last day (yesterday)
      return t(translations.CHAT.YESTERDAY);
    } else if (time.isSame(moment().subtract(7, 'days'), 'week')) { // if chat last week then display (week day name)
      return time.format('dddd');
    } else {
      return time.format('DD/MM/YYYY');  // if chat is 1 week or more days ago then display date
    }

  }


  // Function for updating prefetched messages in all message chat list/ archived chat list
  const updateMessages = (data) => {
    let chatListObject = chatListAllData
    let chatListArray = chatList
    if (chatType === ChatConstants.chatType.ARCHIVED) {
      chatListObject = archivedChatListAllData
      chatListArray = archivedChatList
    }
    let index = chatListArray.findIndex((o) => o.id === chatId)
    if (index != -1) {
      chatListArray[index].messages = [data, ...chatListArray[index].messages]
    }
    if (chatListObject?.data?.data) {
      chatListObject.data.data = chatListArray
      if (chatType === ChatConstants.chatType.ARCHIVED) {
        dispatch(getArchivedChatSuccess({ data: chatListObject, append: false }))
        return
      }
      dispatch(getChatSuccess({ data: chatListObject, append: false }))
    }
  }


  const openGroupSettingsModal = () => {
    setShowInitiateGroupChatModal(true)
    if (!subsidiaryMemberList || Object.keys(subsidiaryMemberList).length === 0) {
      dispatch(getsubsidiaryActiveMemberListRequest({ subsidiary_id: sub }))
    }
  }


  return (
    <>
      <ChatWrapper className='h-100'>
        {/* Renders when chats are being loaded  */}
        {loadingChat && <Box className='noChatMessageContainer'>
          <span>{t(translations.CHAT.LOADING_PLEASE_WAIT)}...</span>
        </Box>}
        {/* Renders when chats are loaded but no chat is selected  */}
        {!loadingChat && !chatId && <Box className='noChatMessageContainer'>
          <span>{t(translations.CHAT.START_NEW_CONVERSATION)}</span>
        </Box>
        }
        {/* Renders when chats are loaded and on of the chat is selected  */}
        {!loadingChat && chatId && <Box className='chatMessageMainContainer'>
          <Box className="chatMessageHeader">
            <AvatarandDetails groupTitle={chatData?.title} setMessages={setMessages} setPage={setPage} userInfo={userData} activeText={t(translations.CHAT.ACTIVE_NOW)} hideStatus={true} hideFlag={true} chatTime={""} popup={popup} setChatId={setChatId} chatId={chatId} hidebackButton={false} chatData={chatData} />
            {showChatOptions &&
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {/* isGroup */}
                {(sub && isGroup && chatType !== ChatConstants.chatType.BLOCKED) && <Box className={'pointer'} onClick={openGroupSettingsModal}> <SvgSetting style={{ marginRight: "10px" }} /></Box>}
                <ChatOptions chatType={chatType} chatId={chatId} showOptions={showOptions} _showOptions={_showOptions} setUnread={setUnread} />
              </Box>
            }
          </Box>
          <Box className='textAndInputContainer'>
            <Box className={(popup ? 'textBoxContainerPopup' : '') + ' textBoxContainer scrollBar ' + (messages.length !== 0 ? '' : ' noMessageContainer') + (navigator.platform === 'iPhone' ? "iosMessageContainer" : "")} onScroll={(e) => handleScroll(e)} ref={scrollRef}>

              {/* Renders when messages are loaded but has no messages */}
              {!loadingMessages && messages.length === 0 &&
                <Box
                  className='noChat'
                  sx={{
                    '.no-msg-image': {
                      display: "block",
                      width: '40%',
                      margin: '0 auto',
                    },
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)'
                  }}
                >
                  <img className='no-msg-image' src={noChatImage} alt={"no-msg"} />
                  <Box className='flexColumn noMessageText'>
                    <span>{t(translations.CHAT.NO_MSG_YET)}</span>
                    <span>{t(translations.CHAT.START_TYPING)}</span>
                  </Box>
                </Box>}

              {/* Renders when messages are loaded and has messages /}*/}
              {messages.length > 0 && <Box id='scrollmsgDiv' className='scrollmsgDiv'></Box>}

              {messages.length > 0 && messages.map((item, index) => {
                let date = new Date(item.created_at).toDateString()
                let nextDate = ''
                if (messages.length > 1) {
                  nextDate = new Date(messages[index + 1]?.created_at).toDateString()
                }
                let self = true
                // If message is sent by subsidiary the checking current subsidiary ID and message's sub ID are same or not, if same then it is message by self 
                if (item.chat_user_info && item.chat_user_info.subsidiary_id) {
                  self = item.chat_user_info.subsidiary_id != sub ? false : true;
                  if (item.chat_user_info.subsidiary_id == sub && item.chat_user_info.id != user.id) {
                    self = false
                  }
                }
                else if (sub && item.chat_user_info && item.chat_user_info.subsidiary_id === null && item.chat_user_info.id == user.id) {
                  self = false
                }
                // If message is sent by user the checking current user ID and message's user ID are same or not, if same then it is message by self 
                else if (item.chat_user_info && item.chat_user_info.subsidiary_id == null && item.chat_user_info.id) {
                  self = (item.chat_user_info && item.chat_user_info.id !== user.id) ? false : true;
                }
                return <Box key={"message" + (item.id ?? item.refId)} id={'message' + index}>
                  {
                    <Box className='msg-time-container' >
                      <Box className={nextDate === '' || nextDate !== date ? "msg-time-border" : ""}></Box>
                      <span
                        className={"msg-time"}>
                        {(nextDate === '' || nextDate !== date) ? getTime(date) : ""}</span>
                    </Box>
                  }
                  <ChatMessage messages={messages} self={self} message={item.message} userData={item.chat_user_info} time={item.created_at} mediaFiles={item.media} recID={item.id} currentMessage={item} chatId={chatId} refId={item.refId} />
                </Box>
              })
              }

              {/* Renders when messages are being loaded */}
              {loadingMessages && <Box sx={{ color: "#666666", fontSize: "12px" }} className='flexRow justifyContentCenter'>
                <span>{t(translations.CHAT.UPDATE_CONVERSATION)}... </span>
              </Box>}
            </Box>

            <Box className='inputAndAttachmentBox'>
              {/* Preview image/files when any of the file has been attached   */}
              {
                (attachment instanceof File || attachment.length > 0) &&
                <Box className='attachmentPreview scrollBar' id='fileList' sx={{
                  maxHeight: popup || mobile ? '120px' : "235px",
                  minHeight: popup || mobile ? '120px' : "235px",
                }}>
                  {/* If attachment type is file */}
                  {(attachment !== null) &&
                    <>
                      {attachment.map((attachmentFile, i) => {
                        return <>
                          <AttachmentPreview attachmentFile={attachmentFile} attachment={attachment} setAttachment={setAttachment} index={i} />
                        </>
                      })}
                      {attachment.length < 5 && <ChooseAttachment multipleFile={true} setImagePreviews={setImagePreviews} attachment={attachment} setAttachment={setAttachment} attachmentType={attachmentType} setAttachmentType={setAttachmentType} setText={setText} />}
                    </>
                  }
                </Box>
              }

              <Box className={'inputContainer ' + `${chatType === ChatConstants.chatType.ARCHIVED || (chatData && chatData.is_archived) ? 'archived-border' : chatType === ChatConstants.chatType.BLOCKED || chatData && chatData.chat_user_info && chatData.chat_user_info.id === null ? 'blocked-border' : ""}`}>
                {/* Displaying text that message can't be sent as this user is deleted */}
                {chatData && chatData.chat_user_info && chatData.chat_user_info.id === null &&
                  <Box className='accessMessage'>
                    <p> {t(translations.CHAT.USER_DELETED_MESSAGE)} </p>
                  </Box>
                }

                {/* Displaying warning message when user is not deleted and has blocked or achived the chat that message can't be sent */}
                {chatData && chatData.chat_user_info && chatData.chat_user_info.id && (chatType !== ChatConstants.chatType.ALL_MESSAGES || (chatData && chatData.is_archived)) ? <ArchiveAndBlockedText chatType={chatType} chatId={chatId} setChatId={setChatId} popup={popup} /> : null}

                {/* Displaying text input field  when user is not deleted and is in all message list */}
                {chatData && chatData.chat_user_info && chatData.chat_user_info.id && !chatData.is_archived && chatType === ChatConstants.chatType.ALL_MESSAGES ? <>

                  {/* Fields to attach files ar send texts  */}
                  {!attachment.length && <ChooseAttachment imagePreviews={imagePreviews} setImagePreviews={setImagePreviews} attachment={attachment} setAttachment={setAttachment} attachmentType={attachmentType} setAttachmentType={setAttachmentType} setText={setText} />}
                  <TextField id="standard-basic" variant="standard" fullWidth
                    className='inputTextContainer scrollBar'
                    autoFocus
                    multiline
                    placeholder={t(translations.CHAT.TYPE_SOMETHING) + "...."}
                    InputProps={{ disableUnderline: true }}
                    value={text}
                    onChange={(e) => handleText(e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey && text.trim() !== "" && !mobile) {
                        e.preventDefault()
                        sendMessage(attachment.length ? ChatConstants.attachmentType.FILE_OR_IMAGE : ChatConstants.attachmentType.TEXT, new Date().toISOString())
                      }
                    }}
                  />
                  {text.trim() !== "" || attachment.length ? <SvgSend className="pointer" style={{ margin: '0 10px' }} onClick={() => sendMessage(attachment.length > 0 ? ChatConstants.attachmentType.FILE_OR_IMAGE : ChatConstants.attachmentType.TEXT, new Date().toISOString())} /> : ""}
                </> : null
                }
                <Box id='textField' className='scrollerDiv'></Box>
              </Box>

            </Box>


          </Box>
        </Box>}
        <CreateNewGroupChat chatType={chatType} chatId={chatId} setGroupTitle={setGroupTitle} groupTitle={groupTitle} groupMembers={groupMembers} showInitiateChatModal={showInitiateGroupChatModal} setShowInitiateChatModal={setShowInitiateGroupChatModal} subId={userData?.subsidiary_id} chatData={chatData} />
      </ChatWrapper>
    </>
  )
}
export default ChatMessageScreen;