import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, Stack } from '@mui/system'
import { Avatar, Backdrop, CircularProgress, Typography } from '@mui/material'
import { SvgCloseIcon } from '../svgicons/svgNew'
import { colorNew } from 'styles/StyleConstants'
import { langugageSwitchReset } from 'store/actions/component-actions'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { translations } from 'locales/translations';
import { localstorageKey } from 'config/constants';
import { changeLanguageRequest, getActiveLanguageRequest } from 'store/actions/auth-actions';
import { setCookie } from 'config/variables'
const MobileLanguages = (props: any) => {
  const { t, i18n } = useTranslation();
  const { languageData, languageChangedData, loading, loggedIn } = useSelector((state: RootStateOrAny) => state.auth)
  const [currentLan, _currentLan] = useState<string>('fr');
  const [selectedLanguageId, _selectedLanguageId] = React.useState<number>(1);
  const dispatch = useDispatch();
  const location = useLocation();
  const isMounted = useRef(false)
  // PusherClient.subscribe('private-user_language_change231')
  // const channelAc = PusherClient.channel('private-user_language_change231');
  // const listenMessages = async () => {
  //  await channelAc.bind('UserLanguageChangeEvent', (data: any) => {});
  // }
  // useEffect(() => {
  //   if (Object.keys(languageData).length < 1) {
  //     dispatch(getActiveLanguageRequest())
  //   }
  // }, [])


  const languagesSwitch = (code) => {
    handleChange(code)
  }
  const handleChange = (code, force = true) => {
    if (currentLan !== code || force) {
      const currentPathname = window.location.pathname.replace(/\/+$/, '');
      const matchUrl = window.location.pathname.includes(`/${currentLan}`);
      let newUrl;
      if (matchUrl) {
        newUrl = window.location.pathname.replace(`/${currentLan}`, `/${code}`);
      } else if (currentPathname) {
        newUrl = window.location.pathname.replace(`/`, `/${code}/`);
      } else {
        newUrl = window.location.pathname.replace(``, `/${code}`);
      }
      if (location.search) {
        newUrl += location.search
      }
      localStorage.setItem(localstorageKey.i18nextLng, code);  // this writes to the localStorage to keep the change
      i18n.changeLanguage(code);
      window.location.replace(newUrl);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      if (languageChangedData) {
        let dispatchedId = []
        if (Object.keys(languageData).length > 0) {
          dispatchedId = languageData.languages.filter(x => x.id === languageChangedData)
        }
        if (dispatchedId.length) {
          handleChange(dispatchedId[0].code)
        }
      }
    }
    isMounted.current = true;
  }, [languageChangedData])

  useEffect(() => {
    const currentLan = i18n.language
    _currentLan(currentLan)
  }, [])

  const checkedProp = (key) => {
    if (key === 'en') {
      if (currentLan === key || currentLan || 'en-GB') {
        return true
      }
    } else if (key === currentLan) {
      return true
    } else {
      return false
    }
  }

  const changeLanguagesByApi = (id, code) => {
    _currentLan(code)
    dispatch(changeLanguageRequest({ language_id: item.id }))
  }

  return (
    <>
      <Box sx={{
        p: 1, minWidth: 300, maxWidth: 300,
        '.switcher': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: '0px 1px 6px 2px rgb(0 0 0 / 4%)',
          borderRadius: '6px',
          padding: '16px',
          columnGap: '30px',
          marginBottom: '16px',
          gridTemplateColumns: '85% 10%',
          '.switcher-inner': {
            pointerEvents: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            'input': {
              pointerEvents: 'none'
            }
          }
        }

      }}>
        <Box className="switch_head"
          sx={{
            pt: 1,
            fontWeight: '500',
            fontSize: '18px',
            lineHeight: '22px',
            textAlign: 'center',
            letterSpacing: '0.02em',
            paddingBottom: '24px',
            '.close-icon': {
              position: 'absolute',
              right: '10px',
              top: '20px',
              'svg': {
                'path': {
                  stroke: colorNew.colorGrayDark,
                  fill: colorNew.colorGrayDark
                }
              }
            },
          }}
        >
          {t(translations.mobileSideBar.SWITCHL)}
          <span className="close-icon pointer"><SvgCloseIcon onClick={() => { dispatch(langugageSwitchReset()) }} /></span>
        </Box>

        {languageData?.languages?.map((item, index) => (
          <Box key={index} onClick={(e) => {
            if (loggedIn) {
              dispatch(changeLanguageRequest({ language_id: item.id }))
            } else {
              languagesSwitch(item.code)
            }
            setCookie('language', item.code, 10000)
          }} className="switcher pointer"
            sx={{
              opacity: loading ? 0.5 : 1
            }}
          >
            <Box className="switcher-inner">
              <Stack sx={{ p: 1 }}>
                <Avatar
                  sx={{ width: 30, height: 30 }}
                  src={item.flag_thumbnail} />
              </Stack>
              <Typography sx={{
                fontWeight: 'bold',
                fontStyle: 'italic',
                fontSize: '14px',
              }}>
                {item.name}
              </Typography>
            </Box>
            <Box>
              <input className="form-check-input" onClick={(e) => {
              }} value={item.code} type="radio" checked={checkedProp(item.code) ? true : false} id="flexRadioDefault2" name="lan" />
            </Box>
          </Box>
        ))}

      </Box>

    </>
  )
}

MobileLanguages.propTypes = {
  title: PropTypes.string
}

export default MobileLanguages