import { put, select, takeEvery } from 'redux-saga/effects';
import {
  getVehicleBrandRequest,
  getVehicleBrandSuccess,
  getVehicleBrandFailure,

  getVehicleYearRequest,
  getVehicleYearSuccess,
  getVehicleYearFailure,

  getVehicleTypeRequest,
  getVehicleTypeSuccess,
  getVehicleTypeFailure,

  getVehicleVersionRequest,
  getVehicleVersionSuccess,
  getVehicleVersionFailure,

  getVehicleModelListRequest,
  getVehicleModelListSuccess,
  getVehicleModelListFailure,

  getPowerListRequest,
  getPowerListSuccess,
  getPowerListFailure,

  getFuelListRequest,
  getFuelListSuccess,
  getFuelListFailure,

  getGearBoxListRequest,
  getGearBoxListSuccess,
  getGearBoxListFailure,

  getVehicleBodyListRequest,
  getVehicleBodyListSuccess,
  getVehicleBodyListFailure,

  getVehicleValidateRequest,
  getVehicleValidateSuccess,
  getVehicleValidateFailure,

  //get vehicle equipment list
  getVehicleEquipmentRequest,
  getVehicleEquipmentSuccess,
  getVehicleEquipmentFailure,

  getVehicleInteriorRequest,
  getVehicleInteriorSuccess,
  getVehicleInteriorFailure,

  getVehicleConditionsRequest,
  getVehicleConditionsSuccess,
  getVehicleConditionsFailure,

  getVehicleSeasonRequest,
  getVehicleSeasonSuccess,
  getVehicleSeasonFailure,

  getVehicleTiersRequest,
  getVehicleTiersSuccess,
  getVehicleTiersFailure,

  getVehicleColorsRequest,
  getVehicleColorsSuccess,
  getVehicleColorsFailure,

  getVehicleWheelsRequest,
  getVehicleWheelsSuccess,
  getVehicleWheelsFailure,

  updateVehicleDataRequest,
  updateVehicleDataSuccess,
  updateVehicleDataFailure,

  getVehicleFrameSizeRequest,
  getVehicleFrameSizeSuccess,
  getVehicleFrameSizeFailure,

  createAddsRequest,
  createAddsSuccess,
  createAddsFailure,

  getPublicAddsRequest,
  getPublicAddsSuccess,
  getPublicAddsFailure,

  getPublicAuctionAddsRequest,
  getPublicAuctionAddsSuccess,
  getPublicAuctionAddsFailure,

  getVehicleMaterialRequest,
  getVehicleMaterialSuccess,
  getVehicleMaterialFailure,

  getVehicleBatteryPositionRequest,
  getVehicleBatteryPositionSuccess,
  getVehicleBatteryPositionFailure,

  getVehicleDriveTypesRequest,
  getVehicleDriveTypesSuccess,
  getVehicleDriveTypesFailure,

  getVehicleSuspensionTypesRequest,
  getVehicleSuspensionTypesSuccess,
  getVehicleSuspensionTypesFailure,

  getVehicleBrakeTypesRequest,
  getVehicleBrakeTypesSuccess,
  getVehicleBrakeTypesFailure,

  getVehicleEnginePositionsRequest,
  getVehicleEnginePositionsSuccess,
  getVehicleEnginePositionsFailure,

  getVehicleEnginesRequest,
  getVehicleEnginesSuccess,
  getVehicleEnginesFailure,

  getVehicleComponentsRequest,
  getVehicleComponentsSuccess,
  getVehicleComponentsFailure,

  getVehicleSexRequest,
  getVehicleSexSuccess,
  getVehicleSexFailure,

  getAuctionRequest,
  getAuctionSuccess,
  getAuctionFailure,

  getAuctionAdsCountRequest,
  getAuctionAdsCountSuccess,
  getAuctionAdsCountFailure,

  getPublicAddsDetailRequest,
  getPublicAddsDetailSuccess,
  getPublicAddsDetailFailure,

  createBidRequest,
  createBidSuccess,
  createBidFailure,

  uploadVehicleImagesRequest,
  uploadVehicleImagesSuccess,
  uploadVehicleImagesFailure,

  bidAcceptRejectRequest,
  bidAcceptRejectSuccess,
  bidAcceptRejectFailure,

  vehicleSoldReserveRequest,
  vehicleSoldReserveSuccess,
  vehicleSoldReserveFailure,

  getVehicleDetailRequest,
  getVehicleDetailSuccess,
  getVehicleDetailFailure,

  editAdRequest,
  editAdSuccess,
  editAdFailure,

  getAllCustomersRequest,
  getAllCustomersSuccess,
  getAllCustomersFailure,

  deleteVehicleRequest,
  deleteVehicleSuccess,
  deleteVehicleFailure,

  deleteClassicAuctionAdRequest,
  deleteClassicAuctionAdSuccess,
  deleteClassicAuctionAdFailure,

  getVehicleTargetAudienceRequest,
  getVehicleTargetAudienceSuccess,
  getVehicleTargetAudienceFailure,

  getAdDetailRequest,
  getAdDetailSuccess,
  getAdDetailFailure,

  cancelReservationRequest,
  cancelReservationSuccess,
  cancelReservationFailure,

  confirmVehiclePurchaseRequest,
  confirmVehiclePurchaseSuccess,
  confirmVehiclePurchaseFailure,

  vehicleExpertiseSaveSuccess,
  vehicleExpertiseSaveFailure,
  vehicleExpertiseUpdateSuccess,

  vehicleExpertiseUpdateFailure,
  vehicleExpertiseFetchSuccess,
  vehicleExpertiseFetchFailure,

  vehicleExpertiseSaveRequest,
  vehicleExpertiseUpdateRequest,
  vehicleExpertiseFetchRequest,

  callSuccess,
  callFailure,
  callRequest,

  getEuroRequest,
  getEuroSuccess,
  getEuroFailure,

  addAdInFavouriteRequest,
  addAdInFavouriteSuccess,
  addAdInFavouriteFailure,

  removeAdFromFavouriteSuccess,
  removeAdFromFavouriteFailure,
  removeAdFromFavouriteRequest,

  getCarFilterSuccess,
  getCarFilterFailure,

  getBikeFilterSuccess,
  getBikeFilterFailure,

  getStepFilterSuccess,
  getStepFilterFailure,
  getBikeFilterRequest,
  getStepFilterRequest,
  getCarFilterRequest,
  getPublicAddsShopSuccess,
  getPublicAddsShopFailure,
  getPublicAddsShopRequest,
  getAuctionHomeSuccess,
  getAuctionHomeFailure,
  getAuctionHomeRequest,
  getHomePublicAddsRequest,
  getHomePublicAddsSuccess,
  getHomePublicAddsFailure,
  rejectVehiclePurchaseRequest,
  rejectVehiclePurchaseSuccess,
  rejectVehiclePurchaseFailure,
  putAdsIntoAuctionRequest,
  putAdsIntoAuctionSuccess,
  putAdsIntoAuctionFailure,

  getHotDealSuccess,
  getHotDealRequest,
  getHotDealFailure,
  putAdsIntoHotDealSuccess,
  putAdsIntoHotDealFailure,
  putAdsIntoHotDealRequest
}
  from '../actions/vehicle-actions';
import { ApiPaths } from 'services/ApiPaths';
import Api from 'services/axios';
import { productTypes } from 'config/variables';
import { adsTypesNum, localstorageKey } from 'config/constants';

export const getUser = (state) => state.auth
const vehiclesStore = (state) => state.vehicle

function* _getEuroRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.euroStandars;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getEuroSuccess(row));
  } catch (e) {
    yield put(getEuroFailure());
  }
}

function* _getVehicleBrandRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.brands;
    uri += "?filter[product_type_id]=" + payload.filter.product_type_id + "&sort=-is_popular";
    const { data } = yield Api.get(uri);
    const row = data.data;
    yield put(getVehicleBrandSuccess(row));
  } catch (e) {
    yield put(getVehicleBrandFailure());
  }
}

function* _getVehicleYearRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.years;
    if (payload.filter.brand_id) {
      uri += "?filter[brand_id]=" + payload.filter.brand_id;
    }
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleYearSuccess(row));
  } catch (e) {
    yield put(getVehicleYearFailure());
  }
}


function* _getVehicleTypeRequest({ payload }) {
  try {
    const uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.types;
    const { data } = yield Api.get(uri, {
    });
    const row = data.data
    localStorage.setItem(localstorageKey.vehicleTypeData, JSON.stringify(row));
    yield put(getVehicleTypeSuccess(row));
  } catch (e) {
    yield put(getVehicleTypeFailure());
  }
}

function* _getVehicleVersionRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.versions;
    uri += "?filter[model_id]=" + payload.filter.model_id + "&filter[body_vehicle_id]=" + payload.filter.body_vehicle_id + "&filter[gearbox_id]=" + payload.filter.gearbox_id + "&list=0&filter[power_cv]=" + payload.filter.power_cv;
    if (payload?.filter?.fuel_id) {
      uri += "&filter[fuel_id]=" + payload.filter.fuel_id
    }
    if (payload?.filter?.country_id) {
      uri += "&filter[country_id]=" + payload.filter.country_id
    }
    if (payload?.filter?.year) {
      uri += "&filter[year]=" + payload.filter.year;
    }
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleVersionSuccess(row));
  } catch (e) {
    yield put(getVehicleVersionFailure());
  }
}

function* _getVehicleModelListRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.model_list + '?';
    // + "?filter[brand_id]="+payload.filter.brand_id;
    let AllFilter = payload.filter
    Object.keys(AllFilter).forEach((element, index) => {
      if (index === 0) {
        uri += `filter[${element}]=${AllFilter[element]}`
      } else {
        uri += `&filter[${element}]=${AllFilter[element]}`
      }
    });
    const { data } = yield Api.get(uri, {
    });
    const row = data.data
    localStorage.setItem('modelList', JSON.stringify(row.items))
    yield put(getVehicleModelListSuccess(row));
  } catch (e) {
    yield put(getVehicleModelListFailure());
  }
}

function* _getPowerListRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.power + '?';
    // uri += "?filter[model_id]="+payload.filter.model_id+"&filter[fuel_id]="+payload.filter.fuel_id;
    // if (payload.filter.year !== ''){
    //   uri += "&filter[year]="+payload.filter.year;
    // }
    let AllFilter = payload.filter
    Object.keys(AllFilter).forEach((element, index) => {
      if (index === 0) {
        uri += `filter[${element}]=${AllFilter[element]}`
      } else {
        uri += `&filter[${element}]=${AllFilter[element]}`
      }
    });
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getPowerListSuccess(row));
  } catch (e) {
    yield put(getPowerListFailure());
  }
}

function* _getFuelListRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.fuels + '/?';
    let AllFilter = payload.filter
    if (AllFilter && AllFilter !== null && Object.keys(AllFilter).length > 0) {
      Object.keys(AllFilter).forEach((element, index) => {
        if (index === 0) {
          uri += `filter[${element}]=${AllFilter[element]}`
        } else {
          uri += `&filter[${element}]=${AllFilter[element]}`
        }
      });
    }
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getFuelListSuccess(row));
  } catch (e) {
    yield put(getFuelListFailure());
  }
}

function* _getGearBoxListRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.gearboxes + '?';
    if ('filter' in payload) {
      let AllFilter = payload.filter
      Object.keys(AllFilter).forEach((element, index) => {
        if (index === 0) {
          uri += `filter[${element}]=${AllFilter[element]}`
        } else {
          uri += `&filter[${element}]=${AllFilter[element]}`
        }
      });
      // uri += "?filter[model_id]="+payload.filter.model_id+"&filter[fuel_id]="+payload.filter.fuel_id+"&filter[power_cv]="+payload.filter.power_cv+"&filter[product_type_id]="+payload.filter.product_type_id;  
      // if (payload.filter.year !== ''){
      //   uri += "&filter[year]="+payload.filter.year;
      // }
    } else {
      uri += "filter[product_type_id]=" + payload.product_type_id;
    }

    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getGearBoxListSuccess(row));
  } catch (e) {
    yield put(getGearBoxListFailure());
  }
}

function* _getVehicleBodyListRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.bodies + '?';
    if ("filter" in payload) {
      let AllFilter = payload.filter
      Object.keys(AllFilter).forEach((element, index) => {
        if (index === 0) {
          uri += `filter[${element}]=${AllFilter[element]}`
        } else {
          uri += `&filter[${element}]=${AllFilter[element]}`
        }
      });
      // uri += "?filter[model_id]="+payload.filter.model_id+"&filter[fuel_id]="+payload.filter.fuel_id+"&filter[power_cv]="+payload.filter.power_cv+"&filter[gearbox_id]="+payload.filter.gearbox_id;
      // if (payload.filter.year !== ''){
      //   uri += "&filter[year]="+payload.filter.year;
      // }
    } else {
      uri += "filter[product_type_id]=" + payload.product_type_id;
    }

    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleBodyListSuccess(row));
  } catch (e) {
    yield put(getVehicleBodyListFailure());
  }
}

function* _getVehicleValidateRequest({ payload }) {

  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.validate;
    if ('versionId' in payload) {
      uri += '/' + payload.versionId;
    }
    let params = payload;
    const { data } = yield Api.post(uri, params);
    const row = data.data
    yield put(getVehicleValidateSuccess(row));
  } catch (e: any) {
    yield put(getVehicleValidateFailure(e.response));
  }
}

/*  Get vehicle equipment list */
function* _getVehicleEquipmentRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.equipments;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleEquipmentSuccess(row));
  } catch (e: any) {
    yield put(getVehicleEquipmentFailure(e.response));
  }
}

/*  Get vehicle interior list */
function* _getVehicleInteriorRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.interiors;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleInteriorSuccess(row));
  } catch (e: any) {
    yield put(getVehicleInteriorFailure(e.response));
  }
}

/*  Get vehicle season list */
function* _getVehicleSeasonRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.tireTypes;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleSeasonSuccess(row));
  } catch (e: any) {
    yield put(getVehicleSeasonFailure(e.response));
  }
}

/*  Get vehicle tiers list */
function* _getVehicleTiersRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.tires + '?';
    if ("product_type_id" in payload) {
      uri += "filter[product_type_id]=" + payload.product_type_id;
    }
    if ("width" in payload) {
      uri += "filter[width]=" + payload.width + '&';
    }
    if ("height" in payload) {
      uri += "filter[height]=" + payload.height + '&';
    }
    if ("diameter" in payload) {
      uri += "filter[diameter]=" + payload.diameter + '&';
    }
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleTiersSuccess(row));
  } catch (e) {
    yield put(getVehicleTiersFailure(e.response));
  }
}

/*  Get vehicle colors list */
function* _getVehicleColorsRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.colors;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleColorsSuccess(row));
  } catch (e) {
    yield put(getVehicleColorsFailure(e.response));
  }
}

/*  Get vehicle wheels list */
function* _getVehicleWheelsRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.drivingWheels;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleWheelsSuccess(row));
  } catch (e) {
    yield put(getVehicleWheelsFailure(e.response));
  }
}

/*  Get vehicle condition list */
function* _getVehicleConditionsRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.vehicleConditions;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleConditionsSuccess(row));
  } catch (e) {
    yield put(getVehicleConditionsFailure(e.response));
  }
}

/*  Get vehicle frame size list */
function* _getVehicleFrameSizeRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.frameSizes;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleFrameSizeSuccess(row));
  } catch (e) {
    yield put(getVehicleFrameSizeFailure(e.response));
  }
}

/* update vehicle data */
function* _updateVehicleDataRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.ownerVehicles + '/' + payload.id + '?_method=patch';
    if ('inside_color_id' in payload) {
      if (payload.inside_color_id === null) {
        delete payload.inside_color_id
      }
    }
    if ('is_hybrid' in payload) {
      delete payload.is_hybrid
    }
    const { data } = yield Api.post(uri, { ...payload });
    const row = data.data
    if (row.item) {
      row.item = { ...row.item, ...payload }
    }
    yield put(updateVehicleDataSuccess(row));
  } catch (e) {
    yield put(updateVehicleDataFailure(e.response));
  }
}

function* _createAddsRequest({ payload }) {
  try {
    const uri = ApiPaths.vehicles.addVehicles;
    const { data } = yield Api.post(uri, payload);
    const row = data.data
    yield put(createAddsSuccess(row));
  } catch (e) {
    yield put(createAddsFailure(e.response));
  }
}

// put in auction 

function* _putAdsIntoAuctionRequest({ payload }) {
  try {
    const uri = ApiPaths.vehicles.putInAuction + '/' + payload.ad_id + "/move_classic_ad_into_auction";
    delete payload.ad_id
    const { data } = yield Api.post(uri, payload);
    // const row = data.data
    yield put(putAdsIntoAuctionSuccess(data));
  } catch (e) {
    yield put(putAdsIntoAuctionFailure(e?.response?.data || ''));
  }
}

// put in hot deals 

function* _putAdsIntoHotDealRequest({ payload }) {
  try {
    const uri = ApiPaths.vehicles.putInhotDeal + '/' + payload.ad_id + "/move_classic_ad_into_hotdeal";
    delete payload.ad_id
    const { data } = yield Api.post(uri, payload);
    // const row = data.data
    yield put(putAdsIntoHotDealSuccess(data));
  } catch (e) {
    yield put(putAdsIntoHotDealFailure(e?.response?.data || ''));
  }
}

// GET PUBLIC ADDS
function* _getPublicAddsRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.addVehicles;
    uri += "?filter[type_of_sale]=" + payload.typeOfSale
    // if(!payload.filter){
    if ('productTypeId' in payload) {
      uri += "&filter[product_type_id]=" + payload.productTypeId.toString();
    }
    // }

    if ('currentUserBid' in payload) {
      uri += "&filter[current_user_bid]=" + payload.currentUserBid;
    }

    if ('adAuctionActive' in payload) {
      uri += "&filter[ad_auction_active]=" + payload.adAuctionActive;
    }

    if ('adAuctionFinished' in payload) {
      uri += "&filter[ad_auction_finished]=" + payload.adAuctionFinished;
    }

    if ('adAuctionWon' in payload) {
      uri += "&filter[ad_auction_won]=" + payload.adAuctionWon;
    }
    if ("withSold" in payload) {
      uri += "&withSold=" + payload.withSold
    }

    if ('adAuctionLost' in payload) {
      uri += "&filter[ad_auction_lost]=" + payload.adAuctionLost;
    }
    if ('ad_auction_active' in payload) {
      uri += '&filter[ad_auction_active]=' + payload.ad_auction_active;
    }
    if ('adAuctionWaitingApproval' in payload) {
      uri += '&filter[ad_auction_waiting_approval]=' + payload.adAuctionWaitingApproval;
    }

    if ("auction_id" in payload) {
      uri += "&filter[auction_id]=" + payload.auction_id
    }
    if ('subsidiaryId' in payload) {
      uri += "&filter[subsidiary_id]=" + payload.subsidiaryId
    }
    if ("currentPage" in payload) {
      uri += "&page=" + payload.currentPage
    }
    if ('sort' in payload) {
      uri += "&sort=" + payload.sort
    }

    if ('keyword' in payload) {
      uri += "&filter[keyword]=" + payload.keyword
    }
    if ('per_page' in payload) {
      uri += "&per_page=" + payload.per_page
    }
    if ('filter' in payload) {
      uri += payload.filter;
    }
    if ('subsidiarySlug' in payload) {
      uri += "&filter[subsidiary_slug]=" + payload.subsidiarySlug
    }
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(getPublicAddsSuccess(row));
  } catch (e) {
    yield put(getPublicAddsFailure(e.response));
  }
}
function* _getHomePublicAddsRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.addVehicles;
    uri += "?filter[type_of_sale]=" + payload.typeOfSale
    // if(!payload.filter){
    if ('productTypeId' in payload) {
      uri += "&filter[product_type_id]=" + payload.productTypeId.toString();
    }
    // }

    if ('currentUserBid' in payload) {
      uri += "&filter[current_user_bid]=" + payload.currentUserBid;
    }

    if ('adAuctionActive' in payload) {
      uri += "&filter[ad_auction_active]=" + payload.adAuctionActive;
    }

    if ('adAuctionFinished' in payload) {
      uri += "&filter[ad_auction_finished]=" + payload.adAuctionFinished;
    }

    if ('adAuctionWon' in payload) {
      uri += "&filter[ad_auction_won]=" + payload.adAuctionWon;
    }
    if ("withSold" in payload) {
      uri += "&withSold=" + payload.withSold
    }

    if ('adAuctionLost' in payload) {
      uri += "&filter[ad_auction_lost]=" + payload.adAuctionLost;
    }
    if ('ad_auction_active' in payload) {
      uri += '&filter[ad_auction_active]=' + payload.ad_auction_active;
    }
    if ('adAuctionWaitingApproval' in payload) {
      uri += '&filter[ad_auction_waiting_approval]=' + payload.adAuctionWaitingApproval;
    }

    if ("auction_id" in payload) {
      uri += "&filter[auction_id]=" + payload.auction_id
    }
    if ('subsidiaryId' in payload) {
      uri += "&filter[subsidiary_id]=" + payload.subsidiaryId
    }
    if ("currentPage" in payload) {
      uri += "&page=" + payload.currentPage
    }
    if ('sort' in payload) {
      uri += "&sort=" + payload.sort
    }

    if ('keyword' in payload) {
      uri += "&filter[keyword]=" + payload.keyword
    }
    if ('per_page' in payload) {
      uri += "&per_page=" + payload.per_page
    }
    if ('filter' in payload) {
      uri += payload.filter;
    }
    if ('subsidiarySlug' in payload) {
      uri += "&filter[subsidiary_slug]=" + payload.subsidiarySlug
    }
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(getHomePublicAddsSuccess(row));
  } catch (e) {
    yield put(getHomePublicAddsFailure(e.response));
  }
}
// GET PUBLIC ADDS SHOP PAGE
function* _getPublicAddsShopRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.addVehicles;
    uri += "?filter[type_of_sale]=" + payload.typeOfSale
    // if(!payload.filter){
    if ('productTypeId' in payload) {
      uri += "&filter[product_type_id]=" + payload.productTypeId.toString();
    }
    // }

    if ('currentUserBid' in payload) {
      uri += "&filter[current_user_bid]=" + payload.currentUserBid;
    }

    if ('adAuctionActive' in payload) {
      uri += "&filter[ad_auction_active]=" + payload.adAuctionActive;
    }

    if ('adAuctionFinished' in payload) {
      uri += "&filter[ad_auction_finished]=" + payload.adAuctionFinished;
    }

    if ('adAuctionWon' in payload) {
      uri += "&filter[ad_auction_won]=" + payload.adAuctionWon;
    }
    if ("withSold" in payload) {
      uri += "&withSold=" + payload.withSold
    }

    if ('adAuctionLost' in payload) {
      uri += "&filter[ad_auction_lost]=" + payload.adAuctionLost;
    }
    if ('ad_auction_active' in payload) {
      uri += '&filter[ad_auction_active]=' + payload.ad_auction_active;
    }
    if ('adAuctionWaitingApproval' in payload) {
      uri += '&filter[ad_auction_waiting_approval]=' + payload.adAuctionWaitingApproval;
    }

    if ("auction_id" in payload) {
      uri += "&filter[auction_id]=" + payload.auction_id
    }
    // if ('subsidiaryId' in payload) {
    //   uri += "&filter[subsidiary_id]="+payload.subsidiaryId
    // }
    if ("currentPage" in payload) {
      uri += "&page=" + payload.currentPage
    }
    if ('sort' in payload) {
      uri += "&sort=" + payload.sort
    }

    if ('keyword' in payload) {
      uri += "&filter[keyword]=" + payload.keyword
    }
    if ('per_page' in payload) {
      uri += "&per_page=" + payload.per_page
    }
    if ('filter' in payload) {
      uri += payload.filter;
    }
    if ('subsidiarySlug' in payload) {
      uri += "&filter[subsidiary_slug]=" + payload.subsidiarySlug
    }

    if ('except_ad_id' in payload)
      uri += "&filter[except_ad_id]=" + payload.except_ad_id

    if ('with_media' in payload)
      uri += "&filter[with_media]=" + payload.with_media
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(getPublicAddsShopSuccess(row));
  } catch (e) {
    yield put(getPublicAddsShopFailure(e.response));
  }
}

// GET PUBLIC ADDS
function* _getPublicAuctionAddsRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.addVehicles;
    uri += "?filter[type_of_sale]=" + payload.typeOfSale
    // if(!payload.filter){
    if ('productTypeId' in payload) {
      uri += "&filter[product_type_id]=" + payload.productTypeId.toString();
    }
    // }

    if ('currentUserBid' in payload) {
      uri += "&filter[current_user_bid]=" + payload.currentUserBid;
    }

    if ('adAuctionActive' in payload) {
      uri += "&filter[ad_auction_active]=" + payload.adAuctionActive;
    }

    if ('adAuctionFinished' in payload) {
      uri += "&filter[ad_auction_finished]=" + payload.adAuctionFinished;
    }

    if ('adAuctionWon' in payload) {
      uri += "&filter[ad_auction_won]=" + payload.adAuctionWon;
    }
    if ("withSold" in payload) {
      uri += "&withSold=" + payload.withSold
    }

    if ('adAuctionLost' in payload) {
      uri += "&filter[ad_auction_lost]=" + payload.adAuctionLost;
    }
    if ('ad_auction_active' in payload) {
      uri += '&filter[ad_auction_active]=' + payload.ad_auction_active;
    }
    if ('adAuctionWaitingApproval' in payload) {
      uri += '&filter[ad_auction_waiting_approval]=' + payload.adAuctionWaitingApproval;
    }

    if ("auction_id" in payload) {
      uri += "&filter[auction_id]=" + payload.auction_id
    }
    if ('subsidiaryId' in payload) {
      uri += "&filter[subsidiary_id]=" + payload.subsidiaryId
    }
    if ('subsidiarySlug' in payload) {
      uri += "&filter[subsidiary_slug]=" + payload.subsidiarySlug
    }
    if ("currentPage" in payload) {
      uri += "&page=" + payload.currentPage
    }
    if ('sort' in payload) {
      uri += "&sort=" + payload.sort
    }

    if ('keyword' in payload) {
      uri += "&filter[keyword]=" + payload.keyword
    }
    if ('per_page' in payload) {
      uri += "&per_page=" + payload.per_page
    }
    if ('filter' in payload) {
      uri += payload.filter;
    }
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(getPublicAuctionAddsSuccess(row));
  } catch (e) {
    yield put(getPublicAuctionAddsFailure(e.response));
  }
}

/* get vehicle material list api */
function* _getVehicleMaterialRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.materials;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleMaterialSuccess(row));
  } catch (e) {
    yield put(getVehicleMaterialFailure(e.response));
  }
}

/* get vehicle battery type list api */
function* _getVehicleBatteryPositionRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.battery_positions;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleBatteryPositionSuccess(row));
  } catch (e) {
    yield put(getVehicleBatteryPositionFailure(e.response));
  }
}

/* get vehicle drive type list api */
function* _getVehicleDriveTypesRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.drive_types;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleDriveTypesSuccess(row));
  } catch (e) {
    yield put(getVehicleDriveTypesFailure(e.response));
  }
}

/* get vehicle suspension type list api */
function* _getVehicleSuspensionTypesRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.suspensions;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleSuspensionTypesSuccess(row));
  } catch (e) {
    yield put(getVehicleSuspensionTypesFailure(e.response));
  }
}

/* get vehicle brake type list api */
function* _getVehicleBrakeTypesRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.brakes;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleBrakeTypesSuccess(row));
  } catch (e) {
    yield put(getVehicleBrakeTypesFailure(e.response));
  }
}

/* get vehicle engine positions list api */
function* _getVehicleEnginePositionsRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.engine_positions;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleEnginePositionsSuccess(row));
  } catch (e) {
    yield put(getVehicleEnginePositionsFailure(e.response));
  }
}

/* get vehicle engines list api */
function* _getVehicleEnginesRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.engines;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleEnginesSuccess(row));
  } catch (e) {
    yield put(getVehicleEnginesFailure(e.response));
  }
}

/* get vehicle component list api */
function* _getVehicleComponentsRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.battery_types;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleComponentsSuccess(row));
  } catch (e) {
    yield put(getVehicleComponentsFailure(e.response));
  }
}

/*  Get vehicle sex list */
function* _getVehicleSexRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.sex;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleSexSuccess(row));
  } catch (e) {
    yield put(getVehicleSexFailure(e.response));
  }
}

function* _getAuctionRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.auction + '?';
    if ("product_type_id" in payload) {
      uri += "&filter[product_type_id]=" + payload.product_type_id
    }
    if ("auctiontype" in payload) {
      uri += `&filter[${payload.auctiontype}]=1`
    }
    if ("seller_allowed" in payload) {
      uri += `&filter[seller_allowed]=${payload.seller_allowed}`
    }
    if ("accepting_registration" in payload) {
      uri += `&filter[accepting_registration]=1`
    }
    if ("auction_active_or_upcoming" in payload) {
      uri += `&filter[auction_active_or_upcoming]=1`
    }
    if ("country_id" in payload) {
      uri += `&filter[country_id]=${payload.country_id}`
    }
    if ("page" in payload) {
      uri += `&page=${payload.page}`
    }
    if ("country_slug" in payload) {
      uri += `&filter[country_slug]=${payload.country_slug}`
    }
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(getAuctionSuccess(row));
  } catch (e) {
    yield put(getAuctionFailure(e.response));
  }
}

function* _getHotDealRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.hotDeals;
    if (payload) {
      uri += payload
    }
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(getHotDealSuccess(row));
  } catch (e) {
    yield put(getHotDealFailure(e.response));
  }
}

function* _getAuctionHomeRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.auction;
    if (payload) {
      uri += payload
    }
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(getAuctionHomeSuccess(row));
  } catch (e) {
    yield put(getAuctionHomeFailure());
  }
}

function* _getAuctionAdsCountRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.addVehicles + ApiPaths.vehicles.auctionPlace + ApiPaths.company.count;
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(getAuctionAdsCountSuccess(row));
  } catch (e) {
    yield put(getAuctionAdsCountFailure());
  }
}

//GET ADDS DETAIL
function* _getPublicAddsDetailRequest({ payload }) {
  try {
    const uri = ApiPaths.vehicles.addVehicles + '/' + payload.id;
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(getPublicAddsDetailSuccess(row));
  } catch (e) {
    yield put(getPublicAddsDetailFailure(e.response));
  }
}

function* _createBidRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.offers + ApiPaths.vehicles.addVehicles + '/' + payload.id;
    const { data } = yield Api.post(uri, {
      bid_amount: payload.bid_amount,
      type: payload.type
    });
    yield put(createBidSuccess(data));
  } catch (e) {
    yield put(createBidFailure(e.response));
  }
}

function* _uploadVehicleImagesRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.ownerVehicles + '/' + payload.id + ApiPaths.vehicles.images;
    const formdata = new FormData();
    for (let index = 0; index < payload.medias.length; index++) {
      formdata.append(`medias[${index}]`, payload.medias[index].file ? payload.medias[index].file : payload.medias[index].uuid);
    }
    const { data } = yield Api.post(uri, formdata);
    yield put(uploadVehicleImagesSuccess(data.data));
  } catch (e) {
    yield put(uploadVehicleImagesFailure(e.response));
  }
}

function* _bidAcceptRejectRequest({ payload }) {
  try {
    const uri = ApiPaths.vehicles.addVehicles + ApiPaths.vehicles.myAdds + '/' + payload.id + ApiPaths.vehicles.bidAcceptReject + "?bid_accepted=" + payload.value;
    const { data } = yield Api.get(uri, {});
    const row = data
    row['id'] = payload.id
    yield put(bidAcceptRejectSuccess(row));
  } catch (e) {
    yield put(bidAcceptRejectFailure(e.response));
  }
}

function* _vehicleSoldReserveRequest({ payload }) {
  try {
    const uri = ApiPaths.vehicles.ownerVehicles + "/" + payload.id + ApiPaths.vehicles.sold;
    const { data } = yield Api.post(uri, payload.data);
    const row = data
    yield put(vehicleSoldReserveSuccess(row));
  } catch (e) {
    yield put(vehicleSoldReserveFailure(e.response));
  }
}

/*  Get vehicle detail list */
function* _getVehicleDetailRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.ownerVehicles + "/" + payload.id
    const { data } = yield Api.get(uri);
    const row = data.data
    if ("item" in row) {
      row.item.product_type_id = payload.product_type_id
    }
    yield put(getVehicleDetailSuccess(row));
  } catch (e) {
    yield put(getVehicleDetailFailure(e.response));
  }
}

function* _editAdRequest({ payload }) {
  try {
    const uri = ApiPaths.vehicles.addVehicles + '/' + payload.id + '?_method=patch';
    const { data } = yield Api.post(uri, payload.data);
    const row = data.data
    yield put(editAdSuccess(row));
  } catch (e) {

    yield put(editAdFailure(e.response));
  }
}

function* _getAllCustomersRequest({ payload }) {
  try {
    const uri = ApiPaths.vehicles.customers;
    const { data } = yield Api.get(uri, { params: payload });
    yield put(getAllCustomersSuccess(data));
  } catch (e) {
    yield put(getAllCustomersFailure(e.response));
  }
}

//delete park vehicle
function* _deleteVehicleRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.ownerVehicles + '/' + payload.id;;
    const { data } = yield Api.delete(uri, {});
    const row = data.data
    row['id'] = payload.id
    yield put(deleteVehicleSuccess(row));
  } catch (e) {
    yield put(deleteVehicleFailure(e.response.data));
  }
}

//delete clasic and auction vehicle
function* _deleteClassicAuctionAdRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.addVehicles + '/' + payload.id;
    const { data } = yield Api.delete(uri, {});
    const row = data.data
    row['id'] = payload.id
    yield put(deleteClassicAuctionAdSuccess(row));
  } catch (e) {
    yield put(deleteClassicAuctionAdFailure());
  }
}

/* get vehicle target audience list api */
function* _getVehicleTargetAudienceRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.targetAudiences;
    uri += "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getVehicleTargetAudienceSuccess(row));
  } catch (e) {
    yield put(getVehicleTargetAudienceFailure(e.response));
  }
}

/*  Get vehicle detail list */
function* _getAdDetailRequest({ payload }) {
  try {
    let uri = `${ApiPaths.vehicles.addVehicles}/${payload.id}`
    const { data } = yield Api.get(uri);
    const row = data.data
    if ("item" in row) {
      row.item.product_type_id = payload.product_type_id
    }
    yield put(getAdDetailSuccess(row));
  } catch (e) {
    yield put(getAdDetailFailure(e.response));
  }
}

function* _cancelReservationRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.ownerVehicles + "/" + payload.id

    if (payload.typeOfSale === adsTypesNum.hotDealAds) {
      uri += ApiPaths.vehicles.cancelReservationRequestToAdmin;
    } else {
      uri += ApiPaths.vehicles.cancelReservation;
    }

    if ('park' in payload) {
      uri += "?park=" + payload.park;
    }
    if (payload.typeOfSale && payload.typeOfSale !== adsTypesNum.hotDealAds) {
      uri += "?type_of_sale=" + payload.typeOfSale;
    }

    const { data } = yield payload.typeOfSale === adsTypesNum.hotDealAds ? Api.post(uri, { reason: payload.reason }) : Api.get(uri, {});
    const row = data
    yield put(cancelReservationSuccess({
      id: payload.id,
      redirect: payload.redirect ?? true,
      ...row
    }));
  } catch (e) {
    yield put(cancelReservationFailure(e.response));
  }
}

function* _confirmVehiclePurchaseRequest(payload) {
  try {
    let uri;
    if (payload?.payload?.subsidiary_id) {
      uri = ApiPaths.vehicles.ownerVehicles + '/' + payload.payload.owner_vehicle_id + ApiPaths.vehicles.confirmVehicle + `?subsidiary_id=${payload?.payload?.subsidiary_id}`
    } else {
      uri = ApiPaths.vehicles.ownerVehicles + '/' + payload.payload.owner_vehicle_id + ApiPaths.vehicles.confirmVehicle
    }
    const { data } = yield Api.get(uri, {});
    const row = data
    row['id'] = payload.payload.owner_vehicle_id
    yield put(confirmVehiclePurchaseSuccess(row));
  } catch (e) {
    yield put(confirmVehiclePurchaseFailure(e.response));
  }
}

function* _rejectVehiclePurchaseRequest(payload) {
  try {
    let uri;
    if (payload?.payload?.subsidiary_id) {
      uri = ApiPaths.vehicles.ownerVehicles + '/' + payload.payload.owner_vehicle_id + ApiPaths.vehicles.rejectVehicle + `?subsidiary_id=${payload?.payload?.subsidiary_id}`
    } else {
      uri = ApiPaths.vehicles.ownerVehicles + '/' + payload.payload.owner_vehicle_id + ApiPaths.vehicles.rejectVehicle
    }
    const body = {}
    if (payload?.payload?.reason) {
      body['reason'] = payload.payload.reason
    }
    const { data } = yield Api.post(uri, body);
    const row = data
    row['id'] = payload.payload.owner_vehicle_id
    row['is_buyer'] = payload.payload.is_buyer
    yield put(rejectVehiclePurchaseSuccess(row));
  } catch (e) {
    yield put(rejectVehiclePurchaseFailure(e.response));
  }
}

function* _vehicleExpertiseSaveRequest({ payload }) {
  const dataStore = yield select(vehiclesStore);
  let cloneExperies = [...dataStore.validateData.expertise]
  const name = payload.payload?.name || ''
  delete payload.payload.name;
  try {
    const uri = ApiPaths.vehicles.ownerVehicles + '/' + payload.payload.id + ApiPaths.vehicles.expertise;
    const { data } = yield Api.post(uri, payload.payload);
    const row = data.data
    var expertise: any = [];
    if ('item' in row) {
      let item = row.item;
      let expertise_section = {
        name
      }
      item['expertise_section'] = expertise_section;
      expertise = [...cloneExperies, item];
    } else {
      expertise = cloneExperies;
    }
    yield put(vehicleExpertiseSaveSuccess(expertise));
  } catch (e) {
    yield put(vehicleExpertiseSaveFailure(e.response));
  }
}

function* _vehicleExpertiseUpdateRequest({ payload }) {
  const dataStore = yield select(vehiclesStore);
  let cloneExperies = [...dataStore.validateData.expertise]
  const name = payload?.name || ''
  delete payload.name;
  try {
    const uri = ApiPaths.vehicles.expertise + "/" + payload.part_id + "?_method=PATCH";
    const { data } = yield Api.post(uri, payload);
    const row = data.data;
    let expertise: any = []
    if (row === null) {
      const deleteData = cloneExperies.filter(p => p.id !== payload.part_id);
      expertise = deleteData;
      yield put(vehicleExpertiseUpdateSuccess(expertise));
    }
    if ('item' in row) {
      let item = row.item;
      let expertise_section = {
        name
      }
      item['expertise_section'] = expertise_section;
      const selectedIndex = cloneExperies.findIndex(p => p.id === row.item.id);
      cloneExperies[selectedIndex] = item
      expertise = cloneExperies;
      yield put(vehicleExpertiseUpdateSuccess(expertise));
    }
  } catch (e) {
    yield put(vehicleExpertiseUpdateFailure(e.response));
  }
}

function* _vehicleExpertiseFetchRequest({ payload }) {
  try {
    const uri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.expertise_sections + "?filter[product_type_id]=" + payload.product_type_id;
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(vehicleExpertiseFetchSuccess(row));
  } catch (e) {
    yield put(vehicleExpertiseFetchFailure(e.response));
  }
}

function* _callRequest({ payload }) {

  try {
    const uri = `${ApiPaths.call.call}/${payload.vehicle_id}/call`;
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(callSuccess(row));
  } catch (e) {
    yield put(callFailure(e.response));
  }
}

function* _addAdInFavouriteRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.favourite + ApiPaths.vehicles.addVehicles + '/' + payload.id;
    // const ctb: any = yield select(state => state.ctb.active_company);
    // if (ctb.id) uri += `?subsidiary_id=${ctb.id}`;
    const { data } = yield Api.post(uri, {});
    const row = data.data;
    yield put(addAdInFavouriteSuccess(row));
  } catch (e) {
    const error = e.response
    error['data']["id"] = payload.id
    yield put(addAdInFavouriteFailure(error));
  }
}

function* _removeAdFromFavouriteRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.favourite + ApiPaths.vehicles.addVehicles + '/' + payload.id;
    // const ctb: any = yield select(state => state.ctb.active_company);
    // if (ctb.id) uri += `?subsidiary_id=${ctb.id}`
    const { data } = yield Api.delete(uri, {});
    const row = data.data;
    row['id'] = payload.id
    yield put(removeAdFromFavouriteSuccess(row));
  }
  catch (e) {
    const error = e.response
    error['data']["id"] = payload.id
    yield put(removeAdFromFavouriteFailure(error));
  }
}

function* _getCarFilterRequest() {
  try {
    let typeuri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.types;
    let fuelUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.fuels + `?filter[product_type_id]=${productTypes.car}`;
    let gearUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.gearboxes + `?filter[product_type_id]=${productTypes.car}`;
    let interiorUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.interiors + `?filter[product_type_id]=${productTypes.car}`;
    let frameUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.frameSizes + `?filter[product_type_id]=${productTypes.car}`;
    let euroUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.euroStandars;
    let EquipmemtUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.equipments + `?filter[product_type_id]=${productTypes.car}`;
    let colorsUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.colors + `?filter[product_type_id]=${productTypes.car}`;
    let brandUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.brands + `?filter[product_type_id]=${productTypes.car}`;
    let bodiesUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.bodies + `?filter[product_type_id]=${productTypes.car}`;
    let conditionUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.vehicleConditions + `?filter[product_type_id]=${productTypes.car}`;
    const fuelData = yield Api.get(fuelUri)
    const typeData = yield Api.get(typeuri)
    const gearData = yield Api.get(gearUri)
    const interiorData = yield Api.get(interiorUri)
    const frameData = yield Api.get(frameUri)
    const euroData = yield Api.get(euroUri)
    const EquipmemtData = yield Api.get(EquipmemtUri)
    const colorsData = yield Api.get(colorsUri)
    const brandData = yield Api.get(brandUri)
    const bodiesData = yield Api.get(bodiesUri)
    const conditionData = yield Api.get(conditionUri)
    let FilterData = {
      typeList: typeData.data.data,
      fuellist: fuelData.data.data,
      gearList: gearData.data.data,
      interiorList: interiorData.data.data,
      frameList: frameData.data.data,
      euroList: euroData.data.data,
      equipmentList: EquipmemtData.data.data,
      colorList: colorsData.data.data,
      brandList: brandData.data.data,
      bodiesList: bodiesData.data.data,
      conditionList: conditionData.data.data,
      lang: localStorage.getItem(localstorageKey.i18nextLng)
    };
    yield put(getCarFilterSuccess(FilterData))
    localStorage.setItem('carFilterdata', JSON.stringify(FilterData));
  } catch (e) {
    yield put(getCarFilterFailure())
  }
}
function* _getBikeFilterRequest({ payload }) {
  try {
    let DriveUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.drive_types + `?filter[product_type_id]=${productTypes.bike}`;
    let fuelUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.fuels + `?filter[product_type_id]=${productTypes.bike}`;
    let frameUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.frameSizes + `?filter[product_type_id]=${productTypes.bike}`;
    let brandUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.brands + `?filter[product_type_id]=${productTypes.bike}`;
    let bodiesUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.bodies + `?filter[product_type_id]=${productTypes.bike}`;
    let conditionUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.vehicleConditions + `?filter[product_type_id]=${productTypes.bike}`;
    const driveData = yield Api.get(DriveUri)
    const fuelData = yield Api.get(fuelUri)
    const frameData = yield Api.get(frameUri)
    const brandData = yield Api.get(brandUri)
    const bodiesData = yield Api.get(bodiesUri)
    const conditionData = yield Api.get(conditionUri)
    let FilterData = {
      driveList: driveData.data.data,
      fuellist: fuelData.data.data,
      frameList: frameData.data.data,
      brandList: brandData.data.data,
      bodiesList: bodiesData.data.data,
      conditionList: conditionData.data.data,
      lang: localStorage.getItem(localstorageKey.i18nextLng)
    };
    yield put(getBikeFilterSuccess(FilterData))
    localStorage.setItem('bikeFilterdata', JSON.stringify(FilterData));
  } catch (e) {
    yield put(getBikeFilterFailure())
  }
}


function* _getStepFilterRequest() {
  try {
    let DriveUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.drive_types + `?filter[product_type_id]=${productTypes.step}`;
    let fuelUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.fuels + `?filter[product_type_id]=${productTypes.step}`;
    let frameUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.frameSizes + `?filter[product_type_id]=${productTypes.step}`;
    let brandUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.brands + `?filter[product_type_id]=${productTypes.step}`;
    let bodiesUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.bodies + `?filter[product_type_id]=${productTypes.step}`;
    let conditionUri = ApiPaths.vehicles.vehicles + ApiPaths.vehicles.vehicleConditions + `?filter[product_type_id]=${productTypes.step}`;
    const driveData = yield Api.get(DriveUri)
    const fuelData = yield Api.get(fuelUri)
    const frameData = yield Api.get(frameUri)
    const brandData = yield Api.get(brandUri)
    const bodiesData = yield Api.get(bodiesUri)
    const conditionData = yield Api.get(conditionUri)
    let FilterData = {
      driveList: driveData.data.data,
      fuellist: fuelData.data.data,
      frameList: frameData.data.data,
      brandList: brandData.data.data,
      bodiesList: bodiesData.data.data,
      conditionList: conditionData.data.data,
      lang: localStorage.getItem(localstorageKey.i18nextLng)
    };
    yield put(getStepFilterSuccess(FilterData))
    localStorage.setItem('stepFilterdata', JSON.stringify(FilterData));
  } catch (e) {
    yield put(getStepFilterFailure())
  }
}


export const vehicleSagas = [
  takeEvery(getVehicleBrandRequest, _getVehicleBrandRequest),
  takeEvery(getVehicleYearRequest, _getVehicleYearRequest),
  takeEvery(getVehicleTypeRequest, _getVehicleTypeRequest),
  takeEvery(getVehicleVersionRequest, _getVehicleVersionRequest),
  takeEvery(getVehicleModelListRequest, _getVehicleModelListRequest),
  takeEvery(getPowerListRequest, _getPowerListRequest),
  takeEvery(getFuelListRequest, _getFuelListRequest),
  takeEvery(getGearBoxListRequest, _getGearBoxListRequest),
  takeEvery(getVehicleBodyListRequest, _getVehicleBodyListRequest),
  takeEvery(getVehicleValidateRequest, _getVehicleValidateRequest),
  takeEvery(getVehicleEquipmentRequest, _getVehicleEquipmentRequest),
  takeEvery(getVehicleInteriorRequest, _getVehicleInteriorRequest),
  takeEvery(getVehicleConditionsRequest, _getVehicleConditionsRequest),
  takeEvery(getVehicleSeasonRequest, _getVehicleSeasonRequest),
  takeEvery(getVehicleTiersRequest, _getVehicleTiersRequest),
  takeEvery(getVehicleColorsRequest, _getVehicleColorsRequest),
  takeEvery(getVehicleWheelsRequest, _getVehicleWheelsRequest),
  takeEvery(getVehicleFrameSizeRequest, _getVehicleFrameSizeRequest),
  takeEvery(updateVehicleDataRequest, _updateVehicleDataRequest),
  takeEvery(createAddsRequest, _createAddsRequest),
  takeEvery(putAdsIntoAuctionRequest, _putAdsIntoAuctionRequest),
  takeEvery(getPublicAddsRequest, _getPublicAddsRequest),
  takeEvery(getPublicAuctionAddsRequest, _getPublicAuctionAddsRequest),
  takeEvery(getVehicleMaterialRequest, _getVehicleMaterialRequest),
  takeEvery(getVehicleBatteryPositionRequest, _getVehicleBatteryPositionRequest),
  takeEvery(getVehicleDriveTypesRequest, _getVehicleDriveTypesRequest),
  takeEvery(getVehicleSuspensionTypesRequest, _getVehicleSuspensionTypesRequest),
  takeEvery(getVehicleBrakeTypesRequest, _getVehicleBrakeTypesRequest),
  takeEvery(getVehicleEnginePositionsRequest, _getVehicleEnginePositionsRequest),
  takeEvery(getVehicleEnginesRequest, _getVehicleEnginesRequest),
  takeEvery(getVehicleComponentsRequest, _getVehicleComponentsRequest),
  takeEvery(getVehicleSexRequest, _getVehicleSexRequest),
  takeEvery(getAuctionRequest, _getAuctionRequest),
  takeEvery(getAuctionAdsCountRequest, _getAuctionAdsCountRequest),
  takeEvery(getPublicAddsDetailRequest, _getPublicAddsDetailRequest),
  takeEvery(createBidRequest, _createBidRequest),
  takeEvery(uploadVehicleImagesRequest, _uploadVehicleImagesRequest),
  takeEvery(bidAcceptRejectRequest, _bidAcceptRejectRequest),
  takeEvery(vehicleSoldReserveRequest, _vehicleSoldReserveRequest),
  takeEvery(getVehicleDetailRequest, _getVehicleDetailRequest),
  takeEvery(editAdRequest, _editAdRequest),
  takeEvery(getAllCustomersRequest, _getAllCustomersRequest),
  takeEvery(deleteVehicleRequest, _deleteVehicleRequest),
  takeEvery(deleteClassicAuctionAdRequest, _deleteClassicAuctionAdRequest),
  takeEvery(getVehicleTargetAudienceRequest, _getVehicleTargetAudienceRequest),
  takeEvery(getAdDetailRequest, _getAdDetailRequest),
  takeEvery(cancelReservationRequest, _cancelReservationRequest),
  takeEvery(confirmVehiclePurchaseRequest, _confirmVehiclePurchaseRequest),
  takeEvery(rejectVehiclePurchaseRequest, _rejectVehiclePurchaseRequest),
  takeEvery(vehicleExpertiseSaveRequest, _vehicleExpertiseSaveRequest),
  takeEvery(vehicleExpertiseUpdateRequest, _vehicleExpertiseUpdateRequest),
  takeEvery(vehicleExpertiseFetchRequest, _vehicleExpertiseFetchRequest),
  takeEvery(callRequest, _callRequest),
  takeEvery(addAdInFavouriteRequest, _addAdInFavouriteRequest),
  takeEvery(removeAdFromFavouriteRequest, _removeAdFromFavouriteRequest),
  takeEvery(getEuroRequest, _getEuroRequest),
  takeEvery(getCarFilterRequest, _getCarFilterRequest),
  takeEvery(getBikeFilterRequest, _getBikeFilterRequest),
  takeEvery(getStepFilterRequest, _getStepFilterRequest),
  takeEvery(getPublicAddsShopRequest, _getPublicAddsShopRequest),
  takeEvery(getHomePublicAddsRequest, _getHomePublicAddsRequest),
  takeEvery(getAuctionHomeRequest, _getAuctionHomeRequest),
  takeEvery(getHotDealRequest, _getHotDealRequest),
  takeEvery(putAdsIntoHotDealRequest, _putAdsIntoHotDealRequest)
]