import { Box, CircularProgress } from '@mui/material';
import { SvgPlusLite, SvgSearchIcon } from 'app/components/svgicons/svg';
import { useCallback, useEffect, useRef, useState } from 'react'
import AvatarandDetails from '../ChildComponents/AvatarandDetails';
import { ChatWrapper } from '../../style/chatStyle';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import MoreOptions from '../ChildComponents/MoreOptions';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { SvgExpandedViewIcon } from 'app/components/svgicons/svg2';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';
import { Nullable, chatDataInterface, chatList, statusType } from '../../interface/interface';
import CreateNewChat from '../ChildComponents/CreateNewChat';
import MoreOptionsMobile from '../ChildComponents/MoreOptionsMobile';
import { useWindowSize } from 'app/Hooks/windowResize';
import CreateNewChatMobile from '../ChildComponents/CreateNewChatMobile';
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  Type as ListType,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import * as ChatConstants from "config/chatConfig"
import { archiveParticularChatRequest, chatStoreSuccess, particularChatSuccess, getUserListRequest, blockParticularChatRequest, getChatSuccess, sendMessageSuccess, unreadParticularChatRequest, muteParticularChatRequest, getChatRequest, lastMessagesState, getBlockedChatRequest, getArchivedChatRequest } from 'store/actions/chat-action';
import { Toaster } from 'services/Toaster';
import DeleteConfirm from 'app/components/stylesComponents/Ads/DeleteConfirm';
import ChatOptionsMobile from '../ChildComponents/ChatOptionsMobile';
import { Strings, getSubsidiaryStored } from 'config/variables';
import ToastMessageWithButton from 'app/components/ToastMessageWithButton';
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader';
import ChatSkeleton from '../../Skeleton/ChatSkeleton';

const ChatList = ({ expandedView = false, chatId, setChatId, popup = false, chatType, setChatType, showInitiateChatModal, setShowInitiateChatModal, chatListData, setSwipedChat, setSelectedChatID }: chatList) => {
  const { t } = useTranslation()


  const location = useLocation()

  const history = useHistory()

  const { muteAllChat, unreadAllChat, userList, blockedChatList, chatListAllData, loadingArchivedChats, loadingBlockedChats, loadingAllChats, archivedChatListAllData, blockedChatListAllData, loadingUsers } = useSelector((state: RootStateOrAny) => state.chat)

  const { userData } = useSelector((state: RootStateOrAny) => state.user)

  //Hook for checking Window size
  const [mobile] = useWindowSize();

  const [allChatListData, setAllChatListData] = useState<Array<Object>>([])
  const [filteredChatListData, setFilteredChatListData] = useState<Array<Object>>([])
  const chatRef = useRef<HTMLDivElement>(null);
  const [isAllReadStatus, setIsAllReadStatus] = useState<statusType>(0)
  const [isAllMuteStatus, setIsAllMuteStatus] = useState<statusType>(0)
  const [allUserList, setAllUserList] = useState<Array<Object>>([])
  const [searchText, setSearchText] = useState<string>("")
  const [licensePlateAndCountry, setLicensePlateAndCountry] = useState<object>({
    country: '',
    LicensePlate: ''
  })
  const [page, setPage] = useState<number>(1)

  const dispatch = useDispatch();

  const [selectedChat, setSelectedChat] = useState<chatDataInterface>({})
  const [confirmPopup, setConfirmPopup] = useState<boolean>(false)

  const [open, setOpen] = useState<boolean>(false)

  const [searched, _searched] = useState<boolean>(false)

  const scrollRef: any = useRef(null)

  useEffect(() => {
    dispatch(chatStoreSuccess({}))
  }, [])

  useEffect(() => {
    // get list of suggested users for initiating chat    
    if (userList.length > 0) {
      setAllUserList(userList)
      return
    }
    setAllUserList([])
  }, [userList])

  useEffect(() => {
    setSelectedChat({})
    setOpen(false)
  }, [])

  useEffect(() => {
    if (chatListData) {
      // In case of mobile and popup, removes chats from chat list if they don't have messages
      if (mobile || popup) {
        let newData = []
        chatListData.forEach((elem) => {
          if (elem.last_message) {
            newData.push(elem)
          }
        })
        setAllChatListData(newData)
        return
      }
      setAllChatListData(chatListData)
    }
  }, [chatListData])

  useEffect(() => {
    setFilteredChatListData(allChatListData)
    setTimeout(() => {
      // for holding the scroll position when getting back to list component in mobile or popup
      if ((!mobile || location.pathname.includes('chat')) && (mobile || !location.pathname.includes('chat'))) {
        let scrollpos = localStorage.getItem('chatScrollPosition');
        if (scrollpos && document.getElementById('chatList')) {
          document.getElementById('chatList').scrollTop = JSON.parse(scrollpos);
        }
      }
    }, 200)
  }, [allChatListData])



  // Setting All read and mute variables
  useEffect(() => {
    if (chatListData && chatListData.length > 0) {
      let readIndex = chatListData.some(o => o.is_read === ChatConstants.readStatus.read)
      if (readIndex) {
        setIsAllReadStatus(ChatConstants.readStatus.unRead)
      }
      let muteIndex = chatListData.some(o => o.is_muted === ChatConstants.muteStatus.unMute)
      if (muteIndex) {
        setIsAllMuteStatus(ChatConstants.muteStatus.mute)
      }
    }
  }, [chatListData])


  //Function to Handle Archive chat Action 
  const handleArchiveAction = (item) => {
    localStorage.setItem('chat_id', null)
    setSwipedChat(item.id)
    let data = {
      id: item.id,
      data: !item.is_archived ? 1 : 0
    }
    dispatch(archiveParticularChatRequest(data))
  }

  //Function to filter out chats searched by user
  const handleSearch = (text) => {
    let alldata = [...allChatListData]
    let searchedValues = alldata.filter(item => {
      return item['chat_user_info'].name.toLowerCase().includes(text.toLowerCase()) || item['title']?.toLowerCase().includes(text.toLowerCase());
    });
    setFilteredChatListData(searchedValues)
  }


  useEffect(() => {
    // Resetting variable when API triggered for muting/unmuting all messages
    if (muteAllChat && Object.keys(muteAllChat).length > 0) {
      setIsAllMuteStatus(muteAllChat.data.is_muted ? 0 : 1)
    }

    // Resetting variable when API triggered ar reading/unreading all messages
    if (unreadAllChat && Object.keys(unreadAllChat).length > 0) {
      setIsAllReadStatus(unreadAllChat.data.is_read ? 0 : 1)
    }
  }, [muteAllChat, unreadAllChat])

  // function for Updating read status
  const setChat = (id) => {
    dispatch(particularChatSuccess({}))
    setChatId(id)
  }

  //returns message for confirmation box for subsidiary or private user
  const getWarningMessage = (chatData) => {

    let msg = t(translations.CHAT.ARE_YOU_SURE_TO_BLOCK_THIS_USER)

    if (chatType === ChatConstants.chatType.ALL_MESSAGES && Object.keys(chatData).length > 0 && chatData.chat_user_info.subsidiary_id) {
      msg = t(translations.CHAT.ARE_YOU_SURE_TO_BLOCK_THIS_SUBSIDIARY)
    }

    if (chatType === ChatConstants.chatType.BLOCKED) {
      msg = t(translations.CHAT.ARE_YOU_SURE_TO_UNBLOCK_THIS_USER)
      if (Object.keys(chatData).length > 0 && chatData.chat_user_info.subsidiary_id) {
        msg = t(translations.CHAT.ARE_YOU_SURE_TO_UNBLOCK_THIS_SUBSIDIARY)
      }
    }

    return msg
  }

  //function for reading/unreading chat messages
  const handleUnread = (item) => {
    let data = {
      id: item.id,
      is_read: item.is_read ? ChatConstants.readStatus.unRead : ChatConstants.readStatus.read
    }
    dispatch(unreadParticularChatRequest(data))
    setOpen(false)
    setSelectedChat({})
  }

  //function for mute/unmute chat messages
  const handleMute = (item) => {
    //If chat is already muted, we will send opposite of it to unmute it 
    let data = {
      id: item.id,
      data: item.is_muted ? ChatConstants.muteStatus.unMute : ChatConstants.muteStatus.mute
    }
    dispatch(muteParticularChatRequest(data))
    setOpen(false)
    setSelectedChat({})
  }

  //function for archieve/unarchieve chat 
  const handleArchive = (item) => {
    //If chat is already archived, we will send opposite of it to arhcive it 
    let data = {
      id: item.id,
      data: item.is_archived ? ChatConstants.archivedStatus.unArchive : ChatConstants.archivedStatus.archive
    }
    dispatch(chatStoreSuccess({}))
    dispatch(archiveParticularChatRequest(data))
    setOpen(false)
    setSelectedChat({})
  }

  //function for block/unblock chat 
  const handleBlock = (item) => {
    localStorage.setItem('chat_id', null)
    setSwipedChat(item.id)
    let chatData = item
    if (chatType === ChatConstants.chatType.BLOCKED) {
      chatData = blockedChatList.find(o => o.id === item.id)
    }
    let blockedUser: Nullable<number> = null;
    let blockedSubsidiary: Nullable<number> = null;

    blockedUser = chatData.chat_user_info.id;

    blockedSubsidiary = chatData.chat_user_info.subsidiary_id;

    //sending Id of subsidiary if it exsits otherwise sending id of private user
    const blockedEntity = blockedSubsidiary ?? blockedUser;

    let data = {
      id: blockedEntity,
      isblocked: chatType === ChatConstants.chatType.BLOCKED ? true : false,
      type: blockedSubsidiary === null ? ChatConstants.BLOCK_TYPE.USER : ChatConstants.BLOCK_TYPE.SUBSIDIARY
    }
    setSelectedChat({})
    dispatch(blockParticularChatRequest(data))
    setConfirmPopup(false)
  }

  //this function return swiped view when triggerd  
  const trailingActions = (item, chatType) => {
    return <LeadingActions>
      <SwipeAction onClick={() => {
        setSelectedChat(item)
      }}>
        <Box
          className='btn-on-swipe'
          sx={{ background: '#617AF7', color: "#fff" }}>
          <ChatOptionsMobile
            componenttype="chatList"
            chatData={selectedChat}
            handleUnread={() => {
              handleUnread(selectedChat)
              setSwipedChat(selectedChat.id)
            }}
            handleMute={() => {
              handleMute(selectedChat)
              setSwipedChat(selectedChat.id)
            }}
            handleArchive={() => {
              handleArchive(selectedChat)
              setSwipedChat(selectedChat.id)
            }}
            handleBlock={() => {
              handleBlock(selectedChat)
              setSwipedChat(selectedChat.id)
            }}
            open={item.id === selectedChat.id && open} setOpen={setOpen} chatType={chatType}
          />
        </Box>
      </SwipeAction>
      <SwipeAction destructive={chatType === ChatConstants.chatType.BLOCKED ? false : true} onClick={() => {
        if (chatType === ChatConstants.chatType.BLOCKED) {
          setConfirmPopup(true)
          return;
        }
        handleArchiveAction(item)
      }}>
        <Box
          className='btn-on-swipe'
          sx={{ background: chatType === ChatConstants.chatType.BLOCKED ? "#EC1C24" : "#FBC623", color: chatType === ChatConstants.chatType.BLOCKED ? "#fff" : "#000" }}>
          {chatType === ChatConstants.chatType.BLOCKED ? t(translations.CHAT.UNBLOCKS) : item.is_archived ? t(translations.CHAT.UNARCHIVE) : t(translations.CHAT.ARCHIVE)}
        </Box>
      </SwipeAction>
    </LeadingActions>
  }

  //Search users from api
  const handleSearchUser = (event: any) => {
    setSearchText(event.target.value)
    setPage(1)
  };

  useEffect(() => {
    //code searching user for initiate new chat   
    if (searchText !== '' || showInitiateChatModal) {
      const delayDebounceFn = setTimeout(() => {
        // if search user by user name for initiat new chat then
        dispatch(getUserListRequest({ keyword: searchText }));
        return
      }, 500)
      return () => clearTimeout(delayDebounceFn)
    }

  }, [searchText])


  const handleSearchForLicensePlate = () => {
    if (loadingUsers) {
      return
    }
    if (licensePlateAndCountry.LicensePlate && licensePlateAndCountry.country) {
      // if search user by license plate and country for initiat new chat then
      dispatch(getUserListRequest({ license_plate: licensePlateAndCountry.LicensePlate, country_id: licensePlateAndCountry.country, searchByLicensePlate: 1 }));
      _searched(true)
    }
  }

  const handleInitaiteChat = () => {
    // if user profile is not verified but member of subsidiary can initiate chat 
    if (userData?.profile_is_verified === false && !getSubsidiaryStored()) {
      Toaster.error(<ToastMessageWithButton type='error' buttonText={t(translations.ads.CLICK_HERE_TO_VERIFY)} message={Strings.verifyString} click={() => history.push(routes.userRoutes.idVerfication)} />, false)
      return;
    }

    setPage(1)
    setShowInitiateChatModal(true)
    dispatch(getUserListRequest({ keyword: '' }))
  }

  //removes chat from list if it is blank
  const handleList = () => {
    let chatListObject = { ...chatListAllData }
    let allData = [...chatListData]
    if (allData && allData.length > 0) {
      let newListData = []
      allData.forEach((elem) => {
        if (elem.last_message) {
          newListData.push(elem)
        }
      })
      if (chatListObject?.data?.data) {
        chatListObject.data.data = newListData
        dispatch(getChatSuccess({ data: chatListObject, append: false }))
      }
    }
  }

  //resetting and redirecting  to chatlist in mobile view or popup
  const handleNavigate = () => {
    localStorage.removeItem('chatScrollPosition')
    handleList()
    history.push({
      pathname: routes.userRoutes.chat
    })
  }

  const openChat = (item) => {
    dispatch(lastMessagesState([]))
    if (item.messages) {
      dispatch(lastMessagesState(item.messages))
    }
    dispatch(particularChatSuccess({}))
    setChat(item.id)
    removeQueryParams()
    localStorage.setItem('chatScrollPosition', document.getElementById('chatList').scrollTop);
  }


  const removeQueryParams = () => {
    // remove query params as landed on chat page from notification (which includes chat_id and subsidiary_id as query params)
    const queryParams = new URLSearchParams(location.search)
    const chatId = queryParams.get('chat_id');
    const chatType = queryParams.get('chat_type');
    const subsidiaryId = queryParams.get('subsidiary_id');
    const switchParam = queryParams.get('switch');
    const subName = queryParams.get('subName');

    // if getting chat id and subsidiary Id in url then 
    if (subName || chatId || chatType || subsidiaryId || switchParam) {
      // remove chat_id && chat_type && subsidiary_id
      queryParams.delete('chat_id');
      queryParams.delete('chat_type');
      queryParams.delete('subsidiary_id');
      queryParams.delete('switch');
      queryParams.delete('subName');
      history.replace({
        search: queryParams.toString(),
      })
    }
  };

  //Function request for next page data when scrolled to bottom
  const handleScroll = (e) => {
    if (loadingAllChats) {
      return
    }
    // bottom return boolean value and Math.abs to get absolute value 
    const bottom = (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1000;
    if (chatType === ChatConstants.chatType.ALL_MESSAGES) {
      if (bottom && chatListAllData && Object.keys(chatListAllData).length > 0) {
        if (chatListAllData.data.current_page < chatListAllData.data.last_page) {
          //if current page is less than last page number then requesting for next page api call 
          dispatch(getChatRequest({ page: chatListAllData.data.current_page + 1 }))
        }
      }
    }
    if (chatType === ChatConstants.chatType.BLOCKED) {
      if (bottom && blockedChatListAllData && Object.keys(blockedChatListAllData).length > 0) {
        if (blockedChatListAllData.data.current_page < blockedChatListAllData.data.last_page) {
          //if current page is less than last page number then requesting for next page api call 
          dispatch(getBlockedChatRequest({ page: blockedChatListAllData.data.current_page + 1 }))
        }
      }
    }
    if (chatType === ChatConstants.chatType.ARCHIVED) {
      if (bottom && archivedChatListAllData && Object.keys(archivedChatListAllData).length > 0) {
        if (archivedChatListAllData.data.current_page < archivedChatListAllData.data.last_page) {
          //if current page is less than last page number then requesting for next page api call 
          dispatch(getArchivedChatRequest({ page: archivedChatListAllData.data.current_page + 1 }))
        }
      }
    }
  }

  const showSkeleton = useCallback(() => {
    if (chatType === ChatConstants.chatType.ALL_MESSAGES) {
      if ((Object.keys(chatListAllData).length === 0 || chatListAllData?.data?.data.length === 0) && loadingAllChats) {
        return true
      }
      return false
    }
    if (chatType === ChatConstants.chatType.BLOCKED) {
      if ((Object.keys(blockedChatListAllData).length === 0 || blockedChatListAllData?.data?.data.length === 0) && loadingBlockedChats) {
        return true
      }
      return false
    }
    if (chatType === ChatConstants.chatType.ARCHIVED) {
      if ((Object.keys(archivedChatListAllData).length === 0 || archivedChatListAllData?.data?.data.length === 0) && loadingArchivedChats) {
        return true
      }
      return false
    }
    return false
  }, [loadingAllChats, loadingArchivedChats, loadingBlockedChats, chatType, chatListAllData, blockedChatListAllData, archivedChatListAllData])

  return (
    <>
      {loadingUsers && <ContentLoader />}

      <ChatWrapper ref={chatRef}>
        <Box className='chat-wrapper' >
          {mobile && showInitiateChatModal ?
            <CreateNewChatMobile setSearchText={setSearchText} setLicensePlateAndCountry={setLicensePlateAndCountry} handleSearchForLicensePlate={handleSearchForLicensePlate} setChatType={setChatType} handleSearchUser={handleSearchUser} searchText={searchText} page={page} setPage={setPage} allUserList={allUserList} resetFunc={setShowInitiateChatModal} setChatId={setChatId} searched={searched} />
            :
            <>
              <Box className={popup ? 'popup-header' : 'userList-header'} >
                {popup &&
                  <Box className='chatListHeaderPopup'>
                    <span>
                      {t(translations.CHAT.CHATS)}
                    </span>
                  </Box>}
                {!popup &&
                  <Box className='Search-box'>
                    <SvgSearchIcon className='pointer' />
                    <input type='text' id="standard-basic-search" placeholder={t(translations.CHAT.SEARCH)} onChange={(e) => handleSearch(e.target.value)} />
                  </Box>}
                <Box className='more-option'>
                  <span className='add-chat'>
                    {mobile && chatType === ChatConstants.chatType.ALL_MESSAGES ?
                      <span onClick={() => handleInitaiteChat()}><SvgPlusLite className='pointer' /></span>
                      : <CreateNewChat setSearchText={setSearchText} setLicensePlateAndCountry={setLicensePlateAndCountry} handleSearchForLicensePlate={handleSearchForLicensePlate} chatTypeId={chatType} setChatType={setChatType} allUserList={allUserList} handleSearchUser={handleSearchUser} searchText={searchText} page={page} setPage={setPage} setShowInitiateChatModal={setShowInitiateChatModal} showInitiateChatModal={showInitiateChatModal} setChatId={setChatId} searched={searched} />}
                  </span>
                  {expandedView &&
                    <Box onClick={handleNavigate} className='pointer'>
                      <SvgExpandedViewIcon />
                    </Box>}
                  {(!expandedView && mobile) &&
                    //Filter option drawer that opens from bottom in mobile view
                    <MoreOptionsMobile chatType={chatType} setChatType={setChatType} isAllReadStatus={isAllReadStatus} isAllMuteStatus={isAllMuteStatus} drawer={false} setSelectedChatID={setSelectedChatID} setChatId={setChatId} />
                  } {(!expandedView && !mobile) &&
                    //Filter option that opens in web view 
                    <MoreOptions chatRef={chatRef} chatType={chatType} setChatType={setChatType} isAllReadStatus={isAllReadStatus} isAllMuteStatus={isAllMuteStatus} drawer={false} setSelectedChatID={setSelectedChatID} setChatId={setChatId} />
                  }
                </Box>
              </Box>
              {popup &&
                <Box className='userList-header-popup' >
                  <Box className='Search-box'>
                    <SvgSearchIcon className='pointer' />
                    <input type='text' id="standard-basic-search" placeholder={t(translations.CHAT.SEARCH_USERS)} onChange={(e) => handleSearch(e.target.value)} />
                  </Box>
                </Box>
              }
              {!popup && <Box className='chatListHeader'>
                <span>
                  {
                    chatType === ChatConstants.chatType.BLOCKED ? t(translations.CHAT.BLOCKED) :
                      chatType === ChatConstants.chatType.ARCHIVED ? t(translations.CHAT.ARCHIVED) :
                        t(translations.CHAT.ALL_MESSAGE)
                  }
                </span>
              </Box>}
              <Box className={(popup ? 'user-list-popup ' : '') + (chatListAllData?.data?.data.length === 0 && " alignItemsCenter justifyContentCenter ") + ' user-list scrollBar '} id="chatList" onScroll={(e) => handleScroll(e)} ref={scrollRef}>

                {loadingAllChats && <ContentLoader />}


                {showSkeleton() && [1, 2, 3, 4, 5, 6, 7, 8, 9].map((skeleton) => {
                  return <ChatSkeleton key={`ChatSkeleton-${skeleton}`} />
                })}


                {filteredChatListData.length > 0 && (
                  <>
                    {
                      filteredChatListData.map((item, index) => {
                        let message = ""
                        if (item.last_message) {
                          if (item.last_message.message) {
                            message = item.last_message.message
                          } else if (item.last_message.media && item.last_message.media.length > 0) {
                            message = item.last_message.media[0].file_name
                          }

                        }
                        return <Box key={"chat-" + item.id}>
                          {!mobile &&
                            <Box className={(item.id !== chatId ? "" : "newMessage") + " userAvatarAndDetails"} onClick={() => openChat(item)}>
                              <AvatarandDetails groupTitle={item.title} userInfo={item.chat_user_info} chatTime={item.last_chat_at} hideStatus={false} hideFlag={false} text={message} isRead={item.is_read} isMuted={item.is_muted} messageList={true} typeOfChat={item.type} chatData={item} />
                            </Box>
                          }
                          {mobile &&
                            <Box>
                              <SwipeableList
                                type={ListType.IOS}
                                fullSwipe={false}
                              >
                                <SwipeableListItem
                                  actionDelay={5000}
                                  trailingActions={trailingActions(item, chatType)}
                                  onSwipeEnd={() => setSelectedChat(item)}
                                  blockSwipe={item.chat_user_info && item.chat_user_info.id === null}
                                >
                                  <Box onClick={() => openChat(item)} sx={{ padding: '10px', width: '100%' }} >
                                    <AvatarandDetails groupTitle={item.title} userInfo={item.chat_user_info} chatTime={item.last_chat_at} hideStatus={false} hideFlag={false} text={message} isRead={item.is_read} isMuted={item.is_muted} hidebackButton={true} messageList={true} typeOfChat={item.type} chatData={item} />
                                  </Box>
                                </SwipeableListItem>
                              </SwipeableList>
                            </Box>
                          }
                        </Box>
                      })
                    }
                    {
                      ((loadingAllChats && chatType === ChatConstants.chatType.ALL_MESSAGES) || (loadingBlockedChats && chatType === ChatConstants.chatType.BLOCKED) || (loadingArchivedChats && chatType === ChatConstants.chatType.ARCHIVED)) &&
                      <Box className='flexRow justifyContentCenter'>
                        <CircularProgress />
                      </Box>
                    }
                  </>

                )}


                {chatListAllData?.data?.data.length === 0 &&
                  <Box sx={{
                    margin: '0px 24px',
                    textAlign: 'center'
                  }}>
                    <span>{t(translations.CHAT.NO_CHATS)}</span>
                  </Box>
                }

                <DeleteConfirm open={confirmPopup} setOpen={setConfirmPopup} deleteFunc={() => handleBlock(selectedChat)} confirmBtn={t(translations.ads.CONFIRM)} message={getWarningMessage(selectedChat)} />
              </Box>
            </>}
        </Box>
      </ChatWrapper >
    </>
  )
}

export default ChatList;