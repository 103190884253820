import { Box } from '@mui/material'
import { SVGPlayBtn } from '../Assets/NavigationHeaderSvg'
import { Link } from 'react-router-dom'
import { routes } from 'routes/routes'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { getSubsidiaryStored, vehicles } from 'config/variables'

const MyCartoBike = () => {

  const isSub = getSubsidiaryStored();

  const { t } = useTranslation();

  return (
    <>
      <Box className='sub-menu-container'>
        <Box sx={{
          borderBottom: '1px solid #efefef',
          '.myCar-text': { margin: '10px 0', fontSize: '15px', color: '#666', fontWeight: 400 },
          '.header': { fontSize: '21px', color: '#000', fontWeight: 500 }
        }}>
          <span className='header'>{t(translations.NAVBAR.MY_CARTOBIKE)}</span>
          <Box className='myCar-text'>{t(translations.NAVBAR.EXPERIENCE_WITH_CTB)}</Box>
        </Box>
        <Box className='auction-type'
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            letterSpacing: '0.36px'
          }}
        >
          <Link to={`${routes.vehicleRoutes.myAds}/${vehicles.car}${routes.vehicleRoutes.classic}`}>
            <span className='pointer flexRow alignItemsCenter'><SVGPlayBtn style={{ marginRight: '5px' }} /> {t(translations.NAVBAR.MY_ADS)} </span>
          </Link>
          <Link to={`${routes.vehicleRoutes.myAds}/${vehicles.car}${routes.vehicleRoutes.park}`}>
            <span className='pointer flexRow alignItemsCenter'><SVGPlayBtn style={{ marginRight: '5px' }} />{t(translations.NAVBAR.MY_ORDERS)}</span>
          </Link>
          {isSub &&
            <>
              <Link to={`${routes.vehicleRoutes.myAds}/${vehicles.car}${routes.vehicleRoutes.auction}/live`}>
                <span className='pointer flexRow alignItemsCenter'><SVGPlayBtn style={{ marginRight: '5px' }} />{t(translations.NAVBAR.MY_AUCTION)}</span>
              </Link>
              <Link to={`${routes.vehicleRoutes.myAds}/${vehicles.car}${routes.vehicleRoutes.hotDeals}`}>
                <span className='pointer flexRow alignItemsCenter'><SVGPlayBtn style={{ marginRight: '5px' }} /> {t(translations.HOT_DEALS.MY_HOT_DEALS)} </span>
              </Link>
            </>
          }
          <Link to={`${routes.vehicleRoutes.auctionPlace}${routes.vehicleRoutes.auctionPlaceInprogress}`}>
            <span className='pointer flexRow alignItemsCenter'><SVGPlayBtn style={{ marginRight: '5px' }} /> {t(translations.webTexts.AUCTION_PLACE)} </span>
          </Link>
        </Box>
      </Box></>
  )
}

export default MyCartoBike