import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const FieldWrapper = styled.div`
overflow-y:inherit;

.custom-dropdown {
  ul{
    max-height: 210px;
    overflow-y: auto;
    .filterDropDownOption .optionValue{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

}

/*-----------------------------------Overwriting MUI default Style----------------------------------- */
.autocompleteInputField div{
  border-radius: 0px;
}
.autocompleteInputField input{
  font-size: 18px;
  cursor: pointer;
}
.autocompleteInputField input::placeholder {
  font-size: 19px;
  color: black;
  font-weight: 400;
  letter-spacing: 1px;
  opacity: 100%;
}
.autocompleteInputField div{
  border-radius: 0px;
}
.autocompleteInputField .MuiOutlinedInput-notchedOutline{
  border: 1px solid ${colorNew.colorGrayButton} !important;
}
.autocompleteInputField label{
  left: -2px;
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: #666;
}
.autocompleteInputField .MuiAutocomplete-popupIndicator svg path {
  stroke: #666
}
/*-----------------------------------Overwriting MUI default Style Ends----------------------------------- */

span{
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  color: #666;
}
.field{
  position : relative;
  border: 1px solid ${colorNew.colorGrayButton};
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  min-height: 56px;
  width: 100%;
}
.fieldHeader{
  padding : 0 5px;
  position : absolute;
  top: -10px;
  left: 10px;
  background : white;
  color: var(--text-deep-gray, #666);
}
.inputBox span{
  width : 80%
}
.textMultipleFieldContainer{
  padding : 0 16px;
  display : flex;
  align-items : center;
  justify-content : space-between;
}
.searchMultipleFieldContainer{
  display : flex;
  align-items : center;
  justify-content : space-between;
}
.textMultipleFieldContainer span, .searchMultipleFieldContainer span{
  color: #000;
  font-size : 15px;
}
.textMultipleFieldContainer input{
  min-height : 50px;
  width : 80%;
  border: none;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  color: #000;
  font-size : 15px;
  outline: none;
}
.searchMultipleFieldContainer{
  width : 80%;
}
.searchMultipleFieldContainer div span{
  min-height : 50px;
  width : 40%;
  border: none;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  color: #000;
  font-size : 15px;
}
.multipleDropdownMainContainer{
  width : 100%;
}
.optionsMainContainer{
  opacity : 0;
  position : absolute;
  left :-1px;
  width : 100.6%;
  background : white;
  z-index: 3;
  border: 1px solid #CCC;
  border-top : none;
  padding: 0 !important;
}
.optionContainer{
  width : 100%;
  display : flex;
  justify-content : space-between;
  align-items : center;
  cursor : pointer;
  padding: 0px 0px 0px 7px;
  min-height:40px;
  height:max-content; 
}
.optionListContainer{
  padding :8px 16px;
  gap: 5px;
  overflow-y: auto;
}
.nameContainer{
  min-height : 40px;
  display:flex;
  align-items:center;
}
.optionContainer:hover{
  background-color : aliceblue;
}
.selectDiv{
  min-height : 17px;
  min-width : 17px;
  border: 1px solid black;
  border-radius : 50%;
}
.selected{
  background : #617AF7;
  border: 1px solid #617AF7;
 }
 .selectedValue{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black !important;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.63px;
 }

.OptionsContainerMultipleField{
  position : absolute;
  top: 45px;
  left :-1px;
  width : 100.6%;
  background : white;
  z-index: 2;
  border: 1px solid #CCC;
  border-top : none;
  max-height: 210px;
}
.OptionsSubContainer{
  max-height: 300px;
  overflow-y: scroll;
}
.fieldText{
  color: #666 !important;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.36px;
  margin-left : 5px;
}
.optionIcon{
  max-width :50px;
  max-height :40px;
  margin-right : 10px;
}
.optionColor{
  width :20px;
  height :20px;
  margin-right : 10px;
  border : 1px solid #e5e5e5
}
.continentName{
  color: var(--Shade-1, #384386);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.455px;
}
.optionValue{
  color: var(--text-deep-gray, #666);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.63px;
}
.equipment:hover{
  background-color: aliceblue;
}
.optionSearchContainer{
  padding: 0 16px;
  margin-top: 5px;
}
.optionSearchContainer input{
  height: 40px;
  width: 100%;
  border: 1px solid #E5E5E5;
  color: var(--text-deep-gray,#666);
    font-size: 16px;
    font-weight: 400;
}
.noResultFound{
  padding: 16px;
}
@media (max-width: 768px) {
  .OptionsSubContainer{
    max-height: 700px;
    height: calc(70vh - 51px);
    max-height: unset;
  }
  .optionsMainContainer{
    opacity : 1;
    position : static;
    overflow-y: auto;
    height: 70vh;
    border: none;
  }
  .optionListContainer{
    overflow-y: unset;
  }
  .optionSearchContainer{
    margin: 0;
    top: 0;
    position: sticky;
  }
  .optionContainer:last-child {
    padding-bottom: 50px;
  }
  .OptionsSubContainer  .optionContainer:last-child {
    padding-bottom: 0;
  }
}
`