import React, { useEffect, useRef, useState } from 'react'
import { MyAdsWrapper } from '../../pages/MyAdsPage/Style/MyAdsWrapper'
import { Box, CircularProgress, Modal } from '@mui/material'
import { SVGCross, SvgMobileLeftArrow, SvgSell, SvgfluentIcon, SvgmemSearch } from 'app/components/svgicons/svgNew'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { getAllCustomersRequest } from 'store/actions/vehicle-actions'
import NotFound from 'app/components/RepeatComponents/NoFound'
import UserPlaceholer from 'app/assets/Images/userplaceholder.png'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { selectCustomer } from 'store/actions/myAds-actions'
import { useHistory, useLocation } from 'react-router-dom'
import { routes } from 'routes/routes'
import AccountVerifiedIcon from 'app/Component2/GlobalComponent/AccountVerifiedIcon '
import ToastMessageWithButton from '../ToastMessageWithButton'
import { getSubsidiaryStored } from 'config/variables'
import { Toaster } from 'services/Toaster'

const ChooseCustomer = ({ adID, type }) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const isSub = getSubsidiaryStored()
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    const scrollRef: any = useRef(null)

    const { allCustomers, allCustomerLastPage, loadingCustomers } = useSelector((state: RootStateOrAny) => state.vehicle)
    const { userData } = useSelector((state: any) => state.user);

    const [showModal, setShowModal] = useState<boolean>(false)

    const [page, setPage] = useState<number>(1)
    const [search, setSearch] = useState<string>('')

    const handleModal = () => {
        if (userData?.profile_is_verified === false && isSub === null) {
            Toaster.error(<ToastMessageWithButton type='error' buttonText={t(translations.ads.CLICK_HERE_TO_VERIFY)} message={t(translations.validationAndPopText.VERIFY_ID_STRING)} click={() => history.push(routes.userRoutes.idVerfication)} />, false)
            return;
        }
        setShowModal(prevState => !prevState)
        dispatch(getAllCustomersRequest({ keyword: '', page: 1 }));
    }

    const chooseCustomer = (user) => {
        dispatch(selectCustomer(user))
        setShowModal(prevState => !prevState)
        if (!location.pathname.includes(routes.userRoutes.createOrder)) {
            localStorage.setItem('myadsURL', location.pathname)
            localStorage.setItem('createOrderFor', JSON.stringify(user))
            history.push(`${routes.userRoutes.myVehicles}${routes.userRoutes.createOrder}/${adID}`)
        }
    }

    const handleSearch = (query) => {
        setSearch(query)
    }

    useEffect(() => {
        if (!showModal) {
            setPage(1)
            setSearch('')
        }
    }, [showModal])

    useEffect(() => {
        if (search !== '') {
            const delayDebounceFn = setTimeout(() => {
                dispatch(getAllCustomersRequest({ keyword: search, page: 1 }));
                return
            }, 500)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [search])


    const handleScroll = (e) => {
        if (loadingCustomers) {
            return
        }
        // bottom return boolean value and Math.abs to get absolute value 
        const bottom = (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) <= 1;
        if (bottom && page < allCustomerLastPage) {
            setPage(page + 1)
            let data = {
                page: page + 1
            }
            if (search && search !== '') {
                data['keyword'] = search
            }
            dispatch(getAllCustomersRequest(data));
            return;
        } else {
        }
    }

    return (
        <MyAdsWrapper >

            {type == 'changeCustomer' && <span className='switchCustomer pointer' onClick={handleModal}>{t(translations.webTexts.CHANGE)}</span>}

            {type == 'selectCustomer' && <Box className='myAdsOption myAdsOptionCreateOrder' onClick={handleModal}>
                <SvgSell /> <span>{t(translations.myAdscreen.CREATE_ORDER)}</span>
            </Box>}

            <Modal
                onClose={() => setShowModal(false)}
                open={showModal}>
                <MyAdsWrapper >
                    <Box className='usersModal'>

                        {/* Close modal Icon which apears in web view for modal */}
                        {(isLargeDevice || isExtraLargeDevice) && <SVGCross className='closeIcon' onClick={() => setShowModal(false)} />}

                        <Box className='customersContainer'>

                            <Box className='customerHeader flexRow justifyContentBetween alignItemsCenter'>

                                {/* Close modal Icon which apears in mobile view for modal */}
                                <SvgMobileLeftArrow onClick={() => setShowModal(false)} style={{ opacity: (isSmallDevice || isMediumDevice) ? '100%' : '0' }} />
                                <span>{t(translations.webTexts.SELECT_CUSTOMER)}</span>
                                <SvgMobileLeftArrow style={{ opacity: '0' }} />

                            </Box>

                            <Box className='inputFieldContainer'>
                                <SvgmemSearch />
                                <input type='text' placeholder={t(translations.webTexts.SEARCH_CUSTOMER)} value={search} onChange={(e) => handleSearch(e.target.value)} />
                            </Box>

                            <span className='suggestedMemberHeader'>{t(translations.webTexts.SUGESTION_MEMBER_CARTOBIKE)}</span>

                            <Box className='suggestedCustomers scrollBar' onScroll={(e) => handleScroll(e)} ref={scrollRef}>

                                {/* Customers list appears only when customers data count is more than 0 */}
                                {allCustomers && allCustomers.length > 0 && allCustomers.map((customer) => {
                                    return <Box className='flexRow customerDetails' onClick={() => chooseCustomer(customer)} key={customer.id}>
                                        <Box className='relative'>
                                            <img src={customer.image_url ? customer.image_url : UserPlaceholer} alt={customer.name} className='userAvatar' />
                                            {customer?.customer_limited_address?.flag_thumbnail && <img src={customer.customer_limited_address.flag_thumbnail} alt={customer.name} className='flagImg' />}
                                        </Box>
                                        <Box className='customerNameAndContact' sx={{ px: 2 }}>
                                            <Box component={'span'} className='name' sx={{ display: 'flex' }}>{customer.name} <AccountVerifiedIcon isVerified={customer.profile_is_verified} /></Box>
                                            <span className='contact'>{customer.phone}</span>
                                            <span className='contact'>{customer.email}</span>
                                        </Box>
                                    </Box>
                                })}

                                {/* Circular loader appears whenever new API call is requested */}
                                {loadingCustomers && <Box className='flexRow alignItemsCenter justifyContentCenter spinnerLoader'>
                                    <CircularProgress />
                                </Box>}

                                {/* Not found appears when loading is done and customer list has no data  */}
                                {(!allCustomers || allCustomers.length == 0) && !loadingCustomers &&
                                    <NotFound />
                                }

                            </Box>
                        </Box>
                    </Box>
                </MyAdsWrapper>
            </Modal>
        </MyAdsWrapper>
    )
}

export default ChooseCustomer