import { getUtcTime } from "app/Hooks/useCoundown";
import moment from "moment";
import { localstorageKey } from "./constants";
import { getProductTypeName, productTypes, vehicles } from "./variables";
import { routes } from "routes/routes";
export const envVariables = {
  apiUrl: `${process.env.REACT_APP_API_URL}/`,
  baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  stripePublicKey: `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`,
  googleApiKey: `${process.env.REACT_APP_GOOGLE_KEY}`,
  baseUrlHttps: `${process.env.REACT_APP_BASE_URL_HTTPS}`,
  storageUrl: `${process.env.REACT_APP_API_STORAGE}`,
  captchKey: `${process.env.REACT_APP_CAPTCHA_KEY}`,
  googleAnalyticKey: `${process.env.REACT_APP_GOOGLE_ANALYTIC_KEY}`,
};
export const RouteTypes = {
  public: 'public',
  private: 'private',
  professional: 'professional',
  auth: 'auth',
  professionPrivate: 'professionPrivateRouters'
}

export const vehicleTypeContant = {
  CAR_TYPE: 1,
  MOTORBIKE_TYPE: 3,
  BIKE_TYPE: 4,
  STEP_TYPE: 7,
}

export const vehicleTypeContantInverse = {
  1: 'car',
  3: 'motorcycles',
  4: 'bike',
  7: 'e-scooter'
}

export const sliderType = {
  partners: 1,
  banners: 2,
}

export const customerType = {
  dealer: 1,
  private: 2,
}

export const isAdFavourite = {
  "true": 1,
  "false": 0
}

export const VERIFICATION_STATUS = {
  NOT_APPLIED: 0,
  PENDING: 1,
  VERIFIED: 2
}

export const adsText = {
  classic: 1,
  auction: 2,
  park: 3
}

export const saleType = {
  sold: 1,
  reserve: 2,
}

export const adsTextAuction = {
  live: 1,
  action: 2,
  history: 3
}

export const vatRefundable = {
  true: 1,
  false: 0,
}

export const shopPageType = {
  classic: 'classic',
  auction: 'auction',
  hotDeals: 'hot-deals',
  about: 'about',
  contact: 'contact',
  service: 'service',
};

export const shopPageSettingType = {
  about: 1,
  service: 2
};

export const appConstant = {
  PASSPORT: 'passport',
  SELFIE_WITH_ID: 'selfie_with_id_document',
  NATIONAL_ID_FRONT: 'national_id_front',
  NATIONAL_ID_BACK: 'national_id_back',
};

export const validImageFormats = [
  'image/png',
  'image/gif',
  'image/jpeg',
  'image/jpg'
]


export const restrictedNames = [
  'CartoBike',
  'Car_to_bike',
  'car2bike',
  'Cartobike_',
  '_cartobike',
  '-cartobike',
  'cartobike-',
  'CartoBike2',
  'Carto-Bike',
  'Carto_Bike',
  'Car-tobike',
  'car_tobike',
  'Car2Bike',
  'Car-to-Bike',
  'Car_Bike',
  'CartwoBike',
  'Car_two_bike',
  'Cartwobike_',
  '_cartwobike',
  '-cartwobike',
  'CartwoBike2',
  'Cartwo-Bike',
  'Cartwo_Bike',
  'Car-two-Bike'
]

export const BOT_AGENTS = [
  'googlebot',
  'yahoo! slurp',
  'bingbot',
  'yandex',
  'baiduspider',
  'facebookexternalhit',
  'twitterbot',
  'rogerbot',
  'linkedinbot',
  'embedly',
  'quora link preview',
  'showyoubot',
  'outbrain',
  'pinterest/0.',
  'developers.google.com/+/web/snippet',
  'slackbot',
  'vkshare',
  'w3c_validator',
  'redditbot',
  'applebot',
  'whatsapp',
  'flipboard',
  'tumblr',
  'bitlybot',
  'skypeuripreview',
  'nuzzel',
  'discordbot',
  'google page speed',
  'qwantify',
  'pinterestbot',
  'bitrix link preview',
  'xing-contenttabreceiver',
  'chrome-lighthouse',
  'telegrambot',
  'google-inspectiontool'
]

export const IGNORE_EXTENSIONS = [
  '.js',
  '.css',
  '.xml',
  '.less',
  '.png',
  '.jpg',
  '.jpeg',
  '.gif',
  '.pdf',
  '.doc',
  '.txt',
  '.ico',
  '.rss',
  '.zip',
  '.mp3',
  '.rar',
  '.exe',
  '.wmv',
  '.doc',
  '.avi',
  '.ppt',
  '.mpg',
  '.mpeg',
  '.tif',
  '.wav',
  '.mov',
  '.psd',
  '.ai',
  '.xls',
  '.mp4',
  '.m4a',
  '.swf',
  '.dat',
  '.dmg',
  '.iso',
  '.flv',
  '.m4v',
  '.torrent',
  '.woff',
  '.ttf',
  '.svg',
  '.webmanifest'
];

export function hideCookiePopupForBots(array, element) {
  return array.some(e => element.indexOf(e) !== -1);
}

export function hideCookiePopupForExtensions(array, element) {
  return array.some(e => e === element);
}



export const auctionType = {
  startingSoon: 'start_soon',
  running: 'current'
}

export const hotDealsFilterType = {
  all_started_start_soon: 'all_started_start_soon',
  running: 'started',
  startingSoon: 'start_soon',
  finished: 'recently_finished',
  soldOutHotdeals: 'sold_cars'
}


export const getBase64 = (file) => {
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    return reader.result
  }
  reader.onerror = function (err) {
  }
}

export const getDiffTime = (time) => {
  var beginningTime = getUtcTime();
  var endTime = moment(time);
  return beginningTime.isBefore(endTime)
}

export const getURL = (url) => {
  return window.location.origin + '/' + localStorage.getItem(localstorageKey.i18nextLng) + url
}

export const imageIsValidURL = (urlString) => {
  var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
  return !!urlPattern.test(urlString);
}

export const getParamQueries = (query) => {
  const obj = query.slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})

  // Removes empty keys from query params
  Object.keys(obj).forEach(key => {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });

  return obj;
}

// Updating URL whenever any of the filter is applied
export const updateURL = (filterName, filterValue, history) => {

  const url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);
  // Add only if filter value is not empty (number / string ) or blank array (multilple selection fields)
  if (
    ((typeof filterValue == 'number' || typeof filterValue == 'string') && filterValue !== '' && filterValue !== null && filterValue !== undefined) ||
    (filterValue !== null && typeof filterValue == 'object' && filterValue.length > 0)
  ) {

    // Checks if filter is already present or not in URL (if yes replace it with new values)
    if (params.has(filterName)) {
      params.delete(filterName)
    }
    params.append(filterName, filterValue)
    if (params.has('page')) {
      params.delete('page')
      params.append('page', 1)
    }
  } else {
    // Removes query if filter value is blank
    params.delete(filterName)
  }
  // Updates URL string
  history.replace({ search: params.toString().replaceAll('%2C', ',') })
  window.scrollTo(0, 0)
}

export const getSimilarAdsUrl = (detail) => {
  let str = routes.vehicleRoutes.publicAds + '/' + getProductTypeName[detail.product_type_id]
  if (detail?.owner_vehicle) {
    if (detail.owner_vehicle?.model?.brand_car) {
      str += `/${detail?.owner_vehicle?.model?.brand_car?.slug}/${detail?.owner_vehicle?.model?.slug}`
    }
    if (detail.owner_vehicle?.vehicle_first_register_year) {
      str += `?registration_start=${detail?.owner_vehicle?.vehicle_first_register_year}`
    }
    else if (detail.owner_vehicle?.new_vehicle) {
      str += `?new_vehicle=1`
    }
  }
  return str
}

