import PublicLayout from "app/Component2/Layouts/PublicLayout";
import { useTranslation } from "react-i18next";
import { lan } from 'locales/multiPageTranslation/adsLan'
import { translations } from "locales/translations";
import { Box, CircularProgress } from "@mui/material";
import Header from "../../../components/ListingPageHeader";
import { PublicAdsWrapper } from "../style/PublicAdsWrapper";
import { urlParamInterface } from "config/interface";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getPublicAuctionAdsRequest, updatePublicAuctionAds } from "store/actions/public-actions";
import { getProductTypeId, getSubsidiaryStored, productTypes, trophy, vehicles } from "config/variables";
import PublicAd from "../components/ad/publicAd";
import { AdDetail } from "../interface/interface";
import AdSkeleton from "../components/skeleton/skeleton";
import { ContentLoader } from "app/components/ContantLoader/ContentLoader";
import { useDeviceType } from "app/Hooks/useMediaQuery";
import { PusherClient } from "websocket";
import { customerType, getParamQueries, updateURL } from "config/appConfig";
import { Toaster } from "services/Toaster";
import { createBidFailure, createBidReset, resetBidChange, updateOnBidChange } from "store/actions/vehicle-actions";
import NotFound from "app/components/RepeatComponents/NoFound";
import { SvgMobileLeftArrow } from "app/components/svgicons/svgNew";
import Filter from "app/components/Filters";
import AppliedFilters from "app/components/Filters/components/appliedFilters";
import { checkKey } from "app/components/Filters/filterConfig";
import { getUserSubsidiaryRequest } from "store/actions/company-actions";
import { channelName, pusherName } from "config/utils";
import { NotFoundPage } from "app/pages/NotFoundPage";

const Feature = (props: any) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const subsidiary = getSubsidiaryStored();
    const { vehicle, id, brand_id, model_id, country_id }: urlParamInterface = useParams();
    const scrollRef: any = useRef(null)

    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    const { publicAuctionAdsListLastPage, loadingPublicAds, publicAuctionAdsList, publicAuctionAdsListCurrentPage, publicAuctionAdDetail } = useSelector((state: RootStateOrAny) => state.publicReducer)
    const { loadingStoreChat } = useSelector((state: RootStateOrAny) => state.chat)
    const { bidNew, bidError, likeDislikeProcessing, bidUpdate } = useSelector((state: RootStateOrAny) => state.vehicle)
    const { userData } = useSelector((state: RootStateOrAny) => state.user);
    const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth)
    const { loading } = useSelector((state: RootStateOrAny) => state.vehicle)
    const { filters } = useSelector((state: RootStateOrAny) => state.componentReducers)

    const [search, setSearch] = useState<string>('')
    const [searched, _searched] = useState<boolean>(false)
    const [sort, setSort] = useState<string>('');

    const [scrollFlag, _scrollFlag] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1)
    const [ads, setAds] = useState<Array<AdDetail>>([])

    const [showScroll, _showScroll] = useState<boolean>(false)

    const [subscribed, _subscribed] = useState<boolean>(false)
    const PusherClientInstance = PusherClient()

    const channelAc = loggedIn ? PusherClientInstance?.channel(channelName(subsidiary)) : null;

    const isFilterAvailable = localStorage.getItem('deleteFilterData')
    const [requestedPayload, _requestedPayload] = useState<object>({})

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, true)

        return () => {
            window.removeEventListener("scroll", handleScroll, true)
        }
    }, [scrollFlag, page, publicAuctionAdsListCurrentPage, loadingPublicAds])

    // Reseting last API call's payload on account switch
    useEffect(() => {
        _requestedPayload({})
    }, [subsidiary])

    useEffect(() => {
        // Scrolling to the record from user navigated to ads page
        if (localStorage.getItem('adsId') && (localStorage.getItem('prevPath') == location.pathname || localStorage.getItem('prevPath') == (location.pathname + location.search))) {
            let id = localStorage.getItem('adsId')
            let element = document.getElementById(id)
            if (element) {
                element.scrollIntoView({ block: "start" })
                setTimeout(() => {
                    localStorage.removeItem('adsId')
                    localStorage.removeItem('prevPath')
                }, 200)
                return;
            }
        }
    }, [ads])


    const productType = useMemo(() => getProductTypeId(vehicle), [vehicle])

    // Removing Ads data from localstorage if List data has not been loaded yet
    useEffect(() => {
        if (publicAuctionAdsList && publicAuctionAdsList.length == 0) {
            localStorage.removeItem('prevPath')
            localStorage.removeItem('adsId')
        }
    }, [])

    useEffect(() => {
        if ((location.search != '' || brand_id || model_id) && localStorage.getItem('prevPath') != (location.pathname + location.search)) {
            if (loading || ((brand_id || model_id || country_id) && !isFilterAvailable)) {
                return
            }
            setPage(1)
            let payload = getParamQueries(location.search)
            payload['page'] = 1
            payload['auction_id'] = id
            payload['product_type_id'] = productType
            payload['sort'] = sort
            if (brand_id) {
                const filterValue = checkKey(brand_id, productType, 'brand')
                const key = filterValue?.name ?? 'brand_slug'
                payload[key] = filterValue?.slug ?? brand_id
            }
            if (model_id) {
                const filterValue = checkKey(model_id, productType, 'model')
                const models = JSON.parse(localStorage.getItem('modelList') || '[]')
                if (filterValue?.name != 'country_slug' && models && models.length == 0) {
                    return
                }
                const key = filterValue?.name ?? 'model_slug'
                payload[key] = filterValue?.slug ?? model_id
            }
            // requested payload holds last API calls payload
            // In case of multiple API requests , it compares previous with latest payload
            // if it is same it stops API request 
            if (JSON.stringify(payload) == JSON.stringify(requestedPayload)) {
                return
            }
            dispatch(getPublicAuctionAdsRequest(payload))
            _requestedPayload(payload)
            if (scrollRef && scrollRef.current) {
                scrollRef.current.scrollTo(0, 0);
            }
        }
    }, [location.search, sort, productType, brand_id, model_id, subsidiary, loading, requestedPayload])

    useEffect(() => {
        if ((location.search == '' && !brand_id && !model_id) && localStorage.getItem('prevPath') != location.pathname) {
            setPage(1)
            const payload = {
                auction_id: id,
                page: 1,
                product_type_id: productType,
                keyword: search ?? '',
                sort: sort
            }
            dispatch(getPublicAuctionAdsRequest(payload))
            _requestedPayload(payload)
            if (scrollRef && scrollRef.current) {
                scrollRef.current.scrollTo(0, 0);
            }
        }
    }, [location.search, sort, productType, brand_id, model_id, subsidiary])


    useEffect(() => {
        setAds(publicAuctionAdsList)
    }, [publicAuctionAdsList])

    // update local api current page with global state of current page
    useEffect(() => {
        setPage(publicAuctionAdsListCurrentPage)
    }, [publicAuctionAdsListCurrentPage])

    //Function request for next page data when scrolled to bottom
    const handleScroll = function (e) {
        // called when the window is scrolled.
        if (loadingPublicAds) {
            return
        }
        setScrollTopButton(window.innerHeight + window.scrollY)
        const scrolledpercent = ((window.innerHeight + window.scrollY) / document.body.scrollHeight) * 100
        const requestCall = scrolledpercent >= 70;
        if (requestCall && scrollFlag && page < publicAuctionAdsListLastPage) {
            let payload = {}
            if (location.search != '') {
                payload = getParamQueries(location.search)
            }
            setPage((page) => page + 1)
            payload['auction_id'] = id
            payload['page'] = page + 1
            payload['product_type_id'] = productType
            payload['keyword'] = search
            payload['sort'] = sort
            if (brand_id) {
                const filterValue = checkKey(brand_id, productType, 'brand')
                const key = filterValue?.name ?? 'brand_slug'
                payload[key] = filterValue?.slug ?? brand_id
            }
            if (model_id) {
                const filterValue = checkKey(model_id, productType, 'model')
                const key = filterValue?.name ?? 'model_slug'
                payload[key] = filterValue?.slug ?? model_id
            }
            dispatch(getPublicAuctionAdsRequest(payload))
            _scrollFlag(false)
            return;
        } else {
            _scrollFlag(true)
        }
    }


    const setScrollTopButton = (scrollPosition) => {
        if (scrollPosition > 1000) {
            _showScroll(true)
            return
        }
        _showScroll(false)
    }

    // Updating current price of ads if any of the user places bid on ad in realtime via socket
    const listenMessages = async () => {
        await channelAc?.bind(pusherName(subsidiary), (data: any) => {
            _subscribed(true)
            if (data) {
                dispatch(updateOnBidChange({ data }))

            }
        });
    }

    // Getting redux value of new bid and updating local values
    useEffect(() => {
        if (bidUpdate && Object.keys(bidUpdate).length > 0) {
            let oldAds = [...publicAuctionAdsList]
            let existence = oldAds.findIndex((ad) => ad.id == bidUpdate.data.id)
            if (existence != -1) {
                oldAds[existence].end_auction = bidUpdate.data.end_auction
                if (oldAds[existence].best_manual_bid_relation == null) {
                    oldAds[existence].best_manual_bid_relation = { 'amount': bidUpdate.data.new_price }
                }
                if (oldAds[existence].best_manual_bid_relation) {
                    oldAds[existence].best_manual_bid_relation.amount = bidUpdate.data.new_price
                }
                oldAds[existence].filled_percent = bidUpdate.data.filled_percent
                if (loggedIn && subsidiary && bidUpdate.data.customer_type == customerType.dealer && bidUpdate.data.winner_id == subsidiary) {
                    oldAds[existence].trophy = trophy.won
                }
                else if (loggedIn && !subsidiary && bidUpdate.data.customer_type == customerType.private && (bidUpdate.data.winner_id == userData.id || bidUpdate.data.winner_id == userData.id)) {
                    oldAds[existence].trophy = trophy.won
                } else {
                    oldAds[existence].trophy = trophy.lose
                }
                setAds(oldAds)
                dispatch(resetBidChange())
            }
        }
    }, [bidUpdate, publicAuctionAdsList])


    // updating old user's bid data in list with new one
    useEffect(() => {
        if (bidNew && Object.keys(bidNew).length > 0) {
            let oldAds = [...publicAuctionAdsList]
            let existence = oldAds.findIndex((ad) => ad.id == bidNew.new_bid.ad_vehicle_id)
            if (existence != -1) {
                oldAds[existence].best_bid = bidNew.bestBid
                if (oldAds[existence].best_manual_bid_relation == null) {
                    oldAds[existence].best_manual_bid_relation = { 'amount': bidNew?.new_bid?.amount ?? 0 }
                }
                if (oldAds[existence].best_manual_bid_relation) {
                    oldAds[existence].best_manual_bid_relation.amount = bidNew?.new_bid?.amount ?? 0
                }
                if (bidNew.end_auction && bidNew.end_auction.item) {
                    oldAds[existence].end_auction = bidNew.end_auction.item
                }
                if (bidNew.is_winner) {
                    oldAds[existence].trophy = trophy.won
                }
                setAds(oldAds)
                dispatch(createBidReset())
            }
        }
    }, [bidNew, publicAuctionAdsList])

    useEffect(() => {
        if (bidError && Object.keys(bidError).length > 0) {
            if (bidError.data) {
                Toaster.error(Object.values(bidError.data).toString())
                dispatch(createBidFailure({}))
                return
            }
            Toaster.error(bidError.message)
            dispatch(createBidFailure({}))
        }
    }, [bidError])


    useEffect(() => {
        if (ads && ads.length > 0 && !subscribed && loggedIn) {
            listenMessages()
        }
    }, [ads])

    const removeAdsAuction = (id) => {
        dispatch(updatePublicAuctionAds({ id: id }))
    }


    // -------------------------------------------------Works for tab view-------------------------------------------------
    const handleChange = (e) => {
        // DO NOT INCLUDE '%' IN SEARCH STRING, it will create problem during encoding and decoding the search string in url 
        if (e.target.value.includes('%')) return;
        setSearch(e.target.value)
        _searched(true)
    }

    useEffect(() => {
        if (isMediumDevice) {
            // If user hasn't changed in search field anything we won't update URL
            if (!searched) {
                return
            }
            const debounce = setTimeout(() => {
                updateURL('keyword', search, history)
            }, 500)
            return () => clearTimeout(debounce)
        }
    }, [search])


    useEffect(() => {
        if (isMediumDevice) {
            let payload = getParamQueries(location.search)
            if (payload.keyword) {
                let str = decodeURI(payload.keyword)
                if (str.includes('+')) {
                    _searched(true)
                    setSearch(str.replaceAll("+", ' '))
                    return
                }
                _searched(true)
                setSearch(str)
                return

            }
            setSearch('')
        }
    }, [location.search])
    // -------------------------------------------------Works for tab view Ends-------------------------------------------------

    if (publicAuctionAdDetail && Object.keys(publicAuctionAdDetail).length > 0) {
        if (publicAuctionAdDetail.auction_event === null) {
            return <NotFoundPage />
        }
    }

    return (
        <PublicLayout
            title={`${t(translations.summaryVehicleScreen.AUCTIONS_ADS)} - Cartobike`}
            meta={t(translations.summaryVehicleScreen.AUCTIONS_ADS)}
            description={`Auction ads ${t(...lan.toSaleIn())} Belgium ${t(...lan.toSaleIn())} ${t(...lan.metaDescriptionNext1())}`}
            image={process.env.REACT_APP_BASE_URL + '/content.jpg'}
            type="website"
            hideFooter={true}
            hideHeader={isSmallDevice || isMediumDevice ? true : false}
            showNavbar={true}

        >

            {/* Loader appears in web and tab view */}
            {!isSmallDevice && (loadingStoreChat || loadingPublicAds || likeDislikeProcessing) ? <ContentLoader /> : null}

            {/* Loader appears in mobile view when filters are not open */}
            {!filters && isSmallDevice && (loadingStoreChat || loadingPublicAds || likeDislikeProcessing) ? <ContentLoader /> : null}

            <PublicAdsWrapper>
                <Box className='homeMainContainer'>

                    {(isSmallDevice || isMediumDevice) && <Header setSearch={setSearch} setSort={setSort} sort={sort} search={search} isAuction={true} auctionName={publicAuctionAdDetail?.auction_event?.title ?? ''} searched={searched} _searched={_searched} />}

                    <Box className="flexRow">

                        <Box className="filterBox" sx={{ top: isMediumDevice ? Object.keys(getParamQueries(location.search)).length > 0 ? "120px" : "68px" : "" }}>
                            <Filter handleBackFromFilters={() => { return }} keyword={search} handleChangeSearch={handleChange} />
                        </Box>

                        <Box className="listContainer" >
                            {(isLargeDevice || isExtraLargeDevice) && <> <Header setSearch={setSearch} setSort={setSort} sort={sort} search={search} isAuction={true} auctionName={publicAuctionAdDetail?.auction_event?.title ?? ''} searched={searched} _searched={_searched} />
                            </>}


                            <Box className="adsListing" sx={{ p: { xs: 0, sm: 2 } }}>

                                {ads.length > 0 && ads.map((ad) => {
                                    return <PublicAd adDetail={ad} key={ad.id} removeAdsAuction={removeAdsAuction} />
                                })}

                                {ads.length == 0 && loadingPublicAds && [1, 2, 3, 4, 5, 6].map((item) => {
                                    return <AdSkeleton key={item} />
                                })}

                                {(publicAuctionAdsList.length == 0 && !loadingPublicAds) && <NotFound />}

                                {publicAuctionAdsList.length > 0 && loadingPublicAds && <Box className="adContainerLoader">
                                    <CircularProgress />
                                </Box>}
                            </Box>

                            {isSmallDevice && showScroll && <Box className="scrollTop" onClick={() => window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            })}>
                                <SvgMobileLeftArrow />
                            </Box>}
                        </Box>
                    </Box>
                </Box>

            </PublicAdsWrapper>

        </PublicLayout >
    )
}

export default Feature;