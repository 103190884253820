import { Box, Typography } from '@mui/material'
import CreditSection from './CreditSection'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCreditPlansRequest } from 'store/actions/user-actions'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes/routes'
import CreditSkeleton from './CreditSkeleton'
import { localstorageKey } from 'config/constants'
import { hasPermissions, PERMISSIONS } from 'config/permission'
import { Toaster } from 'services/Toaster'

const CreditPricing = () => {
    //Redux states
    const { allCreditPlans, loading } = useSelector((state: RootStateOrAny) => state.user)

    const permissionList = useSelector((state: any) => state.user.permissionData)
    const hasAddPermission = hasPermissions(permissionList, PERMISSIONS.FEATURES.FINANCE, PERMISSIONS.ACTIONS.ADD)
    const hasUpdatePermission = hasPermissions(permissionList, PERMISSIONS.FEATURES.FINANCE, PERMISSIONS.ACTIONS.UPDATE)

    //hooks
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const history = useHistory();


    useEffect(() => {
        //API call for getting credit plan list
        localStorage.removeItem(localstorageKey.purchaseCredit)
        dispatch(getCreditPlansRequest());
    }, [])


    // function to handle buy credits
    const handleBuyNow = (Id, stripeProductId, stripePriceId) => {
        // redirect on credit payment screen with price detail
        //here sp_ID (stripe_product_id) and pid is price_id of credit plan
        // st_pr_id (stripe price id)
        // btoa() is used here for encode ids
        if (!hasAddPermission && !hasUpdatePermission) {
            // if users do not have permissions for making payments, then display error
            Toaster.error(t(translations.CTB_CREDIT.DONT_HAVE_PERMISSION_TO_THIS_ACTION))
            return
        }
        window.scrollTo(0, 0)
        localStorage.setItem(localstorageKey.purchaseCredit, history.location.pathname)
        history.push({ pathname: routes.userRoutes.creditsPayment, search: "?sp_Id=" + btoa(stripeProductId) + '&pid=' + btoa(Id) + '&st_pr_id=' + btoa(stripePriceId) })
    }

    return (
        <>
            <Box className='pricing-container'>
                <Typography component={'h1'} className='creditPrice-heading' > {t(translations.HOT_DEALS.CREDIT_PRICE)} </Typography>
                <Box className='creds scrollOnHover scrollBar'>
                    {!loading && Object.keys(allCreditPlans).length > 0 ?
                        allCreditPlans.map((credits, index) => {
                            return <CreditSection key={index + 'creditplan'} credits={credits.prices[0]} stripeProductId={credits.stripe_product_id} handleBuyNow={handleBuyNow} />
                        }
                        ) :
                        Array(4).fill(null).map((item, index) => {
                            return <CreditSkeleton key={`${index}skeleton`} />
                        })

                    }
                </Box>
            </ Box>
        </>
    )
}

export default CreditPricing