import { Box } from '@mui/material'
import { SvgInstagramIconDark, SvgSiteLogoBW, SvgSnapIcondark, SvgYoutubeIconDark, SvgfacebookIconDark, SvgticktokIconDark, SvgtwitterIconDark } from 'app/components/svgicons/svgNew';
import { handleResetOnPageChange } from 'config/utils';
import { getSubsidiaryStored, vehicles } from 'config/variables';
import { translations } from 'locales/translations';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { routes } from 'routes/routes';

const FooterMenu = () => {

  const { t } = useTranslation();
  const isSub = getSubsidiaryStored();

  const dispatch = useDispatch()
  const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth);
  const { vehicle } = useParams()
  const { i18n } = useTranslation()


  return (
    <Box className="flexColumn alignItemsCenter" sx={{ pt: 2, mt: 4 }}>
      <Box className='flexRow footer-justify footerMenu flexWrap' >
        <Box className='flexColumn footerLogoContainer' sx={{ mb: 4 }}>
          <SvgSiteLogoBW style={{ marginBottom: '5px' }} />
          <span>{t(translations.NAVBAR.MY_CARTOBIKE_TAGLINE)}</span>
        </Box>
        <Box className='flexRow flexWrap justifyContentAround footerList' sx={{ gap: '10px' }}>
          <Box className='footerListContainer' sx={{ mb: 4 }}>
            <span className='listHeader'>{t(translations.formText.AUCTIONS)}</span>
            <ul>
              <li>
                <Link to={`${routes.vehicleRoutes.auction}/${vehicles.car}`}>
                  <span>
                    {t(translations.NAVBAR.AUCTION_CAR)}
                  </span>
                </Link>
              </li>
              <li>
                <Link to={`${routes.vehicleRoutes.auction}/${vehicles.motorcycles}`}>
                  <span>
                    {t(translations.NAVBAR.AUCTION_MOTORCYCLES)}
                  </span>
                </Link>
              </li>
              <li >
                <Link to={`${routes.vehicleRoutes.auction}/${vehicles.bike}`}>
                  <span>
                    {t(translations.NAVBAR.AUCTION_BIKE)}
                  </span>
                </Link>
              </li>
              <li >
                <Link to={`${routes.vehicleRoutes.auction}/${vehicles.step}`}>
                  <span>
                    {t(translations.NAVBAR.AUCTION_STEP)}
                  </span>
                </Link>
              </li>
            </ul>
          </Box>
          <Box className='footerListContainer' sx={{ mb: 4 }}>
            <span className='listHeader'>{t(translations.NAVBAR.VEHICLE_ADS)}</span>
            <ul>
              <li >
                <Link to={{
                  pathname: `${routes.vehicleRoutes.publicAds}/${vehicles.car}`,
                  state: { redirect: true }
                }}>
                  <span onClick={() => handleResetOnPageChange(dispatch, vehicle, vehicles.car)} >
                    {t(translations.NAVBAR.CARS)}
                  </span>
                </Link>
              </li>
              <li >
                <Link to={{
                  pathname: `${routes.vehicleRoutes.publicAds}/${vehicles.motorcycles}`,
                  state: { redirect: true }
                }}>
                  <span onClick={() => handleResetOnPageChange(dispatch, vehicle, vehicles.motorcycles)} >
                    {t(translations.NAVBAR.MOTORCYCLES)}
                  </span>
                </Link>
              </li>
              <li>
                <Link to={{
                  pathname: `${routes.vehicleRoutes.publicAds}/${vehicles.bike}`,
                  state: { redirect: true }
                }}>
                  <span onClick={() => handleResetOnPageChange(dispatch, vehicle, vehicles.car)} >
                    {t(translations.NAVBAR.BIKES)}
                  </span>
                </Link>
              </li>
              <li>
                <Link to={{
                  pathname: `${routes.vehicleRoutes.publicAds}/${vehicles.step}`,
                  state: { redirect: true }
                }}>
                  <span onClick={() => handleResetOnPageChange(dispatch, vehicle, vehicles.car)} >
                    {t(translations.NAVBAR.SCOOTERS)}
                  </span>
                </Link>
              </li>
            </ul>
          </Box>
          <Box className='footerListContainer' sx={{ mb: 4 }}>
            <span className='listHeader'>{t(translations.HOT_DEALS.HOT_DEALS)}</span>
            <ul>
              <li>
                <Link to={`${routes.publicRoutes.hotDeals}?dealtype=started`}>
                  <span>
                    {t(translations.HOT_DEALS.TODAYS_DEALS)}
                  </span>
                </Link>
              </li>
              <li>
                <Link to={`${routes.publicRoutes.hotDeals}?dealtype=start_soon`}>
                  <span>
                    {t(translations.HOT_DEALS.START_SOON_DEALS)}
                  </span>
                </Link>
              </li>
            </ul>
          </Box>

          {/* User profile link appear only when user has looged in. */}
          {loggedIn &&
            <Box className='footerListContainer' sx={{ mb: 4 }}>
              <span className='listHeader'>{t(translations.NAVBAR.MY_CARTOBIKE)}</span>
              <ul>
                <li>
                  <Link to={routes.userRoutes.myProfile}>
                    <span>
                      {t(translations.NAVBAR.DASHBOARD)}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to={routes.vehicleRoutes.publicAds + '/' + vehicles.car}>
                    <span>
                      {t(translations.NAVBAR.MY_ORDERS)}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to={routes.vehicleRoutes.myAds + '/' + vehicles.car + routes.vehicleRoutes.classic}>
                    <span>
                      {t(translations.NAVBAR.MY_ADS)}
                    </span>
                  </Link>
                </li>
                {isSub && <li>
                  <Link to={`${routes.vehicleRoutes.myAds}/${vehicles.car}${routes.vehicleRoutes.hotDeals}`}>
                    <span>
                      {t(translations.HOT_DEALS.MY_HOT_DEALS)}
                    </span>
                  </Link>
                </li>
                }
                <li>
                  <Link to={routes.vehicleRoutes.auctionPlace + routes.vehicleRoutes.auctionPlaceInprogress}>
                    <span>
                      {t(translations.webTexts.AUCTION_PLACE)}
                    </span>
                  </Link>
                </li>
              </ul>
            </Box>
          }

          <Box className='footerListContainer' sx={{ mb: 4 }}>
            <span className='listHeader'>{t(translations.FOOTER_MENU.HELP_AND_SUPPORT)}</span>
            <ul>
              <li>
                <Link to={routes.publicRoutes.contactUs} onClick={() => window.scrollTo(0, 0)}>
                  <span>
                    {t(translations.FOOTER_MENU.CONTACT_SUPPORT)}
                  </span>
                </Link>
              </li>
              <li >
                <Link to={routes.publicRoutes.faq}>
                  <span>
                    {t(translations.FOOTER_MENU.FAQ)}
                  </span>
                </Link>
              </li>
              <li>
                <Link to={routes.publicRoutes.become_partner}>
                  <span>
                    {t(translations.NAVBAR.BECOME_A_PARTNER)}
                  </span>
                </Link>
              </li>
              <li>
                <a href={`${process.env.REACT_APP_FORUM_URL_HTTPS}/${i18n.language}`} target='_blank' title='Cartobike Forum'>
                  <span>
                    {t(translations.NAVBAR.FORUM)}
                  </span>
                </a>
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
      <Box className='footer-social flexColumn'>
        <p className='listHeader'>{t(translations.NAVBAR.FOLLOW_US_ON)}</p>
        <Box className='flexRow flexWrap'>
          <Box className='socialIcons'>
            <a href={process.env.REACT_APP_FACEBOOK_SOCIAL_LINK} target='_blank'>
              <SvgfacebookIconDark />
            </a>
          </Box>
          <Box className='socialIcons'>
            <a href={process.env.REACT_APP_INSTA_SOCIAL_LINK} target='_blank'  >
              <SvgInstagramIconDark />
            </a>
          </Box>
          <Box className='socialIcons'>
            <a href={process.env.REACT_APP_SNAPCHAT_SOCIAL_LINK} target='_blank' >
              <SvgSnapIcondark />
            </a>
          </Box>
          <Box className='socialIcons'>
            <a href={process.env.REACT_APP_TIK_TOK_SOCIAL_LINK} target='_blank'>
              <SvgticktokIconDark />
            </a>
          </Box>
          <Box className='socialIcons'>
            <a href={process.env.REACT_APP_TWITTER_SOCIAL_LINK} target='_blank'>
              <SvgtwitterIconDark />
            </a>
          </Box>
          <Box className='socialIcons'>
            <a href={process.env.REACT_APP_YOUTUBE_SOCIAL_LINK} target='_blank' >
              <SvgYoutubeIconDark />
            </a>
          </Box>
        </Box>
      </Box>
    </Box >
  )
}

export default FooterMenu