import { Autocomplete, Box, Drawer, Radio, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { FieldWrapper } from './style'
import { SvgMobileLeftArrow, SvgchevronIcon } from '../svgicons/svgNew'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { lan } from 'locales/multiPageTranslation/adsLan'

// Code for list of dropdown elements
const ListElement = ({ showIcon, list, isSelected, handleChange, title }) => {

    const { t } = useTranslation()
    const [isSmallDevice] = useDeviceType()

    const [optionSearch, _optionSearch] = useState<string>('')
    const [optionsList, _optionsList] = useState<Array<Object>>(() => list ?? [])

    useEffect(() => {
        _optionsList(list)
    }, [list])

    useEffect(() => {
        if (optionSearch && optionSearch !== '') {
            _optionsList(list.filter((option) => option.slug.toLowerCase().includes(optionSearch.toLowerCase())))
            return
        }
        _optionsList(list)
    }, [optionSearch])

    return (
        <Box className='optionsMainContainer'>
            {/* Search Field */}
            <Box className='optionSearchContainer'>
                <input type='text' placeholder='Search' value={optionSearch} onChange={(e) => _optionSearch(e.target.value)} />
            </Box>

            <Box className='flexColumn optionListContainer scrollBar' sx={{
                maxHeight: !isSmallDevice ? '300px' : 'unset'
            }}>

                {/* List of Options */}
                {
                    (optionsList && optionsList.length > 0) &&
                    optionsList.map((option) => {
                        return <Box key={title + option.id} className='optionContainer' sx={{ backgroundColor: isSelected(option.slug) ? 'aliceblue' : '' }} onClick={() => { handleChange(option) }}>
                            <Box className='nameContainer'>
                                {showIcon && option.media && option.media.length > 0 && <img src={option.media[0].thumb_url} alt='icon' className='optionIcon' />}
                                <span className='optionValue'>{option.name}</span>
                            </Box>
                            <span className={(isSelected(option.slug) ? 'selected' : '') + ' selectDiv'}></span>

                        </Box>
                    })

                }

                {/* No result found if search query doesnt matches any list options */}
                {
                    optionsList && optionsList.length == 0 && <span className='optionValue noResultFound'>{t(...lan.notFound())}</span>
                }
            </Box>
        </Box>
    )
}

const SearchDropdownForSlug = ({ title, value, list, handleChange, showIcon = false }) => {

    const { t } = useTranslation()

    const [openDropdown, _openDropdown] = useState<boolean>(false)
    const [showValue, _showValue] = useState<string>('')
    const [isSmallDevice] = useDeviceType()

    const ref = useRef()

    // Generating text to show as selected value in field
    useEffect(() => {
        _openDropdown(false)
        if (value && Object.keys(value).length > 0) {
            _showValue(value.name)
        }
    }, [value])


    const isSelected = (slug) => {
        if (value && Object.keys(value).length > 0) {
            if (value.slug == slug) {
                return true
            }
            return false
        }
    }

    return (
        <FieldWrapper>

            {isSmallDevice &&
                <Box className='field flexRow alignItemsCenter' ref={ref}>
                    <span className='fieldHeader'>{title}</span>
                    <Box className='inputBox flexRow justifyContentBetween alignItemsCenter pointer'
                        sx={{
                            px: 2, width: '100%',
                            'svg': {
                                'path': {
                                    stroke: '#666'
                                }
                            }
                        }}
                        onClick={() => _openDropdown((previousState) => !previousState)}>
                        <span className='selectedValue'>
                            {(value == null || value == undefined || Object.keys(value).length == 0) ? t(translations.webTexts.NOT_SELECTED) : showValue}
                        </span>
                        <SvgchevronIcon />
                    </Box>
                    <Drawer
                        anchor='bottom'
                        open={openDropdown}
                        onClose={() => _openDropdown(false)}
                        sx={{
                            '.MuiPaper-root': {
                                height: '70vh',
                                borderRadius: '6% 6% 0 0',
                                overflowY: 'inherit'
                            },
                        }}
                    >
                        <FieldWrapper>
                            <Box className='drawerTitleStyle'>
                                <SvgMobileLeftArrow onClick={() => _openDropdown(false)} />
                                {title}
                                <SvgMobileLeftArrow style={{ opacity: 0 }} />
                            </Box>
                            <ListElement list={list} handleChange={handleChange} isSelected={isSelected} showIcon={showIcon} title={title} />
                        </FieldWrapper >
                    </Drawer >
                </Box >
            }

            {!isSmallDevice &&
                <Autocomplete
                    popupIcon={<SvgchevronIcon />}
                    getOptionLabel={(option) => option.name}
                    id="checkboxes-tags-demo"
                    options={list}
                    open={openDropdown}
                    onOpen={() => _openDropdown(true)}
                    onClose={() => _openDropdown(false)}
                    renderInput={(params) => <TextField {...params} label={title} placeholder={!openDropdown ? t(translations.webTexts.NOT_SELECTED) : null} InputLabelProps={{ shrink: true }} className='autocompleteInputField' />}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} style={{ paddingLeft: '10px' }} className="flexRow justifyContentBetween alignItemsCenter filterDropDownOption pointer" key={option.name + option.id}>
                            <span className='optionValue'>{option.name}</span>
                            <Radio
                                checked={selected}
                            />
                        </li>
                    )}
                    value={Object.keys(value).length > 0 ? value : null}
                    onChange={(event: any, newValue: string | null) => {
                        handleChange(newValue);
                    }}
                    disablePortal
                    disableClearable={true}
                    classes={{
                        paper: 'custom-dropdown',
                    }}
                />
            }
        </FieldWrapper >
    )
}

export default SearchDropdownForSlug